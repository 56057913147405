export const getSocialJoinProject = (
	userShortUrl,
	shortUrl,
	autoexec = true
) => ({
	method: 'get',
	url: `/socialJoin/project/${userShortUrl}/${shortUrl}`,
	autoexec
})

export const getJoinUsersByProjectId = params => ({
	method: 'get',
	url: '/socialJoin/users',
	params,
	autoexec: false
})

export const getJoinStatsBySharerId = params => ({
	method: 'get',
	url: '/socialJoin/shareUserId',
	params,
	autoexec: false
})


export const getTotalJoinStatsByShareUserId = params => ({
	method: 'get',
	url: '/socialJoin/shareUserId/total',
	params,
	autoexec: false
})

