import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearItems,
	insertItemsBefore,
	resetNewsreelPage,
	setPage,
	updateItems
} from '../newsreelPageSlice'
import { getAgregatedRecordsService } from 'services/aggregator.service'
import useLoadingMask from 'hooks/useLoadingMask'
import useAxios from 'hooks/useAxios'

const useNewsreelElements = () => {
	const dispatch = useDispatch()
	const [loadMore, setLoadMore] = useState(true)
	const { activeFilters, items, page, firtsPageReload, search } = useSelector(
		state => state.newsreelPage
	)
	const { showMask, hideMask } = useLoadingMask()
	const { axiosService } = useAxios()

	const afterFetch = (data, error) => {
		if (!error) {
			dispatch(updateItems(data))
			setLoadMore(data?.length > 0)
		}
		hideMask()
	}
	const afterAddNewsreel = (data, error) => {
		if (!error) {
			dispatch(insertItemsBefore(data))
		}
		hideMask()
	}

	const fetchItems = (page, newsreelAdded) => {
		showMask()
		const afterFn = newsreelAdded ? afterAddNewsreel : afterFetch
		axiosService(
			getAgregatedRecordsService({
				activeFilters,
				search,
				page
			}),
			afterFn
		)
	}

	const getItems = clear => {
		const page0 = clear ? 0 : page.value
		fetchItems(page0)
	}

	const onScroll = ev => {
		if (
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 10 &&
			loadMore
		) {
			dispatch(setPage(page.value + 1))
		}
	}

	useEffect(() => {
		setLoadMore(true)
		getItems(true)
		dispatch(clearItems())
		if (page?.value !== 0) {
			dispatch(setPage(0))
		}
	}, [activeFilters, search])

	useEffect(() => {
		if (page?.value !== 0) {
			getItems(false)
		}
	}, [page])

	useEffect(() => {
		if (firtsPageReload !== null) {
			fetchItems(0, true)
		}
	}, [firtsPageReload])

	useEffect(
		() => () => {
			dispatch(resetNewsreelPage())
		},
		[]
	)

	return {
		items,
		onScroll
	}
}

export default useNewsreelElements
