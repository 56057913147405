import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDaoTierLevelingChangeDialogOpen } from '../signInSlice'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getDaoTierById } from 'services/daoTier.service'
import { Mixpanel } from 'services/mixpanel.service'

export const useDaoTierLevelChangeDialog = () => {
	const [tierBenefits, setTierBenefits] = useState([])
	const [nextTierBenefits, setNextTierBenefits] = useState([])

	const { user, daoTierLevelingChangeDialogOpen } = useSelector(
		state => state.signIn
	)
	const dispatch = useDispatch()

	const [{ loading }, getTierBenefits] = useAxiosFetch(
		getDaoTierById(user.daoTierId, false),
		(response, error) => {
			if (!error) {
				setTierBenefits(response?.benefits ?? [])
			}
		}
	)

	const [{ loading: nextLoading }, getNextTierBenefits] = useAxiosFetch(
		getDaoTierById(user.nextTierId, false),
		(response, error) => {
			if (!error) {
				Mixpanel.track('user_dao_tier_lvl_change_evt', {
					distinct_id: localStorage.getItem('id'),
					...response
				})
				setNextTierBenefits(response?.benefits ?? [])
			}
		}
	)

	const handleClose = () => {
		dispatch(setDaoTierLevelingChangeDialogOpen(false))
	}

	const currentTier = _.capitalize(user?.daoTierName)

	useEffect(() => {
		if (daoTierLevelingChangeDialogOpen) {
			if (user?.daoTierId) getTierBenefits()
			if (user?.nextTierId) getNextTierBenefits()
		}
	}, [daoTierLevelingChangeDialogOpen])

	return {
		user,
		loading,
		nextLoading,
		currentTier,
		handleClose,
		tierBenefits,
		nextTierBenefits,
		daoTierLevelingChangeDialogOpen
	}
}
