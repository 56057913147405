import { Box, Button, Grid, Icon, Typography } from '@mui/material'
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { LoadMask, LightTooltip } from 'component/common'
import useSuperfan from './useSuperfan'
import images from 'assets/images'
import styles from './Superfan.module.scss'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { abbreviateNumberFixed, zeroPad } from 'utils/utils'
import { SuperFanDialog } from './superFanDialog'
import SuperFanImages from 'assets/images/superFan1'

const Superfan = () => {
	const {
		user,
		fanLevel,
		showMask,
		fgrEarned,
		openDialog,
		setOpenDialog,
		isAuthenticated,
		disabledClaimBtn,
		handleClaim
	} = useSuperfan()

	const nextLevel = fanLevel?.nextLevel ?? fanLevel
	const levelsNumber = fanLevel?.nextLevel
		? fanLevel?.nextLevel?.id - 1
		: fanLevel?.id
	const numberBeforehash = zeroPad(fanLevel?.userSuperFanRank ?? 0, 3)
	const numberAfterhash = zeroPad(fanLevel?.totalSuperFans ?? 0, 3)
	const superfanImg =
		levelsNumber === 0 ? SuperFanImages.locked.src : fanLevel?.image
	const getLevelMetricInfo = (metricId, info, threshold) => {
		switch (metricId) {
			case 1:
				return {
					message: `${abbreviateNumberFixed(
						info?.count,
						1
					)}/${abbreviateNumberFixed(threshold, 1)} FAN`,
					toolTip: "How many tokens you've staked to this project",
					iconEl: <Icon baseClassName="fa-kit" className="fa-fan-stake" />
				}
			case 4:
				return {
					message: `${Number(info?.count).toFixed(1)}`,
					toolTip: 'Your review for this project',
					iconEl: <Icon baseClassName="fas" className="fa-star-half-stroke" />
				}
			case 3:
				return {
					message: `${info?.count}/${threshold}`,
					toolTip: "How many times you've shared this project",
					iconEl: <Icon baseClassName="fal" className="fa-share" />
				}
			case 2:
				return {
					message: 'Following',
					toolTip:
						info?.percent >= 100
							? "You're following this project"
							: "You're not following this project",
					iconEl:
						info?.percent >= 100 ? <FavoriteIcon /> : <FavoriteBorderIcon />
				}
		}
	}
	const renderLevelMetrics = () => {
		return nextLevel.levelMetrics.map(lm => {
			const { message, toolTip, iconEl } = getLevelMetricInfo(
				lm.metricId,
				lm?.completed,
				lm?.threshold
			)
			const statusClass =
				lm?.completed?.percent >= 100 ? 'complete' : 'incomplete'

			return (
				<Box
					className={`${styles.level_metric} ${styles[statusClass]}`}
					key={lm.id}
				>
					<LightTooltip
						arrow
						placement="top"
						className="tooltip"
						title={toolTip}
					>
						<p>
							{iconEl}
							{message}
						</p>
					</LightTooltip>
				</Box>
			)
		})
	}

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 5,
		width: '100%',
		position: 'relative',
		marginTop: 8,
		marginBottom: 4,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			background: '#000000'
		},
		[`&>.${linearProgressClasses.barColorPrimary}`]: {
			background:nextLevel?.id!==5? `#000 linear-gradient(90deg, ${nextLevel?.barColor} 75%, #01ACBD 80%, #000000);`:`#000 linear-gradient(90deg, ${nextLevel?.barColor}, #01ACBD);`

		}
	}))
	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<Grid item container md={6} className={styles.project_impact}>
					<Grid item md={8}>
						<Box className={styles.top}>
							<Box className={styles.impact_info}>
								<Typography className={styles.impactHeader}>
									Your Impact on this Project
								</Typography>
								<Typography
									component={'h2'}
									className={levelsNumber !== 0 ? styles.ranks : styles.locked}
									onClick={() => setOpenDialog(true)}
								>
									<img className={styles.rankImg} src={superfanImg} />
									<Box className={styles.levelsSection}>
										SuperFan{' '}
										<span>
											{fanLevel?.completed ? fanLevel?.name : 'Locked'}
										</span>
									</Box>
									{fanLevel && (
										<span className={levelsNumber !== 0 ? styles.nextLevel : `${styles.nextLevel} ${styles.graytext}`}>
											#{numberBeforehash} of {numberAfterhash}
										</span>
									)}
								</Typography>
								<SuperFanDialog open={openDialog} handleClose={setOpenDialog} />
								<Box className={styles.progressBox}>
									<BorderLinearProgress
										variant="determinate"
										value={nextLevel?.progress ?? 0}
									/>
									{(fanLevel?.nextLevel && nextLevel?.id!==5) && (
										<span className={styles.levelText}>
											{nextLevel?.name?.toLowerCase()}
										</span>
									)}
								</Box>

								<Box className={styles.superfan}>
									<Box className={styles.level_metrics}>
										{nextLevel?.levelMetrics && renderLevelMetrics()}
									</Box>
								</Box>
								<Box className={styles.benefits}>
									<Typography>
										{fanLevel?.nextLevel && (
											<span>
												<i className="fal fa-info-circle"></i> {nextLevel?.name}
											</span>
										)}
										{fanLevel?.nextLevel && (
											<span>
												{' '}
												benefits include a{' '}
												<strong>
													<span>+{fanLevel?.nextLevel?.bonus}%</span> bonus
												</strong>
											</span>
										)}
										{fanLevel?.id === 1 && (
											<span>
												{' '}
												and a <em>SuperFan Badge</em>.
											</span>
										)}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid id="earnedFGR" item md={4} xs={12}>
						{/* <img src={images.help} /> */}
						<Box className={styles.top}>
							<Box className={styles.fgr_info}>
								<Box>
									<Typography component={'h2'}>Your Earned FGR</Typography>
									<Typography component={'h3'} className={styles.earned_fan}>
										<img src={images.newToken} alt="token" />
										{fgrEarned} FAN
									</Typography>
								</Box>
								<Box>
									<Typography>
										{isAuthenticated ? user?.fgrRate : 0}% FGR
										{fanLevel?.completed && (
											<>
												{' '}
												+ <span>{fanLevel?.bonus}% Bonus</span>
											</>
										)}
									</Typography>
									<Button onClick={handleClaim} disabled={disabledClaimBtn}>
										Claim
									</Button>
								</Box>
							</Box>
						</Box>
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default Superfan
