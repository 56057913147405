export const getVestingStats = () => ({
	method: 'get',
	url: '/vestingWallet/user'
})

export const getVestingShedule = () => ({
	method: 'get',
	url: '/vestingWallet/schedules'
})

