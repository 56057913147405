import { createSlice } from '@reduxjs/toolkit'
import { dashboardItemTypes } from 'component/common'

const initialState = {
	sort: 'trending',
	filter: {
		types: ['project'],
		stages: [],
		genres: []
	},
	daoProposalUpdated: null,
	types: dashboardItemTypes,
	stages: [],
	genres: [],
	items: [],
	page: {
		value: 0,
		reload: true
	}
}

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setSort: (state, { payload }) => {
			state.sort = payload
		},
		clearFilters: state => {
			state.filter = initialState.filter
		},
		removeFilter: (state, { payload }) => {
			const { option, id } = payload
			state.filter[option] = state.filter[option].filter(el => el !== id)
		},
		updateFilters: (state, { payload }) => {
			state.filter = {
				...state.filter,
				...payload
			}
		},
		clearItems: state => {
			state.items = initialState.items
		},
		updateItems: (state, { payload }) => {
			const newItems = [...state.items, ...payload].filter(
				(v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
			)
			state.items = newItems
		},
		setStages: (state, { payload }) => {
			state.stages = payload
		},
		setGenres: (state, { payload }) => {
			state.genres = payload
		},
		setDaoProposalUpdated: (state, { payload }) => {
			state.daoProposalUpdated = payload
		},
		setPage: (state, { payload }) => {
			state.page = { value: payload, reload: !state.page.reload }
		}
	}
})

export const {
	setSort,
	setStages,
	setGenres,
	clearItems,
	clearFilters,
	setDaoProposalUpdated,
	updateFilters,
	removeFilter,
	updateItems,
	setPage
} = dashboardSlice.actions

export default dashboardSlice.reducer
