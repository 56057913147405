import { LightTooltip } from 'component/common'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { BadgePreview } from '../badgePreview'
import { Box, Typography } from '@mui/material'
import styles from './GoScoreBadge.module.scss'

export const GoScoreBadge = ({ image, reward, name, description, percent }) => {
	const [anchorEl, setAnchorEl] = useState(null)

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<li onClick={handlePopoverOpen} className={styles.badge}>
				<LightTooltip title={name}>
					<Box className={styles.badge}>
						<Box className={styles.mask}></Box>
						<img src={image} />
						<Typography>{name}</Typography>
					</Box>
				</LightTooltip>
			</li>
			<BadgePreview
				{...{
					anchorEl,
					handlePopoverClose,
					image,
					reward,
					name,
					description,
					percent
				}}
			/>
		</>
	)
}

GoScoreBadge.propTypes = {
	image: PropTypes.string,
	reward: PropTypes.number,
	name: PropTypes.string,
	description: PropTypes.string,
	percent: PropTypes.number
}
