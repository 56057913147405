import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	project: {},
	page: 1,
	closeModalOpen: false,
	headerImage: undefined,
	poster: undefined,
	errors: {},
	statuses: [],
	types: [],
	genres: []
}

export const projectAddWizardSlice = createSlice({
	name: 'projectAddWizard',
	initialState,
	reducers: {
		resetWizard: () => initialState,
		updateProject: (state, { payload }) => {
			state.project = {
				...state.project,
				...payload
			}
		},
		setPage: (state, { payload }) => {
			state.page = payload
		},
		setStatuses: (state, { payload }) => {
			state.statuses = payload
		},
		setTypes: (state, { payload }) => {
			state.types = payload
		},
		setGenres: (state, { payload }) => {
			state.genres = payload
		},
		setErrors: (state, { payload }) => {
			state.errors = payload
		},
		updateHeaderImage: (state, { payload }) => {
			state.headerImage = payload
		},
		updatePoster: (state, { payload }) => {
			state.poster = payload
		},
		setCloseModalOpen: (state, { payload }) => {
			state.closeModalOpen = payload
		}
	}
})

export const {
	setGenres,
	resetWizard,
	updatePoster,
	updateProject,
	updateHeaderImage,
	setCloseModalOpen,
	setStatuses,
	setErrors,
	setTypes,
	setPage
} = projectAddWizardSlice.actions

export default projectAddWizardSlice.reducer
