import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCommentService } from '@/services/comment.service'
import { Mixpanel } from '@/services/mixpanel.service'
import useNotification from '@/hooks/useNotification'
import { setLoginDialogOpen } from '../../../../../../header/signInSlice'
import useAxios from '../../../../../../../hooks/useAxios'
import { newsreelCommentAddedHook } from '../../../../../../common/newsreelTab/newsreelTabSlice'
import { commentAddedHook } from '../../../../../../newsreelPage/newsreelPageSlice'
import { reviewCommentAddedHook } from '../../../../../../projectDetail/projectDetailSlice'

export const useCommentHandler = (itemId: string, type: string) => {
	const [comment, setComment] = useState('')
	const dispatch = useDispatch()
	const { showNotification } = useNotification()
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const { axiosService } = useAxios()

	const handleComment = (value: string) => {
		setComment(value)
	}

	const handleSubmitComment = async () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}

		const res = axiosService(
			addCommentService({
				[`${type}Id`]: itemId,
				content: comment
			}),
			(data, error) => {
				if (!error) {
					Mixpanel.track('new_comment_add_event', {
						distinct_id: user.id,
						displayName: user.displayName,
						username: user.username,
						...data
					})

					console.log('Comment added', data)
					if (type === 'newsreel')
						dispatch(newsreelCommentAddedHook({ ...data, feedId: itemId }))

					dispatch(commentAddedHook({ ...data, feedId: itemId }))
					dispatch(reviewCommentAddedHook({ ...data, feedId: itemId }))
					clearComment()
					showNotification({ message: 'Comment added successfully' })
				}
			}
		)
	}

	const clearComment = () => {
		setComment('')
	}

	return {
		comment,
		handleComment,
		handleSubmitComment,
		clearComment
	}
}
