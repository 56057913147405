import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './CircularBar.module.scss'

const CircularBar = ({
	value1,
	value2,
	value3,
	value4,
	time,
	background,
	lineWidth,
	height,
	classes,
	children
}) => {
	const [percent, setPercent] = useState(0)
	const circularProgress = useRef(null)

	const speed = (time * 1000) / (value1 + value2 + value3 + value4)
	useEffect(() => {
		if (percent < value1 + value2 + value3 + value4 && percent < 100) {
			setTimeout(() => {
				setPercent(prev => prev + 1)
			}, speed)
		}
	}, [percent, value1, value2, value3, value4])

	useEffect(() => {
		setPercent(0)
	}, [value1, value2, value3, value4])

	const conicGradient = (value1, value2, value3, value4) => {
		const totalValue = [value1, value2, value3, value4].reduce(
			(sum, item) => sum + item,
			0
		)
		const colorArray = [
			{
				color: '#08F996',
				value: (value1 / totalValue) * 100
			},
			{
				color: '#DFD600',
				value: (value2 / totalValue) * 100
			},
			{
				color: '#5BCDEC',
				value: (value3 / totalValue) * 100
			},
			{
				color: '#EC865B',
				value: (value4 / totalValue) * 100
			}
		]
		const filteredColors = colorArray.filter(item => item.value !== 0)
		filteredColors.sort((a, b) => a.value - b.value)
		const stops = []
		let counter = 0
		let prevCounter = 0
		for (let i = 0; i < filteredColors.length; i++) {
			const currentColor = filteredColors[i]
			counter +=
				currentColor.value < 5
					? currentColor.value + (Number(currentColor.value) % 5) > 2
						? Number(currentColor.value) % 5
						: 5
					: currentColor.value
			if (i !== filteredColors.length - 1) {
				stops.push(
					`${currentColor.color} ${i === 0 ? '-3.6deg' : prevCounter + '%'} ${
						counter - 5
					}%`
				)
				prevCounter +=
					currentColor.value < 5
						? currentColor.value + (Number(currentColor.value) % 5) > 2
							? Number(currentColor.value) % 5
							: 5
						: currentColor.value
			}
			if (i === filteredColors.length - 1)
				stops.push(
					`${currentColor.color} ${prevCounter}%  95%, ${filteredColors[0].color} 365.5deg`
				)
		}
		return `
		conic-gradient(
		from 270deg at 50% 50%,${stops.join(', ')})
		`
	}

	const radialGradient = `
  		radial-gradient(50% 50% at 50% 50%, 
  		rgba(255, 255, 255, 0.6) 61%,
   		rgba(255, 255, 255, 0) 82%,
   		rgba(255, 255, 255, 0) 84%,
   		rgba(255, 255, 255, 0.6) 100%)`

	return (
		<div
			className={`${styles.circular_progress} ${classes}`}
			ref={circularProgress}
			style={{
				boxShadow: `0px 0px 30px 0px #5BCDEC`,
				background: `${conicGradient(
					value1,
					value2,
					value3,
					value4
				)}, ${radialGradient}`,
				height,
				width: height
			}}
		>
			<div
				className={styles.circular_progress_before}
				style={{
					height: height - lineWidth,
					width: height - lineWidth,
					background
				}}
			>
				{children}
			</div>
		</div>
	)
}

CircularBar.propTypes = {
	value1: PropTypes.number,
	value2: PropTypes.number,
	value3: PropTypes.number,
	value4: PropTypes.number,
	time: PropTypes.number,
	background: PropTypes.string,
	lineWidth: PropTypes.number,
	height: PropTypes.number,
	classes: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

export default CircularBar
