// components/shared/UserAvatar.tsx
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { User } from '../../types'

interface UserAvatarProps {
	user: User
	size?: 'sm' | 'md' | 'lg'
}

export const UserAvatar = ({ user, size = 'md' }: UserAvatarProps) => {
	const sizeClasses = {
		sm: 'h-8 w-8',
		md: 'h-12 w-12',
		lg: 'h-16 w-16'
	}

	return (
		<Avatar className={`${sizeClasses[size]} bg-black`}>
			<AvatarImage
				src={user.avatar || '/placeholder.svg'}
				alt={user.displayName}
			/>
			<AvatarFallback>{user.displayName[0]}</AvatarFallback>
		</Avatar>
	)
}
