import { useDispatch, useSelector } from 'react-redux'
import {
	reloadDaoTiers,
	updateVerificationEmailData
} from 'component/profile/profileSlice'
import useLoadingMask from 'hooks/useLoadingMask'
import useAxios from 'hooks/useAxios'
import { claimDaoTierBonus, upgradeUserDaoTier } from 'services/daoTier.service'
import useNotification from 'hooks/useNotification'
import useHeader from 'component/header/useHeader'
import {
	setDaoTierLevelingChangeDialogOpen,
	setCreatePlatformWalletDialogOpen,
	setCreateMagicWalletDialogOpen
} from 'component/header/signInSlice'
import useCommon from 'hooks/useCommon'

export const useTierDialog = ({ id, handleClose }) => {
	const dispatch = useDispatch()
	const { checkNcNoMagic } = useCommon()
	const { user } = useSelector(state => state.profile)
	const { user: signInUser } = useSelector(state => state.signIn)
	const {
		relaodUserInfo,
		getUnvisiteNotificationCount,
		loadUserTierInfo,
		showConfetti
	} = useHeader()
	const { axiosService } = useAxios()
	const { showNotification } = useNotification()
	const { showMask, hideMask } = useLoadingMask()
	const isEmailVerified = signInUser?.isEmailVerified === 1

	const isDaoTierConfirmed = Number(signInUser?.daoTierConfirmed) === 1
	const isDaoTierCompleted = Number(signInUser?.daoTierCompleted) === 1
	const upgradeAvailable =
		(!isDaoTierConfirmed && isDaoTierCompleted) ||
		Number(signInUser?.nextTierCompleted) === 1

	const enableUpgradeBtn = upgradeAvailable && id === signInUser?.nextTierId

	const claimTierBenefitsBlockchain = () => {
		axiosService(claimDaoTierBonus(), (_data, error) => {
			if (!error) {
				showNotification({ message: 'Tier bonus completed successfully!' })
			}
			relaodUserInfo()
			getUnvisiteNotificationCount()
		})
	}

	const upgradeTier = () => {
		showMask()
		axiosService(upgradeUserDaoTier(), (_data, error) => {
			if (!error) {
				claimTierBenefitsBlockchain()
				loadUserTierInfo(() => {
					dispatch(setDaoTierLevelingChangeDialogOpen(true))
					showConfetti(5000)
				})
				dispatch(reloadDaoTiers())
				relaodUserInfo()
				getUnvisiteNotificationCount()
			}
			hideMask()
		})
	}

	const openVerifyEmailDialog = () => {
		dispatch(updateVerificationEmailData({ open: true }))
	}

	const handleClickUpgradeDao = () => {
		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			upgradeTier()
			handleClose()
		}
	}

	const handleTriggerClaimMagicWallet = () => {
		dispatch(setCreateMagicWalletDialogOpen(true))
	}

	return {
		user,
		isEmailVerified,
		enableUpgradeBtn,
		handleTriggerClaimMagicWallet,
		openVerifyEmailDialog,
		handleClickUpgradeDao
	}
}
