import _ from 'lodash'
import { Box, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import { GoScore } from '../goScore'
import styles from './TopGenreProject.module.scss'

const TopGenreProject = ({ project }) => {
	const {
		script,
		lookbook,
		treatment,
		goScoreStage,
		socialMediaShares,
		likes,
		stakesTotal,
		reviewsAvg
	} = project

	return (
		<Box className={styles.top_genre_project}>
			<Box className={styles.top}>
				<Box className={styles.goscore}>
					<GoScore showText={false} projectId={project?.id} />
				</Box>
				<a href={`/project/${project.slug}`} target="_blank" rel="noreferrer">
					{project.title}
				</a>
				<ul className={styles.stats}>
					<li>
						<Icon baseClassName="fas" className="fa-star-half-stroke" />
						{reviewsAvg}
					</li>
					<li>
						<Icon baseClassName="fal" className="fa-heart" />
						{likes}
					</li>
					<li>
						<Icon baseClassName="fa-kit" className="fa-fan-stake" />
						{stakesTotal}
					</li>
					<li>
						<Icon baseClassName="fal" className="fa-share" />
						{socialMediaShares ?? 0}
					</li>
				</ul>
			</Box>
			<Box className={styles.footer}>
				<ul className={styles.categories}>
					<li key="goscore" className={`${styles[goScoreStage?.label]}`}>
						{goScoreStage?.name}
					</li>
					<li
						key="lookbook"
						className={_.isNil(lookbook) ? styles.disabled : ''}
					>
						<a href={lookbook} target="_blank" rel="noreferrer">
							<Icon baseClassName="fal" className="fa-file-pdf" />
							Lookbook
						</a>
					</li>
					<li
						key="treatment"
						className={_.isNil(treatment) ? styles.disabled : ''}
					>
						<a href={treatment} target="_blank" rel="noreferrer">
							<Icon baseClassName="fal" className="fa-file-pdf" />
							Treatment
						</a>
					</li>
					<li
						key="script"
						// className={_.isNil(script) ? styles.disabled : ''}
						className={styles.disabled}
					>
						<a href={script} target="_blank" rel="noreferrer" disabled>
							<Icon baseClassName="fal" className="fa-file-pdf" />
							Script
						</a>
					</li>
				</ul>
			</Box>
		</Box>
	)
}

TopGenreProject.propTypes = {
	project: PropTypes.object
}

export default TopGenreProject
