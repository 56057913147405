import {
	Grid,
	Container,
	Button,
	Typography,
	Checkbox,
	FormControlLabel
} from '@mui/material'
import {
	AutocompleteBox,
	ChipSelectBox,
	LoadMask,
	SelectBox,
	TextFieldControl
} from 'component/common'
import useSettings from './useSettings'
import styles from './Settings.module.scss'

const Settings = () => {
	const {
		user,
		genres,
		showMask,
		countries,
		userGenres,
		projectTypes,
		digestOptions,
		triggeredOptions,
		triggeredOptionsFilter,
		setTriggeredOptionsFilter,
		isTriggeredOptionChecked,
		isDigestOptionChecked,
		checkTriggeredOption,
		checkDigestOption,
		handleChange,
		handleCancel,
		handleSave,
		walletAddress,
		isNonCustodial
	} = useSettings()

	const renderDigestOptions = () =>
		digestOptions.map(el => {
			return (
				<FormControlLabel
					key={`digest-option-${el?.id}`}
					className="custom-checkBox"
					label={el?.name}
					control={
						<Checkbox
							checked={isDigestOptionChecked(el?.id)}
							onChange={event => {
								checkDigestOption(el?.id, event.target.checked)
							}}
						/>
					}
				/>
			)
		})

	const renderTriggeredOptions = () =>
		triggeredOptions
			.filter(el =>
				el?.name
					.toLocaleLowerCase()
					.includes(triggeredOptionsFilter.toLocaleLowerCase())
			)
			.map(el => {
				return (
					<FormControlLabel
						key={`trigger-option-${el?.id}`}
						className="custom-checkBox"
						label={el?.name}
						control={
							<Checkbox
								checked={isTriggeredOptionChecked(el?.id)}
								onChange={event => {
									checkTriggeredOption(el?.id, event.target.checked)
								}}
							/>
						}
					/>
				)
			})

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<Container
					disableGutters
					maxWidth="md"
					className={styles.settings_section}
				>
					<Grid container>
						<Grid container spacing={4}>
							<Grid item xs={12} md={8}>
								<h4>Adjust Your Settings</h4>
								<Typography>
									Update your password, set your favorite genres and choose the
									email notifications you receive.
								</Typography>
							</Grid>
							<Grid item xs={12} md={4} className={styles.controls}>
								<Button className="yellow-bg-button" onClick={handleSave}>
									Save
								</Button>
								<Button className={styles.cancel_btn} onClick={handleCancel}>
									Exit
								</Button>
							</Grid>
						</Grid>
						{!isNonCustodial && (
							<>
								<Grid item container md={12} spacing={4}>
									<Grid item xs={12} md={12}>
										<Typography component={'h1'}>Wallet Settings</Typography>
										<Typography>
											The Film.io platform uses a custodial wallet to provide a
											better user experience and to cover gas fees. Your
											custodial wallet address is shown below.
										</Typography>
									</Grid>
									<Grid item xs={12} md={12}>
										<TextFieldControl
											label="Custodial Wallet Address"
											placeholder=""
											variant={'blue'}
											value={walletAddress}
											disabled
										/>
									</Grid>
								</Grid>
							</>
						)}
						<Grid item container md={12} spacing={4}>
							<Grid item xs={12} md={10}>
								<Typography component={'h1'}>General Settings</Typography>
							</Grid>
							{/* <Grid item xs={12} md={6}>
								<TextFieldControl
									label="Choose a new password"
									placeholder="Enter your current password here."
									variant={'blue'}
									value={password?.current || ''}
									onChange={e => {
										handlePasswdChange({ current: e.target.value })
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextFieldControl
									label="Repeat New Password"
									placeholder="Re-enter your new password here (must match)."
									variant={'blue'}
									value={password?.repeat || ''}
									onChange={e => {
										handlePasswdChange({ repeat: e.target.value })
									}}
								/>
							</Grid> */}
							<Grid item xs={12} md={6}>
								<ChipSelectBox
									{...{
										label: 'Favorite Genres',
										values: user?.favGenres || [],
										variant: 'blue',
										options: genres || [],
										setValues: values => {
											handleChange({ favGenres: values })
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<ChipSelectBox
									{...{
										label: 'Preferred Project Type',
										values: user?.favProjectTypes || [],
										variant: 'blue',
										options: projectTypes || [],
										setValues: values => {
											handleChange({ favProjectTypes: values })
										}
									}}
								/>
							</Grid>
							{/* <Grid item xs={12} md={6}>
								<SelectBox
									{...{
										options: countries || [],
										value: user?.countryId || '',
										variant: 'blue',
										placeholder: 'Choose your Country',
										label: 'Country',
										setValue: value => {
											handleChange({
												countryId: value
											})
										}
									}}
								/>
							</Grid> */}
							<Grid item xs={12} md={6}>
								<AutocompleteBox
									{...{
										label: 'Country',
										variant: 'blue',
										placeholder: 'Choose your Country',
										options: countries || [],
										setValue: countryId => {
											handleChange({ countryId })
										},
										value: user?.countryId
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<SelectBox
									{...{
										options: userGenres || [],
										value: user?.genderId,
										variant: 'blue',
										placeholder: 'Your gender',
										label: 'Your gender',
										setValue: genderId => {
											handleChange({ genderId })
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} md={10}>
								<Typography component={'h1'}>Notification Settings</Typography>
								<Typography>
									We send email notifications when certain actions happen on the
									platform, as well as a digests that captures your activity on
									the platform as a whole. You can turnd them on or off below.
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography component={'h2'}>
									Choose your digest notifications
								</Typography>
								{renderDigestOptions()}
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography component={'h2'} className={styles.filter}>
									Choose your triggered notifications
								</Typography>

								<TextFieldControl
									placeholder="Filter notifications"
									variant={'blue'}
									value={triggeredOptionsFilter}
									onChange={e => {
										setTriggeredOptionsFilter(e.target.value)
									}}
								/>
								<Grid className={styles.triggeredOpts}>
									{renderTriggeredOptions()}
								</Grid>
							</Grid>
						</Grid>
						{/* <Grid item xs={12} md={5}>
							<div className="control-field mb-0">
								<p>Social Integrations</p>
							</div>
							<div className="social-integration-blue">
								<span>
									<i className="fa-brands fa-facebook"></i>
									Login with Facebook.
								</span>
								<a href="/#">Connect</a>
							</div>

							<div className="social-integration-blue">
								<span>
									<i className="fa-brands fa-twitter"></i>
									Login with Twitter.
								</span>
								<a href="/#">Connect</a>
							</div>
						</Grid> */}
						<Grid item xs={12} md={12} className={styles.controls}>
							<Button className="yellow-bg-button" onClick={handleSave}>
								Save
							</Button>
							<Button className={styles.cancel_btn} onClick={handleCancel}>
								Exit
							</Button>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	)
}

export default Settings
