import { useDispatch, useSelector } from 'react-redux'
import {
	setDaoTierLevelingDialogOpen,
	setImportFanTokenDialogOpen,
	updateSignInUser
} from '../signInSlice'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getDaoTierById } from 'services/daoTier.service'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useHeader from '../useHeader'
import {
	getWelcomeMessageService,
	updateUserFields
} from 'services/profile.service'
import { Mixpanel } from 'services/mixpanel.service'
import useAxios from '../../../hooks/useAxios'
import _ from 'lodash'

export const useDaoTierLevelingDialog = () => {
	const [message, setMessage] = useState('')
	const [data, setData] = useState({
		benefits: [],
		todo: [],
		name: ''
	})
	const { daoTierLevelingDialogOpen, user } = useSelector(state => state.signIn)
	const { showConfetti } = useHeader()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { axiosService } = useAxios()

	const [, agreedDocuments] = useAxiosFetch(
		updateUserFields({ isDaoTermsAgreed: true, id: user.id }),
		(data, error) => {
			if (!error) {
				localStorage.setItem('isDaoTermsAgreed', data?.isDaoTermsAgreed)
				dispatch(updateSignInUser({ isDaoTermsAgreed: data?.isDaoTermsAgreed }))
				if (user?.isTokenSale === 0) {
					navigate('/loading')
				}
			}
		}
	)

	const fetchTodoBenefits = nextTierId => {
		axiosService(getDaoTierById(nextTierId, false), (response, error) => {
			if (!error) {
				Mixpanel.track('user_dao_tier_leveling_evt', {
					distinct_id: localStorage.getItem('id'),
					response,
					displayName: localStorage.getItem('displayName'),
					id: localStorage.getItem('id'),
					username: localStorage.getItem('username')
				})
				const todo = response?.metrics?.map(metric => {
					let description =
						metric.id === 1
							? `${metric.name} to a project`
							: `${metric.name} a project`

					const isEmailVerifiedMetric = metric.id === 6
					if (isEmailVerifiedMetric) description = metric?.name
					const thresholdProgress = isEmailVerifiedMetric
						? user?.isEmailVerified
						: 0

					return {
						description,
						id: metric.id,
						threshold: metric.threshold,
						thresholdProgress
					}
				})
				setData({
					todo,
					benefits: response?.benefits ?? [],
					name: response.name
				})
			}
		})
	}

	const [{ loadingMessage }, getWelcomeMessage] = useAxiosFetch(
		getWelcomeMessageService(),
		(response, error) => {
			if (!error) {
				setMessage(response?.message)
			}
		}
	)

	const handleClose = () => {
		agreedDocuments()
		dispatch(setDaoTierLevelingDialogOpen(false))
		dispatch(setImportFanTokenDialogOpen(true))
	}

	const handleGoToExplore = () => {
		handleClose()
		navigate('/explore')
	}

	useEffect(() => {
		if (daoTierLevelingDialogOpen) {
			showConfetti(5000)
			fetchTodoBenefits(user.nextTierId)
			getWelcomeMessage()
		}
	}, [daoTierLevelingDialogOpen])

	useEffect(() => {
		if (user?.isDaoTermsAgreed === 0 && !_.isNil(user.nextTierId)) {
			navigate('/explore')
			dispatch(setDaoTierLevelingDialogOpen(true))
		}
	}, [user])

	return {
		user,
		data,
		message,
		loadingMessage,
		daoTierLevelingDialogOpen,
		handleGoToExplore,
		handleClose
	}
}
