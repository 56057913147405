import { useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { createProject } from 'services/project.service'
import { useDispatch, useSelector } from 'react-redux'
import { setAddProjectDialogOpen } from '../signInSlice'
import { useNavigate } from 'react-router-dom'
import { getGenresService } from 'services/genres.service'
import { getProjectTypes } from 'services/seed.service'

export const useAddProjectDialog = () => {
	const initialData = {
		genres: [],
		typeId: '',
		title: ''
	}
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { addProjectDialogOpen: open } = useSelector(state => state.signIn)
	const [isAgree, setIsAgree] = useState(false)
	const [projectTypes, setProjectTypes] = useState([])
	const [genres, setGenres] = useState([])
	const [data, setData] = useState(initialData)

	const isValid =
		isAgree &&
		data?.genres?.length > 0 &&
		data?.title.trim() !== '' &&
		data?.typeId !== ''

	const [{ loading }, addProject] = useAxiosFetch(
		createProject(data),
		(data, error) => {
			if (!error) {
				handleClose(`/project/${data.id}`)
			}
		}
	)

	const [{ loading: loadingGenres }] = useAxiosFetch(
		getGenresService(),
		(data, error) => {
			if (!error) setGenres(data)
		}
	)

	const [{ loading: loadingProjectTypes }] = useAxiosFetch(
		{ ...getProjectTypes(), autoexec: true },
		(data, error) => {
			if (!error) setProjectTypes(data)
		}
	)

	const handleSubmit = async event => {
		event.preventDefault()
		addProject()
	}

	const handleChange = payload => {
		setData({
			...data,
			...payload
		})
	}

	const handleClose = redirect => {
		setData(initialData)
		setIsAgree(false)
		dispatch(setAddProjectDialogOpen(false))

		if (redirect) {
			// TODO take a look for a better solution
			setTimeout(() => {
				return navigate(redirect)
			}, 0)
		}
	}

	return {
		open,
		data,
		genres,
		isValid,
		isAgree,
		loading,
		projectTypes,
		loadingGenres,
		loadingProjectTypes,
		handleSubmit,
		handleChange,
		handleClose,
		setIsAgree
	}
}
