import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSocialShareLink } from 'utils/socialShare.util'
import productDetailImages from 'assets/images/product-detail'
import useNotification from 'hooks/useNotification'

export const useShareForm = ({closeMenu,project}) => {
	const [open, setOpen] = useState(false)
	const [socialNetwork, setSocialNetwork] = useState(null)
	const [linkCopied] = useState('')
	const { user } = useSelector(state => state.signIn)
	const [message, setMessage] = useState('')
	const { showNotification } = useNotification()

	const baseUrl = window.location.origin
	const link = encodeURI(
		`${baseUrl}/join/${socialNetwork}/${user?.shortUrl}/${project?.shortUrl}?utm_source=prd&utm_campaign=share&utm_medium=proj`
	)
	const limitChars = 280 - (project?.title?.length ?? 0) - link?.length - 3

	const handleCopy = () => {
		if (socialNetwork !== 'custom') {
			navigator.clipboard.writeText(message).then(() => {
				const win = window.open(shareLink, '_blank', 'noreferrer')

				if (win != null) {
					win.focus()
				}
			})
		} else {
			const shareUrl = `${baseUrl}/join/mail/${user?.shortUrl}/${project?.shortUrl}?utm_source=prd&utm_campaign=share&utm_medium=proj`

			navigator.clipboard.writeText(message + ' ' + shareUrl).then(() => {
				showNotification({
					message:
						'Your referral link and message have been copied to your clipboard.'
				})
			})

			setOpen(false)
		}
	}

	const handleCopyMobile = () => {
		if (socialNetwork !== 'custom') {
			navigator.clipboard.writeText(message).then(() => {
				showNotification({
					message: 'Your message has been copied to your clipboard.'
				})
			})
		} else {
			const shareUrl = `${baseUrl}/join/mail/${user?.shortUrl}/${project?.shortUrl}?utm_source=prd&utm_campaign=share&utm_medium=proj`

			navigator.clipboard.writeText(message + ' ' + shareUrl).then(() => {
				showNotification({
					message:
						'Your referral link and message have been copied to your clipboard.'
				})
			})

			setOpen(false)
		}
	}

	const handleClose = () => {
		setOpen(false)
	}

	const openDialog = (sn) => {
		setOpen(true) 
		setSocialNetwork(sn)
		closeMenu()
	}

	const handleCopyLink = () => {
		const shareUrl = `${baseUrl}/join/mail/${user?.shortUrl}/${project?.shortUrl}?utm_source=prd&utm_campaign=share&utm_medium=proj`
		navigator.clipboard.writeText(shareUrl).then(() => {
			showNotification({
				message: 'Your referral link has been copied to your clipboard.'
			})
		})
	}

	useEffect(() => {
		setMessage(
			`I just discovered "${project?.title}" on Filmio, where YOU can help greenlight the future of entertainment! #filmio`
		)
	}, [project])

	const shareLink = getSocialShareLink({ socialNetwork, link, message })
	const projectCover =
		project?.coverImg || productDetailImages.defaultProjectCover

	return {
		open,
		link,
		message,
		shareLink,
		linkCopied,
		limitChars,
		openDialog,
		setMessage,
		handleClose,
		handleCopy,
		handleCopyMobile,
		projectCover,
		handleCopyLink
	}
}
