import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	user: {
		projects: []
	},
	userId: null,
	tabIndex: 0,
	walletTabIndex: 0,
	updateDaoTiers: false,
	magicEmailUpdateData: {
		open: false,
		email: '',
		error: '',
		isInProgress: false
	},
	verificationEmailData: {
		open: false,
		email: '',
		page: 1
	},
	whitelistWalletDialogData: {
		open: false
	},
	whitelistTransferDialogOpen: false
}

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		reset: () => initialState,
		updateUser: (state, { payload }) => {
			state.user = {
				...state.user,
				...payload
			}
		},
		updateMagicEmailUpdateDialogData: (state, { payload }) => {
			state.magicEmailUpdateData = {
				...state.magicEmailUpdateData,
				...payload
			}
		},
		closeMagicEmailUpdateDialog: (state, { payload }) => {
			state.magicEmailUpdateData = initialState.magicEmailUpdateData
		},
		updateVerificationEmailData: (state, { payload }) => {
			state.verificationEmailData = {
				...state.verificationEmailData,
				...payload
			}
		},
		updateWhitelistWalletDialogData: (state, { payload }) => {
			state.whitelistWalletDialogData = {
				...state.whitelistWalletDialogData,
				...payload
			}
		},
		closeVerificationEmailDialog: (state, { payload }) => {
			state.verificationEmailData = initialState.verificationEmailData
		},
		closeWhitelistWalletDialog: (state, { payload }) => {
			state.whitelistWalletDialogData = initialState.whitelistWalletDialogData
		},
		setTabIndex: (state, { payload }) => {
			state.tabIndex = payload
		},
		setWhitelistTransferDialogOpen: (state, { payload }) => {
			state.whitelistTransferDialogOpen = payload
		},
		setWhitelistTransferDialogInOpen: (state, { payload }) => {
			state.whitelistTransferDialogInOpen = payload
		},
		setWalletTabIndex: (state, { payload }) => {
			state.walletTabIndex = payload
		},
		reloadDaoTiers: state => {
			state.updateDaoTiers = !state.updateDaoTiers
		},
		setUserId: (state, { payload }) => {
			state.userId = payload
		}
	}
})

export const {
	reset,
	updateUser,
	setTabIndex,
	setWalletTabIndex,
	closeWhitelistWalletDialog,
	closeVerificationEmailDialog,
	setWhitelistTransferDialogOpen,
	setWhitelistTransferDialogInOpen,
	updateMagicEmailUpdateDialogData,
	closeMagicEmailUpdateDialog,
	updateWhitelistWalletDialogData,
	updateVerificationEmailData,
	reloadDaoTiers,
	setUserId
} = profileSlice.actions

export default profileSlice.reducer
