import { Grid, Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginDialogOpen } from 'component/header/signInSlice'
import styles from './TokenSection.module.scss'
import token from 'assets/images/homepage/token-utility.webp'

export const TokenSection = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { isAuthenticated } = useSelector(state => state.signIn)
	
	const handleGoToExplore = () => {
		if (!isAuthenticated) dispatch(setLoginDialogOpen(true))
		else {
			navigate('/explore')
		}
	}
	
	return (
		<>
		<Grid>
		<Box className={styles.token}>
			<Grid container className={styles.token_desc}>
				<Box className={styles.explanation}>
					<h2>Utility, Creativity, Governance</h2>
					<Typography>
					Film.io&lsquo;s FAN token is the key to <strong>unlocking</strong> a world of entertainment possibilities. As a holder of this powerful utility token, you gain access to exclusive <strong>voting rights</strong>, allowing you to influence which projects get greenlit and <strong>champion</strong> your favorite films and creators.
					</Typography>
					<Typography>
					The FAN token also enables you to participate in <strong>rewards</strong> programs, determines your <strong>SuperFAN place</strong>, earn <strong>unique perks</strong>, and <strong>engage</strong> directly with the entertainment community. With Film.io&lsquo;s FAN token, you&lsquo;re not just a fan you become an active <strong>participant</strong> in shaping the future of film and entertainment.
					</Typography>
					<button
						onClick={handleGoToExplore}
					>Login / Signup</button>
				</Box>
				<Box className={styles.hero}>
					<img src={token.src} />
				</Box>
			</Grid>
		</Box>
		</Grid>
		</>
	)
}

export default TokenSection