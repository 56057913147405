import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import images from 'assets/images/product-detail'
import styles from './TileItem.module.scss'

const TileItem = ({ content, name, poster, link, tileTypeId, project }) => {
	const typeIdMap = {
		1: {
			class: 'project',
			buttonLabel: 'Read More'
		},
		2: {
			class: 'link',
			buttonLabel: 'View'
		},
		3: {
			class: 'update',
			buttonLabel: ''
		}
	}
	return (
		<div
			id={tileTypeId}
			className={`${styles.list_item} ${styles.otherProduct} ${
				styles[typeIdMap[tileTypeId].class]
			}`}
		>
			<a href={link} target="_blank" rel="noreferrer">
				<img
					loading="lazy"
					className={styles.artwork}
					src={project?.poster ?? poster ?? images?.posterNotAvailable}
					alt=""
				/>
			</a>
			<div className={styles.other_product_content}>
				<div className={styles.projectInfo}>
					<a
						href={link}
						className={styles.sub_content}
						target="_blank"
						rel="noreferrer"
					>
						{' '}
						{name}
					</a>
					{project?.title ? (
						<div className={styles.projectName}>{project?.title}</div>
					) : (
						''
					)}
					<div className={styles.description}> {content} </div>
					{link || project?.slug ? (
						<Button
							component={'a'}
							href={project?.slug ? `/project/${project?.slug}` : link}
							color="primary"
							variant="contained"
							className={styles.view_link}
							target="_blank"
							rel="noreferrer"
						>
							{typeIdMap[tileTypeId].buttonLabel}
						</Button>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	)
}

TileItem.propTypes = {
	content: PropTypes.string,
	name: PropTypes.string,
	poster: PropTypes.string,
	link: PropTypes.string,
	tileTypeId: PropTypes.number,
	project: PropTypes.object
}

export default TileItem
