import comments from './comments.png'
import ratings from './ratings.png'
import shares from './shares.png'
import stakers from './stakers.png'
import tokens from './tokens.png'
import views from './views.png'
import commentsV2 from './comments_v2.png'
import ratingsV2 from './ratings_v2.svg'
import sharesV2 from './shares_v2.png'
import stakersV2 from './stakers_v2.png'
import tokensV2 from './tokens_v2.png'
import viewsV2 from './views_v2.png'
const images = {
	comments: comments.src,
	ratings: ratings.src,
	shares: shares.src,
	stakers: stakers.src,
	tokens: tokens.src,
	views: views.src,
	commentsV2: commentsV2.src,
	ratingsV2: ratingsV2.src,
	sharesV2: sharesV2.src,
	stakersV2: stakersV2.src,
	tokensV2: tokensV2.src,
	viewsV2: viewsV2.src
}

export default images
