import { Button, Icon, IconButton, InputAdornment } from '@mui/material'
import { PasswordStrenght, TextFieldControl } from 'component/common'
import { Link } from 'react-router-dom'
import styles from './ResetPassword.module.scss'
import useResetPassword from './useResetPassword'

const ResetPassword = () => {
	const {
		data,
		title,
		isValid,
		showPassword,
		handleClickShowPassword,
		handleMouseDownPassword,
		handleClickSave,
		handleChange
	} = useResetPassword()

	return (
		<div className={styles.reset_password_section}>
			<div className={styles.reset_form}>
				<h2>{title}</h2>
				{isValid ? (
					<form className={styles.reset_password_form}>
						<label>New password</label>
						<TextFieldControl
							type={!showPassword ? 'password' : 'text'}
							hiddenLabel
							fullWidth
							autoFocus
							name="password"
							placeholder="Password"
							value={data.password}
							onChange={e => {
								handleChange({ password: e.target.value })
							}}
							InputProps={{
								disableUnderline: true,
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											className={showPassword ? styles.hide : ''}
										>
											<Icon baseClassName="fas" className="fa-eye" />
										</IconButton>
										<IconButton
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											className={!showPassword ? styles.hide : ''}
										>
											<Icon baseClassName="fas" className="fa-eye-slash" />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
						<label>Repeat password</label>
						<TextFieldControl
							FormHelperTextProps={{
								component: 'div',
								classes: { root: styles.form_helper }
							}}
							helperText={<PasswordStrenght password={data.password} />}
							type={!showPassword ? 'password' : 'text'}
							hiddenLabel
							fullWidth
							name="repeat"
							placeholder="Repeat password"
							value={data.repeat}
							onChange={e => {
								handleChange({ repeat: e.target.value })
							}}
							InputProps={{
								disableUnderline: true,
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											className={showPassword ? styles.hide : ''}
										>
											<Icon baseClassName="fas" className="fa-eye" />
										</IconButton>
										<IconButton
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											className={!showPassword ? styles.hide : ''}
										>
											<Icon baseClassName="fas" className="fa-eye-slash" />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>

						<Button
							className={styles.yellow_bg_button_reset}
							onClick={handleClickSave}
						>
							Reset Password
						</Button>
					</form>
				) : (
					<div>
						<h5>This link is expired</h5>
						<Link to="/forgotPassword">Resend link</Link>
					</div>
				)}
			</div>
		</div>
	)
}

export default ResetPassword
