import { IconButton, Icon, Button, Grid, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { TextArea } from 'component/common/textArea'
import { LoadMask } from 'component/common/loadMask'
import useAddNewsreel from './useAddNewsreel'
import styles from './AddNewsreelForm.module.scss'

const renderThumbnails = (files, removeImg) => {
	const urlList = files.map(file => URL.createObjectURL(file))
	return urlList.map((src, i) => (
		<Box className={styles.thumbnail} key={`thumbnail-${i}`}>
			<IconButton
				size="small"
				onClick={() => {
					removeImg(i)
				}}
			>
				<Icon baseClassName="fas" className="fa-light fa-xmark" />
			</IconButton>
			<img src={src} alt="" />
		</Box>
	))
}

const Input = styled('input')({
	display: 'none'
})

const AddNewsreelForm = ({ feature }) => {
	const {
		removeImg,
		handleContentChange,
		handleAddNewsreel,
		handleUploadClick,
		clearForm,
		loading,
		content,
		errors,
		media
	} = useAddNewsreel(feature)

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<Grid item md={12} xs={12}>
					<Box className="newsreel-section projectinfo">
						<h5>New Post</h5>
						<Box className="post-section">
							<TextArea
								error={errors?.content}
								aria-label="What say you?"
								placeholder="What say you?"
								value={content}
								onChange={handleContentChange}
							/>
							<Box className={styles.thumbnails}>
								{renderThumbnails(media, removeImg)}
							</Box>
							<Box className="post-group">
								<label htmlFor="icon-button-file">
									<Input
										accept="image/*"
										id="icon-button-file"
										type="file"
										onChange={handleUploadClick}
										multiple
										autoFocus
									/>
									<IconButton aria-label="upload picture" component="span">
										<Icon baseClassName="fas" className="fa-light fa-images" />
									</IconButton>
								</label>
								<Box>
									<Button
										variant="text"
										className="cancel-btn"
										size="small"
										onClick={clearForm}
									>
										Clear
									</Button>
									<Button
										onClick={handleAddNewsreel}
										className="yellow-bg-button"
										size="small"
									>
										Post
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				</Grid>
			)}
		</>
	)
}

AddNewsreelForm.propTypes = {
	feature: PropTypes.string
}

export default AddNewsreelForm
