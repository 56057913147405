import { Button, Box, Icon, IconButton, Typography } from '@mui/material'
import { TextFieldControl, CustomButton, CustomDialog } from 'component/common'
import useLoginDialog from './useLoginDialog'
import styles from './LoginDialog.module.scss'
import metamaskLogo from 'assets/images/metamask-color.png'
import { isMetamask, isMobile, isSafariMacOs } from 'utils/utils'
import { CaptchaCheck } from 'component/common/captchaCheck'

export const LoginDialog = () => {
	const {
		open,
		email,
		errors,
		password,
		twitterLink,
		facebookLink,
		captchaToken,
		hidePassword,
		passwordtoggle,
		loginDialogMessage,
		handleOpenAccountDialog,
		passwordChangeHandler,
		handleForgotPassword,
		emailChangeHandler,
		handleKeypress,
		handleNcLogin,
		handleMMLogin,
		passwordShow,
		handleSubmit,
		handleClose
	} = useLoginDialog()

	const currentURL = window.location.href
	const metaMaskButtonUrl = currentURL
		.replace('https://', '')
		.replace('http://', '')

	const openSocialLoginWindow = link => () => {
		window.open(
			link,
			'Social Login',
			'height=680,width=1032,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no'
		)
	}

	return (
		<CustomDialog {...{ open, handleClose }}>
			<Box className={styles.header}>
				Welcome to Filmio
				<span>Join us and claim your free $FAN tokens!</span>
				{loginDialogMessage && <Typography>{loginDialogMessage}</Typography>}
			</Box>
			<Box className={styles.content}>
				<Box className={styles.fields}>
					<TextFieldControl
						error={errors.email}
						hiddenLabel
						fullWidth
						name="username"
						placeholder="Email"
						value={email}
						onKeyPress={handleKeypress}
						onChange={emailChangeHandler}
						className={styles.emailField}
					/>
					{!hidePassword && (
						<Box className={styles.password_container}>
							<TextFieldControl
								error={errors.password}
								helperText={
									<Button
										className={styles.passwd_helper_text}
										onClick={handleForgotPassword}
									>
										Forgot Password?
									</Button>
								}
								type={passwordtoggle ? 'password' : 'text'}
								hiddenLabel
								fullWidth
								name="password"
								placeholder="Password"
								value={password}
								onKeyPress={handleKeypress}
								onChange={passwordChangeHandler}
							/>

							<Button variant="text" onClick={passwordShow}>
								{passwordtoggle ? 'Show' : 'Hide'}
							</Button>
						</Box>
					)}
				</Box>
				<Box className={styles.recaptcha}>
					<CaptchaCheck />
				</Box>
				<CustomButton
					customStyle={'blue_wo_border'}
					onClick={handleSubmit}
					disabled={captchaToken === null}
				>
					Login/Signup
				</CustomButton>

				<Button onClick={handleClose} className={styles.back}>
					Cancel
				</Button>
			</Box>
			<Box className={styles.otherLogin}>
				{!isSafariMacOs() && (
					<>
						{isMetamask() || !isMobile() ? (
							<Button
								className={styles.wallet_mobile_cnct}
								onClick={handleMMLogin}
								disabled={captchaToken === null}
							>
								<img src={metamaskLogo.src} alt="MetaMask" />
								MetaMask
							</Button>
						) : (
							<Button
								className={styles.wallet_mobile_cnct}
								disabled={captchaToken === null}
								href={`https://metamask.app.link/dapp/${metaMaskButtonUrl}`}
							>
								<img src={metamaskLogo.src} alt="MetaMask" />
								<Typography>MetaMask</Typography>
							</Button>
						)}
					</>
				)}
				<Button
					className={styles.wallet_cnct}
					onClick={handleNcLogin}
					startIcon={<Icon baseClassName="fas" className="fa-wallet" />}
					disabled={captchaToken === null}
				>
					Wallet Connect
				</Button>
				<Box
					className={styles.twitterCnct}
					onClick={openSocialLoginWindow(twitterLink)}
				>
					<IconButton size="small" className={styles.walletBtn}>
						<Box className={styles.backDrop}>
							<Icon baseClassName="fab" className="fa-x-twitter" />
						</Box>
					</IconButton>
					<Typography>X</Typography>
				</Box>
				<Box
					className={styles.facebookCnct}
					onClick={openSocialLoginWindow(facebookLink)}
				>
					<IconButton size="small" className={styles.walletBtn}>
						<Box className={styles.backDrop}>
							<Icon baseClassName="fab" className="fa-facebook-f" />
						</Box>
					</IconButton>
					<Typography>Facebook</Typography>
				</Box>
			</Box>
			<Box className={styles.actions} style={{ display: 'none' }}>
				<Typography>
					Don&apos;t have an account?{' '}
					<span onClick={handleOpenAccountDialog}>Create One Here</span>
				</Typography>
			</Box>
			<Box className={styles.agree}>
				<p>
					By signing up, you agree to the{' '}
					<a
						href="https://film.io/constitution"
						target="_blank"
						rel="noreferrer"
					>
						DAO Constitution
					</a>
					{', '}
					<a
						href="https://film.io/token-terms-conditions/"
						target="_blank"
						rel="noreferrer"
					>
						Token Terms & Conditions
					</a>
					{' and '}
					<a
						href="https://film.io/terms-of-use/"
						target="_blank"
						rel="noreferrer"
					>
						Terms of Use
					</a>
				</p>
			</Box>
		</CustomDialog>
	)
}
