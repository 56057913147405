import { Grid, Typography } from '@mui/material'
import {
	ChipSelectBox,
	SelectBox,
	TextArea,
	TextFieldControl
} from 'component/common'
import { CropPoster } from 'component/common/cropPoster'
import { DropZone } from 'component/common/dropZone'
import HtmlEditor from 'component/common/htmlEditor/HtmlEditor'
import { createRef, useEffect } from 'react'
import { getVideoInfoFromUrl } from 'utils/video.utils'
import { CropImage } from '../../common/cropImage'
import useProjectAddWizard from '../useProjectAddWizard'
import styles from './SubmitPage.module.scss'

const SubmitPage = () => {
	const {
		types,
		poster,
		errors,
		genres,
		project,
		statuses,
		scrolled,
		headerImage,
		setHeaderImage,
		setVideoInfo,
		updateErrors,
		handleChange,
		setScrolled,
		setPoster
	} = useProjectAddWizard(false)

	const lookbookRef = createRef()
	const posterRef = createRef()
	const loglineRef = createRef()
	const storyRef = createRef()

	useEffect(() => {
		if (!scrolled) {
			if (errors.header || errors.title) setScrolled(true)
			else if (errors.lookbook) {
				lookbookRef.current.scrollIntoView()
				setScrolled(true)
			} else if (errors.poster) {
				posterRef.current.scrollIntoView()
				setScrolled(true)
			} else if (errors.logline) {
				loglineRef.current.scrollIntoView()
				setScrolled(true)
			} else if (errors.story) {
				storyRef.current.scrollIntoView()
				setScrolled(true)
			}
		}
	}, [errors, scrolled])

	return (
		<Grid container className={styles.submit_page}>
			<Grid item xs={12} className={styles.edit_form} container spacing={4}>
				<Grid item xs={12}>
					<TextFieldControl
						error={errors?.title}
						label="Title*"
						value={project?.title || ''}
						variant={'blue'}
						autoFocus
						onChange={e => {
							handleChange({ title: e.target.value })
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<CropImage
						{...{
							helperTextLeft:
								'png, jpeg accepted. 24mb max. 1400px x 538px maximum',
							error: errors?.header,
							setCroppedImage: image => {
								setHeaderImage(image)
								updateErrors({ header: false })
							},
							defaultImage: project?.coverImg,
							headerImage
						}}
					/>
				</Grid>

				<Grid item xs={12} md={8} container spacing={2}>
					<Grid item xs={12} md={6}>
						<SelectBox
							{...{
								options: types,
								value: project?.typeId,
								variant: 'blue',
								placeholder: 'Project Type',
								label: 'Project Type*',
								setValue: value => {
									handleChange({
										typeId: value
									})
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SelectBox
							{...{
								options: statuses,
								value: project?.statusId,
								variant: 'blue',
								placeholder: 'Status',
								label: 'Status',
								setValue: value => {
									handleChange({
										statusId: value
									})
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ChipSelectBox
							{...{
								values: project?.genres,
								variant: 'blue',
								label: 'Genres*',
								options: genres ?? [],
								placeholder: 'Your Genres. Choose up to 3',
								helperText: 'Choose up to 3',
								setValues: values => {
									handleChange({ genres: values })
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextFieldControl
							label="Trailer"
							value={project?.trailer ?? ''}
							variant={'blue'}
							placeholder="The link to your Trailer at YouTube"
							helperText="Youtube or Vimeo link"
							onChange={async e => {
								handleChange({ trailer: e.target.value })
								const info = await getVideoInfoFromUrl(e.target.value)
								setVideoInfo(info)
							}}
						/>
					</Grid>
					<Grid item xs={12} md={12} ref={loglineRef}>
						<TextArea
							error={errors?.logline}
							variant={'blue'}
							minRows={5}
							label="Logline*"
							placeholder="-- EXAMPLE LOGLINE -- Wounded and on the run, notorious gunman Quirt Evans (John Wayne) gallops onto a farm owned by Quaker Thomas Worth (John Halloran) and his family and collapses. When Quirt urgently insists upon sending a telegram, Thomas and his daughter Penelope (Gail Russell) drive him into town in their wagon. -- EXAMPLE LOGLINE --"
							aria-label="logline"
							helperText="Your story’s synopsis"
							value={project?.logline ?? ''}
							onChange={e => {
								handleChange({
									logline: e.target.value
								})
							}}
						/>
					</Grid>
					<Grid item md={12} ref={storyRef}>
						<HtmlEditor
							{...{
								error: errors?.story,
								label: 'Project Story*',
								helperText:
									'The project story is a place to get your fans excited about your project and your journey as a filmmaker. (You can use some HTML in your description as well as add images.)',
								value: project?.story,
								setValue: value => {
									handleChange({
										story: value
									})
								}
							}}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} md={4} container spacing={2}>
					<Grid item md={12} ref={posterRef}>
						<Typography component={'h2'} sx={{ marginBottom: '0.5rem' }}>
							Poster*
						</Typography>
						<CropPoster
							{...{
								helperTextLeft:
									'png, jpeg accepted. 24mb max. 976px x 1371px maximum',
								error: errors?.poster,
								setCroppedImage: image => {
									setPoster(image)
									updateErrors({ poster: false })
								},
								defaultImage: project?.poster,
								poster
							}}
						/>
					</Grid>
					<Grid item md={12} ref={lookbookRef}>
						<DropZone
							{...{
								error: errors?.lookbook,
								maxFiles: 1,
								multiple: false,
								maxSize: 24 * 1024 * 1024,
								accept: {
									'document/pdf': [],
									'application/pdf': []
								},
								label: 'Lookbook*',
								helperTextLeft: 'pdf accepted. 24mb max size',
								helperTextRightComponent: <a href="/#">What is a Lookbook?</a>,
								defaultFile: project?.lookbook,
								setFile: file => {
									handleChange({
										lookbook: file
									})
								},
								clearFile: () => {
									handleChange({
										lookbook: undefined
									})
								}
							}}
						/>
					</Grid>
					<Grid item md={12} xs={12} className={styles.dropB}>
						<DropZone
							{...{
								error: errors?.treatment,
								maxFiles: 1,
								multiple: false,
								maxSize: 24 * 1024 * 1024,
								accept: {
									'document/pdf': [],
									'application/pdf': []
								},
								label: 'Treatment',
								helperTextLeft: 'pdf accepted. 24mb max size',
								defaultFile: project?.treatment,
								setFile: file => {
									handleChange({
										treatment: file
									})
								},
								clearFile: () => {
									handleChange({
										treatment: undefined
									})
								}
							}}
						/>
					</Grid>
					<Grid item md={12} className={styles.dropB}>
						<DropZone
							{...{
								error: errors?.script,
								maxFiles: 1,
								multiple: false,
								maxSize: 24 * 1024 * 1024,
								accept: {
									'document/pdf': [],
									'application/pdf': []
								},
								label: 'Script',
								helperTextLeft: 'pdf accepted. 24mb max size',
								defaultFile: project?.script,
								setFile: file => {
									handleChange({
										script: file
									})
								},
								clearFile: () => {
									handleChange({
										script: undefined
									})
								}
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default SubmitPage
