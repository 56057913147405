'use client'

import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Box, Button, Icon, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import styles from './DropZone.module.scss'
import useNotification from 'hooks/useNotification'

export const getOriginalName = filename => {
	const path = filename.split('__')
	if (!isNaN(Number(path[0])) && path.length > 1) path.shift()
	return path.join('__')
}

function DropZone({
	error,
	label,
	accept,
	maxFiles = 1,
	setFile,
	setBlob,
	defaultFile,
	clearFile,
	helperTextLeft,
	helperTextRight,
	helperTextRightComponent,
	maxSize
}) {
	const [archive, setArchive] = useState(defaultFile)
	const { showNotification } = useNotification()

	const onDrop = useCallback(acceptedFiles => {
		acceptedFiles.map(file => {
			setFile && setFile(file)
			const reader = new FileReader()
			reader.onload = function (e) {
				setBlob && setBlob(e.target.result)
			}
			reader.readAsDataURL(file)
			return file
		})
	}, [])

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		acceptedFiles,
		fileRejections
	} = useDropzone({
		accept,
		onDrop,
		maxFiles,
		maxSize
	})

	useEffect(() => {
		if (acceptedFiles && acceptedFiles[0]) {
			setArchive(acceptedFiles[0])
		} else {
			setArchive(defaultFile)
		}
	}, [acceptedFiles, defaultFile])

	useEffect(() => {
		const error = _.get(fileRejections, ['0', 'errors', '0'])
		const fileTooLarge = _.get(error, ['code']) === 'file-too-large'
		if (fileTooLarge)
			showNotification({
				message: `File is larger than ${getSize(maxSize)}`,
				type: 'error'
			})
		else if (error) showNotification({ message: error?.message, type: 'error' })
	}, [fileRejections])

	const getSize = size => {
		let measure = 'KB'
		let calcSize = size / 1024
		if (calcSize > 1024) {
			measure = 'MB'
			calcSize = calcSize / 1024
		}
		return `${_.ceil(calcSize, 1)} ${measure}`
	}

	const handleClearFile = () => {
		acceptedFiles.pop()
		setArchive(undefined)
		clearFile()
	}

	return (
		<Box className={`${styles.dropzone} ${error ? styles.error : ''}`}>
			{label && <Typography component={'h2'}>{label}</Typography>}
			{!archive ? (
				<Box {...getRootProps()} className={styles.buttons}>
					<input className="input-zone" {...getInputProps()} />
					<Box className="text-center">
						{isDragActive ? (
							<Button className="dropzone-content" variant="contained">
								Release to drop the files here
							</Button>
						) : (
							<Button className="dropzone-content" variant="contained">
								Drag & Drop your files or Browse
							</Button>
						)}
					</Box>
				</Box>
			) : (
				<Box className={styles.file}>
					<Typography className={styles.label_control}>
						{getOriginalName(archive.path)}
						<Typography component={'small'}>{getSize(archive.size)}</Typography>
					</Typography>
					<IconButton size="small" onClick={handleClearFile}>
						<Icon baseClassName="fas" className="fa-light fa-xmark" />
					</IconButton>
				</Box>
			)}
			<Box className={styles.footer}>
				<Typography component={'small'} className={styles.helperTextLeft}>
					{error || helperTextLeft}
				</Typography>
				{!error &&
					(helperTextRightComponent ||
						(helperTextRight && (
							<Typography component={'small'}>{helperTextRight}</Typography>
						)))}
			</Box>
		</Box>
	)
}

DropZone.propTypes = {
	accept: PropTypes.object,
	maxFiles: PropTypes.number,
	setFile: PropTypes.func,
	setBlob: PropTypes.func,
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	defaultFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	clearFile: PropTypes.func,
	label: PropTypes.string,
	helperTextLeft: PropTypes.string,
	helperTextRight: PropTypes.string,
	helperTextRightComponent: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]),
	maxSize: PropTypes.number
}

export default DropZone
