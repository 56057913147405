import PropTypes from 'prop-types'
import { ArrowLeftIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { useDispatch } from 'react-redux'
import loadingChest from 'assets/images/dao-tiers/loading.svg'
import {
	setCreateMagicWalletDialogOpen,
	setShowFanDashboardTierInfoMenu
} from 'component/header/signInSlice'
import useFanTierInfoMenu from './useFanTierInfoMenu'
import { cn } from '../../../../../lib/utils'
import { Skeleton } from 'components/ui/skeleton'

export const FanTierInfoMenu = ({ closeDaoTierInfo }) => {
	const { signInUser, daoTierInfo } = useFanTierInfoMenu()
	const dispatch = useDispatch()
	const isEmailVerified = signInUser?.isEmailVerified === 1

	const handleTriggerClaimMagicWallet = () => {
		dispatch(setCreateMagicWalletDialogOpen(true))
	}

	const renderTodo = () => {
		const metrics = daoTierInfo?.nextDaoTier?.metrics ?? {}
		const userMetrics = daoTierInfo?.userMetrics
		return Object.keys(metrics).map(key => {
			const threshold = metrics[key]
			const value = userMetrics[key] ?? 0
			const completed = value >= threshold
			let name = ''
			let label = ''
			let goal = ''
			let extra = ''

			switch (key) {
				case 'Is User Verified':
					name = 'Verified Account'
					break
				case 'Refer':
					name = 'Refer'
					label = 'users '
					goal = threshold
					break
				case 'Setup Platform Wallet':
					name = key
					extra = (
						<Button
							className="bg-cyan-500 text-xs font-bold text-white"
							onClick={handleTriggerClaimMagicWallet}
						>
							Setup
						</Button>
					)
					break
				default:
					name = key
					label = key === 'Stake' ? 'FAN ' : 'projects '
					goal = threshold
					break
			}

			return (
				<div
					key={key}
					className={`flex justify-between ${completed ? 'text-green-400' : 'text-muted-foreground'} text-base`}
				>
					<span className="tabular-nums">
						({value}/{threshold})
					</span>
					<span className="ml-2">
						{name} {goal} {label}
						{!isEmailVerified && extra}
					</span>
				</div>
			)
		})
	}

	const renderBenefits = benefits =>
		benefits
			?.filter(b => {
				const isAirdropUser =
					Number(signInUser?.isAirdropFan) === 1 ||
					Number(signInUser?.isAirdropCreator) === 1
				if (!isAirdropUser && b.highlight) return false
				return true
			})
			.map(b => {
				return (
					<div
						key={b.id}
						className={cn(
							b.highlight ? 'text-green-50' : '',
							'text-base text-white'
						)}
					>
						<span className="inline-flex items-center gap-4">
							<img
								src={loadingChest.src}
								alt="Loading"
								className="inline-block h-4 w-4"
							/>
						</span>{' '}
						{b.description}
					</div>
				)
			})

	return (
		<div className="flex w-full flex-col items-center justify-center gap-1 px-2 py-4 text-base">
			<div className="mb-4 flex w-full items-center">
				<Button
					variant="ghost"
					className="-mx-4 text-lg font-bold text-white"
					onClick={closeDaoTierInfo}
				>
					<ArrowLeftIcon size={24} />
					DAO Membership Tier
				</Button>
			</div>

			<div className="-mx-6 w-full rounded-lg bg-highlight p-4">
				<div className="text-lg font-bold text-white">
					{signInUser?.daoTierName} DAO Member
				</div>
				<div className="mb-2 text-sm font-semibold text-gray-400">
					Membership Detail and Unlocks
				</div>
				{daoTierInfo?.daoTier?.benefits ? (
					renderBenefits(daoTierInfo?.daoTier?.benefits)
				) : (
					<div className="flex items-center gap-2 py-2">
						<Skeleton className="h-4 w-4 rounded-full" />
						<Skeleton className="h-4 flex-1" />
					</div>
				)}
			</div>

			<div className="my-6 h-px w-full bg-[#F4F4F566]"></div>

			{daoTierInfo?.nextDaoTier ? (
				<div className="flex w-full flex-col gap-4 px-4">
					<div>
						<div className="font-bold text-white">
							{signInUser?.nextTierName} DAO Member{' '}
							<span className="text-gray-400">(Next Tier)</span>
						</div>
						<div className="text-sm font-semibold text-gray-400">
							Membership Detail and Unlocks
						</div>
					</div>

					<div>
						<div className="font-bold text-white">Requirements</div>
						{renderTodo()}
					</div>
					<div>
						<div className="font-bold text-white">Rewards</div>
						{renderBenefits(daoTierInfo?.nextDaoTier?.benefits)}
					</div>
				</div>
			) : (
				<div className="flex w-3/4 flex-col">
					<div className="text-lg font-bold text-white">Next DAO Tier</div>
					<div className="text-sm font-semibold text-gray-400">
						You have reached the final tier!
					</div>
				</div>
			)}
		</div>
	)
}

FanTierInfoMenu.propTypes = {
	handleClose: PropTypes.func
}
