import PropTypes from 'prop-types'
import { Box, LinearProgress } from '@mui/material'
import styles from './PasswordStrenght.module.scss'

export const PasswordStrenght = ({ password }) => {
	const containsUpperAndLowerCase = () => {
		return /(?=.*[a-z])(?=.*[A-Z])/.test(password)
	}

	const containsNumberOrSpecialChar = () => {
		return /[0-9!@#$%^&*)(+=.\-_]+/.test(password)
	}

	const getPasswdStrengthInfo = () => {
		let progress = 0
		let barcolor = ''
		let text = ''
		if (containsUpperAndLowerCase()) progress += 33
		if (containsNumberOrSpecialChar()) progress += 33
		if (password.length > 7) progress += 34

		switch (progress) {
			case 33:
			case 34:
				barcolor = 'bar_33'
				text = 'needs more work'
				break
			case 66:
			case 67:
				barcolor = 'bar_66'
				text = 'could be better'
				break
			case 100:
				barcolor = 'bar_100'
				text = 'lookin good!'
				break
		}
		return {
			barcolor,
			progress,
			text
		}
	}
	const { barcolor, text, progress } = getPasswdStrengthInfo()

	return (
		<Box className={styles.password_rules}>
			<p className={[styles.show_rule]}>{text}</p>
			<LinearProgress
				variant="determinate"
				classes={{ bar1Determinate: styles[barcolor] }}
				value={progress}
			/>
			<Box className={styles.results}>
				<span className={containsUpperAndLowerCase() ? styles.show_rule : ''}>
					<strong>aA</strong> one uppercase & one lowercase character
				</span>
				<span className={password.length > 7 ? styles.show_rule : ''}>
					<strong>::::</strong> at least 8 characters long
				</span>
				<span className={containsNumberOrSpecialChar() ? styles.show_rule : ''}>
					<strong>1$</strong> at least one number or special character
				</span>
			</Box>
		</Box>
	)
}

PasswordStrenght.propTypes = {
	password: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}
