import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import Persona from 'persona'
import { formatDate } from '../../../utils/date.utils'
import { Field, InquiryAttributes } from 'persona/dist/lib/interfaces'

const kycTemplateId = process.env.NEXT_PUBLIC_KYC_TEMPLATE_ID
const kycEnv = process.env.NEXT_PUBLIC_KYC_ENVIRONMENT

export const useKyc = ({
	onKycComplete
}: {
	onKycComplete?: ({
		inquiryId,
		status,
		fields
	}: {
		inquiryId: string
		status: string
		fields: Record<string, Field> | InquiryAttributes
	}) => void
} = {}) => {
	const { user } = useSelector(state => state.signIn)
	const personaKycRef = useRef<any>(null)

	const showKyc = () => {
		let personaClient = personaKycRef.current
		if (!personaClient) {
			console.log('ble')
			personaClient = new Persona.Client({
				templateId: kycTemplateId,
				referenceId: user.id,
				environmentId: kycEnv,
				onComplete: onKycComplete,
				fields: {
					nameFirst: user?.firstName,
					nameLast: user?.lastName,
					birthdate: formatDate(user.birthday, 'finputDate'),
					addressCountryCode: user.countryId,
					emailAddress: user.email
				}
			})
			personaKycRef.current = personaClient
		}

		personaClient.open()
	}

	useEffect(() => {
		return () => {
			const personaKyc = personaKycRef.current
			if (!personaKyc) {
				return
			}
			personaKyc.destroy()
			personaKycRef.current = null
		}
	}, [])

	return {
		showKyc
	}
}
