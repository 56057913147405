import _ from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useNotification from 'hooks/useNotification'
import { getGenresService } from 'services/genres.service'
import { editProjectService, getProjectById } from 'services/project.service'
import { createProjectPreviewService } from 'services/projectPreview.service'
import { getProjectStatus, getProjectTypes } from 'services/seed.service'
import { Mixpanel } from 'services/mixpanel.service'
import { getStudios } from 'services/studio.service'
import { updateProject } from './projectEditSlice'
import { updateMetaTags } from 'utils/utils'
import { FilmioProjectContract } from 'utils/blockchain/functions'

const useProjectEdit = () => {
	const dispatch = useDispatch()
	const { project } = useSelector(state => state.projectEdit)
	const { user: signInUser } = useSelector(state => state.signIn)
	const [headerImage, setHeaderImage] = useState(undefined)
	const [poster, setPoster] = useState(undefined)
	const [videoInfo, setVideoInfo] = useState(null)
	const [errors, setErrors] = useState({})
	const [scrolled, setScrolled] = useState(false)
	const [changeDeteted, setChangeDeteted] = useState(false)
	const [redirectAfterSave, setRedirectAfterSave] = useState(true)
	const { showNotification } = useNotification()
	const navigate = useNavigate()
	const { id } = useParams()

	const [{ loading }, fetchProject] = useAxiosFetch(
		{
			...getProjectById({ id, isUpdate: true }),
			autoexec: true
		},
		afterFetchProject
	)

	function afterFetchProject(data, error) {
		if (!error) {
			dispatch(updateProject(data))
			updateMetaTags({
				title: `Edit ${data?.title} — Filmio`
			})
			setTimeout(() => {
				setChangeDeteted(false)
			})
		}
	}

	const [{ response: projectTypes, loading: loadingProjectTypes }] =
		useAxiosFetch({
			...getProjectTypes(),
			autoexec: true
		})

	const [{ response: projectStatus, loading: loadingProjectStatus }] =
		useAxiosFetch({
			...getProjectStatus(),
			autoexec: true
		})

	const [{ response: studios, loading: loadingStudios }] = useAxiosFetch(
		getStudios()
	)

	const [{ loading: saving }, saveChanges] = useAxiosFetch(
		editProjectService({
			...project,
			coverImg: headerImage,
			poster
		}),
		afterSaveChanges
	)

	function afterSaveChanges(data, error) {
		if (!error) {
			Mixpanel.track('submitted_project_edit_event', {
				distinct_id: signInUser.id,
				displayName: signInUser.displayName,
				username: signInUser.username,
				...data
			})
			setChangeDeteted(false)
			showNotification({ message: 'Project updated successfully' })
			if (redirectAfterSave) navigate(`/project/${data?.slug}`)
			else {
				fetchProject()
			}
		}
	}

	const [{ loading: savingPreview }, savePreviewChanges] = useAxiosFetch(
		{
			...createProjectPreviewService({
				...project,
				projectId: id,
				coverImg: headerImage,
				poster
			}),
			autoexec: false
		},
		afterSavePreviewChanges
	)

	function afterSavePreviewChanges() {
		showNotification({ message: 'Project preview created successfully' })
		const baseUrl = window.location.origin
		window.open(
			`${baseUrl}/project/draft/${id}`,
			'_blank',
			'noopener,noreferrer'
		)
	}

	const [{ response: genres }] = useAxiosFetch(getGenresService())

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const isValidSubmitForAppoval = () => {
		let isValid = true
		const approvalErrors = {}
		const message = 'This field is required for submit for approval'
		if (_.isNil(project.title) || project.title.trim() === '') {
			approvalErrors.title = message
			isValid = false
		}
		if (
			(_.isNil(project.coverImg) && _.isNil(headerImage)) ||
			headerImage === null
		) {
			approvalErrors.header = message
			isValid = false
		}
		if (_.isNil(project.lookbook)) {
			approvalErrors.lookbook = message
			isValid = false
		}

		if ((_.isNil(project.poster) && _.isNil(poster)) || poster === null) {
			approvalErrors.poster = message
			isValid = false
		}
		if (_.isNil(project.logline)) {
			approvalErrors.logline = message
			isValid = false
		}
		const story = project?.story?.replace(/<\/?[^>]+(>|$)/g, '')
		if (_.isNil(story) || story.trim() === '') {
			approvalErrors.story = message
			isValid = false
		}
		updateErrors(approvalErrors)
		return isValid
	}

	const showErrors = () => {
		setScrolled(false)
		showNotification({
			message: 'Complete the required fields to submit for approval',
			type: 'error'
		})
	}

	const handleSubmitForApproval = async () => {
		setRedirectAfterSave(true)
		if (isValidSubmitForAppoval()) {
			let projectHash
			if (_.isNil(project?.projectHash)) {
				projectHash = await FilmioProjectContract.createLock(id, id)
				if (_.isNil(projectHash)) {
					showNotification({
						message: 'You need to create the project on the blockchain!'
					})
					return
				}
			}
			saveChanges({ stageId: 2, projectHash })
		} else showErrors()
	}

	const handleSaveChanges = () => {
		setRedirectAfterSave(false)
		if (isValidSubmitForAppoval()) saveChanges()
		else showErrors()
	}

	const handleSaveChangesWithoutValidate = () => {
		setRedirectAfterSave(false)
		saveChanges()
	}

	const handleChange = payload => {
		setChangeDeteted(true)
		const key = Object.keys(payload)[0]
		updateErrors({ [key]: false })
		dispatch(updateProject(payload))
	}

	const handleSavePreviewChanges = () => {
		savePreviewChanges()
	}

	const handleCancel = () => {
		navigate(`/project/${project?.slug}`)
	}

	const showMask =
		loading ||
		loadingProjectStatus ||
		loadingProjectTypes ||
		loadingStudios ||
		saving

	return {
		errors,
		genres,
		saving,
		project,
		scrolled,
		showMask,
		videoInfo,
		changeDeteted,
		savingPreview,
		studios: studios?.data?.rows,
		projectTypes: projectTypes?.data,
		projectStatus: projectStatus?.data,
		handleSaveChangesWithoutValidate,
		handleSavePreviewChanges,
		handleSubmitForApproval,
		handleSaveChanges,
		setChangeDeteted,
		setHeaderImage,
		setVideoInfo,
		setScrolled,
		handleCancel,
		handleChange,
		updateErrors,
		setPoster
	}
}

export default useProjectEdit
