import _ from 'lodash'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useLoadingMask from 'hooks/useLoadingMask'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getExploreInfoService } from 'services/dashboard.service'
import { setDaoProposalUpdated } from '../dashboardSlice'
import { useParams } from 'react-router-dom'
import { setSocialJoinParams } from 'component/header/signInSlice'

const useExplore = () => {
	const { daoProposalUpdated } = useSelector(state => state.dashboard)
	const [tiles, setTiles] = useState([])
	const [daoProposals, setDaoProposals] = useState([])
	const [favorites, setFavorites] = useState([])
	const [trending, setTrending] = useState([])
	const [newest, setNewest] = useState([])
	const [featuredCreators, setFeaturedCreators] = useState([])
	const [recent, setRecent] = useState([])
	const { showMask, hideMask } = useLoadingMask()
	const dispatch = useDispatch()
	const { network, userShortUrl } = useParams()
	const { activeContests } = useSelector(state => state.signIn)

	const afterFetch = (data, error) => {
		if (!error) {
			setRecent(data?.recentViewed)
			setTiles(data?.tiles)
			setFavorites(data?.favorites)
			setTrending(data?.trending)
			setNewest(data?.newest)
			setFeaturedCreators(data?.featuredCreators)
			setDaoProposals(data?.daoProposals)
		}
	}

	const [{ loading }] = useAxiosFetch(
		getExploreInfoService({ userShortUrl }),
		afterFetch
	)

	useEffect(() => {
		if (!_.isNil(daoProposalUpdated)) {
			const proposals = daoProposals.map(el =>
				el?.id === daoProposalUpdated?.id ? daoProposalUpdated : el
			)
			setDaoProposals(proposals)
			dispatch(setDaoProposalUpdated(null))
		}
	}, [daoProposalUpdated])

	useEffect(() => {
		loading ? showMask() : hideMask()
	}, [loading])

	useEffect(() => {
		dispatch(setSocialJoinParams({ userShortUrl, network }))
	}, [network, userShortUrl])

	return {
		tiles,
		recent,
		favorites,
		daoProposals,
		featuredCreators,
		trending,
		newest,
		hasBanner: activeContests?.length > 0
	}
}

export default useExplore
