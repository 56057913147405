import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	profile: {}
}

export const profileEditSlice = createSlice({
	name: 'profileEdit',
	initialState,
	reducers: {
		reset: () => initialState,
		updateProfile: (state, { payload }) => {
			state.profile = {
				...state.profile,
				...payload
			}
		}
	}
})

export const { reset, updateProfile } = profileEditSlice.actions

export default profileEditSlice.reducer
