import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	tiles: undefined
}

export const tileManagementSlice = createSlice({
	name: 'tileManagement',
	initialState,
	reducers: {
		reset: () => initialState,
		updateTiles: (state, { payload }) => {
			state.tiles = payload
		}
	}
})

export const { reset, updateTiles } = tileManagementSlice.actions

export default tileManagementSlice.reducer
