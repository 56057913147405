import { CustomDialog } from '../../common'
import { CustomButton } from 'component/common'
import { Stack, Typography, Box } from '@mui/material'
import { usePlatformWalletCreatedDialog } from './usePlatformWalletCreatedDialog'
import styles from './PlatformWalletCreatedDialog.module.scss'

export const PlatformWalletCreatedDialog = () => {
	const { open, close } = usePlatformWalletCreatedDialog()

	return (
		<CustomDialog open={open} removeX>
			<Stack spacing={2}>
				<Box className={styles.content}>
					<Typography variant="h6" align="center">
						Thanks for setting up your Platform Wallet!
					</Typography>
					<Typography variant="body1" align="center" opacity={0.8}>
						Now, all transactions will be gasless and all rewards will be stored
						in your platform wallet.
					</Typography>
					<a
						href={
							'https://film.io/2024/04/new-film-io-platform-safety-measures-and-updates/'
						}
						target="_blank"
						rel="noreferrer"
						className={styles.infoLink}
					>
						More Information
					</a>
					<CustomButton customStyle={'cancel_dark_blue'} onClick={close}>
						Close
					</CustomButton>
				</Box>
			</Stack>
		</CustomDialog>
	)
}
