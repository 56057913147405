export const TRANSACTION_TYPES = {
	WELCOME_BONUS_REWARD: 1,
	PROJECT_REVIEW: 2,
	PROJECT_SHARE: 3,
	TOKEN_STAKE: 4,
	CREATOR_DONATION: 5,
	SPECIAL_BONUS: 6,
	TOKEN_UNSTAKE: 7,
	FGR_CLAIMING: 8,
	PROJECT_DONATION: 9,
	DAO_TIER_BONUS: 10,
	PROJECT_RATING: 11,
	TOKEN_DISBURSEMENT: 12,
	AIRDROP_TOKENS_UNLOCKED: 13,
	SOCIAL_JOIN_REWARD: 14,
	CAMPAIGN_REWARD: 15
}
