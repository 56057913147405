import { useState } from 'react'
import PropTypes from 'prop-types'
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Typography,
	Grid,
	Icon,
	IconButton
} from '@mui/material'
import styles from './vestingScheduleDialog.module.scss'
export const VestingScheduleDialog = ({ open, schedules, handleClose }) => {
	const [scheduleIndex, setScheduleIndex] = useState(0)
	const headingText = 'Token Release Schedule'
	const handleCloseDialog = () => {
		console.log('VestingScheduleDialog close')
		handleClose()
	}

	const handlePreviousSchedule = () => {
		setScheduleIndex(pre => pre - 1)
	}
	const handleNextSchedule = () => {
		setScheduleIndex(pre => pre + 1)
	}

	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={`${styles.dialog} warning`}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleCloseDialog}
		>
			<DialogContent className={styles.dialog_content}>
				<Typography component={'h2'}>{headingText}</Typography>
				<Box className={styles.schedules_data_content}>
					{schedules[scheduleIndex]?.schedule?.releases?.map(
						(release, index) => {
							return (
								<Grid
									item
									key={
										schedules[scheduleIndex]?.schedule?.id + index.toString()
									}
									md={12}
									xs={12}
									className={styles.grid_data}
								>
									<span>Disbursement #{index + 1}</span>
									<span>{new Date(release * 1000).toDateString()}</span>
								</Grid>
							)
						}
					)}
				</Box>
				<Box>
					<Typography component={'h3'}>
						Grand Total Tokens:{' '}
						{schedules[scheduleIndex]?.schedule?.totalAmount}
					</Typography>
				</Box>
				{schedules?.length > 0 && (
					<Box className={styles.icon_btns}>
						<div className={styles.left_dir}>
							{scheduleIndex > 0 && schedules?.length > 0 && (
								<IconButton
									aria-label="addComment"
									size="small"
									onClick={handlePreviousSchedule}
									className={styles.left_arrow_btn}
								>
									<Icon
										baseClassName="fas"
										className="fa-light fa-arrow-left"
									/>
								</IconButton>
							)}
						</div>
						<div className={styles.right_dir}>
							{scheduleIndex < schedules?.length - 1 && (
								<IconButton
									aria-label="addComment"
									size="small"
									onClick={handleNextSchedule}
									className={styles.right_arrow_btn}
								>
									<Icon
										baseClassName="fas"
										className="fa-light fa-arrow-right"
									/>
								</IconButton>
							)}
						</div>
					</Box>
				)}
				<Box className={styles.actions}>
					<Button
						size="small"
						onClick={handleCloseDialog}
						className={styles.close_btn}
						style={{ marginLeft: '10px' }}
					>
						Cancel
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

VestingScheduleDialog.propTypes = {
	open: PropTypes.bool,
	schedules: PropTypes.array,
	handleClose: PropTypes.func
}
