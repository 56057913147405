import useAxios from 'hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getVestingStats } from 'services/vesting.service'

const useVestingOverview = () => {
	const { axiosService } = useAxios()
	const { user } = useSelector(state => state.signIn)
	const [vestingStats, setVestingStats] = useState(null)

	useEffect(() => {
		axiosService(getVestingStats(), (data, error) => {
			if (!error) {
				setVestingStats(data)
			}
		})
	}, [])

	return {
		vestingStats,
		user
	}
}

export default useVestingOverview
