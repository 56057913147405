import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import ActivityItem from './activityItem/ActivityItem'
import styles from './RecentActivity.module.scss'

const RecentActivityStatic = ({ activities, title }) => {
	const renderActivities = () => {
		return activities.map((act, idx) => {
			act.projectTitle = title
			return <ActivityItem {...act} key={`activity-${idx}`} />
		})
	}

	return (
		<Box className={styles.activities}>
			{renderActivities()}
			<Box className={styles.footer}></Box>
		</Box>
	)
}

RecentActivityStatic.propTypes = {
	activities: PropTypes.array,
	title: PropTypes.string
}

export default RecentActivityStatic
