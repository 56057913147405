import PropTypes from 'prop-types'
import Rating from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/Star'

const labels = {
	0.5: 'Useless',
	1: 'Useless+',
	1.5: 'Poor',
	2: 'Poor+',
	2.5: 'Ok',
	3: 'Ok+',
	3.5: 'Good',
	4: 'Good+',
	4.5: 'Excellent',
	5: 'Excellent+'
}

function getLabelText(value) {
	return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`
}

export default function ReviewRating({ rating, setRating, ...otherProps }) {
	return (
		<Rating
			value={Number(rating)}
			size="small"
			precision={1}
			getLabelText={getLabelText}
			emptyIcon={
				<StarIcon style={{ opacity: 1, color: '#ffffff' }} fontSize="inherit" />
			}
			onChange={(event, newValue) => {
				setRating(newValue)
			}}
			{...otherProps}
		/>
	)
}

ReviewRating.propTypes = {
	rating: PropTypes.any,
	setRating: PropTypes.func,
	otherProps: PropTypes.object
}
