import { useEffect, useRef } from 'react'

const useOutsideClick = (callback, extra) => {
	const ref = useRef()

	useEffect(() => {
		const handleClick = event => {
			if (ref.current && !ref.current.contains(event.target)) {
				callback()
			}
		}

		document.addEventListener('click', handleClick, true)

		return () => {
			document.removeEventListener('click', handleClick, true)
		}
	}, [ref, extra])

	return ref
}

export default useOutsideClick
