import { Box } from '@mui/material'
import {
	Chart as ChartJS,
	ArcElement,
	LinearScale,
	CategoryScale,
	BarElement,
	Tooltip,
	Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import styles from './GoScoreImpactMetricBar.module.scss'
import PropTypes from 'prop-types'
import { useState } from 'react'
import _ from 'lodash'

ChartJS.register(
	ArcElement,
	LinearScale,
	CategoryScale,
	BarElement,
	Tooltip,
	Legend
)

const GoScoreImpactMetricBar = ({ info, setSectionHover }) => {
	const data = {
		labels: [''],
		datasets: [
			{
				data: [0],
				backgroundColor: ['#06FFD8']
			},
			{
				data: [0, 0],
				backgroundColor: ['#FE3285', '#FE3285']
			},
			{
				data: [0, 0],
				backgroundColor: ['#3FA8F4', '#3FA8F4']
			}
		]
	}

	const options = {
		indexAxis: 'y',
		responsive: true,
		barThickness: 15,
		maintainAspectRatio: false,
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false,
					drawBorder: false,
					offset: false
				},
				ticks: {
					display: false
				}
			},
			y: {
				stacked: true,
				grid: {
					display: false,
					drawBorder: false,
					offset: false
				},
				ticks: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false
			}
		},
		onHover: (ev, elements, chart) => {
			const idx = _.get(elements, [0, 'index'], null)
			return setSectionHover(idx)
		}
	}

	const [pieData, setPieData] = useState(data)

	const {
		followersImpact,
		sharesImpact,
		ratingReviewImpact,
		viewsImpact,
		stakedImpact
	} = info

	useState(() => {
		pieData.datasets[0].data = [stakedImpact]

		pieData.datasets[1].data = [followersImpact + sharesImpact]

		pieData.datasets[2].data = [ratingReviewImpact + viewsImpact]

		setPieData(pieData)
	}, info)

	return (
		<Box className={styles.score_metric}>
			<Bar {...{ data: pieData, options }} />
		</Box>
	)
}

GoScoreImpactMetricBar.propTypes = {
	info: PropTypes.object,
	setSectionHover: PropTypes.func
}

export default GoScoreImpactMetricBar
