import _ from 'lodash'
import {
	Box,
	Icon,
	Typography,
	FormControlLabel,
	Checkbox,
	Button
} from '@mui/material'
import { TextFieldControl, CustomButton, CustomDialog } from 'component/common'
import useJoinDialog from './useJoinDialog'
import { isMetamask, isMobile, isSafariMacOs } from 'utils/utils'
import images from 'assets/images'
import metamaskLogo from 'assets/images/metamask-color.png'
import styles from './JoinDialog.module.scss'
import { CaptchaCheck } from 'component/common/captchaCheck'

export const JoinDialog = () => {
	const {
		data,
		open,
		errors,
		newsletter,
		captchaToken,
		setNewsletter,
		handleGoToLogin,
		handleNcLogin,
		handleMMLogin,
		handleSubmit,
		handleChange,
		handleClose
	} = useJoinDialog()

	const currentURL = window.location.href
	const metaMaskButtonUrl = currentURL
		.replace('https://', '')
		.replace('http://', '')

	return (
		<CustomDialog {...{ open, handleClose, variant: 'join' }}>
			<Box className={styles.title}>
				Welcome to Film.io
				<span>Join us and claim your free $FAN tokens!</span>
			</Box>
			<Box className={styles.content}>
				<Box className={styles.social_login} style={{ display: 'none' }}>
					<CustomButton
						startIcon={<img src={images.metamaskColor} />}
						customStyle={'social_login'}
					>
						Metamask
					</CustomButton>
					<CustomButton
						startIcon={<Icon baseClassName="fab" className="fa-x-twitter" />}
						customStyle={'social_login'}
					>
						Twitter
					</CustomButton>
					<CustomButton
						startIcon={<Icon baseClassName="fab" className="fa-facebook" />}
						customStyle={'social_login'}
					>
						Facebook
					</CustomButton>
				</Box>
				<Typography className={styles.or} style={{ display: 'none' }}>
					or
				</Typography>
				<Box className={styles.fields}>
					<Box className={styles.row}>
						<TextFieldControl
							error={errors?.firstName}
							classes={{ root: styles.first_name }}
							hiddenLabel
							fullWidth
							name="firstName"
							placeholder="First Name"
							value={data.firstName}
							onChange={e => {
								handleChange({ firstName: e.target.value })
							}}
						/>
						<TextFieldControl
							error={errors?.lastName}
							classes={{ root: styles.last_name }}
							hiddenLabel
							fullWidth
							name="username"
							placeholder="Last Name"
							value={data.lastName}
							onChange={e => {
								handleChange({ lastName: e.target.value })
							}}
						/>
					</Box>
					<TextFieldControl
						error={errors?.email}
						classes={{ root: styles.email_input }}
						hiddenLabel
						fullWidth
						name="email"
						placeholder="Email"
						value={data.email}
						onChange={e => {
							handleChange({ email: _.trim(e.target.value) })
						}}
					/>
				</Box>
				<Box className={styles.recaptcha}>
					<CaptchaCheck />
				</Box>
				<CustomButton
					customStyle={'blue_wo_border'}
					onClick={handleSubmit}
					disabled={captchaToken === null}
				>
					Login/SignUp
				</CustomButton>
				<Button onClick={handleGoToLogin} className={styles.back}>
					Go Back
				</Button>
			</Box>

			<Box className={styles.otherLogin}>
				{!isSafariMacOs() && (
					<>
						{isMetamask() || !isMobile() ? (
							<Button
								className={styles.wallet_mobile_cnct}
								onClick={handleMMLogin}
							>
								<img src={metamaskLogo.src} alt="MetaMask" />
								MetaMask
							</Button>
						) : (
							<Button
								className={styles.wallet_mobile_cnct}
								href={`https://metamask.app.link/dapp/${metaMaskButtonUrl}`}
							>
								<img src={metamaskLogo.src} alt="MetaMask" />
								<Typography>MetaMask</Typography>
							</Button>
						)}
					</>
				)}
				<Button
					className={styles.wallet_cnct}
					onClick={handleNcLogin()}
					startIcon={<Icon baseClassName="fas" className="fa-wallet" />}
				>
					Wallet Connect
				</Button>
			</Box>
			<Box className={styles.agree}>
				<p>
					By signing up, you agree to the{' '}
					<a
						href="https://film.io/constitution"
						target="_blank"
						rel="noreferrer"
					>
						DAO Constitution
					</a>
					{', '}
					<a
						href="https://film.io/token-terms-conditions/"
						target="_blank"
						rel="noreferrer"
					>
						Token Terms & Conditions
					</a>
					{' and '}
					<a
						href="https://film.io/terms-of-use/"
						target="_blank"
						rel="noreferrer"
					>
						Terms of Use
					</a>
				</p>

				<FormControlLabel
					style={{ display: 'none' }}
					className={styles.newsletters}
					label="Stay up on all things Film.io. Join our newsletter today!"
					control={
						<Checkbox
							checked={newsletter}
							onChange={event => {
								setNewsletter(event.target.checked)
							}}
						/>
					}
				/>
			</Box>
		</CustomDialog>
	)
}
