import PropTypes from 'prop-types'
import { Box, Button, Grid, Icon, Typography } from '@mui/material'
import { NewsreelItem } from './newsreelItem'
import useNewsreelTab from './useNewsreelTab'
import { AddNewsreelForm } from './addNewsreelForm'
import { RecentActivity } from 'component/projectDetail/dashboardTab/recentActivity'
import { renderProfileActivities } from 'component/profile/dashboardTab/DashboardTab'
import images from 'assets/images'
import styles from './NewsreelTab.module.scss'
import InfiniteScroll from 'react-infinite-scroll-component'

export const NEWSREEL_FEATURES = {
	project: 'project',
	profile: 'profile'
}

export const ACTIVITY_TYPES = {
	stake: 'stake',
	follow: 'follow',
	share: 'share'
}

const renderNewsreelItems = newsreels =>
	newsreels.map(newsreel => (
		<NewsreelItem {...{ ...newsreel }} key={newsreel.id} />
	))

const NewsreelTab = ({ feature }) => {
	const {
		user,
		loadMore,
		isProject,
		isProfile,
		newsreels,
		emptyMessage,
		loadingNewsreels,
		handleSocialClick,
		hideAddNewsreelForm,
		isOneNewsreelView,
		onScroll
	} = useNewsreelTab(feature)

	return (
		<InfiniteScroll
			onScroll={onScroll}
			dataLength={10000000}
			scrollableTarget="scroll-provider"
		>
			<Grid
				container
				spacing={2}
				rowSpacing={2}
				sx={{ marginBottom: '80px', position: 'relative' }}
			>
				<Grid item md={7} xs={12}>
					{isOneNewsreelView && (
						<Button
							className={styles.back_btn}
							onClick={handleSocialClick(1)}
							startIcon={
								<Icon baseClassName="fas" className="fa-chevron-left" />
							}
						>
							Newsreel Home
						</Button>
					)}
					{!hideAddNewsreelForm && <AddNewsreelForm {...{ feature }} />}
					<Grid item md={12}>
						{newsreels.length > 0 ? (
							renderNewsreelItems(newsreels)
						) : (
							<Box className={styles.message_box}>
								<img src={images.newsreelEmpty} />
								<Typography component={'h1'}>{emptyMessage}</Typography>
							</Box>
						)}
					</Grid>
				</Grid>
				<Grid
					item
					md={5}
					sx={{
						marginTop: '48px',
						padding: '20px',
						background: 'rgba(0, 0, 0, 0.35)',
						borderRadius: '20px'
					}}
				>
					<Typography
						component={'h3'}
						sx={{
							fontWeight: '700',
							fontSize: '16px',
							marginTop: '32px',
							marginBottom: '12px',
							color: '#FFFFFF'
						}}
					>
						Recent Activity
					</Typography>
					{isProject && <RecentActivity />}
					{isProfile && renderProfileActivities(user?.lastActivities)}
				</Grid>
			</Grid>
			{!loadMore && newsreels.length > 0 && (
				<Box className={styles.loader}>
					Looks like you&apos;ve reached the end of the road
				</Box>
			)}
			{loadingNewsreels && (
				<Box className={styles.loader}>
					<Icon baseClassName="fas" className="fa-spinner fa-spin-pulse" />
				</Box>
			)}
		</InfiniteScroll>
	)
}

NewsreelTab.propTypes = {
	feature: PropTypes.string
}

export default NewsreelTab
