import { getContractValue, sendContractBlockchainTransaction } from '../magic'
import { retrieveEventArgs, getTransactionFee } from '../config/utils'
import { contractsConfig } from '../config/constants'
import { ethers } from 'ethers'

class FilmioDaoContract {
	static contractName = 'filmioDao'

	// Write Functions
	static async vote(proposalId, inFavor, transactionNote, signer = null) {
		try {
			const hash = await sendContractBlockchainTransaction(
				FilmioDaoContract.contractName,
				'vote',
				[proposalId, inFavor],
				transactionNote,
				signer
			)

			const voteDetails = (
				await retrieveEventArgs(hash, 'Voted', contractsConfig.filmioDao.abi)
			)[0]

			const votingWeight = Number(
				ethers.utils.formatUnits(voteDetails[3].toBigInt(), 8)
			)

			return {
				hash,
				votingWeight
			}
		} catch (error) {
			return {
				hash: null,
				votingWeight: 0
			}
		}
	}

	static async getFeesVote(
		proposalId, inFavor, signer
	) {
		return await getTransactionFee(
			signer,
			FilmioDaoContract.contractName,
			'vote',
			[proposalId, inFavor]
		)
	}

	static async createProposal(
		ipfsHash,
		startDate,
		duration,
		transactionNote,
		signer = null
	) {
		const hash = await sendContractBlockchainTransaction(
			FilmioDaoContract.contractName,
			'createProposal',
			[ipfsHash, startDate, duration],
			transactionNote,
			signer
		)
		const eventArgs = await retrieveEventArgs(
			hash,
			'NewProposal',
			contractsConfig.filmioDao.abi
		)
		const proposalDetails = eventArgs[0]

		const proposalId = Number(proposalDetails[0].toBigInt())

		return {
			hash,
			proposalId,
			ipfsHash
		}
	}

	static async getFeesCreateProposal(
		ipfsHash,
		startDate,
		duration,
		signer
	) {
		return await getTransactionFee(
			signer,
			FilmioDaoContract.contractName,
			'createProposal',
			[ipfsHash, startDate, duration]
		)
	}

	// Read Functions
	static async getProposal(proposalId) {
		return await getContractValue(FilmioDaoContract.contractName, 'getProposal', [
			proposalId
		])
	}

	static async getVotingWeight(proposalId, address) {
		return await getContractValue(FilmioDaoContract.contractName, 'hasVoted', [
			proposalId,
			address
		])
	}
}

export default FilmioDaoContract
