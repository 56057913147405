import { useDispatch, useSelector } from 'react-redux'
import { setSearch } from './newsreelPageSlice'

const useNewsreelPage = () => {
	const dispatch = useDispatch()
	const { search } = useSelector(state => state.newsreelPage)
	const { isAuthenticated } = useSelector(state => state.signIn)
	const handleSearch = text => {
		dispatch(setSearch(text))
	}

	return {
		search,
		isAuthenticated,
		handleSearch
	}
}

export default useNewsreelPage
