import PropTypes from 'prop-types'
import {
	TableSortLabel,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { formatDate } from 'utils/date.utils'
import useTransactionsTable from './useTransactionsTable'
import { getEthTransactionLink } from 'utils/utils'
import tokenBlue from 'assets/images/new_token_blue.svg'
import styles from './TransactionsTable.module.scss'

const headCells = [
	{
		align: 'center',
		id: 'baseCurrencyAmount',
		label: 'Purchase'
	},
	{
		id: 'amount',
		label: 'Fan Tokens'
	},
	{
		id: 'createdAt',
		label: 'Purchase Date'
	},
	{
		id: 'blockchainHash',
		label: 'TXN HASH'
	}
]

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => {
					const active = orderBy === headCell.id
					return (
						<TableCell
							key={headCell.id}
							align={headCell.align}
							padding={'none'}
							sortDirection={active ? order : false}
						>
							{headCell.id !== 'icon' && (
								<TableSortLabel
									active={active}
									direction={active ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{active ? (
										<Box component="span" sx={visuallyHidden}>
											{order === 'desc'
												? 'sorted descending'
												: 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
							)}
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired
}

export default function TransactionsTable() {
	const {
		order,
		count,
		pagin,
		orderBy,
		emptyRows,
		transactions,
		handleChangeRowsPerPage,
		handleRequestSort,
		handleChangePage
	} = useTransactionsTable()

	const renderRows = (data = []) => {
		return data.map(row => {
			const {
				id,
				amount,
				baseCurrencyAmount,
				blockchainHash,
				createdAt,
				baseCurrency,
				baseCurrencyId
			} = row
			const hash = getEthTransactionLink(blockchainHash)
			const baseCurrencyIcon = baseCurrency?.icon
			const iconBase = baseCurrencyId === 1 ? 'fa-brands' : 'fa-regular'
			const iconClass = `${iconBase} fa-${baseCurrencyIcon}`

			return (
				<TableRow key={id}>
					<TableCell align="center" width="14%">
						<i className={iconClass}></i>
						{baseCurrencyAmount}
					</TableCell>
					<TableCell align="left" width="20%">
						<img src={tokenBlue.src} /> {amount}
					</TableCell>
					<TableCell align="left" width="18%">
						{formatDate(createdAt, 'med')}
					</TableCell>
					<TableCell align="left" width="18%" maxwidth="14%">
						{hash}
					</TableCell>
				</TableRow>
			)
		})
	}

	return (
		<>
			<Box className={styles.transactions_table}>
				<h4>Your Purchases</h4>
				{transactions?.length > 0 && (
					<Box>
						<TableContainer className={styles.table_container}>
							<Table stickyHeader>
								<EnhancedTableHead
									order={order}
									orderBy={orderBy}
									onRequestSort={handleRequestSort}
								/>
								<TableBody>
									{renderRows(transactions)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{transactions?.length > 0 && (
							<TablePagination
								rowsPerPageOptions={[6, 18, 60]}
								component="div"
								count={count || 0}
								rowsPerPage={pagin.limit}
								classes={{ root: styles.pagination }}
								page={pagin.page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						)}
					</Box>
				)}
			</Box>
		</>
	)
}
