import _ from 'lodash'
import { useEffect, useState } from 'react'
import {
	getProjectStakeService,
	unstakeBlockchainService,
	unstakeService
} from 'services/stake.service'
import { useDispatch, useSelector } from 'react-redux'
import { notificationExtTypes } from 'component/common'
import {
	reloadProject,
	setStakes
} from 'component/projectDetail/projectDetailSlice'
import useNotification from 'hooks/useNotification'
import useHeader from 'component/header/useHeader'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { reloadRecentActivity } from 'component/profile/walletTab/walletTabSlice'
import useNotifications from 'component/header/notifications/useNotifications'
import { Mixpanel } from 'services/mixpanel.service'
import { StakingContract } from 'utils/blockchain/functions'
import {
	setCreatePlatformWalletDialogOpen,
	setFrontTransactionsInProgress,
	setLoginDialogOpen
} from 'component/header/signInSlice'
import { getConfiguration } from 'services/configuration.service'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useCommon from 'hooks/useCommon'

const useStakesTable = () => {
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const { project, stakes, projectId } = useSelector(
		state => state.projectDetail
	)
	const {
		showNotification,
		showNotificationExtended,
		completeNotificationExtended,
		closeNotificationExtended
	} = useNotification()
	const dispatch = useDispatch()
	const { relaodUserInfo } = useHeader()
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const { getUnvisiteNotificationCount } = useNotifications()
	const [configuration, setConfiguration] = useState({})
	const { checkNcNoMagic } = useCommon()

	useAxiosFetch(
		getConfiguration(),
		(response, error) => {
			if (!error) {
				setConfiguration(response)
			}
		},
		!isAuthenticated
	)
	const [count, setCount] = useState(0)
	const [pagin, setPagin] = useState({
		page: 0,
		limit: 6,
		offset: 0,
		orderBy: 'createdAt',
		order: 'desc'
	})

	const { order, orderBy, limit, offset } = pagin

	const afterFetchTransactions = (response, error) => {
		if (!error) {
			setCount(response.count)
			dispatch(setStakes(response.rows))
			dispatch(reloadProject())
		}
		hideMask()
	}

	const getProjectStakes = () => {
		showMask()
		if (!_.isNil(projectId))
			axiosService(
				getProjectStakeService(projectId, {
					limit,
					offset,
					order,
					orderBy
				}),
				afterFetchTransactions
			)
	}

	const unstakeBlockchain = (transactionId, blockchainHash) => {
		axiosService(
			unstakeBlockchainService({ transactionId, blockchainHash }),
			(data, error) => {
				if (!error) {
					!_.isNil(data?.blockchainHash)
						? completeNotificationExtended()
						: closeNotificationExtended()
				}
				relaodUserInfo()
				dispatch(reloadRecentActivity())
				getUnvisiteNotificationCount()
				getProjectStakes()
			}
		)
	}

	const afterUnstake = async (stake, error) => {
		if (!error) {
			getUnvisiteNotificationCount()
			getProjectStakes()
			dispatch(reloadRecentActivity())
			dispatch(setFrontTransactionsInProgress(true))
			hideMask()
			showNotificationExtended({
				projectTitle: project?.title,
				total: stake?.amount,
				type: notificationExtTypes.UNSTAKING
			})
			const blockchainHash = await StakingContract.unstakeTokens(
				stake?.blockchainHash,
				stake?.amount,
				stake?.unstakeId
			)
			unstakeBlockchain(stake?.id, blockchainHash)
			dispatch(setFrontTransactionsInProgress(false))

			if (_.isNil(blockchainHash)) {
				Mixpanel.track('transaction_failed_unstake_evt', {
					distinct_id: user.id,
					displayName: user.displayName,
					username: user.username
				})
			} else {
				Mixpanel.track('transaction_successful_unstake_evt', {
					distinct_id: user.id,
					displayName: user.displayName,
					username: user.username,
					amount: stake?.amount,
					transactionId: stake?.id,
					blockchainHash: stake?.blockchainHash
				})
			}
		}
		getUnvisiteNotificationCount()
		getProjectStakes()
	}

	const unstake = transactionId => {
		showMask()
		axiosService(unstakeService({ transactionId }), afterUnstake)
	}

	const handleRequestSort = (_event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setPagin({
			...pagin,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		})
	}

	const handleChangePage = (_event, newPage) => {
		const offset = Number(newPage) * Number(pagin.limit)
		setPagin({
			...pagin,
			offset,
			page: newPage
		})
	}

	const handleChangeRowsPerPage = event => {
		setPagin({
			...pagin,
			page: 0,
			offset: 0,
			limit: event.target.value
		})
	}

	const handleDecision = (transactionId, amount) => () => {
		const message =
			'This will permanently lower your FGR percentage. This action cannot be undone.'
		const buttonText = 'I understand'
		setTimeout(() => {
			showNotification({
				message,
				cancelButton: true,
				cancelButtonText: `Keep my ${user?.fgrRate ?? 100}% FGR`,
				buttonText,
				buttonCallback: () => {
					showNotificationExtended({
						projectTitle: project?.title,
						total: amount,
						type: notificationExtTypes.UNSTAKING
					})
					unstake(transactionId)
				}
			})
		})
	}

	const handleUnstake =
		(transactionId, amount, stakedProject = null) =>
		() => {
			if (checkNcNoMagic()) {
				dispatch(setCreatePlatformWalletDialogOpen(true))
			} else {
				const projectTitle = stakedProject?.title ?? project?.title
				const stageId = stakedProject?.stageId ?? project?.stageId
				if (configuration?.unstakeFgrRate !== user?.fgrRate && stageId === 4) {
					const userFgrRate = user?.fgrRate ?? 100
					const message = `Before you unstake your tokens from ${projectTitle}, consider this: withdrawing means forfeiting your valuable ${userFgrRate}% Fan Governance Reward (across all projects) and negatively impact this project’s Go Score™. By keeping your tokens staked, you maintain the ${userFgrRate}% FGR and support the project's growth.`
					const title = 'Critical Decision'
					showNotification({
						title,
						message,
						imageHeader: true,
						cancelButton: true,
						cancelButtonText: `Keep my ${userFgrRate}% FGR`,
						buttonText: `Unstake and reset my FGR to ${configuration?.unstakeFgrRate}%`,
						variant: 'warning',
						buttonCallback: handleDecision(transactionId, amount)
					})
				} else {
					showNotificationExtended({
						projectTitle,
						total: amount,
						type: notificationExtTypes.UNSTAKING
					})
					unstake(transactionId)
				}
			}
		}

	const handleClickTransaction = () => {
		if (isAuthenticated) return
		dispatch(setLoginDialogOpen(true))
	}

	useEffect(() => {
		if (!_.isNil(projectId)) getProjectStakes()
	}, [pagin, projectId])

	const emptyRows =
		pagin.page > 0 ? Math.max(0, (1 + pagin.page) * pagin.limit - count) : 0

	return {
		user,
		isAuthenticated,
		pagin,
		count,
		order,
		stakes,
		project,
		orderBy,
		emptyRows,
		handleChangeRowsPerPage,
		handleRequestSort,
		getProjectStakes,
		handleChangePage,
		handleUnstake,
		handleClickTransaction
	}
}

export default useStakesTable
