import axios from 'axios'
import {
	removeAuthenticated,
	setLoginDialogOpen,
	setNotificationsCount,
	setShowConfetti,
	setTransactionsInProgress,
	updateSignInUser
} from 'component/header/signInSlice'
import useAxios from 'hooks/useAxios'
import { useDispatch, useSelector } from 'react-redux'
import { claimUserRewardsService } from 'services/auth.service'
import { getUserTierInfoService } from 'services/daoTier.service'
import { getUnvisiteNotificationCountService } from 'services/notification.service'
import { getLoggedInUserInfo, logoutService } from 'services/profile.service'
import { addObjectToLocalStorage } from 'utils/utils'
import { useNavigate } from 'react-router-dom'
import useWeb3 from 'hooks/useWeb3'
import { Mixpanel } from 'services/mixpanel.service'
import magic from 'utils/blockchain/config/constants'
import useLoadingMask from 'hooks/useLoadingMask'
import _ from 'lodash'

const useHeader = () => {
	const dispatch = useDispatch()
	const { axiosService } = useAxios()
	const { disconnectAsync } = useWeb3()
	const { showMask, hideMask } = useLoadingMask()
	const { isAuthenticated } = useSelector(state => state.signIn)
	const navigate = useNavigate()

	const getUnvisiteNotificationCount = () => {
		if (!isAuthenticated) {
			return
		}
		axiosService(getUnvisiteNotificationCountService(), (result, error) => {
			if (!error) {
				const { transactionInProgress, unvisitedCount } = result
				dispatch(setTransactionsInProgress(transactionInProgress))
				dispatch(setNotificationsCount(unvisitedCount))
			}
		})
	}

	const claimUserRewards = async userId => {
		await axios(claimUserRewardsService({ userId }))
		await relaodUserInfo()
		await getUnvisiteNotificationCount()
	}

	const afterFetchUser = data => {
		addObjectToLocalStorage(data)
		dispatch(updateSignInUser(data))
	}

	const relaodUserInfo = async () => {
		loadUserTierInfo()
		const res = await axios(getLoggedInUserInfo())
		afterFetchUser(res.data)
	}

	const loadUserTierInfo = async (cb = null) => {
		if (!isAuthenticated) {
			return
		}
		const daoInfo = await axios(getUserTierInfoService())
		addObjectToLocalStorage(daoInfo?.data)
		dispatch(updateSignInUser(daoInfo?.data))
		cb && cb()
	}

	const showConfetti = time => {
		dispatch(setShowConfetti(true))
		setTimeout(() => {
			dispatch(setShowConfetti(false))
		}, time)
	}

	const handleLogout = async () => {
		showMask()
		navigate('/')
		await axios(logoutService())
		// always disconnect for the case of whitelisted wallet connected
		await disconnectAsync()
		Mixpanel.reset()
		localStorage.clear()
		dispatch(removeAuthenticated())
		dispatch(setLoginDialogOpen(true))
		hideMask()
		if (!_.isNil(magic)) {
			await magic?.user?.logout()
		}
	}

	return {
		relaodUserInfo,
		loadUserTierInfo,
		showConfetti,
		claimUserRewards,
		handleLogout
	}
}

export default useHeader
