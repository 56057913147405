export const getAgregatedRecordsService = data => ({
	method: 'post',
	url: '/aggregator',
	data,
	autoexec: false
})

export const getNewsreelRecordsService = data => ({
	method: 'post',
	url: '/aggregator/newsreel',
	data,
	autoexec: false
})
