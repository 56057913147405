export const getActiveContests = (autoexec = true) => ({
	method: 'get',
	url: `/contest/active`,
	autoexec
})

export const enrollContest = ({ contestId, projectId }) => ({
	method: 'post',
	url: `/contest/enroll`,
	data: {
		contestId,
		projectId
	}
})

export const setContestNotifiedService = () => ({
	method: 'post',
	url: '/contest/notified'
})
