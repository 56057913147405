import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function useBlokchainWallet() {
	const [address, setAddress] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)
	const { ncWalletAddress } = useSelector(state => state.signIn)

	const handleOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const formatAddress = blockchainHash => {
		const blockchainHashText = `${blockchainHash.slice(
			0,
			4
		)}...${blockchainHash.slice(-4)}`
		return blockchainHashText
	}

	useEffect(() => {
		if (!_.isNil(ncWalletAddress?.address)) {
			const address = formatAddress(ncWalletAddress?.address)
			setAddress(address)
		}
	}, [ncWalletAddress])

	return {
		address,
		anchorEl,
		handleClose,
		handleOpen
	}
}
