import { useSelector, useDispatch } from 'react-redux'
import { setContestJoinedDialogOpen } from 'component/header/signInSlice'

export const useContestJoinedDialog = () => {
	const dispatch = useDispatch()
	const { contestJoinedDialogOpen: open } = useSelector(state => state.signIn)

	const close = () => {
		dispatch(setContestJoinedDialogOpen(false))
	}

	return {
		open,
		close
	}
}
