import { useParams } from 'react-router-dom'
import { LoadMask } from 'component/common'
import useHeader from '../header/useHeader'
import { useEffect } from 'react'
import { addObjectToLocalStorage } from 'utils/utils'

export default function SharedTwitter() {
	const { secret: tcs, token: tci } = useParams()
	const { loadUserTierInfo, relaodUserInfo } = useHeader()
	addObjectToLocalStorage({ tcs, tci })

	useEffect(() => {
		relaodUserInfo()
		loadUserTierInfo(() => {
			window.opener.postMessage({ msg: 'completed' }, '*')
			self.close()
		})
	}, [])

	return <LoadMask />
}
