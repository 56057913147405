import _ from 'lodash'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosFetch from 'hooks/useAxiosFetch'
import {
	getProfileUserById,
	getProfileByUsername
} from 'services/profile.service'
import { useDispatch, useSelector } from 'react-redux'
import {
	setUserId,
	updateUser,
	updateVerificationEmailData
} from './profileSlice'
import { setLoginDialogOpen } from 'component/header/signInSlice'
import { followUnfollowUser } from 'services/followUnfollow.service'
import useNotification from 'hooks/useNotification'
import { updateMetaTags } from 'utils/utils'
import useAxios from 'hooks/useAxios'

const useProfile = () => {
	const dispatch = useDispatch()
	const { username } = useParams()
	const { user, tabIndex, userId } = useSelector(state => state.profile)
	const { showNotification } = useNotification()
	const { user: signInUser, isAuthenticated } = useSelector(
		state => state.signIn
	)
	const navigate = useNavigate()
	const { axiosService } = useAxios()
	const isSignInUserProfile = signInUser.id === userId

	const { isAdmin } = signInUser

	const handleFollowUnfollowUserClick = async () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}

		const followingUser = user?.isFollowedByUser
		const res = await followUnfollowUser(userId, followingUser)
		if (res.status === 200) {
			const username = user.displayName
			const message = `You are ${
				followingUser ? 'no longer' : 'now'
			} following ${username}`
			showNotification({ message })
			dispatch(updateUser({ isFollowedByUser: !followingUser }))
		}
	}

	const [{ loading }, fetchUser] = useAxiosFetch(
		getProfileUserById(userId),
		afterFetchProfile
	)

	const getUserId = () => {
		axiosService(getProfileByUsername(username), (data, error) => {
			if (!error) {
				dispatch(setUserId(data?.id))
			} else navigate('/notfound')
		})
	}

	function afterFetchProfile(data, error) {
		if (!error) {
			dispatch(updateUser(data))
			const description = (data?.about ?? '').split('.')[0]
			updateMetaTags({
				title: `${data?.displayName} — Filmio`,
				description
			})
		}
	}

	const handleVerifyEmail = () => {
		dispatch(updateVerificationEmailData({ open: true }))
	}

	const handleOpenVerificationEmailModal = async () => {
		dispatch(updateVerificationEmailData({ open: true }))
	}

	useEffect(() => {
		if (!_.isNil(userId)) fetchUser()
	}, [userId])

	useEffect(() => {
		getUserId()
		return () => {
			dispatch(setUserId(null))
		}
	}, [username])

	return {
		user,
		isAdmin,
		loading,
		tabIndex,
		signInUser,
		isSignInUserProfile,
		handleOpenVerificationEmailModal,
		handleFollowUnfollowUserClick,
		handleVerifyEmail
	}
}

export default useProfile
