import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { getProjectGoScoreUnlockStatsService } from 'services/goscore.service'
import { useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'
import styles from './GoScoreUnlockProgress.module.scss'

export const GoScoreUnlockProgress = () => {
	const [data, setData] = useState([])
	const { projectId } = useSelector(state => state.projectDetail)
	const { axiosService } = useAxios()

	const getProjectGoScoreUnlockStats = () => {
		axiosService(
			getProjectGoScoreUnlockStatsService(projectId),
			(data, error) => {
				if (!error) {
					setData(data)
				}
			}
		)
	}

	useEffect(() => {
		if (!_.isNil(projectId)) getProjectGoScoreUnlockStats()
	}, [projectId])

	const renderProgress = (stageMetrics = []) => {
		return stageMetrics.map(sm => {
			return (
				<Box className={styles.progress_item} key={`metric-${sm.metricId}`}>
					<Box
						className={styles.progress_bar}
						sx={{
							background: `linear-gradient(90deg, #01BF52, #01ACBD, #333333 ${sm.percent}%);`
						}}
					></Box>
					<Typography>{sm.title}</Typography>
				</Box>
			)
		})
	}

	return (
		<Grid item md={7} className={styles.unlock_progress}>
			<Typography className={styles.title}>
				You have completed {data?.avgPercent?.toFixed(2)}% of the requirements
				to unlock the <strong>Go Score</strong>.
			</Typography>
			<Typography className={styles.faint}>
				You’ll find your progress towards completing the “Proof of Adoption”
				stage below.
			</Typography>
			<Box className={styles.progress_content}>
				{renderProgress(data?.stageMetrics)}
			</Box>
		</Grid>
	)
}
