import { Box } from '@mui/material'
import { LoadMask } from 'component/common'
import { AidropJoinDialog } from 'component/header/aidropJoinDialog'
import { updateMetaTags } from 'utils/utils'
import { Hero } from './hero'
import { Works } from './works'
import styles from './Home.module.scss'
import useHome from './useHome'

const Home = () => {
	const { loading } = useHome()

	updateMetaTags({})

	return (
		<>
			<AidropJoinDialog />
			{loading ? (
				<LoadMask />
			) : (
				<Box className={styles.home}>
					<Box className={styles.blanket}></Box>
					<Hero />
					<Works />
				</Box>
			)}
		</>
	)
}

export default Home
