'use client'

import Checkbox from '@mui/material/Checkbox'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useState } from 'react'

const theme = createTheme({
	palette: {
		primary: {
			main: '#FFFFFF'
		},
		neutral: {
			main: '#2DABE2',
			contrastText: '#fff'
		}
	},
	checkbox: { checkedColor: 'red' }
})

export default function ControlledCheckbox() {
	const [checked, setChecked] = useState(true)

	const handleChange = event => {
		setChecked(event.target.checked)
	}

	return (
		<ThemeProvider theme={theme}>
			<Checkbox
				checked={checked}
				onChange={handleChange}
				color="neutral"
				variant="contained"
				inputProps={{ 'aria-label': 'controlled' }}
			/>
		</ThemeProvider>
	)
}
