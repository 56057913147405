import {
	Box,
	Table,
	TableBody,
	TableCell,
	Typography,
	TableContainer,
	TablePagination,
	IconButton,
	TableHead,
	TableRow,
	Avatar,
	Icon
} from '@mui/material'
import { formatDateToRelative } from 'utils/date.utils'
import useRecentSignupsTable from './useRecentSignupsTable'
import styles from './RecentSignupsTable.module.scss'
import { Link } from 'react-router-dom'

const headCells = [
	{
		id: 'avatar',
		label: ''
	},
	{
		id: 'user',
		label: 'Name'
	},
	{
		id: 'createdAt',
		label: 'Join Date'
	},
	{
		id: 'actions',
		label: ''
	}
]

export default function RecentSignupsTable() {
	const {
		pagin,
		count,
		signups,
		emptyRows,
		handleFollowUnfollowUser,
		handleChangeRowsPerPage,
		handleChangePage
	} = useRecentSignupsTable()

	const renderRows = (data = []) => {
		return data.map(row => {
			const { id, avatar, username, createdAt, displayName, following } = row
			return (
				<TableRow key={id}>
					<TableCell align="left" width="10%">
						<Avatar
							alt={'avatar'}
							sx={{
								bgcolor: '#000000',
								border: '1px solid #ffffff'
							}}
							src={avatar}
						/>
					</TableCell>
					<TableCell align="left" width="40%">
						<Link to={`/profile/${username}`}>{displayName}</Link>
					</TableCell>
					<TableCell align="left" width="40%" className={styles.join_date}>
						{formatDateToRelative(createdAt, 'med')}
					</TableCell>
					<TableCell align="left" width="10%">
						<IconButton
							aria-label="follow"
							size="small"
							onClick={handleFollowUnfollowUser({
								following,
								userId: id,
								displayName
							})}
							className={styles.follow}
						>
							{!following && (
								<span>
									<Icon baseClassName="fal" className="fa-user-plus" />
								</span>
							)}
							{following && (
								<span>
									<Icon baseClassName="fal" className="fa-user-minus" />
								</span>
							)}
						</IconButton>
					</TableCell>
				</TableRow>
			)
		})
	}

	return (
		<Box className={styles.signups_table}>
			<h4>Recent Signups</h4>
			<Box>
				<TableContainer className={styles.table_container}>
					{signups?.length === 0 ? (
						<Typography className={styles.title}>
							No found signups yet
						</Typography>
					) : (
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									{headCells.map(headCell => {
										return (
											<TableCell
												key={headCell.id}
												align={headCell.align}
												padding={'none'}
											>
												{headCell.label}
											</TableCell>
										)
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{renderRows(signups)}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * emptyRows
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					)}
				</TableContainer>
				{signups?.length > 0 && (
					<TablePagination
						rowsPerPageOptions={[6, 18, 60]}
						component="div"
						count={count || 0}
						rowsPerPage={pagin.limit}
						classes={{ root: styles.pagination }}
						page={pagin.page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Box>
		</Box>
	)
}
