// components/feed/NewsreelItem.tsx
import { useState } from 'react'
import { ImageList } from '@mui/material'
import { UserAvatar } from './shared/UserAvatar'
import { ImageWithViewerNewsreel } from '../../../../../../common/imageWithViewerNewsreel/ImageWithViewerNewsreel'
import { useCommentHandler } from '../hooks/useCommentHandler'
import { FeedItem } from '../types'
import { UserInfo } from './shared/UserInfo'
import { CommentInput } from './shared/CommentInput'
import { InteractionsBar } from './shared/InteractionsBar'
import { Comment } from './shared/Comment'

export const NewsreelItem = ({
	item
}: {
	item: FeedItem & { type: 'newsreel' }
}) => {
	const [expandedComments, setExpandedComments] = useState(false)
	const { comment, handleComment, handleSubmitComment, clearComment } =
		useCommentHandler(item.record.id, 'newsreel')

	const {
		id,
		reactions,
		user,
		createdAt,
		project,
		content,
		comments,
		commentsCount,
		media
	} = item.record

	const [itemReactions, setItemReactions] = useState(reactions)

	console.log('NewsreelItem', id, commentsCount)

	return (
		<div className="flex flex-col items-start gap-3 overflow-hidden rounded-md bg-[#2196F3]/15 p-4">
			<div className="flex items-start gap-3">
				<UserAvatar user={user} />
				<UserInfo user={user} createdAt={createdAt} project={project} />
			</div>
			<div>
				<p className="text-white">{content}</p>
				{media.length > 0 && (
					<div className="mt-2">
						<ImageList
							variant="masonry"
							cols={Math.min(media.length, 3) || 2}
							gap={0}
						>
							<ImageWithViewerNewsreel media={media} />
						</ImageList>
					</div>
				)}
				<InteractionsBar
					id={id}
					type={item.type}
					hasComments
					commentsCount={commentsCount}
					reactions={itemReactions}
					setReactions={setItemReactions}
					onCommentClick={() => setExpandedComments(prev => !prev)}
				/>
			</div>
			<CommentInput
				value={comment}
				onChange={handleComment}
				onSubmit={handleSubmitComment}
				onClear={clearComment}
			/>
			{expandedComments && (
				<div className="ml-2 divide-y">
					{comments.map(comment => (
						<Comment key={comment.id} comment={comment} />
					))}
				</div>
			)}
		</div>
	)
}
