import { Box, Button, Grid, Icon, Typography } from '@mui/material'
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import images from 'assets/images'
import { LightTooltip } from 'component/common'
import styles from './Superfan.module.scss'

const SuperfanStatic = () => {
	const getLevelMetricInfo = (metricId, info, threshold) => {
		switch (metricId) {
			case 1:
				return {
					icon: 'gem',
					message: '0/100 FAN',
					toolTip: "How many tokens you've staked to this project",
					iconVariant: 'fal'
				}
			case 4:
				return {
					icon: 'star-half-stroke',
					message: '0.00',
					toolTip: 'Your review for this project',
					iconVariant: 'fas'
				}
			case 3:
				return {
					icon: 'share',
					message: '0',
					toolTip: "How many times you've shared this project",
					iconVariant: 'fal'
				}
			case 2:
				return {
					icon: 'heart',
					message: 'Following',
					toolTip:
						info?.percent >= 100
							? "You're following this project"
							: "You're not following this project",
					iconVariant: info?.percent >= 100 ? 'fas' : 'fal'
				}
		}
	}

	const renderLevelMetrics = () => {
		return [2, 1, 4].map(metricId => {
			const { icon, message, toolTip, iconVariant } = getLevelMetricInfo(
				metricId,
				true,
				0
			)
			const statusClass = 'complete'
			return (
				<Box
					className={`${styles.level_metric} ${styles[statusClass]}`}
					key={metricId}
				>
					<LightTooltip
						arrow
						placement="top"
						className="tooltip"
						title={toolTip}
					>
						<p>
							<Icon baseClassName={iconVariant} className={`fa-${icon}`} />
							{message}
						</p>
					</LightTooltip>
				</Box>
			)
		})
	}

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 5,
		width: '100%',
		position: 'relative',
		marginTop: 8,
		marginBottom: 4,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			background: '#000000'
		},
		[`&>.${linearProgressClasses.barColorPrimary}`]: {
			background: `#000 linear-gradient(90deg, #000 75%, #01ACBD 80%, #000000);`
		}
	}))

	return (
		<Grid item container md={6} className={styles.project_impact}>
			<Grid item md={8}>
				<Box className={styles.top}>
					<Box className={styles.impact_info}>
						<Typography component={'h2'}>
							<img src={images.blankHeart} />
							SuperFan Locked
						</Typography>
						<BorderLinearProgress variant="determinate" value={0} />
						<Box className={styles.superfan}>
							<Box className={styles.level_metrics}>{renderLevelMetrics()}</Box>
						</Box>
						<Box className={styles.benefits}>
							<Typography>
								Level 1 benefits include a{' '}
								<strong>
									<span>+2%</span> bonus
								</strong>
								<span>
									{' '}
									and a <em>SuperFan Badge</em>.
								</span>
							</Typography>
						</Box>
					</Box>
				</Box>
			</Grid>
			<Grid id="earnedFGR" item md={4} xs={12}>
				<Box className={styles.top}>
					<Box className={styles.fgr_info}>
						<Box>
							<Typography component={'h2'}>Your Staking Rewards</Typography>
							<Typography component={'h3'} className={styles.earned_fan}>
								<img src={images.newToken} alt="token" />0 FAN
							</Typography>
						</Box>
						<Box>
							<Typography>100% FGR</Typography>
							<Button disabled={true}>Claim</Button>
						</Box>
					</Box>
				</Box>
			</Grid>
		</Grid>
	)
}

export default SuperfanStatic
