import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1080,
			xl: 1620
		}
	},
	palette: {
		type: 'dark',
		primary: {
			main: '#ffffff',
			contrastText: '#ffffff'
		},
		secondary: {
			light: '#262626',
			main: '#262626',
			contrastText: '#262626'
		},
		success: {
			main: '#53abc9'
		},
		contrastThreshold: 3,
		tonalOffset: 0.2
	},
	typography: {
		fontFamily: 'Titillium Web'
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					padding: '8px 24px',
					borderRadius: '4px',
					textTransform: 'capitalize'
				},
				containedPrimary: {
					'&:hover': {
						background: '#005AFF'
					}
				}
			}
		},
		MuiPaper: {
			root: {
				color: '#ffffff'
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					color: '#ffffff'
				},
				colorSecondary: {
					'&$checked': {
						color: '#ffffff'
					}
				}
			}
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					input: {
						width: '100%',
						fontSize: '1rem',
						borderRadius: '0.5rem',
						padding: '9.7px 0.5rem'
					}
				}
			}
		},
		MuiIcon: {
			styleOverrides: {
				root: {
					color: '#ffff'
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: '#ffff',
					fontSize: '0.8rem',
					marginLeft: 0,
					fontStyle: 'italic',
					lineHeight: '1.6rem'
				}
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					color: '#ffff',
					textTransform: 'capitalize'
				}
			}
		}
	}
})
