import useAxiosFetch from 'hooks/useAxiosFetch'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getDashboardService,
	getSharerNameService
} from 'services/dashboard.service'
import { clearItems, setPage, updateItems } from './dashboardSlice'
import { useParams } from 'react-router-dom'
import {
	setJoinDialogOpen,
	setSocialJoinParams
} from 'component/header/signInSlice'

const useDashboard = () => {
	const dispatch = useDispatch()
	const [loadMore, setLoadMore] = useState(true)
	const { filter, sort, items, page } = useSelector(state => state.dashboard)
	const { isAuthenticated } = useSelector(state => state.signIn)
	const [sharerDisplayName, setSharerDisplayName] = useState([])
	const { userShortUrl, network } = useParams()
	const { activeContests } = useSelector(state => state.signIn)

	const afterFetch = (data, error) => {
		if (!error) {
			dispatch(updateItems(data))
			setLoadMore(data?.length > 0)
		}
	}

	const [{ loading }, fetchItems] = useAxiosFetch(
		getDashboardService({
			...filter,
			sort,
			page: page.value
		}),
		afterFetch
	)

	const [, getSharerName] = useAxiosFetch(
		getSharerNameService({
			userShortUrl
		}),
		(data, error) => {
			if (!error) {
				setSharerDisplayName(data?.sharerName)
			}
		}
	)

	const getItems = (clear = false) => {
		if (clear) {
			// clear items and reset page to 0, then fetch new items
			dispatch(clearItems())
			dispatch(setPage(0))
			fetchItems({ data: { page: 0 } })
		} else {
			// continue fetching items based on current page
			fetchItems({ data: { page: page.value } })
		}
	}

	const onScroll = () => {
		if (
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 10 &&
			loadMore &&
			!loading
		) {
			dispatch(setPage(page.value + 1))
		}
	}

	const handleOpenJoinDialog = () => {
		dispatch(setJoinDialogOpen(true))
	}

	// if filter or sort changes, reset page to 0 and fetch new data
	useEffect(() => {
		setLoadMore(true)
		getItems(true)
	}, [filter, sort])

	// if page changes, fetch new data
	useEffect(() => {
		if (page.value !== 0) {
			getItems(false)
		}
	}, [page])

	// clear items and reset page to 0 when component unmounts
	useEffect(() => {
		if (!isAuthenticated) getSharerName()
		return () => {
			dispatch(setPage(0))
		}
	}, [])

	useEffect(() => {
		dispatch(setSocialJoinParams({ userShortUrl, network }))
	}, [userShortUrl, network])

	return {
		items,
		loading,
		loadMore,
		isAuthenticated,
		sharerDisplayName,
		handleOpenJoinDialog,
		onScroll,
		hasBanner: activeContests?.length > 0
	}
}

export default useDashboard
