import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getFollowers, getFollowing } from 'services/followUnfollow.service'
import { UserCardItem } from '../userCardItem'
import { useSelector } from 'react-redux'
import useLoadingMask from 'hooks/useLoadingMask'
import { useEffect } from 'react'
import _ from 'lodash'

const FollowingFollowerTab = ({ type }) => {
	const { userId } = useSelector(state => state.profile)
	const { showMask, hideMask } = useLoadingMask()
	const service = type === 'follower' ? getFollowers : getFollowing

	const [{ response, loading }, fetchUsers] = useAxiosFetch({
		...service(userId),
		autoexec: false
	})

	useEffect(() => {
		if (!_.isNil(userId)) fetchUsers()
	}, [userId])

	if (loading) showMask()
	else hideMask()

	const renderUsers = users => {
		const showDeleteButton = type !== 'follower'
		return users.map(user => (
			<Grid item md={4} key={user?.id}>
				<UserCardItem {...{ ...user, fetchUsers, showDeleteButton }} />
			</Grid>
		))
	}

	return (
		<Grid container spacing={4} justifyContent={'center'}>
			{response?.data && renderUsers(response?.data)}
		</Grid>
	)
}

FollowingFollowerTab.propTypes = {
	type: PropTypes.string
}

export default FollowingFollowerTab
