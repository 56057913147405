import { useSocialShareSection } from 'hooks/useSocialShareSection'
import {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogTitle
} from '@/components/ui/dialog'
import { Textarea } from '@/components/ui/textarea'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { useState } from 'react'
import { CiMail } from 'react-icons/ci'
import { SlSocialFacebook } from 'react-icons/sl'
import { FiTwitter, FiLinkedin } from 'react-icons/fi'
import { Label } from '@/components/ui/label'
export const SocialShareSection = ({ isMobile = false }) => {
	const {
		open,
		link,
		message,
		limitChars,
		handleOpenDialog,
		setMessage,
		handleClose,
		handleCopy,
		handleCopyLink,
		handleCopyMobile
	} = useSocialShareSection()
	return (
		<div
			className={` ${!isMobile?'max-[639px]:hidden sm:!flex':'max-[639px]:!flex sm:!hidden'} flex justify-start items-center gap-4`}
		>
			<Button variant="link" className="p-0 m-0" onClick={handleCopyLink}>
				<CiMail className="w-4 h-4" />
			</Button>
			<Button
				variant="link"
				className="p-0 m-0"
				onClick={handleOpenDialog('facebook')}
			>
				<SlSocialFacebook className="w-4 h-4" />
			</Button>
			<Button
				variant="link"
				className="p-0 m-0"
				onClick={handleOpenDialog('x')}
			>
				<FiTwitter className="w-4 h-4" />
			</Button>
			<Button
				variant="link"
				className="p-0 m-0"
				onClick={handleOpenDialog('linkedin')}
			>
				<FiLinkedin className="w-4 h-4" />
			</Button>
			{open && (
			<Dialog open={open} onOpenChange={handleClose}>
			  <DialogContent className="sm:max-w-[335px]">
				<DialogHeader>

				  <DialogTitle className='text-xl tracking-[-0.025em] font-semibold'>Share</DialogTitle>
				</DialogHeader>
				<div className="space-y-4">
					<Label>Message</Label>
				  <Textarea
					placeholder="Message"
					value={message}
					maxLength={limitChars}
					onChange={(e) => {
					  if (e.target.value.length <= limitChars) setMessage(e.target.value);
					}}
					className="w-full custom-scrollbar text-xs"
					rows={5}
				  />
				  <a href="#" onClick={handleCopyMobile} className="w-full sm:hidden text-white flex items-center justify-end text-sm space-x-2 hover:cursor-pointer">
					<i className="far fa-copy"></i><span>Copy your Message</span> 
				  </a>
				  <span className="text-white flex items-center text-sm space-x-2">Link</span>
				  <div className="mt-2 text-gray-800">
					<Input value={link} readOnly className="w-full cursor-not-allowed" />
				  </div>
				  <div className="text-sm">
					When you click <b>share</b>, your message will be copied to the clipboard. Simply paste it into the share box at your social network of choice.
				  </div>
				</div>
				<DialogFooter>
				  <Button variant="secondary" onClick={handleClose}>Cancel</Button>
				  <Button onClick={handleCopy} variant="outline" className='text-white'>Share!</Button>
				</DialogFooter>
			  </DialogContent>
			</Dialog>
		  )}
		</div> 
	)
}
