import { useEffect, useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getSuperfanLevelService } from 'services/superfan.service'
import {
	claimFgrBlockchainService,
	claimFGRService,
	getProjectFgrUnclaimed
} from 'services/project.service'
import useNotification from 'hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import useHeader from 'component/header/useHeader'
import { setFanLevel } from './superfanSlice'
import useAxios from 'hooks/useAxios'
import {
	setClaiming,
	showFgrModal
} from 'component/header/claimFgrDialog/claimFgrSlice'
import useNotifications from 'component/header/notifications/useNotifications'
import { reloadRecentActivity } from 'component/profile/walletTab/walletTabSlice'
import _ from 'lodash'

const useSuperfan = (autoexec = true) => {
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const { fanLevel } = useSelector(state => state.superfan)
	const [fgrEarned, setFGREarned] = useState(0)
	const [openDialog, setOpenDialog] = useState(false)
	const { project, projectId } = useSelector(state => state.projectDetail)
	const { claiming } = useSelector(state => state.claimFgr)
	const dispatch = useDispatch()
	const { showNotification } = useNotification()
	const { relaodUserInfo, showConfetti } = useHeader()
	const { axiosService } = useAxios()
	const { getUnvisiteNotificationCount } = useNotifications()

	const [{ loading }, updateSuperfanLevel] = useAxiosFetch(
		getSuperfanLevelService(projectId),
		(data, error) => {
			if (!error) {
				dispatch(setFanLevel(data))
			}
		}
	)

	const claimFgrBlockchain = transactionId => {
		axiosService(claimFgrBlockchainService(transactionId), (data, error) => {
			if (!error) {
				showNotification({ message: 'FGR tokens claimed successfully!' })
			}
			getUnvisiteNotificationCount()
			dispatch(setClaiming(false))
			dispatch(reloadRecentActivity())
			relaodUserInfo()
		})
	}

	const [{ loading: claimingFRG }, claimFGR] = useAxiosFetch(
		claimFGRService(projectId),
		(data, error) => {
			if (!error) {
				const title = project.title
				claimFgrBlockchain(data?.id)
				dispatch(showFgrModal({ fgrEarned, title, claiming: true }))
				showConfetti(5000)
				setFGREarned(0)
				getUnvisiteNotificationCount()
			}
		}
	)

	const [{ loading: loadingUnclaimedFRG }, getProjectFGR] = useAxiosFetch(
		getProjectFgrUnclaimed(projectId),
		(data, error) => {
			if (!error) {
				setFGREarned(data.total)
			}
		},
		!isAuthenticated
	)

	const handleClaim = () => {
		claimFGR()
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			if (autoexec) {
				updateSuperfanLevel()
				getProjectFGR()
			}
		}
	}, [projectId])

	const disabledClaimBtn = fgrEarned === 0 || claiming
	const showMask = loading || claimingFRG || loadingUnclaimedFRG

	return {
		user,
		fanLevel,
		showMask,
		fgrEarned,
		openDialog,
		setOpenDialog,
		handleClaim,
		isAuthenticated,
		updateSuperfanLevel,
		disabledClaimBtn
	}
}

export default useSuperfan
