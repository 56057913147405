import {
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Button,
	Icon
} from '@mui/material'
import { Link } from 'react-router-dom'
import useFgrTable from './useFgrTable'
import styles from './FgrTable.module.scss'
import { NumericFormat } from 'react-number-format'
import { getFileUrl } from 'utils/url.util'
import { formatDateToRelative } from 'utils/date.utils'

const headCells = [
	{
		id: 'title',
		label: 'Project'
	},
	{
		id: 'creator',
		label: 'Creator'
	},
	{
		id: 'amount',
		label: 'Amount'
	},
	{
		id: 'lastClaimed',
		label: 'Last Claimed'
	},
	{
		id: 'action',
		label: ''
	}
]

function EnhancedTableHead() {
	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.align}
							padding={'none'}
						>
							{headCell.label}
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

export default function FgrTable() {
	const {
		pagin,
		emptyRows,
		projectsFgr,
		fgrProjectIdInProgress,
		handleChangeRowsPerPage,
		handleChangePage,
		handleClaimFgr
	} = useFgrTable()

	const renderRows = (data = []) => {
		return data.map(row => {
			const { id, slug, user, title, poster, fgrUnclaimed, lastClaimingDate } =
				row
			const loading = fgrProjectIdInProgress === id
			return (
				<TableRow key={id}>
					<TableCell scope="row" width="30%" align="left">
						<Box className={styles.project}>
							<img src={getFileUrl(poster)} className={styles.poster} />
							<Link to={`/project/${slug}`}>{title}</Link>
						</Box>
					</TableCell>
					<TableCell align="left" width="20%">
						<Link to={`/profile/${user?.username}`}>{user?.displayName}</Link>
					</TableCell>
					<TableCell align="left" width="20%">
						<NumericFormat
							value={fgrUnclaimed}
							displayType="text"
							thousandSeparator=","
						/>
					</TableCell>
					<TableCell align="left" width="20%">
						{lastClaimingDate
							? formatDateToRelative(lastClaimingDate)
							: 'Never Claimed'}
					</TableCell>
					<TableCell align="left" width="10%" maxwidth="10%">
						<Button
							className={styles.claim_btn}
							onClick={handleClaimFgr({
								title,
								fgrEarned: fgrUnclaimed,
								projectId: id
							})}
							endIcon={
								loading && (
									<Icon baseClassName="far" className="fa-spinner fa-spin" />
								)
							}
						>
							{!loading && 'Claim'}
						</Button>
					</TableCell>
				</TableRow>
			)
		})
	}

	return (
		<Box className={styles.activity_table}>
			<Box>
				<TableContainer className={styles.table_container}>
					{projectsFgr?.rows?.length === 0 ? (
						<Typography className={styles.title}>
							No found wallet activities yet
						</Typography>
					) : (
						<Table stickyHeader>
							<EnhancedTableHead />
							<TableBody>
								{renderRows(projectsFgr?.rows)}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * emptyRows
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					)}
				</TableContainer>
				{projectsFgr?.rows?.length > 0 && (
					<TablePagination
						rowsPerPageOptions={[6, 18, 60]}
						component="div"
						count={projectsFgr?.count || 0}
						rowsPerPage={pagin?.limit}
						classes={{ root: styles.pagination }}
						page={pagin?.page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Box>
		</Box>
	)
}
