import _ from 'lodash'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { CircularProgressBar } from 'component/common/circularProgressBar'
import {
	getProjectGoScoreMilestoneService,
	getProjectGoScoreService
} from 'services/goscore.service'
import { ScoreDialog } from '../scoreDialog'
import stageImages from 'assets/images/stages'
import styles from './GoScore.module.scss'
import { useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'

const GoScore = ({ color, showText = true }) => {
	const { projectId } = useSelector(state => state.projectDetail)
	const [milestones, setMilestones] = useState(false)
	const [data, setData] = useState({})
	const [open, setOpen] = useState(false)
	const [isHover, setIsHover] = useState(false)
	const { axiosService } = useAxios()

	const getProjectGoScoreMilestone = () => {
		axiosService(
			getProjectGoScoreMilestoneService(projectId),
			(data, error) => {
				if (!error) {
					setMilestones(data)
				}
			}
		)
	}

	const getProjectGoScore = () => {
		axiosService(getProjectGoScoreService(projectId), (data, error) => {
			if (!error) {
				setData(data)
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			getProjectGoScoreMilestone()
			getProjectGoScore()
		}
	}, [projectId])

	const handleMouseEnter = () => {
		setIsHover(true)
	}

	const handleMouseLeave = () => {
		setIsHover(false)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleOpen = () => {
		if (showText && !data?.locked) setOpen(true)
	}

	const topPercent = Math.round(100 - data?.percentile) + 1

	return (
		<Box className={styles.container}>
			<Box className={styles.score_container}>
				<Box
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					className={styles.score}
					style={{
						boxShadow: isHover ? `0 0 0.9rem 0.4rem ${color}` : 'none'
					}}
					onClick={handleOpen}
				>
					<CircularProgressBar
						{...{
							height: 104,
							lineWidth: 6,
							lineColor: _.get(milestones, [0, 'color']),
							background: '#1d6878',
							lineEmptyColor: '#1c6878',
							time: 0.5,
							value: data?.stakingPercent,
							classes: `${styles.bar}`,
							id: 'stake'
						}}
					/>
					<CircularProgressBar
						{...{
							height: 94,
							lineWidth: 6,
							lineColor: _.get(milestones, [1, 'color']),
							background: '#1d6878',
							lineEmptyColor: '#1c6878',
							time: 0.5,
							value: data?.socialPercent,
							classes: `${styles.bar}`,
							id: 'social'
						}}
					/>
					<CircularProgressBar
						{...{
							height: 84,
							lineWidth: 6,
							lineColor: _.get(milestones, [2, 'color']),
							background: '#121212',
							lineEmptyColor: '#1c6878',
							time: 0.5,
							value: data?.projectPercent,
							classes: `${styles.bar}`,
							id: 'projectbar'
						}}
					/>
					<img src={stageImages.scoreRing} className={styles.ring_image} />
					{data?.locked ? (
						<img src={stageImages.locked} className={styles.center_image} />
					) : (
						<Typography className={styles.center_text}>
							{data.goScore && Math.round(data.goScore)}
						</Typography>
					)}
				</Box>
				{showText &&
					(!data?.locked ? (
						<Typography className={styles.text} onClick={handleOpen}>
							This project is in the top {topPercent}% on the platform!
						</Typography>
					) : (
						<Typography className={styles.text} onClick={handleOpen}>
							This project is locked
						</Typography>
					))}
			</Box>
			{showText && (
				<Typography className={styles.bottom_text}>
					Go Score <sup>TM</sup>
				</Typography>
			)}
			<ScoreDialog {...{ open, handleClose, data }} />
		</Box>
	)
}

GoScore.propTypes = {
	color: PropTypes.string,
	showText: PropTypes.bool
}

export default GoScore
