// components/shared/UserInfo.tsx
import { Link } from 'react-router-dom'
import { Crown } from 'lucide-react'
import { User, Project } from '../../types'
import { formatDateToRelative } from 'utils/date.utils'

interface UserInfoProps {
	user: User
	createdAt: string
	project?: Project | null
}

export const UserInfo = ({ user, createdAt, project }: UserInfoProps) => (
	<div className="flex-1">
		<div className="flex items-center gap-2">
			<Link
				className="font-semibold text-white hover:underline"
				to={`/profile/${user.username}`}
			>
				{user.displayName}
			</Link>
			{user.isVerified === 1 && (
				<span className="flex items-center gap-1 rounded-full bg-blue-500/10 px-2 py-0.5 text-xs text-blue-500">
					<Crown className="h-3 w-3" />
					Verified
				</span>
			)}
		</div>
		<div className="flex gap-2 text-sm text-gray-400">
			<span>{formatDateToRelative(createdAt)}</span>
			{project && (
				<Link to={`/project/${project.slug}`} className="underline">
					from {project.title}
				</Link>
			)}
		</div>
	</div>
)
