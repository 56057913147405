'use client'

import { useState } from 'react'
import { Box, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { TextArea } from 'component/common/textArea'
import { addCommentService } from 'services/comment.service'
import useNotification from 'hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import {
	reviewCommentAddedHook,
	setShowAddCommentForm
} from 'component/projectDetail/projectDetailSlice'
import styles from './AddCommentForm.module.scss'
import useAxios from 'hooks/useAxios'
import { commentAddedHook } from 'component/newsreelPage/newsreelPageSlice'
import { Mixpanel } from 'services/mixpanel.service'
import { newsreelCommentAddedHook } from 'component/common/newsreelTab/newsreelTabSlice'
import { setLoginDialogOpen } from 'component/header/signInSlice'

const AddCommentForm = ({ feedId, type, reload }) => {
	const [content, setContent] = useState('')
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const { showNotification } = useNotification()
	const dispatch = useDispatch()
	const { axiosService } = useAxios()

	const addComment = () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}
		axiosService(
			addCommentService({
				[`${type}Id`]: feedId,
				content
			}),
			(data, error) => {
				if (!error) {
					Mixpanel.track('new_comment_add_event', {
						distinct_id: user.id,
						displayName: user.displayName,
						username: user.username,
						...data
					})
					if (type === 'newsreel')
						dispatch(newsreelCommentAddedHook({ ...data, feedId }))
					dispatch(commentAddedHook({ ...data, feedId }))
					dispatch(reviewCommentAddedHook({ ...data, feedId }))
					setContent('')
					showNotification({ message: 'Comment added successfully' })
					reload()
					setTimeout(() => {
						handleClose()
					}, 0)
				}
			}
		)
	}

	const handleClose = () => {
		dispatch(setShowAddCommentForm(false))
	}

	return (
		<Box className={styles.add_comment}>
			<TextArea
				autoFocus
				maxRows={5}
				aria-label="comment"
				value={content}
				limitChars={700}
				onChange={e => {
					setContent(e.target.value)
				}}
			/>
			<Button className="yellow-bg-button" onClick={addComment}>
				Reply
			</Button>
			<Button variant="text" onClick={handleClose}>
				Cancel
			</Button>
		</Box>
	)
}

AddCommentForm.propTypes = {
	feedId: PropTypes.string,
	type: PropTypes.string,
	reload: PropTypes.func
}

export default AddCommentForm
