import originality from './originality.png'
import originalityRing from './originality-ring.png'
import adoption from './adoption.png'
import adoptionRing from './adoption-ring.png'
import development from './development.png'
import developmentRing from './development-ring.png'
import distribution from './distribution.png'
import distributionRing from './distribution-ring.png'
import scoreRing from './score-ring.png'
import locked from './locked.png'

const stagesImages = {
	originalityRing: originalityRing.src,
	originality: originality.src,
	adoption: adoption.src,
	adoptionRing: adoptionRing.src,
	development: development.src,
	developmentRing: developmentRing.src,
	distribution: distribution.src,
	distributionRing: distributionRing.src,
	scoreRing: scoreRing.src,
	locked: locked.src
}

export default stagesImages
