import { Box, Button, Dialog, Typography } from '@mui/material'
import fgrClaimTop from 'assets/images/fgr_claim_top.svg'
import styles from './ClaimFgrDialog.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { hideFgrModal } from './claimFgrSlice'

export const ClaimFgrDialog = () => {
	const { open, projectTitle, fgrClaimed } = useSelector(
		state => state.claimFgr
	)
	const dispatch = useDispatch()
	const handleClose = () => {
		dispatch(hideFgrModal())
	}
	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={styles.fgr_dialog}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
		>
			<img src={fgrClaimTop.src} />
			<Box className={styles.content}>
				<Typography component={'h1'}>Congratulations</Typography>
				<Typography>
					{' '}
					You just claimed <span> {fgrClaimed} FAN Tokens</span>!{' '}
				</Typography>
				<Typography>Thank you for supporting {projectTitle}! </Typography>
				<Box className={styles.controls}>
					<Button onClick={handleClose}>Got It!</Button>
				</Box>
			</Box>
			<Box className={styles.detail_dialog}></Box>
		</Dialog>
	)
}
