import _ from 'lodash'
import { Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
	closeMagicEmailUpdateDialog,
	updateMagicEmailUpdateDialogData
} from 'component/profile/profileSlice'
import { updateProfile } from 'component/profileEdit/profileEditSlice'
import { CustomDialog, TextFieldControl } from 'component/common'
import useAxios from 'hooks/useAxios'
import styles from './MagicEmailUpdateDialog.module.scss'
import magic from '../../../../utils/blockchain/config/constants'
import {
	magicEmailUpdateService,
	isEmailAvailableService
} from 'services/profile.service'
import useNotification from 'hooks/useNotification'

export const MagicEmailUpdateDialog = () => {
	const dispatch = useDispatch()
	const { showNotification } = useNotification()
	const { axiosService } = useAxios()
	const { magicEmailUpdateData } = useSelector(state => state.profile)
	const { open, email, error, isInProgress } = magicEmailUpdateData
	const { user: signInUser } = useSelector(state => state.signIn)

	const setEmail = email => {
		dispatch(updateMagicEmailUpdateDialogData({ email }))
	}

	const setError = error => {
		dispatch(updateMagicEmailUpdateDialogData({ error }))
	}

	const setIsInProgress = isInProgress => {
		dispatch(updateMagicEmailUpdateDialogData({ isInProgress }))
	}

	const handleClose = () => {
		dispatch(closeMagicEmailUpdateDialog())
	}

	const afterEmailAvailabilityCheck = async (data, error) => {
		if (error) {
			console.error('Failed to check email availability', error)
			setError('Failed to check email availability')
			setIsInProgress(false)
			return
		}

		if (!data?.isEmailAvailable) {
			setError('Email is already in use')
			setIsInProgress(false)
			return
		}

		try {
			const isEmailUpdated = await magic.auth.updateEmailWithUI({ email })
			if (!isEmailUpdated) {
				setError('Failed to update email')
				setIsInProgress(false)
				return
			}

			const idToken = await magic.user.getIdToken()
			axiosService(magicEmailUpdateService({ idToken }), afterEmailUpdate)
		} catch (error) {
			console.error('Failed to update email', error)
			setError('Failed to update email')
			setIsInProgress(false)
		}
	}

	const afterEmailUpdate = (_data, error) => {
		if (error) {
			console.error('Failed to update email', error)
			setError('Failed to update email')
			setIsInProgress(false)
			return
		}

		dispatch(updateProfile({ email: _data?.newEmail }))
		dispatch(closeMagicEmailUpdateDialog())
		showNotification({ message: 'Email updated successfully' })
		setIsInProgress(false)
	}

	const handleUpdateEmail = async () => {
		const errorMessage = signInUser?.isRestricted
			? "You can't change email"
			: !email
			? 'Email is required'
			: ''

		if (errorMessage) {
			setError(errorMessage)
			setIsInProgress(false)
			return
		}
		setError('')
		setIsInProgress(true)
		axiosService(isEmailAvailableService(email), afterEmailAvailabilityCheck)
	}

	return (
		<CustomDialog {...{ open, handleClose, variant: 'email_verification' }}>
			<Box className={styles.email_verification}>
				<Box>
					<Typography component={'h1'}>Enter your new email</Typography>
					<TextFieldControl
						error={error}
						classes={{ root: styles.email_input }}
						hiddenLabel
						fullWidth
						name="email"
						placeholder="Email"
						value={email}
						onChange={e => {
							setEmail(_.trim(e.target.value))
						}}
					/>
				</Box>
				<Box className={styles.actions}>
					<Button
						className="yellow-bg-button"
						onClick={handleUpdateEmail}
						disabled={isInProgress}
					>
						{isInProgress ? 'Updating...' : 'Update Email'}
					</Button>
					<Button onClick={handleClose}>Cancel</Button>
				</Box>
			</Box>
		</CustomDialog>
	)
}
