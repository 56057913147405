export const getProfileUserById = userId => ({
	method: 'get',
	url: `/profile/${userId}`
})
export const getProfileByUsername = username => ({
	method: 'get',
	url: `/profile/username/${username}`,
	autoexec: true
})

export const getProfileEditUserById = userId => ({
	method: 'get',
	url: `/profile/edit/${userId}`,
	autoexec: true
})

export const getProfileProjectListService = () => ({
	method: 'get',
	url: '/profile/projects/list',
	autoexec: true
})

export const magicSignUpService = data => ({
	method: 'put',
	url: '/profile/magicSignUp',
	data
})

export const magicSignInService = data => ({
	method: 'put',
	url: '/profile/magicSignIn',
	data
})

export const ncMagicWalletClaimService = data => ({
	method: 'put',
	url: '/profile/ncMagicWalletClaim',
	data
})

export const magicEmailUpdateService = data => ({
	method: 'put',
	url: '/profile/magicEmailUpdate',
	data
})

export const isEmailAvailableService = email => ({
	method: 'get',
	url: `/profile/isEmailAvailable/${email}`
})

export const claimUserService = data => ({
	method: 'post',
	url: '/profile/claimUser',
	data
})

export const claimExistingSaleUserService = data => ({
	method: 'post',
	url: '/profile/claimExistingUser',
	data
})

export const completeKycService = data => ({
	method: 'post',
	url: '/profile/completeKyc',
	data
})

export const editProfileService = data => {
	const formData = new FormData()
	const projectFields = [
		'id',
		'email',
		'username',
		'displayName',
		'firstName',
		'lastName',
		'about',
		'countryId',
		'birthday',
		'website',
		'profession',
		'genderId',
		'headerImg',
		'avatar',
		'socialNetworks'
	]

	const files = ['avatar', 'headerImg']

	projectFields.forEach(key => {
		if (key === 'socialNetworks') {
			formData.append(key, JSON.stringify(data[key]))
		} else if (data[key] instanceof File) {
			formData.append(key, data[key], data[key].name)
		} else if (!files.includes(key)) {
			formData.append(key, data[key])
		}
	})

	return {
		method: 'put',
		url: '/profile',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		autoexec: false
	}
}

export const getProfileSettingsUserById = () => ({
	method: 'get',
	url: '/profile/settings'
})

export const validateEmailCodeService = data => ({
	method: 'post',
	url: '/profile/validateEmailCode',
	data
})

export const sendEmailCodeService = data => ({
	method: 'post',
	url: '/profile/sendEmailCode',
	data
})

export const updateProfileSettingsService = data => ({
	method: 'put',
	url: '/profile/settings',
	data,
	autoexec: false
})

export const getProfileTransactionsService = ({
	search,
	limit,
	offset,
	order,
	orderBy,
}) => ({
	method: 'get',
	url: `/profile/transactions`,
	params: { search, limit, offset, order, orderBy }
})

export const getProfileStakes = (userId, limit, offset, order, orderBy, search) => ({
	method: 'get',
	url: `/profile/stakes/${userId}`,
	params: { limit, offset, order, orderBy, search },
	autoexec: false
})

export const getProfileEarnings = (userId, limit, offset, order, orderBy, search) => ({
	method: 'get',
	url: `/profile/earnings/${userId}`,
	params: { limit, offset, order, orderBy, search },
	autoexec: false
})

export const getProfileActionsEarnings = (userId, autoexec = true) => ({
	method: 'get',
	url: `/profile/actionEarnings/${userId}`,
	autoexec
})

export const getProfileStakesTotal = userId => ({
	method: 'get',
	url: `/profile/stakesTotal/${userId}`,
	autoexec: true
})

export const getProfileBalanceVariance = userId => ({
	method: 'get',
	url: `/profile/balance/variance/${userId}`,
	autoexec: true
})

export const getProfileEarningsVariance = userId => ({
	method: 'get',
	url: `/profile/earnings/variance/${userId}`,
	autoexec: true
})

export const getLoggedInUserInfo = () => ({
	method: 'get',
	url: '/profile/loggedUserInfo'
})

export const getTeamUsers = params => ({
	method: 'get',
	url: '/profile/teamUsers',
	params,
	autoexec: true
})

export const donateToUser = data => ({
	method: 'post',
	url: '/profile/donate',
	data,
	autoexec: false
})

export const getProfileBalance = userId => ({
	method: 'get',
	url: `/profile/balance/${userId}`,
	autoexec: true
})

export const getProfileWalletOverview = userId => ({
	method: 'get',
	url: `/profile/wallet/overview/${userId}`
})

export const getHodl = () => ({
	method: 'get',
	url: `/profile/hodl`
})

export const donateToProject = data => ({
	method: 'post',
	url: '/profile/donateProject',
	data
})

export const updateUserFields = data => ({
	method: 'put',
	url: '/profile/fields',
	data
})

export const getWelcomeMessageService = () => ({
	method: 'get',
	url: `profile/welcomeMessage`
})

export const reconcileTransactionService = transactionId => ({
	method: 'post',
	url: `profile/reconcile/transaction`,
	data: { transactionId }
})

export const retryTransactionService = transactionId => ({
	method: 'post',
	url: `profile/retry/transaction`,
	data: { transactionId }
})

export const saleUserAccessClaimService = () => ({
	method: 'post',
	url: `profile/saleAccessClaim`
})

export const logoutService = () => ({
	method: 'post',
	url: `profile/logout`
})
