import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

function createData(name, transinfo) {
	return {
		name,
		transinfo
	}
}

const rows = [
	createData(
		'Tx Hash',
		'8ffce5824127dc84da5476471cbe4aab6072a8382808542f7cb8c0499055daba'
	),
	createData('Block', '66078898'),
	createData('Timestamp', '9 months ago (2021-09-25T00:22:24.000)'),
	createData(
		'Tx Hash',
		'8ffce5824127dc84da5476471cbe4aab6072a8382808542f7cb8c0499055daba'
	),
	createData('Block', '66078898'),
	createData('Timestamp', '9 months ago (2021-09-25T00:22:24.000)'),
	createData(
		'Tx Hash',
		'8ffce5824127dc84da5476471cbe4aab6072a8382808542f7cb8c0499055daba'
	),
	createData('Block', '66078898'),
	createData('Timestamp', '9 months ago (2021-09-25T00:22:24.000)'),
	createData(
		'Tx Hash',
		'8ffce5824127dc84da5476471cbe4aab6072a8382808542f7cb8c0499055daba'
	),
	createData('Block', '66078898'),
	createData('Timestamp', '9 months ago (2021-09-25T00:22:24.000)'),
	createData(
		'Tx Hash',
		'8ffce5824127dc84da5476471cbe4aab6072a8382808542f7cb8c0499055daba'
	),
	createData('Block', '66078898'),
	createData('Timestamp', '9 months ago (2021-09-25T00:22:24.000)')
]

export default function EnhancedTable() {
	return (
		<Box sx={{ width: '100%' }}>
			<Paper
				sx={{ width: '100%', mb: 2 }}
				className="wallet-table earning-table"
			>
				<TableContainer>
					<Table aria-labelledby="tableTitle">
						<TableBody>
							{rows.map((row, index) => {
								return (
									<TableRow role="checkbox" tabIndex={-1} key={row.name}>
										<TableCell
											scope="row"
											padding="none"
											width="25%"
											align="left"
										>
											{row.name}
										</TableCell>
										<TableCell width="20%" className="link-contain">
											{row.transinfo}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	)
}
