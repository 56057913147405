import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useWalletClient, useDisconnect, useAccount, useNetwork, useConnect, useSwitchNetwork } from 'wagmi'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { contractsConfig, chains, defaultNetwork } from 'utils/blockchain/config/constants'
import images from 'assets/images'
import { isMetamask, isMobile } from 'utils/utils'
import _ from 'lodash'

const useWeb3 = () => {
	const { connectors, connectAsync } = useConnect({
		chainId: chains[0]?.id
	})
	const { isAuthenticated, user } = useSelector(state => state.signIn)
	const { isConnected, address } = useAccount()
	const { data: signer } = useWalletClient()
	const { disconnectAsync } = useDisconnect()
	const { chain } = useNetwork()
	const { open: openConnectWalletModal } = useWeb3Modal()
	const [isConnectedWrongNetwork, setIsConnectedWrongNetwork] = useState(false)
	const { switchNetwork, switchNetworkAsync } = useSwitchNetwork()

	const metamaskLogin = async (network) => {
		if (isMetamask() || !isMobile() && !isConnected) {
			const injectedConnector = connectors.find(connector => connector.id === 'injected')
			if (!_.isNil(injectedConnector)) {
				let chainId = chains[0]?.id
				if(!_.isNil(network)) {
					chainId = chains.find(chain => chain?.name === network)?.id
				}
				await connectAsync({ chainId, connector: injectedConnector })
			}
		}
	}

	const importFanTokenIntoWallet = useCallback(async () => {
		if (!signer) {
			return
		}

		return await signer.watchAsset({
			type: 'ERC20',
			options: {
				address: contractsConfig.fanToken.address[defaultNetwork],
				symbol: 'FAN',
				decimals: 8,
				image: images.tokenIcon
			}
		})
	}, [signer, contractsConfig])

	// TODO find a better place for this useEffect
	useEffect(() => {
		const isNonCustodial = user?.isNonCustodial === 1
		const isWrongNetwork = (isAuthenticated && !(chain?.id in chains.map(chain => chain?.id)) && isNonCustodial)

		if (isWrongNetwork !== isConnectedWrongNetwork) {
			setIsConnectedWrongNetwork((isAuthenticated && !(chain?.id in chains.map(chain => chain?.id)) && isNonCustodial))
		}
	}, [isAuthenticated, chain, user?.isNonCustodial, chains])

	return {
		signer,
		address,
		connectors,
		isConnected,
		isConnectedWrongNetwork,
		importFanTokenIntoWallet,
		openConnectWalletModal,
		disconnectAsync,
		metamaskLogin,
		connectAsync,
		switchNetwork,
		switchNetworkAsync
	}
}

export default useWeb3
