import _ from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { CircularProgressBar } from 'component/common/circularProgressBar'
import stageImages from 'assets/images/stages'
import styles from './GoScore.module.scss'

const GoScore = ({ color, showText = true, goscore, goScoreMilestones }) => {
	const [isHover, setIsHover] = useState(false)

	const handleMouseEnter = () => {
		setIsHover(true)
	}

	const handleMouseLeave = () => {
		setIsHover(false)
	}

	return (
		<Box className={styles.container}>
			<Box className={styles.score_container}>
				<Box
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					className={styles.score}
					style={{
						boxShadow: isHover ? `0 0 0.9rem 0.4rem ${color}` : 'none'
					}}
				>
					<CircularProgressBar
						{...{
							height: 32,
							lineWidth: 2,
							lineColor: _.get(goScoreMilestones, [0, 'color']),
							background: '#1d6878',
							lineEmptyColor: '#1c6878',
							time: 0.5,
							value: goscore?.stakingPercent,
							classes: `${styles.bar}`,
							id: 'stake'
						}}
					/>
					<CircularProgressBar
						{...{
							height: 30,
							lineWidth: 2,
							lineColor: _.get(goScoreMilestones, [1, 'color']),
							background: '#1d6878',
							lineEmptyColor: '#1c6878',
							time: 0.5,
							value: goscore?.socialPercent,
							classes: `${styles.bar}`,
							id: 'social'
						}}
					/>
					<CircularProgressBar
						{...{
							height: 28,
							lineWidth: 2,
							lineColor: _.get(goScoreMilestones, [2, 'color']),
							background: '#121212',
							lineEmptyColor: '#1c6878',
							time: 0.5,
							value: goscore?.projectPercent,
							classes: `${styles.bar}`,
							id: 'projectbar'
						}}
					/>
					<img src={stageImages.scoreRing} className={styles.ring_image} />
					{goscore?.locked ? (
						<img src={stageImages.locked} className={styles.center_image} />
					) : (
						<Typography className={styles.center_text}>
							{goscore.goScore && Math.round(goscore.goScore)}
						</Typography>
					)}
				</Box>
			</Box>
			{showText && (
				<Typography className={styles.bottom_text}>
					Go Score <sup>TM</sup>
				</Typography>
			)}
		</Box>
	)
}

GoScore.propTypes = {
	color: PropTypes.string,
	projectId: PropTypes.string,
	showText: PropTypes.bool,
	goscore: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	goScoreMilestones: PropTypes.array
}

export default GoScore
