import useAxiosFetch from 'hooks/useAxiosFetch'
import useLoadingMask from 'hooks/useLoadingMask'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserTokenSaleCampaign } from 'services/tokenSaleCampaign.service'
import {
	reloadSaleTransactions,
	setCampaign,
	setClaimUserDialogOpen,
	setPhaseNoStartedDialogOpen,
	setValidateIpDialogOpen
} from './tokenSaleExperienceSlice'
import useAxios from 'hooks/useAxios'
import {
	addTokenSaleTransaction,
	getTokenSalePhase,
	getUserLastTokenSaleTransaction
} from 'services/tokenSaleTransaction.service'
import useNotification from 'hooks/useNotification'
import { saleUserAccessClaimService } from 'services/profile.service'
import useLoginDialog from 'component/header/loginDialog/useLoginDialog'
import { getConfiguration } from 'services/configuration.service'
import styles from './TokenSaleExperience.module.scss'

const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL

const useTokenSaleExperience = () => {
	const [sale, setSale] = useState({})
	const [percentage, setPercentage] = useState(0)
	const [buyCompleted, setBuyCompleted] = useState(false)
	const [listening, setListening] = useState(false)
	const [userInfo, setUserInfo] = useState({})
	const { showMask, hideMask } = useLoadingMask()
	const {
		phaseNoStartedDialogOpen,
		validateIpDialogOpen,
		unallowedLocationsOpen,
		saleTransactions
	} = useSelector(state => state.tokenSaleExperience)
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const dispatch = useDispatch()
	const { axiosService } = useAxios()
	const { showNotification } = useNotification()
	const { afterLogin } = useLoginDialog()
	const [configuration, setConfiguration] = useState({})

	const [{ loading: loadingConfig }] = useAxiosFetch(
		getConfiguration(),
		(response, error) => {
			if (!error) {
				setConfiguration(response)
			}
		}, !isAuthenticated
	)

	const getUserLastSaleTransaction = () => {
		showMask()
		axiosService(getUserLastTokenSaleTransaction(), (response, error) => {
			if (!error) {
				setUserInfo(response)
				setBuyCompleted(true)
			}
			hideMask()
		})
	}

	const addSaleTransaction = data => {
		showMask()
		axiosService(addTokenSaleTransaction(data), (response, error) => {
			if (!error) {
				getUserLastSaleTransaction()
				dispatch(reloadSaleTransactions())
			}
			hideMask()
		})
	}

	const handleShowNotification = (key, textClass) => () => {
		const messages = {
			wallet: (
				<>
					<p>
						At Film.io, we provide a secure and flexible solution for managing
						your FAN tokens, involving our proprietary custodial platform wallet
						and the ability to later claim them into a non-custodial wallet of
						your choice as soon as we list them on exchanges.
					</p>
					<p>
						Your tokens will be available to use on the platform 48 hours after
						the close of the sale, as outlined in your token sale dashboard, and
						will be managed by Film.io’s custodial platform wallet. As soon as
						FAN are listed on an exchange, you will have the ability to “claim”
						your tokens into a non-custodial wallet of your choice. We are
						handling things this way so that tokens cannot get listed on a DEX
						and affect the price before we are ready for our full platform
						launch and aggressive marketing initiatives.
					</p>
				</>
			),
			fgr: (
				<p>
					In the Film.io DAO, fans stake FAN Tokens on the projects they wish to
					support, earning additional FAN Tokens known as an FGR (Fan Governance
					Reward). Staking to a project helps increase the project’s Go Score
					(Film.io’s proprietary algorithm for determining a project’s viability
					of commercial success) and also moves that project through various
					platform milestones that unlock functionality for the creator,
					including increasingly sophisticated funding options and even
					qualification for seed investment from the Global Creativity Pool, an
					ownerless foundation that’s managed by the Film.io DAO.
				</p>
			)
		}
		const message = <div className={textClass}>{messages[key]}</div>
		showNotification({ message })
	}

	const handleShowEmergencyNotification = () => {
		const text = (
			<>
				<p>
					Polygon is experiencing unprecedented gas prices right now, as high as
					1000x normal, which is causing issues with account creation since as a
					gas-less solution we pay for these fees on your behalf, to provide a
					more seamless experience. This is affecting dapps across the Polygon
					ecosystem. Until this gas event passes we are temporarily pausing
					account creation.
				</p>
				<p>You can check the current gas prices by going here:</p>
			</>
		)

		const link = 'https://www.quicknode.com/gas-tracker/polygon'
		const message = (
			<div className={styles.emergency_text}>
				{text}
				<p className={styles.link}>
					<a href={link} target="_blank" rel="noreferrer">
						{link}
					</a>
				</p>
			</div>
		)
		showNotification({ message })
	}

	const [{ loading }] = useAxiosFetch(
		getUserTokenSaleCampaign(),
		(data, error) => {
			if (!error) {
				dispatch(setCampaign(data))
				const notStarted = new Date(data?.startDate) > new Date()
				if (notStarted) dispatch(setPhaseNoStartedDialogOpen(true))
				else dispatch(setValidateIpDialogOpen(true))
			}
		}
	)

	const getTokenSaleCampaignPhases = () => {
		showMask()
		axiosService(getTokenSalePhase(), (response, error) => {
			if (!error) {
				setSale(response)
			}
			hideMask()
		})
	}

	const saleUserAccessClaim = () => {
		showMask('Creating your account!  This may take a minute.')
		axiosService(saleUserAccessClaimService(), (response, error) => {
			if (!error) {
				afterLogin(response, false)
			}
			hideMask()
		})
	}

	const buyAgain = () => {
		setBuyCompleted(false)
	}

	const handleOpenClaimUserDialog = () => {
		dispatch(setClaimUserDialogOpen(true))
	}

	useEffect(() => {
		loading ? showMask() : hideMask()
	}, [loading])

	useEffect(() => {
		const handler = event => {
			if (event?.data?.sender === 'presale-widget-transaction-completed') {
				const currencies = {
					matic: 1,
					eth: 1,
					ethereum: 1,
					usdt: 2
				}
				const { token, amount, hash, baseCurrencyAmount } = event?.data
				const baseCurrencyId =
					currencies[token?.symbol?.toLocaleLowerCase()] ?? 1
				addSaleTransaction({
					amount,
					baseCurrencyAmount,
					blockchainHash: hash,
					baseCurrencyId
				})
			}
		}
		window.addEventListener('message', handler)
		return () => {
			window.removeEventListener('message', handler)
		}
	}, [])

	useEffect(() => {
		if (!phaseNoStartedDialogOpen && !validateIpDialogOpen && !buyCompleted) {
			getTokenSaleCampaignPhases()
			// Activate if we need sale active
			// const event = new Event('startWidget')
			// window.dispatchEvent(event)
			const nextPhasePrice = sale?.nextPhase?.price
			setTimeout(() => {
				const message = { sender: 'filmio-info', percentage, nextPhasePrice }
				window.postMessage(message, '*')
			}, 0)
		}
	}, [phaseNoStartedDialogOpen, validateIpDialogOpen, buyCompleted])

	useEffect(() => {
		if (!listening) {
			const events = new EventSource(`${baseUrl}/user/saleProgress`)

			events.onmessage = event => {
				const progress = Number(event?.data).toFixed(2)
				setPercentage(progress)
			}

			setListening(true)
		}
		if (Number(percentage) === 100) {
			getUserLastSaleTransaction()
		}
	}, [listening, percentage])

	useEffect(() => {
		if (user?.appAccess === 0 && configuration?.saleFreeJoin === 1) {
			handleShowEmergencyNotification()
		}
	}, [user, configuration])

	return {
		sale,
		user,
		userInfo,
		buyCompleted,
		loadingConfig,
		configuration,
		saleTransactions,
		validateIpDialogOpen,
		unallowedLocationsOpen,
		phaseNoStartedDialogOpen,
		handleShowEmergencyNotification,
		handleOpenClaimUserDialog,
		handleShowNotification,
		saleUserAccessClaim,
		buyAgain,
		percentage
	}
}

export default useTokenSaleExperience
