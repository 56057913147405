export const getUserFullTiersMetricsService = (autoexec = false) => ({
	method: 'get',
	url: `/daoTier/user/metrics`,
	autoexec
})

export const getUserTierInfoService = () => ({
	method: 'get',
	url: '/daoTier/user'
})

export const upgradeUserDaoTier = () => ({
	method: 'post',
	url: '/daoTier/upgrade',
	autoexec: false
})

export const claimDaoTierBonus = () => ({
	method: 'post',
	url: '/daoTier/claim/blockchainBonus'
})

export const calculateUserDaoTierProgress = (autoexec = true) => ({
	method: 'post',
	url: '/daoTier/tierProgress',
	autoexec
})

export const calculateUserDaoGlobalProgress = () => ({
	method: 'post',
	url: '/daoTier/tierGlobalProgress',
	autoexec: false
})

export const getDaoTierById = (tierId, autoexec) => ({
	method: 'get',
	url: `/daoTier/${tierId}`,
	autoexec
})
