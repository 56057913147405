import { Carousel } from './Carousel/Carousel'
import { Quests } from './Quests/Quests'
import { ProjectsToCheckout } from './ProjectsToCheckout/ProjectsToCheckout'
import { ReferralPerformance } from './ReferralPerformance'
import { StatsCard } from './StatsCards'
import { SuperFanPerformance } from './SuperFanPerformance'
import { Newsreel } from './Newsreel/Newsreel'

export const Dashboard = () => {
	return (
		<div className="w-full space-y-6">
			<Carousel />

			<div className="flex w-full max-w-full flex-col items-start justify-start gap-6 2xl:flex-row">
				<StatsCard className="flex w-full shrink-0 gap-4 overflow-x-auto 2xl:w-52 2xl:flex-col" />

				<div className="flex w-full flex-1 flex-col items-start justify-start gap-6 md:flex-row">
					<div className="w-full flex-1 space-y-4">
						<div className="w-full rounded-lg border border-[#FFFFFF33]">
							<SuperFanPerformance />
							<ProjectsToCheckout />
						</div>
						<div className="w-full rounded-lg border border-[#FFFFFF33]">
							<ReferralPerformance />
						</div>
					</div>

					<div className="w-full flex-1 space-y-4">
						<Newsreel />
						<Quests />
					</div>
				</div>
			</div>
		</div>
	)
}
