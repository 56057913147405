export const addCommentService = data => ({
	method: 'post',
	url: '/comment',
	data
})

export const deleteCommentService = id => ({
	method: 'delete',
	url: '/comment',
	data: { id }
})

export const getCommentService = data => ({
	method: 'post',
	url: '/comment/page',
	data
})
