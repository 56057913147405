import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Drawer, Icon, IconButton } from '@mui/material'
import styles from './MobileMenu.module.scss'
import { SidebarMenu } from '../sidebarMenu'

function MobileMenu({ isThereActiveContests }) {
	const [open, setOpen] = useState(false)

	const toggle = () => {
		setOpen(!open)
	}

	return (
		<Box className={styles.mobile_menu}>
			<IconButton
				onClick={toggle}
				className={`${styles.open_button} ${
					isThereActiveContests ? styles.with_contests : ''
				}`}
			>
				<Icon baseClassName="fal" className="fa-gear" />
			</IconButton>
			<Drawer
				anchor={'left'}
				open={open}
				onClose={toggle}
				// ModalProps={{ hideBackdrop: true }}
				classes={{
					paperAnchorLeft: styles.test
				}}
			>
				<SidebarMenu {...{ handleClose: toggle }} />
			</Drawer>
		</Box>
	)
}

MobileMenu.propTypes = {
	isThereActiveContests: PropTypes.bool
}

export default MobileMenu
