import { Popover, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import styles from './VerifiedPopover.module.scss'
import { helpBaseUrl } from 'config'

export const VerifiedPopover = ({
	anchorEl,
	handleClose,
	handlePopoverClose
}) => {
	const open = Boolean(anchorEl)
	const description =
		'This account has been verified using bank-grade KYC technology.'

	return (
		<Popover
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			open={open}
			anchorEl={anchorEl}
			onClose={handlePopoverClose}
			PaperProps={{
				className: styles.verified_preview
			}}
		>
			<h2>Verified Account</h2>
			<Icon baseClassName="fas" className="fa-badge-check" />
			<p className={styles.verified_info}>
				{description}&nbsp;
				<a
					href={`${helpBaseUrl}/knowledgebase/film.io-user-verification-system`}
					onClick={handleClose}
					target="_blank"
					rel="noreferrer"
				>
					Learn More
				</a>
			</p>
			<Icon baseClassName="far" className="fa-calendar" />
			<p className={styles.verfied_date}>Verifed Since July 2023</p>
		</Popover>
	)
}

VerifiedPopover.propTypes = {
	anchorEl: PropTypes.object,
	handlePopoverClose: PropTypes.func,
	handleClose: PropTypes.func
}
