import axios from 'axios'

export const followUnfollowProject = async (projectId, follow = false) => {
	const path = follow ? 'unfollow' : 'follow'
	const res = await axios.post(`/${path}/project`, { projectId })
	return res
}

export const followUnfollowUser = async (userId, follow = false) => {
	const path = follow ? 'unfollow' : 'follow'
	const res = await axios.post(`/${path}/user`, { userId })
	return res
}

export const getFollowingFollowerUsers = params => ({
	method: 'get',
	url: '/follow/users',
	params
})

export const getFollowers = userId => ({
	method: 'get',
	url: '/follow/followers',
	params: { userId }
})

export const getFollowing = (userId, autoexec = false) => ({
	method: 'get',
	url: '/follow/following',
	params: { userId },
	autoexec
})

export const getFollowedProjectsByUserId = userId => ({
	method: 'get',
	url: `/follow/followedProjects/${userId}`
})
