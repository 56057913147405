import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'
import { clamp } from 'lodash'

export const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip
		{...{
			TransitionComponent: Fade,
			...props
		}}
		classes={{ popper: className }}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		boxShadow: theme.shadows[1],
		fontSize: '12px',
		lineHeight: '20px',
		padding: 16,
		backgroundColor: '#2D2AB480',
		color: ' #fff',
		border: '1px solid #2D2AB4',
		borderRadius: '8px',
		width: clamp(160, 200, 240),
		textAlign: 'center',
		backdropFilter: 'blur(5px)'
	}
}))
