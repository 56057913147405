import { Turnstile } from '@marsidev/react-turnstile'
import { useDispatch } from 'react-redux'
import { config } from 'config'
import { useEffect, useRef, useState } from 'react'
import { setCaptchaToken } from './captchaCheckSlice'

const CaptchaCheck = () => {
	const ref = useRef()
	const dispatch = useDispatch()
	const [isLocal, setIsLocal] = useState(false)

	const handleOnSuccess = token => {
		dispatch(setCaptchaToken(token))
	}

	useEffect(() => {
		if (window.location.host === 'localhost:3000') {
			setIsLocal(true)
			dispatch(setCaptchaToken('dummy'))
		}
	}, [])

	return (
		<>
			{isLocal ? (
				''
			) : (
				<Turnstile
					siteKey={config.turnstileSiteKey}
					onSuccess={handleOnSuccess}
					options={{
						theme: 'dark'
					}}
					ref={ref}
					onExpire={() => ref.current?.reset()}
				/>
			)}
		</>
	)
}

export default CaptchaCheck
