import { useEffect, useState } from 'react'
import { getProjectUserTeams } from 'services/project.service'
import { useDispatch, useSelector } from 'react-redux'
import {
	setShowAddTeamMemberForm,
	setTeamTabIndex,
	setTeams
} from '../projectDetailSlice'
import _ from 'lodash'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'

const useTeam = (autoexec = true) => {
	const [addTeam, setAddTeam] = useState(false)
	const { user } = useSelector(state => state.signIn)
	const {
		teams,
		showAddTeamMemberForm,
		project,
		projectId,
		rolePermissions,
		teamTabIndex: tabIndex
	} = useSelector(state => state.projectDetail)
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const dispatch = useDispatch()

	const addTeamFn = () => {
		setAddTeam(true)
	}

	const handleTabChange = (_event, newValue) => {
		dispatch(setTeamTabIndex(newValue))
	}

	const fetchProjectUserTeams = () => {
		showMask()
		axiosService(getProjectUserTeams(projectId), (data, error) => {
			if (!error) dispatch(setTeams(data))
			hideMask()
		})
	}

	const handleOpenForm = () => {
		dispatch(setShowAddTeamMemberForm(true))
	}

	const haveAddMemberPermission = () =>
		rolePermissions.some(el => el?.permissionId === 4)

	const showAdd =
		(Number(user.isAdmin) === 1 ||
			user.id === project.userId ||
			haveAddMemberPermission()) &&
		!showAddTeamMemberForm

	useEffect(() => {
		if (!_.isNil(projectId) && autoexec) fetchProjectUserTeams()
	}, [projectId])

	return {
		teams,
		addTeam,
		showAdd,
		tabIndex,
		addTeamFun: addTeamFn,
		showAddTeamMemberForm,
		fetchProjectUserTeams,
		handleTabChange,
		handleOpenForm
	}
}

export default useTeam
