export const addReviewService = data => ({
	method: 'post',
	url: '/review',
	data
})

export const updateReviewHashService = data => ({
	method: 'put',
	url: '/review',
	data
})

export const retryReviewBonusService = reviewId => ({
	method: 'post',
	url: '/review/bonus/retry',
	data: { reviewId }
})

export const getReviewService = (projectId, page) => ({
	method: 'get',
	url: `/review/${projectId}`,
	params: { page },
	autoexec: false
})

export const getUserReviewService = (projectId) => ({
	method: 'get',
	url: `/review/user/${projectId}`,
	autoexec: true
})

export const getReviewByIdService = reviewId => ({
	method: 'get',
	url: `/review/byId/${reviewId}`,
	params: { reviewId },
	autoexec: false
})

export const isReviewedService = projectId => ({
	method: 'get',
	url: `/review/isReviewed/${projectId}`,
	autoexec: false
})
