import styles from './ErrorFallback.module.scss'

export const ErrorHandler = (error, errorInfo) => {
	console.log('Loggin errors:', { error, errorInfo })
}

export const ErrorFallback = () => {
	return (
		<div className={styles.error_fallback}>
			<h1>Something went wrong!</h1>
			<button
				onClick={() => {
					location.reload()
				}}
			>
				Try again
			</button>
		</div>
	)
}
