import _ from 'lodash'
import { useEffect, useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { useDispatch, useSelector } from 'react-redux'
import {
	getProfileEarnings,
	getProfileStakes,
	getProfileTransactionsService,
	retryTransactionService,
	reconcileTransactionService
} from 'services/profile.service'
import {
	reloadRecentActivity,
	setOnly,
	setOrder,
	setOrderBy,
	setPaging,
	setRetyingTransaction,
	setTransactions
} from '../walletTabSlice'
import { claimDaoTierBonus } from 'services/daoTier.service'
import useAxios from 'hooks/useAxios'
import useNotification from 'hooks/useNotification'
import useHeader from 'component/header/useHeader'
import useLoadingMask from 'hooks/useLoadingMask'
import {
	getReviewByIdService,
	retryReviewBonusService,
	updateReviewHashService
} from 'services/review.service'
import {
	addBlockchainStakeService,
	unstakeBlockchainService,
	unstakeService
} from 'services/stake.service'
import { claimFgrBlockchainService } from 'services/project.service'
import { Mixpanel } from 'services/mixpanel.service'
import {
	FanTokenContract,
	FilmioProjectContract,
	StakingContract
} from 'utils/blockchain/functions'
import { addBlockchainReviewHook } from 'component/projectDetail/projectDetailSlice'
import useNotifications from 'component/header/notifications/useNotifications'
import {
	setFrontTransactionsInProgress,
	setCreatePlatformWalletDialogOpen
} from 'component/header/signInSlice'
import { getConfiguration } from 'services/configuration.service'
import { addBlockchainFanWithdrawalService } from 'services/wallet.service'
import useCommon from 'hooks/useCommon'

const useActivityTable = () => {
	const [configuration, setConfiguration] = useState({})
	const [search, setSearch] = useState('')
	const { axiosService } = useAxios()
	const { showNotification } = useNotification()
	const { loadUserTierInfo, relaodUserInfo } = useHeader()
	const { hideMask, showMask } = useLoadingMask()
	const {
		transactions,
		orderBy,
		pagin,
		only,
		order,
		retyingTransaction,
		reloadTransactions
	} = useSelector(state => state.walletTab)
	const { userId } = useSelector(state => state.profile)
	const { user: signInUser, isAuthenticated } = useSelector(state => state.signIn)
	const { checkNcNoMagic } = useCommon()
	const { getUnvisiteNotificationCount } = useNotifications()
	const dispatch = useDispatch()

	useAxiosFetch(getConfiguration(), (response, error) => {
		if (!error) {
			setConfiguration(response)
		}
	}, !isAuthenticated)

	const claimTierBenefitsBlockchain = transactionId => {
		axiosService(claimDaoTierBonus(), (_data, error) => {
			if (!error) {
				showNotification({ message: 'Tier bonus completed successfully!' })
				dispatch(reloadRecentActivity())
				relaodUserInfo()
			}
			dispatch(setRetyingTransaction(false))
		})
	}

	const getReviewById = async reviewId => {
		return new Promise((resolve, reject) => {
			axiosService(getReviewByIdService(reviewId), (review, error) => {
				if (!error) {
					resolve(review)
				}
			})
		})
	}

	const updateBlockchainReview = (reviewId, blockchainHash) => {
		axiosService(
			updateReviewHashService({ reviewId, blockchainHash }),
			(data, error) => {
				if (!error) {
					dispatch(addBlockchainReviewHook(data))
					dispatch(reloadRecentActivity())
					relaodUserInfo()
					getUnvisiteNotificationCount()
				}
			}
		)
	}

	const handleReconcileBlockchainTransaction = transaction => () => {
		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			dispatch(setRetyingTransaction(transaction.id))
			axiosService(
				reconcileTransactionService(transaction.id),
				(data, error) => {
					if (!error && !_.isNil(data) && data?.isReconciled === true) {
						showNotification({
							message: 'Transaction reconciled successfully!'
						})
						dispatch(reloadRecentActivity())
						relaodUserInfo()
						dispatch(setRetyingTransaction(false))
					} else {
						handleRetryTransaction(transaction)
					}
				}
			)
		}
	}

	const retryReview = async reviewId => {
		const review = await getReviewById(reviewId)
		if (_.isNil(review?.blockchainHash)) {
			dispatch(setFrontTransactionsInProgress(true))
			const blockchainHash = await FilmioProjectContract.addRating(
				review?.projectId,
				review?.rating,
				!_.isNil(review?.heading) || !_.isNil(review?.content),
				reviewId
			)
			updateBlockchainReview(reviewId, blockchainHash)
			dispatch(setFrontTransactionsInProgress(false))
		}
	}

	const handleRetryReviewBonus = async (reviewId, transactionId) => {
		await retryReview(reviewId)
		axiosService(retryReviewBonusService(reviewId), (_data, error) => {
			if (!error) {
				showNotification({ message: 'Review bonus completed successfully!' })
				dispatch(reloadRecentActivity())
				relaodUserInfo()
			}
			dispatch(setRetyingTransaction(false))
		})
	}

	const unstakeBlockchain = (transactionId, blockchainHash) => {
		axiosService(
			unstakeBlockchainService({ transactionId, blockchainHash }),
			(data, error) => {
				if (!error) {
					const message = _.isNil(data?.blockchainHash)
						? 'Unstake failed.'
						: 'Unstake completed successfully!'
					showNotification({ message })
				}
				relaodUserInfo()
				dispatch(reloadRecentActivity())
				dispatch(setRetyingTransaction(false))
			}
		)
	}

	const handleDecision = (unstakeId, stakeId) => () => {
		const message =
			'This will permanently lower your FGR percentage. This action cannot be undone.'
		const buttonText = 'I understand'
		setTimeout(() => {
			showNotification({
				message,
				cancelButton: true,
				cancelButtonText: `Keep my ${signInUser?.fgrRate ?? 100}% FGR`,
				buttonText,
				buttonCallback: () => {
					dispatch(setRetyingTransaction(unstakeId))
					axiosService(unstakeService({ transactionId: stakeId }), afterUnstake)
				}
			})
		})
	}

	const handleRetryUnstake = (stakeId, unstakeId, project) => {
		dispatch(setRetyingTransaction(false))
		if (
			configuration?.unstakeFgrRate !== signInUser?.fgrRate &&
			project?.stageId === 4
		) {
			const message = `Before you unstake your tokens from ${project?.title}, consider this: withdrawing means forfeiting your valuable ${signInUser?.fgrRate}% Fan Governance Reward (across all projects) and negatively impact this project’s Go Score™. By keeping your tokens staked, you maintain the ${signInUser?.fgrRate}% FGR and support the project's growth.`
			const title = 'Critical Decision'
			showNotification({
				title,
				message,
				imageHeader: true,
				cancelButton: true,
				cancelButtonText: `Keep my ${signInUser?.fgrRate}% FGR`,
				buttonText: `Unstake and reset my FGR to ${configuration?.unstakeFgrRate}%`,
				variant: 'warning',
				buttonCallback: handleDecision(unstakeId, stakeId)
			})
		} else {
			axiosService(unstakeService({ transactionId: stakeId }), afterUnstake)
		}
	}

	const afterUnstake = async stake => {
		dispatch(setFrontTransactionsInProgress(true))
		const blockchainHash = await StakingContract.unstakeTokens(
			stake?.blockchainHash,
			stake?.amount,
			stake?.unstakeId
		)
		unstakeBlockchain(stake?.id, blockchainHash)
		dispatch(setFrontTransactionsInProgress(false))
		if (_.isNil(blockchainHash)) {
			Mixpanel.track('transaction_failed_unstake_evt', {
				distinct_id: signInUser.id,
				displayName: signInUser.displayName,
				username: signInUser.username,
				amount: stake?.amount,
				transactionId: stake?.id
			})
		} else {
			Mixpanel.track('transaction_successful_unstake_evt', {
				distinct_id: signInUser.id,
				displayName: signInUser.displayName,
				username: signInUser.username,
				amount: stake?.amount,
				transactionId: stake?.id,
				blockchainHash: stake?.blockchainHash
			})
		}
	}

	const addBlockchainStake = (transactionId, blockchainHash, amount) => {
		Mixpanel.track('usr_stake_added_evt', {
			distinct_id: localStorage.getItem('id'),
			amount,
			transactionId,
			displayName: signInUser.displayName,
			id: signInUser.id,
			username: signInUser.username
		})
		axiosService(
			addBlockchainStakeService({
				transactionId,
				blockchainHash
			}),
			(_data, error) => {
				if (!error) {
					const message = !_.isNil(blockchainHash)
						? 'Stake completed successfully!'
						: 'Stake failed'
					showNotification({ message })
					if (!_.isNil(blockchainHash)) {
						loadUserTierInfo()
					}
				}
				dispatch(setRetyingTransaction(false))
				dispatch(reloadRecentActivity())
				relaodUserInfo()
			}
		)
	}

	const addBlockchainWithdrawal = (transactionId, blockchainHash) => {
		axiosService(
			addBlockchainFanWithdrawalService({
				transactionId,
				blockchainHash
			}),
			_data => {
				dispatch(setRetyingTransaction(false))
				dispatch(reloadRecentActivity())
				getUnvisiteNotificationCount()
			}
		)
	}

	const handleRetryTransfer = async ({ transactionId, amount }) => {
		dispatch(setFrontTransactionsInProgress(true))
		const hash = await FanTokenContract.transfer(
			signInUser?.whitelistedAddress,
			Number(amount),
			transactionId
		)

		addBlockchainWithdrawal(transactionId, hash)
		dispatch(setFrontTransactionsInProgress(false))
	}

	const handleRetryStake = async ({ transactionId, amount, projectId }) => {
		dispatch(setFrontTransactionsInProgress(true))
		const hash = await StakingContract.stakeTokens(
			projectId,
			Number(amount),
			transactionId
		)
		addBlockchainStake(transactionId, hash, amount)
		dispatch(setFrontTransactionsInProgress(false))
		if (_.isNil(hash)) {
			Mixpanel.track('transaction_failed_stake_evt', {
				distinct_id: localStorage.getItem('id'),
				amount,
				transactionId,
				displayName: signInUser.displayName,
				id: signInUser.id,
				username: signInUser.username
			})
		} else {
			Mixpanel.track('transaction_successful_stake_evt', {
				distinct_id: signInUser.id,
				amount,
				transactionId,
				displayName: signInUser.displayName,
				id: signInUser.id,
				username: signInUser.username
			})
		}
	}

	const handleRetryClaimFgr = transactionId => {
		axiosService(claimFgrBlockchainService(transactionId), (data, error) => {
			if (!error) {
				showNotification({ message: 'FGR tokens claimed successfully!' })
				dispatch(reloadRecentActivity())
				relaodUserInfo()
			}
			dispatch(setRetyingTransaction(false))
		})
	}

	const retryTransaction = transactionId => {
		axiosService(retryTransactionService(transactionId), (data, error) => {
			if (!error) {
				showNotification({ message: 'Tokens claimed successfully!' })
				dispatch(reloadRecentActivity())
				relaodUserInfo()
			}
			dispatch(setRetyingTransaction(false))
		})
	}

	const afterFetchTransactions = (data, error) => {
		if (!error) dispatch(setTransactions(data))
		hideMask()
	}

	const getWalletTransactions = () => {
		showMask()
		axiosService(
			getProfileTransactionsService({
				search,
				limit: pagin.limit,
				offset: pagin.offset,
				order,
				orderBy
			}),
			afterFetchTransactions
		)
	}

	const getWalletStakes = () => {
		showMask()
		axiosService(
			getProfileStakes(
				userId,
				pagin.limit,
				pagin.offset,
				order,
				orderBy,
				search
			),
			afterFetchTransactions
		)
	}

	const getWalletEarnings = () => {
		showMask()
		axiosService(
			getProfileEarnings(
				userId,
				pagin.limit,
				pagin.offset,
				order,
				orderBy,
				search
			),
			afterFetchTransactions
		)
	}

	// const [{ loading: loadingEarnings }, getWalletEarnings] = useAxiosFetch(
	// 	getProfileEarnings(userId, pagin.limit, pagin.offset, order, orderBy, search),
	// 	afterFetchTransactions
	// )

	useEffect(() => {
		if (!_.isNil(userId)) {
			if (only.stake) getWalletStakes()
			else if (only.earnings) getWalletEarnings()
			else getWalletTransactions()
		}
	}, [search, pagin, order, orderBy, only, reloadTransactions, userId])

	const handleRetryTransaction = transaction => {
		const { typeId, objectId, amount, id, project } = transaction
		if ([4, 16].includes(typeId) && signInUser?.balance < amount) {
			showNotification({
				message: 'You need more $FAN tokens to retry this transaction.'
			})
			return
		}
		switch (typeId) {
			case 2:
				handleRetryReviewBonus(objectId, id)
				break
			case 4:
				handleRetryStake({
					amount,
					projectId: project?.id,
					transactionId: id
				})
				break
			case 7:
				handleRetryUnstake(objectId, id, project)
				break
			case 8:
				handleRetryClaimFgr(id)
				break
			case 10:
			case 13:
				claimTierBenefitsBlockchain(id)
				break
			case 16:
				handleRetryTransfer({
					transactionId: id,
					amount
				})
				break
			case 1:
			case 14:
			case 15:
			case 3:
			case 6:
				retryTransaction(id)
				break
			default:
				break
		}
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		dispatch(setOrder(isAsc ? 'desc' : 'asc'))
		dispatch(setOrderBy(property))
	}

	const handleChangePage = (event, newPage) => {
		const offset = Number(newPage) * Number(pagin.limit)
		dispatch(
			setPaging({
				offset,
				page: newPage
			})
		)
	}

	const handleChangeRowsPerPage = event => {
		dispatch(
			setPaging({
				page: 0,
				offset: 0,
				limit: event.target.value
			})
		)
	}

	const handleEarningsOnlyClick = () => {
		dispatch(setOnly({ stake: false, earnings: !only.earnings }))
	}

	const handleStakesOnlyClick = () => {
		dispatch(setOnly({ earnings: false, stake: !only.stake }))
	}

	const emptyRows =
		pagin.page > 0
			? Math.max(0, (1 + pagin.page) * pagin.limit - transactions.count)
			: 0
	const isFraud = Number(signInUser.isFraud) === 1
	return {
		only,
		pagin,
		order,
		search,
		orderBy,
		isFraud,
		emptyRows,
		transactions,
		retyingTransaction,
		handleStakesOnlyClick,
		handleEarningsOnlyClick,
		handleChangeRowsPerPage,
		handleReconcileBlockchainTransaction,
		handleRequestSort,
		handleChangePage,
		setSearch
	}
}

export default useActivityTable
