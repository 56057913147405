import { Box, Button, Icon, Typography } from '@mui/material'
import { CustomDialog, TextFieldControl, LightTooltip } from 'component/common'
import styles from './WhitelistTransferDialog.module.scss'
import ConnectWallet from '../connectWallet/ConnectWallet'
import useWhitelistTransferDialog from './useWhitelistTransferDialog'
import { NumericFormat } from 'react-number-format'
import { abbreviateNumberFixed } from 'utils/utils'

const WhitelistTransferDialog = () => {
	const {
		code,
		open,
		amount,
		isConnected,
		stakesTotal,
		lockedRewards,
		unlockedTokens,
		disableWithdraw,
		whitelistAddress,
		availableToWithdraw,
		showCodeVerification,
		openConnectWalletModal,
		setOpenConnectWalletModal,
		handleClickTransfer,
		handleClickResend,
		handleVerify,
		handleClose,
		handleChangeForm,
		setCode
	} = useWhitelistTransferDialog()

	return (
		<CustomDialog
			{...{ open, handleClose, removeX: true, variant: 'whitelist_transfer' }}
		>
			{!isConnected && openConnectWalletModal && (
				<ConnectWallet setOpenConnectWalletModal={setOpenConnectWalletModal} />
			)}
			{showCodeVerification ? (
				<Box className={styles.email_verification}>
					<Box>
						<Typography component={'h1'}>Email Verification Code</Typography>
						<Typography>
							Please check your email for the 4-digit verification code.
							<br /> If you haven&apos;t received it, please click
							<span onClick={handleClickResend}> Resend.</span>
						</Typography>
						<TextFieldControl
							type="number"
							value={code}
							variant={'blue'}
							autoFocus
							onChange={e => {
								setCode(e.target.value)
							}}
						/>
					</Box>
					<Box className={styles.actions}>
						<Button className="yellow-bg-button" onClick={handleVerify}>
							Verify
						</Button>
						<Button onClick={handleClose}>Cancel</Button>
					</Box>
				</Box>
			) : (
				<>
					<Box className={styles.content}>
						<Typography className={styles.transfer_text}>
							Transfer Tokens
							<LightTooltip
								enterTouchDelay={0}
								leaveTouchDelay={4000}
								placement="top"
								title="The Platform FAN Balance is a combination of both locked and unlocked tokens. Unlocked tokens are FAN$ transferred in, and they can be transferred out. Locked tokens are tokens received on the platform."
							>
								<span className={styles.help_icon} aria-haspopup="true">
									<Icon baseClassName="far" className={`fa-circle-info`} />
								</span>
							</LightTooltip>
						</Typography>
						<Box className={styles.input_container}>
							<input
								type="number"
								placeholder="0"
								value={amount}
								onChange={e => {
									handleChangeForm(e.target.value)
								}}
							/>
							<Typography className={styles.address}>
								<span>
									<Icon baseClassName="fas" className="fa-wallet" />
								</span>
								{whitelistAddress}
							</Typography>
						</Box>
						<Box className={styles.bottom}>
							<Typography>
								Unlocked Available $FAN:{' '}
								<NumericFormat
									value={availableToWithdraw}
									displayType="text"
									thousandSeparator=","
									decimalScale={2}
								/>
								<small>
									<br />({' '}
									<Icon baseClassName="far" className="fa-lock-keyhole-open" />{' '}
									{abbreviateNumberFixed(unlockedTokens, 2)},{' '}
									<Icon baseClassName="far" className="fa-hourglass-start" />{' '}
									{abbreviateNumberFixed(lockedRewards ?? 0, 2)} ){' '}
									{abbreviateNumberFixed(stakesTotal ?? 0, 2)} staked
									<br />
									FAN Rewards are subject to
									<LightTooltip
										enterTouchDelay={0}
										leaveTouchDelay={4000}
										placement="bottom"
										title="Reward and Bonus tokens are subject to Film.io's Rewards Release Schedule (RRS) and are released based on your activity, reputation, and time (coming soon)."
									>
										<strong> RRS</strong>
									</LightTooltip>
									<br />
									Staked Tokens are not Transferrable
								</small>
							</Typography>
							<Button onClick={() => handleChangeForm(availableToWithdraw)}>
								Max
							</Button>
						</Box>
					</Box>
					<Button
						className={styles.transfer}
						onClick={handleClickTransfer}
						disabled={disableWithdraw}
						endIcon={
							disableWithdraw && (
								<Icon baseClassName="far" className="fa-spinner fa-spin" />
							)
						}
					>
						Transfer
					</Button>
				</>
			)}
		</CustomDialog>
	)
}

export default WhitelistTransferDialog
