import images from 'assets/images/contest'
import PropTypes from 'prop-types'
import { getFileUrl } from 'utils/url.util'

export const ActiveContestBadge = ({ height, width, src }) => {
	const imgSrc = getFileUrl(src)
	return (
		<img
			src={imgSrc ?? images.contestBadge}
			alt="contest-badge"
			width={width || '100%'}
			height={height || '100%'}
		/>
	)
}

ActiveContestBadge.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	src: PropTypes.string
}
