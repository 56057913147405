import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getUserTokenSaleTransactions } from 'services/tokenSaleTransaction.service'
import { setSaleTransactions } from '../tokenSaleExperienceSlice'

const useTransactionsTable = () => {
	const dispatch = useDispatch()
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const { reload, saleTransactions } = useSelector(
		state => state.tokenSaleExperience
	)

	const [count, setCount] = useState(0)
	const [pagin, setPagin] = useState({
		page: 0,
		limit: 6,
		offset: 0,
		orderBy: 'createdAt',
		order: 'desc'
	})

	const { order, orderBy, limit, offset } = pagin

	const getUserSaleTransactions = () => {
		showMask()
		axiosService(
			getUserTokenSaleTransactions({
				limit,
				offset,
				order,
				orderBy
			}),
			(response, error) => {
				if (!error) {
					setCount(response.count)
					dispatch(setSaleTransactions(response.rows))
				}
				hideMask()
			}
		)
	}

	const handleRequestSort = (_event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setPagin({
			...pagin,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		})
	}

	const handleChangePage = (_event, newPage) => {
		const offset = Number(newPage) * Number(pagin.limit)
		setPagin({
			...pagin,
			offset,
			page: newPage
		})
	}

	const handleChangeRowsPerPage = event => {
		setPagin({
			...pagin,
			page: 0,
			offset: 0,
			limit: event.target.value
		})
	}

	useEffect(() => {
		getUserSaleTransactions()
	}, [reload, pagin])

	const emptyRows =
		pagin.page > 0 ? Math.max(0, (1 + pagin.page) * pagin.limit - count) : 0

	return {
		order,
		count,
		pagin,
		orderBy,
		emptyRows,
		transactions: saleTransactions,
		handleChangeRowsPerPage,
		handleRequestSort,
		handleChangePage
	}
}

export default useTransactionsTable
