import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@mui/material'
import { getFileUrl } from 'utils/url.util'
import { abbreviateNumberFixed, getTransactionLink } from 'utils/utils'
import styles from './VoterItem.module.scss'
import useDaoProposalDialog from '../../daoProposalDialog/useDaoProposalDialog'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { RingAvatar } from 'component/common'

const VoterItem = ({
	id,
	avatar,
	accepted,
	daoProposal,
	daoProposalId,
	blockchainHash,
	votingWeight,
	displayName,
	statusId,
	userId
}) => {
	const { user: signInUser } = useSelector(state => state.signIn)
	const { daoProposalUpdated } = useSelector(state => state.dashboard)
	const [retyingTransaction, setRetyingTransaction] = useState(false)
	const { updateVoteDaoProposal } = useDaoProposalDialog()
	const failed = statusId === 3

	const retryVote = async () => {
		setRetyingTransaction(true)
		await updateVoteDaoProposal({
			id,
			proposalBlockchainId: daoProposal?.blockchainId,
			daoProposalId,
			accepted
		})
	}

	useEffect(() => {
		if (!_.isNil(daoProposalUpdated)) {
			setRetyingTransaction(false)
		}
	}, [daoProposalUpdated])

	return (
		<Box className={styles.voter_item}>
			<Box className={styles.voteName}>
				<RingAvatar
					{...{
						avatar: getFileUrl(avatar),
						displayName
					}}
				/>
				<Typography className={styles.displayName}>{displayName}</Typography>
			</Box>
			<Typography className={styles.name}>
				<Box className={accepted ? styles.green : styles.red}></Box>
				{accepted ? 'Approve ' : 'Reject'} Proposal
			</Typography>
			<Typography className={styles.votingWeight}>
				{abbreviateNumberFixed(votingWeight, 2)} FAN
			</Typography>
			{failed ? (
				<>
					{signInUser?.id === userId ? (
						<Button
							className={styles.retry}
							onClick={retryVote}
							disabled={retyingTransaction}
						>
							{retyingTransaction ? 'Retrying...' : 'Retry'}
						</Button>
					) : (
						<Box className={styles.failed}>Failed</Box>
					)}
				</>
			) : (
				<Typography className={styles.voteHash}>
					{getTransactionLink(blockchainHash)}
				</Typography>
			)}
		</Box>
	)
}

VoterItem.propTypes = {
	id: PropTypes.string,
	daoProposal: PropTypes.object,
	daoProposalId: PropTypes.string,
	avatar: PropTypes.string,
	accepted: PropTypes.number,
	displayName: PropTypes.string,
	votingWeight: PropTypes.number,
	statusId: PropTypes.number,
	blockchainHash: PropTypes.string,
	userId: PropTypes.string
}

export default VoterItem
