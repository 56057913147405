import { configureStore } from '@reduxjs/toolkit'
import dashboardReducer from 'component/dashboard/dashboardSlice'
import signInReducer from 'component/header/signInSlice'
import vestingReducer from 'component/profile/walletTab/vestingOverwiew/vestingSlice'
import projectDetailReducer from 'component/projectDetail/projectDetailSlice'
import notificationReducer from 'component/common/notification/notificationSlice'
import profileReducer from 'component/profile/profileSlice'
import projectEditReducer from 'component/projectEdit/projectEditSlice'
import newsreelTabReducer from 'component/common/newsreelTab/newsreelTabSlice'
import profileEditReducer from 'component/profileEdit/profileEditSlice'
import tileManagementReducer from 'component/tileManagement/tileManagementSlice'
import settingsReducer from 'component/settings/settingsSlice'
import homeReducer from 'component/home/homeSlice'
import walletTabReducer from 'component/profile/walletTab/walletTabSlice'
import superfanReducer from 'component/projectDetail/dashboardTab/superfan/superfanSlice'
import claimFgrReducer from 'component/header/claimFgrDialog/claimFgrSlice'
import reactionsReducer from 'component/common/reactions/reactionSlice'
import projectAddWizardReducer from 'component/projectAddWizard/projectAddWizardSlice'
import newsreelPageReducer from 'component/newsreelPage/newsreelPageSlice'
import tokenSaleExperienceReducer from 'component/tokenSaleExperience/tokenSaleExperienceSlice'
import captchaCheckReducer from 'component/common/captchaCheck/captchaCheckSlice'

export const makeStore = () => {
	return configureStore({
		reducer: {
			home: homeReducer,
			signIn: signInReducer,
			profile: profileReducer,
			settings: settingsReducer,
			claimFgr: claimFgrReducer,
			walletTab: walletTabReducer,
			dashboard: dashboardReducer,
			newsreelPage: newsreelPageReducer,
			projectDetail: projectDetailReducer,
			projectAddWizard: projectAddWizardReducer,
			tokenSaleExperience: tokenSaleExperienceReducer,
			tileManagement: tileManagementReducer,
			captchaCheck: captchaCheckReducer,
			notification: notificationReducer,
			projectEdit: projectEditReducer,
			newsreelTab: newsreelTabReducer,
			profileEdit: profileEditReducer,
			reactions: reactionsReducer,
			superfan: superfanReducer,
			vesting: vestingReducer
		}
	})
}
