import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import Countdown from 'react-countdown'
import styles from './CustomCountdown.module.scss'

const CountdownNumber = ({ value, variant, label }) => {
	const renderValue = value < 10 ? `0${value}` : value
	const classes = `${styles.countdown_number} ${styles[variant] ?? ''}`
	return (
		<span className={classes}>
			{renderValue} {label ?? ''}
		</span>
	)
}

CountdownNumber.propTypes = {
	value: PropTypes.number,
	variant: PropTypes.string,
	label: PropTypes.string
}

export const CustomCountdown = ({
	completeComponent,
	date,
	message,
	variant = 'black'
}) => {
	const classes = `${styles.custom_countdown} ${styles[variant] ?? ''}`
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			const Component = completeComponent
			return completeComponent ? (
				<Component />
			) : (
				<Typography className={styles.ended}>
					<span>00 D</span> <span>00 H</span> <span>00 M</span>
				</Typography>
			)
		} else {
			return (
				<Box>
					<CountdownNumber {...{ variant, value: days, label: 'D' }} />
					<span className={styles.points}>:</span>
					<CountdownNumber {...{ variant, value: hours, label: 'H' }} />
					<span className={styles.points}>:</span>
					<CountdownNumber {...{ variant, value: minutes, label: 'M' }} />
					{variant !== 'dao_proporsal' && (
						<>
							<span className={styles.points}>:</span>
							<CountdownNumber {...{ variant, value: seconds }} />
						</>
					)}
				</Box>
			)
		}
	}

	return (
		<Box className={classes}>
			<Box className={styles.message}>{message}</Box>
			{date && <Countdown date={date} renderer={renderer} />}
		</Box>
	)
}

CustomCountdown.propTypes = {
	completeComponent: PropTypes.node,
	date: PropTypes.date,
	message: PropTypes.string,
	variant: PropTypes.string,
	label: PropTypes.string
}
