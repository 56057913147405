import _ from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {
	authNcWalletService,
	getMagicStatusService,
	loginService
} from 'services/auth.service'
import {
	setAirdropJoinOpen,
	setAuthenticated,
	setBanDialogOpen,
	setCongratsDialogOpen,
	setContestJoinedDialogOpen,
	setCreateAccountDialogOpen,
	setImportFanTokenDialogOpen,
	setJoinDialogEmail,
	setJoinDialogOpen,
	setLoginDialogOpen,
	setStartDialogOpen,
	updateSignInUser,
	setIsIngXRestrictedInvestor
} from 'component/header/signInSlice'
import { baseUrl } from 'config'
import useNotification from 'hooks/useNotification'
import { isValidEmail } from 'utils/form.util'
import useLoadingMask from 'hooks/useLoadingMask'
import { addObjectToLocalStorage } from 'utils/utils'
import { getNowDateDiff } from 'utils/date.utils'
import useAxios from 'hooks/useAxios'
import {
	magicSignInService,
	magicSignUpService
} from 'services/profile.service'
import magic from 'utils/blockchain/config/constants'
import { Mixpanel } from 'services/mixpanel.service'
import useHeader from '../useHeader'
import { signMagicVerificationMessage } from 'utils/blockchain/magic'
import useWeb3 from 'hooks/useWeb3'
import useCommon from 'hooks/useCommon'

const useLoginDialog = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { pathname, search } = useLocation()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [hidePassword, setHidePassword] = useState(true)
	const [passwordtoggle, setPasswordtoggle] = useState(true)
	const { loginDialogOpen: open, loginDialogMessage } = useSelector(
		state => state.signIn
	)
	const { captchaToken } = useSelector(state => state.captchaCheck)
	const { showNotification } = useNotification()
	const [errors, setErrors] = useState({})
	const { showMask, hideMask } = useLoadingMask()
	const { loadUserTierInfo } = useHeader()
	const { isAuthenticatedReload } = useCommon()
	const { axiosService } = useAxios()
	const { disconnectAsync, metamaskLogin, openConnectWalletModal } = useWeb3()

	const fullUrlPath = `${pathname}${search}`

	const checkUserStatus = () => {
		showMask()
		axiosService(getMagicStatusService({ email }), (data, error) => {
			if (!error) {
				if (data?.isMagicUser) {
					authOrRegisterMagicUser(email)
				} else {
					dispatch(setLoginDialogOpen(false))
					dispatch(setJoinDialogOpen(true))
					dispatch(setJoinDialogEmail(email))
				}
			} else {
				dispatch(setLoginDialogOpen(true))
			}
			hideMask()
		})
	}

	const authUserToRegisterMagicWallet = ({ email, password }) => {
		showMask()
		axiosService(
			loginService({
				email,
				password
			}),
			(_data, error) => {
				if (!error) {
					authOrRegisterMagicUser(email, true)
				} else {
					hideMask()
				}
			}
		)
	}

	const authOrRegisterMagicUser = async (email, sign = false) => {
		hideMask()
		try {
			const iToken = await magic.auth.loginWithEmailOTP({ email })
			await magicSignIn(iToken, sign)
		} catch (error) {
			hideMask()
			disconnectAsync()
			console.log({ error })
		}
	}

	const authNcMagicUser = async email => {
		hideMask()
		try {
			const iToken = await magic.auth.loginWithEmailOTP({ email })
			axiosService(magicSignUpService({ iToken }), (data, error) => {
				if (!error) {
					afterLogin(data, false)
				}
				hideMask()
			})
		} catch (error) {
			hideMask()
			disconnectAsync()
			console.log({ error })
		}
	}

	const showMessageLoop = sign => {
		if (!sign) {
			showMask()
			return null
		}
		let index = 0
		const messagePrefix = `We're Upgrading Your Film.io Experience.\n`
		const initialMessage = `${messagePrefix} This will take 1-2 minutes, please hang tight.`
		showMask(initialMessage)
		const loadMessages = [
			`We’re still working on the upgrade, thanks for your patience!`,
			`You’re on your way to a much faster blockchain experience!`,
			`We’re almost there, soon you’ll be able to process multiple transactions at the same time!`,
			`Getting close!  You are on your way to a more secure and convenient no password experience!`
		]
		const intervalId = setInterval(() => {
			const message = `${messagePrefix} ${loadMessages[index]}`
			showMask(message)
			index = index === 3 ? 0 : index + 1
		}, 15000)
		return intervalId
	}

	const magicSignIn = async (iToken, sign = false) => {
		const intervalId = showMessageLoop(sign)

		const { message, signature } = await signMagicVerificationMessage()

		axiosService(
			magicSignInService({ iToken, message, signature, captchaToken }),
			(data, error) => {
				if (!error) {
					afterLogin(data, false)
				}
				clearInterval(intervalId)
				hideMask()
			}
		)
	}

	const afterLogin = async (data, error) => {
		if (isAuthenticatedReload()) {
			return
		}
		if (!error) {
			if (data?.isBanned === 1) {
				const { createdAt, banDate } = data
				const banData = { createdAt, banDate }
				addObjectToLocalStorage(banData)
				dispatch(updateSignInUser(banData))
				dispatch(setBanDialogOpen(true))
				hideMask()
				return
			}
			if (
				data?.isDaoTermsAgreed === 1 &&
				data?.fanTokenImported === 0 &&
				data?.isNonCustodial === 1
			) {
				dispatch(setImportFanTokenDialogOpen(true))
			}
			if (
				!_.isNil(data?.unlockRewardsEndDate) &&
				getNowDateDiff(data?.unlockRewardsEndDate).seconds >= 0
			) {
				dispatch(setCongratsDialogOpen(true))
			}
			if (data?.contestNotificationPending) {
				dispatch(setContestJoinedDialogOpen(true))
			}
			dispatch(setAuthenticated(data))
			const isIngXRestrictedInvestor =
				!_.isNil(data?.isIngXInvestor) && data?.isIngXInvestor
			dispatch(setIsIngXRestrictedInvestor(isIngXRestrictedInvestor))
			addObjectToLocalStorage({
				...data,
				isAuthenticated: true,
				isIngXRestrictedInvestor,
				fullUrlPath
			})
			setEmail('')
			registerMixpanelEvent(data)
			loadUserTierInfo()
			setTimeout(() => {
				navigate('/loading')
			})
		}
		dispatch(setAirdropJoinOpen(false))
		hideMask()
	}

	const authNcWallet = data => {
		showMask()
		axiosService(
			authNcWalletService({ captchaToken, ...data }),
			(response, error) => {
				if (!error) {
					if (response?.isBanned === 1) {
						const { createdAt, banDate } = response
						const banData = { createdAt, banDate }
						addObjectToLocalStorage(banData)
						dispatch(updateSignInUser(banData))
						dispatch(setBanDialogOpen(true))
						hideMask()
						return
					}
					if (response?.isNcWithoutMagic) {
						afterLogin(response, false)
					} else {
						authNcMagicUser(response?.email)
					}
				} else {
					disconnectAsync()
				}
				hideMask()
			}
		)
	}

	const registerMixpanelEvent = data => {
		Mixpanel.identify(data?.id)
		Mixpanel.people.set({
			displayName: data?.displayName,
			id: data?.id,
			username: data?.username,
			isDaoTermsAgreed: data?.isDaoTermsAgreed,
			isAirdropCreator: data?.isAirdropCreator,
			isAdmin: data?.isAdmin
		})
		Mixpanel.track('user_login_success_evt', {
			distinct_id: data?.id,
			displayName: data?.displayName,
			id: data?.id,
			username: data?.username,
			isDaoTermsAgreed: data?.isDaoTermsAgreed,
			isAirdropCreator: data?.isAirdropCreator,
			isAdmin: data?.isAdmin,
			projectCount: data?.projectCount,
			balance: data?.balance
		})
	}

	const handleLogin = (email, password) => {
		showMask()
		authUserToRegisterMagicWallet({ email, password })
	}

	const handleMMLogin = () => {
		metamaskLogin()
		handleClose()
	}

	const handleNcLogin = () => {
		openConnectWalletModal()
		handleClose()
	}

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const isValid = () => {
		let isValid = true
		const errorsTmp = {}
		const message = 'This field is required'
		if (_.isNil(password) || (password.trim() === '' && !hidePassword)) {
			errorsTmp.password = message
			isValid = false
		}
		if (_.isNil(email) || email.trim() === '') {
			errorsTmp.email = message
			isValid = false
		}
		if (!_.isNil(email) && !isValidEmail(email)) {
			errorsTmp.email = 'The email format is incorrect.'
			isValid = false
		}
		updateErrors(errorsTmp)
		return isValid
	}

	const handleSubmit = () => {
		const submitService = hidePassword
			? checkUserStatus
			: authUserToRegisterMagicWallet
		if (isValid()) {
			handleClose()
			submitService({ email, password })
		} else
			showNotification({
				message: 'Please fix the form errors to continue',
				type: 'error'
			})
	}

	const passwordChangeHandler = event => {
		updateErrors({ password: false })
		setPassword(event.target.value)
	}

	const emailChangeHandler = event => {
		updateErrors({ email: false })
		setEmail(_.trim(event.target.value))
	}
	const passwordShow = () => {
		setPasswordtoggle(!passwordtoggle)
	}

	const handleClose = () => {
		dispatch(setLoginDialogOpen(false))
	}

	const handleOpenAccountDialog = () => {
		dispatch(setLoginDialogOpen(false))
		dispatch(setCreateAccountDialogOpen(true))
	}

	const handleForgotPassword = () => {
		dispatch(setLoginDialogOpen(false))
		navigate('/forgotPassword')
	}

	const handleGoBack = () => {
		dispatch(setStartDialogOpen(true))
		handleClose()
	}

	const handleKeypress = e => {
		if (e.charCode === 13) {
			handleSubmit()
		}
	}
	const facebookLink = `${baseUrl}/user/sauth/facebook`
	const twitterLink = `${baseUrl}/user/sauth/twitter`

	return {
		open,
		email,
		errors,
		password,
		twitterLink,
		facebookLink,
		captchaToken,
		hidePassword,
		passwordtoggle,
		loginDialogMessage,
		handleOpenAccountDialog,
		passwordChangeHandler,
		handleForgotPassword,
		emailChangeHandler,
		setHidePassword,
		checkUserStatus,
		handleKeypress,
		handleNcLogin,
		handleMMLogin,
		authNcWallet,
		handleGoBack,
		passwordShow,
		handleSubmit,
		handleClose,
		handleLogin,
		afterLogin
	}
}

export default useLoginDialog
