import PropTypes from 'prop-types'
import { Grid, Tabs, Tab, Box, Button } from '@mui/material'
import { AddTeamForm } from './addTeamForm'
import { TeamCard } from './teamCard'
import useTeamTab from './useTeamTab'
import styles from './TeamTab.module.scss'

function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

export default function Team() {
	const {
		teams,
		showAdd,
		tabIndex,
		handleTabChange,
		handleOpenForm,
		showAddTeamMemberForm
	} = useTeamTab()

	const insertLinkLastP = (about, username) => {
		if (about === null) return about
		const firstP = about.split('</p>')[0] + '</p>'
		const index = firstP.lastIndexOf('</p>')
		const link = `<a href="/profile/${username}">Read More</a>`
		const result = `${firstP.slice(0, index)} ${link} ${firstP.slice(index)}`
		return result
	}

	const renderTeam = (team = []) => {
		return team.map((el, i) => {
			const { projectId, teamId, userId } = el
			const { avatar, displayName, username, about } = el.user
			const key = userId + projectId + teamId
			const aboutWithLink = insertLinkLastP(about, username)
			const props = {
				userId: el.userId,
				projectId: el.projectId,
				teamId: el.teamId,
				avatar,
				displayName,
				username,
				role: el?.department?.name,
				position: el?.position,
				about: aboutWithLink
			}
			return <TeamCard key={key} {...props} />
		})
	}

	const crewLabel = 'Crew (' + teams.crew?.length + ')'
	const castLabel = 'Cast (' + teams.cast?.length + ')'
	const collabLabel = 'Collaborators (' + teams.collaborators?.length + ')'

	return (
		<Box sx={{ width: '100%' }} className={styles.team_tab_section}>
			<Box
				sx={{ borderBottom: 1, borderColor: 'divider' }}
				className={styles.team_header}
			>
				<Tabs
					value={tabIndex}
					onChange={handleTabChange}
					aria-label="basic tabs example"
					className={styles.tabs_list}
				>
					<Tab
						label={castLabel}
						{...a11yProps(0)}
						disabled={showAddTeamMemberForm}
					/>
					<Tab
						label={crewLabel}
						{...a11yProps(1)}
						disabled={showAddTeamMemberForm}
					/>
					<Tab
						label={collabLabel}
						{...a11yProps(2)}
						disabled={showAddTeamMemberForm}
					/>
				</Tabs>
				{showAdd && (
					<Button className={styles.addButton} onClick={handleOpenForm}>
						Add
					</Button>
				)}
			</Box>
			{showAddTeamMemberForm && <AddTeamForm />}

			<TabPanel value={tabIndex} index={0}>
				<Grid container spacing={4}>
					{renderTeam(teams.cast)}
				</Grid>
			</TabPanel>
			<TabPanel value={tabIndex} index={1}>
				<Grid container spacing={4}>
					{renderTeam(teams.crew)}
				</Grid>
			</TabPanel>
			<TabPanel value={tabIndex} index={2}>
				<Grid container spacing={4}>
					{renderTeam(teams.collaborators)}
				</Grid>
			</TabPanel>
		</Box>
	)
}
