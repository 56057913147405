import { useDispatch } from 'react-redux'
import { setLoadingMessage, setShowLoading } from 'component/header/signInSlice'

const useLoadingMask = () => {
	const dispatch = useDispatch()
	const showMask = (message = null) => {
		dispatch(setShowLoading(true))
		dispatch(setLoadingMessage(message))
	}
	const hideMask = () => {
		dispatch(setShowLoading(false))
	}

	return {
		showMask,
		hideMask
	}
}

export default useLoadingMask
