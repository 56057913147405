export const getSocialShareLink = ({ socialNetwork, link, message }) => {
	const socialLinks = {
		linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${link}`,
		facebook: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
		x: `https://twitter.com/intent/tweet?url=${link}`
	}
	return socialLinks[socialNetwork] ?? ''
}

export const getSocialNetworkId = network => {
	const socialNetworkMap = {
		facebook: 1,
		x: 3,
		linkedin: 4
	}
	return socialNetworkMap[network] ?? null
}
