import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'
import { closeNotification, closeNotificationExt } from './notificationSlice'
import { Avatar, Button } from '@mui/material'
import { getDateNowFull } from 'utils/date.utils'
import styles from './Notification.module.scss'
import images from 'assets/images'
import { ModalNotification } from './ModalNotification'

export const notificationTypes = {
	SUCCESS: 'success',
	WARNING: 'warning',
	ERROR: 'error',
	INFO: 'info'
}

export const Notification = () => {
	const dispatch = useDispatch()
	const {
		open,
		title,
		message,
		cancelButton,
		type = notificationTypes.SUCCESS,
		cancelButtonText,
		buttonCallback,
		buttonText,
		variant
	} = useSelector(state => state.notification)
	const handleClose = () => {
		dispatch(closeNotification())
	}
	return (
		<ModalNotification
			{...{
				type,
				open,
				title,
				message,
				handleClose,
				cancelButton,
				cancelButtonText,
				buttonCallback,
				buttonText,
				variant
			}}
		/>
	)
}

export const notificationExtTypes = {
	STAKING: 'staking',
	UNSTAKING: 'unstaking',
	TRANSFERING: 'transfering',
	REVIEW: 'review',
	SHARE: 'share',
	WITHDRAW: 'withdraw'
}

export const EXT_DEFAULTS = {
	[notificationExtTypes.STAKING]: {
		title: 'Staking',
		action: 'Staked to',
		loadingMsg: 'Staking...',
		waitMsg: `Your FAN Token amount may take a minute to update.  Navigate to your wallet to refresh.`,
		icon: 'gem'
	},
	[notificationExtTypes.UNSTAKING]: {
		title: 'Unstaking',
		action: 'Unstaked from',
		loadingMsg: 'Reclaiming...',
		icon: 'gem'
	},
	[notificationExtTypes.TRANSFERING]: {
		title: 'Transfering',
		loadingMsg: 'Transfering...'
	},
	[notificationExtTypes.REVIEW]: {
		title: 'Review',
		loadingMsg: 'Creating review...',
		waitMsg:
			'Your FAN Token amount may take a minute to update.  Navigate to your wallet to refresh.',
		icon: 'star-half-stroke'
	},
	[notificationExtTypes.SHARE]: {
		title: 'Social Share',
		loadingMsg: 'Processing share reward...',
		waitMsg:
			'Your FAN Token amount may take a minute to update. Navigate to your wallet to refresh.',
		icon: 'share'
	},
	[notificationExtTypes.WITHDRAW]: {
		title: 'Transferring',
		// action: 'FAN Transfer',
		loadingMsg: 'Transferring...',
		waitMsg: `Your FAN Token amount may take a minute to update. Reload the page in a minute to refresh.`,
		icon: 'gem'
	}
}

function TransitionLeft1(props) {
	return <Slide {...props} direction="left" />
}

export const NotificationExtended = () => {
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.signIn)
	const { ext } = useSelector(state => state.notification)
	const {
		openExtended,
		projectTitle,
		type,
		total,
		completed = false,
		toAvatar,
		toName
	} = ext

	const { action, title, loadingMsg, icon } = _.get(EXT_DEFAULTS, [type], {})

	function stringToColor(string) {
		let hash = 0
		let i
		for (i = 0; i < string?.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash)
		}

		let color = '#'

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff
			color += `00${value.toString(16)}`.slice(-2)
		}
		return color
	}

	function stringAvatar(name = '') {
		return {
			sx: {
				bgcolor: stringToColor(name)
			}
		}
	}

	const handleClose = (_event, reason) => {
		if (reason !== 'clickaway') dispatch(closeNotificationExt())
	}

	const isReward =
		type === notificationExtTypes.REVIEW || type === notificationExtTypes.SHARE
	const titleSufix = isReward ? '' : 'FAN'
	const messageSufix = isReward
		? 'Reward'
		: type === notificationExtTypes.STAKING
		? 'Staked'
		: ''

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
				open={openExtended}
				onClose={handleClose}
				TransitionComponent={TransitionLeft1}
			>
				<div className={styles.notification_extended}>
					<div className={styles.header}>
						<div className={styles.adornment}></div>
						<h1>
							{title} {titleSufix}
						</h1>
						<div>
							<Avatar
								alt={user?.displayName}
								src={user?.avatar}
								{...stringAvatar(user?.displayName)}
								sx={{ width: 56, height: 56 }}
								classes={{ root: styles.avatar }}
							/>
							{icon && (
								<i className={`fa-regular fa-${icon} ${styles.icon_only}`}></i>
							)}
							{type === notificationExtTypes.TRANSFERING && (
								<>
									<i className={`fa-solid fa-arrow-right ${styles.arrow}`}></i>
									<Avatar
										alt={toName}
										src={toAvatar}
										{...stringAvatar(toName)}
										sx={{ width: 56, height: 56 }}
										classes={{ root: styles.avatar }}
									/>
								</>
							)}
						</div>
						<p>{getDateNowFull()}</p>
					</div>
					<div className={styles.content}>
						<h3>DETAILS</h3>
						<div>
							<span>Estimated Gas Fee ?</span>
							<span>FREE</span>
						</div>
						<div>
							<span>FAN Tokens {messageSufix}</span>
							<span>
								<img src={images.newToken} alt="" /> {total}
							</span>
						</div>
						{action && (
							<div>
								<span>Project {action}</span>
								<span>{projectTitle.substring(0, 20)}</span>
							</div>
						)}
					</div>
					<div className={styles.footer}>
						<div>
							<span>Total</span>
							<span>
								<img src={images.newToken} alt="" />
								{total}
							</span>
						</div>
						<div>
							<p>{completed ? 'Completed' : loadingMsg}</p>
							<Button onClick={handleClose}>Close</Button>
						</div>
						<span className={styles.subinfo}>
							Please do not refresh the page while transactions are processing,
							it may cause delays. Your token balance will update when complete.
						</span>
					</div>
				</div>
			</Snackbar>
		</>
	)
}
