import tokenIcon from './token.png'
import followerlarge from './followerlarge.png'
import investorlarge from './investorlarge.png'
import defaultProfileHeader from './default-profile-header.jpeg'
import wallet from './wallet.icon.png'
import communityVideoPoster from './community.image.png'
import empowerFans from './empower.fans.png'
import liberateCreators from './liberate.creators.png'
import twitter from './twitter.png'
import telegram from './telegram.png'
import discord from './discord.png'
import metamaskColor from './metamask-color.png'
import newToken from './new_token.svg'
import newTokenBlack from './new_token_black.svg'
import badgeLocked from './badge-locked.png'
import lock from './lock.svg'
import heart from './heart.svg'
import heartFill from './heart-fill.svg'
import help from './help.svg'
import lockIcon from './lockIcon.svg'
import airdrop2023 from './airdrop_2023.svg'
import newsreelEmpty from './newsreel_empty.svg'
import blankHeart from './superfan/blankHeart.svg'
import arrowGradient from './arrowGradient.svg'
import explainerSlideOne from './homeslides/IMG_1306.jpg'
import explainerSlideTwo from './homeslides/IMG_1309.jpg'
import explainerSlideThree from './homeslides/IMG_1310.jpg'
import explainerSlideFour from './homeslides/IMG_1312.jpg'
import explainerSlideFive from './homeslides/IMG_1315.jpg'
import staffPickLarge from './sss-large.svg'
import placeHolder from './placeholder.svg'
import twitterPreview from './twitter-preview.jpg'
import contestComplete from './contest-complete.png'
import fgrBack from './fgr-back.png'
import unclaimedBack from './unclaimed-back.png'
import ethLogo from './ethereum.svg'
import warning from './warning.png'
import rewardToken from './rewards-token.png'
import transferIllustration from './transfer-illustration.png'
import rewardSafegauard from './rewards-safegaurds.jpg'
import homeHero from './homepage/hero.webp'
import homeShine from './homepage/shine.webp'
import homeGrid from './homepage/grid.webp'
import homeFan from './homepage/fan.png'
import homeCreator from './homepage/creator.png'

const images = {
	wallet: wallet.src,
	warning: warning.src,
	tokenIcon: tokenIcon.src,
	empowerFans: empowerFans.src,
	followerlarge: followerlarge.src,
	liberateCreators: liberateCreators.src,
	communityVideoPoster: communityVideoPoster.src,
	defaultProfileHeader: defaultProfileHeader.src,
	newTokenBlack: newTokenBlack.src,
	investorlarge: investorlarge.src,
	metamaskColor: metamaskColor.src,
	badgeLocked: badgeLocked.src,
	heartFill: heartFill.src,
	lockIcon: lockIcon.src,
	telegram: telegram.src,
	newToken: newToken.src,
	discord: discord.src,
	twitter: twitter.src,
	heart: heart.src,
	lock: lock.src,
	help: help.src,
	airdrop2023: airdrop2023.src,
	newsreelEmpty: newsreelEmpty.src,
	blankHeart: blankHeart.src,
	arrowGradient: arrowGradient.src,
	explainerSlideOne: explainerSlideOne.src,
	explainerSlideTwo: explainerSlideTwo.src,
	explainerSlideThree: explainerSlideThree.src,
	explainerSlideFour: explainerSlideFour.src,
	explainerSlideFive: explainerSlideFive.src,
	staffPickLarge: staffPickLarge.src,
	placeHolder: placeHolder.src,
	twitterPreview: twitterPreview.src,
	contestComplete: contestComplete.src,
	fgrBack: fgrBack.src,
	unclaimedBack: unclaimedBack.src,
	ethLogo: ethLogo.src,
	rewardToken: rewardToken.src,
	transferIllustration: transferIllustration.src,
	rewardSafegauard: rewardSafegauard.src,
	homeHero: homeHero.src,
	homeShine: homeShine.src,
	homeGrid: homeGrid.src,
	homeFan: homeFan.src,
	homeCreator: homeCreator.src
}

export default images
