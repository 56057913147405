import { Box, Button, Typography } from '@mui/material'
import { CustomDialog } from 'component/common'
import { useDispatch, useSelector } from 'react-redux'
import {
	setAirdropCheckDialogOpen,
	setAirdropSignUpDialogOpen
} from '../signInSlice'
import styles from './AirdropSignUpDialog.module.scss'

export const AirdropSignUpDialog = () => {
	const { airdropSignUpDialogOpen } = useSelector(state => state.signIn)
	const dispatch = useDispatch()

	const handleClose = () => {
		dispatch(setAirdropSignUpDialogOpen(false))
	}

	const handleGoBack = () => {
		dispatch(setAirdropCheckDialogOpen(true))
		handleClose()
	}

	return (
		<CustomDialog {...{ open: airdropSignUpDialogOpen, handleClose }}>
			<Box className={styles.header}>
				Be a Part of the World’s First Filmmaking DAO.
				<span>
					Let&apos;s make <span>great entertainment together.</span>
				</span>
			</Box>
			<Box className={styles.content}>
				<Typography>
					Unfortunately it looks like you aren’t on the Early Access list. Join
					the reservation list and you will be added in the next group of users
					that we onboard.
				</Typography>

				<Box>
					<Box className={styles.e_access_btn}>
						<Button
							href="https://beta.film.io/auth/login"
							target="_blank"
							rel="noreferrer"
						>
							Sign Up for Early Access
						</Button>
					</Box>
					<Button onClick={handleGoBack}>Check another Email</Button>
				</Box>
			</Box>
		</CustomDialog>
	)
}
