import { getContractValue, sendContractBlockchainTransaction, signMagicVerificationMessage, getMagicWalletAddress } from '../magic'
import { ethers } from 'ethers'
import _ from 'lodash'

class AddressLinkerContract {
	static contractName = 'addressLinker'

	static async transferFilmioOwnership(signer) {	
		if(_.isNil(signer)) {
			throw new Error('Make sure your wallet is connected!')
		}

		const { message, signature } = await signMagicVerificationMessage()
		const magicWalletAddress = await getMagicWalletAddress()

		return await sendContractBlockchainTransaction(
			AddressLinkerContract.contractName,
			'setNewUserAddress',
			[magicWalletAddress, ethers.utils.toUtf8Bytes(message), signature],
			'',
			signer
		)
	}

	// Read Functions
	static async getOriginWallet(magicWalletAddress) {
		return await getContractValue(AddressLinkerContract.contractName, 'getOriginalOwner', [
			magicWalletAddress
		])
	}
}


export default AddressLinkerContract
