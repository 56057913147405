import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { socialLoginService } from 'services/auth.service'
import { LoadMask } from 'component/common'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { setAuthenticated } from '../signInSlice'
import useHeader from '../useHeader'
import { Mixpanel } from 'services/mixpanel.service'
import { addObjectToLocalStorage } from 'utils/utils'

export default function SocialLogin() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()
	const { loadUserTierInfo } = useHeader()

	const afterLogin = (data, error) => {
		if (!error) {
			dispatch(setAuthenticated(data))
			addObjectToLocalStorage(data)
			Mixpanel.identify(data?.id)
			Mixpanel.track('user_social_login_success_evt', {
				distinct_id: data?.id,
				$email: data?.email,
				$name: data?.displayName,
				id: data?.id,
				username: data?.username,
				isDaoTermsAgreed: data?.isDaoTermsAgreed,
				isAirdropFan: data?.isAirdropFan,
				isAirdropCreator: data?.isAirdropCreator,
				isAdmin: data?.isAdmin,
				projectCount: data?.projectCount,
				balance: data?.balance
			})
			Mixpanel.people.set(data?.id, {
				$name: data?.displayName,
				id: data?.id,
				$email: data?.email,
				username: data?.username,
				isDaoTermsAgreed: data?.isDaoTermsAgreed,
				isAirdropCreator: data?.isAirdropCreator,
				isAdmin: data?.isAdmin
			})
			loadUserTierInfo(() => {
				window.opener.location.href = '/'
				self.close()
			})
		} else {
			navigate('/')
		}
	}

	useAxiosFetch(socialLoginService(params), afterLogin)

	return <LoadMask />
}
