import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	type: 'success',
	open: false,
	message: '',
	title: null,
	cancelButton: false,
	cancelButtonText: 'Cancel',
	buttonText: 'Thanks',
	buttonCallback: null,
	ext: {
		title: '',
		projectTitle: '',
		total: 0,
		avatar: '',
		completed: false,
		openExtended: false,
		loadingMsg: 'Transfering',
		toName: '',
		toAvatar: '',
		transactionFee: 0,
		usdValue: 0
	}
}

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		showNotification: (state, { payload }) => {
			state.ext.openExtended = false
			state.ext.completed = false
			state.open = true
			state.message = payload?.message
			state.title = payload?.title
			state.type = payload?.type
			state.buttonText = payload?.buttonText
			state.buttonCallback = payload?.buttonCallback
			state.cancelButton = payload?.cancelButton
			state.cancelButtonText = payload?.cancelButtonText
			state.variant = payload?.variant
			state.transactionFee = payload?.transactionFee
			state.usdValue = payload?.usdValue
		},
		updateExtended: (state, { payload }) => {
			state.ext = {
				...state.ext,
				...payload
			}
		},
		closeNotificationExt: state => {
			state.ext = {
				...state.ext,
				completed: false,
				openExtended: false
			}
		},
		closeNotification: state => {
			state.open = false
		}
	}
})

export const {
	showNotification,
	closeNotification,
	closeNotificationExt,
	updateExtended
} = notificationSlice.actions

export default notificationSlice.reducer
