import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addReactionService } from '@/services/reaction.service'
import { Mixpanel } from '@/services/mixpanel.service'
import { useToast } from '@/hooks/use-toast'
import { setLoginDialogOpen } from '../../../../../../header/signInSlice'

interface UseReactionsProps {
	feedId: string
	type: string
	onUpdate?: (data: any) => void
}

export const useReactions = ({ feedId, type, onUpdate }: UseReactionsProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const dispatch = useDispatch()
	const { toast } = useToast()
	const { reactions } = useSelector(state => state.reactions)
	const { user, isAuthenticated } = useSelector(state => state.signIn)

	const handleReaction = async (reactionId: number) => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}

		try {
			const res = await addReactionService(reactionId, feedId, type)
			if (res.status === 200) {
				Mixpanel.track('comment_reaction_add_event', {
					distinct_id: user.id,
					displayName: user.displayName,
					username: user.username,
					id: reactionId,
					feedId,
					type
				})
				onUpdate?.(res.data)
				setIsOpen(false)
			}
		} catch (error) {
			toast({
				variant: 'destructive',
				title: 'Error',
				description: 'Failed to add reaction. Please try again.'
			})
			console.error('Failed to add reaction:', error)
		}
	}

	return {
		isOpen,
		reactions,
		handleReaction,
		setIsOpen
	}
}
