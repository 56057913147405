import _ from 'lodash'
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogContent,
	Icon,
	IconButton,
	Typography
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { closeNotificationExt } from '../notification/notificationSlice'
import {
	EXT_DEFAULTS,
	notificationExtTypes
} from '../notification/Notification'
import { getDateNowFull } from 'utils/date.utils'
import styles from './ModalNotificationExtended.module.scss'

export const ModalNotificationExtended = () => {
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.signIn)
	const { ext } = useSelector(state => state.notification)
	const {
		openExtended,
		projectTitle,
		type,
		total,
		completed = false,
		toAvatar,
		toName
	} = ext

	const { action, title, loadingMsg, icon } = _.get(EXT_DEFAULTS, [type], {})

	const isReview = type === notificationExtTypes.REVIEW
	const isReward = type === isReview || type === notificationExtTypes.SHARE
	const titleSufix = isReward ? '' : 'FAN'
	const messageSufix = isReward
		? 'Reward'
		: type === notificationExtTypes.STAKING
		? 'Staked'
		: ''
	const isNonCustodial = user?.isNonCustodial === 1

	const handleClose = _event => {
		dispatch(closeNotificationExt())
	}
	return (
		<Dialog
			open={openExtended}
			disableScrollLock={true}
			className={styles.dialog}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
		>
			<IconButton
				size="small"
				onClick={handleClose}
				className={styles.close_btn}
			>
				<Icon baseClassName="fas" className="fa-xmark" />
			</IconButton>
			<DialogContent className={styles.dialog_content}>
				<Box className={styles.notification_extended}>
					<Box className={styles.header}>
						<Typography component={'h1'}>
							{title} {titleSufix}
						</Typography>
						<Box>
							<Avatar
								alt={user?.displayName}
								src={user?.avatar}
								sx={{ width: 56, height: 56 }}
								classes={{ root: styles.avatar }}
							/>
							{icon && (
								<i className={`fa-regular fa-${icon} ${styles.icon_only}`}></i>
							)}
							{type === notificationExtTypes.TRANSFERING && (
								<>
									<i className={`fa-solid fa-arrow-right ${styles.arrow}`}></i>
									<Avatar
										alt={toName}
										src={toAvatar}
										sx={{ width: 56, height: 56 }}
										classes={{ root: styles.avatar }}
									/>
								</>
							)}
						</Box>
						<p>{getDateNowFull()}</p>
					</Box>
					<Box className={styles.content}>
						<h3>DETAILS</h3>
						{isReview ? (
							<Box>
								<span>Saving your review</span>
							</Box>
						) : (
							<Box>
								<span>FAN Tokens {messageSufix}</span>
								<span className={styles.fees}>{total}</span>
							</Box>
						)}
						{action && (
							<Box>
								<span>Project {action}</span>
								<span>{projectTitle?.substring(0, 20) ?? ''}</span>
							</Box>
						)}
					</Box>
					<Box className={styles.footer}>
						{!isReview && (
							<Box>
								<span>Total</span>
								<span className={styles.fees}>{total}</span>
							</Box>
						)}
						<Box>
							<p>{completed ? 'Completed' : loadingMsg}</p>
							{!isNonCustodial && <Button onClick={handleClose}>Close</Button>}
						</Box>
						{isNonCustodial && (
							<span className={styles.subinfo}>
								This transaction is processing in your wallet. Please check
								there for updates.
							</span>
						)}
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	)
}
