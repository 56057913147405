'use client'

import { Provider } from 'react-redux'
import { NuqsAdapter } from 'nuqs/adapters/react-router'

import { WagmiConfig } from 'wagmi'
import { wagmiConfig } from 'utils/blockchain/config/constants'
import { ThemeProvider } from '@mui/material/styles'
import { ConfirmProvider } from 'material-ui-confirm'
import { theme } from './theme/Theme'
import { BrowserRouter } from 'react-router-dom'
import { useRef } from 'react'
import { makeStore } from './store/store'

type Props = {
	children: React.ReactNode
}

export const ContextProviders = ({ children }: Props) => {
	const storeRef = useRef<any>()
	if (!storeRef.current) {
		storeRef.current = makeStore()
	}

	return (
		<NuqsAdapter>
			<BrowserRouter>
				<WagmiConfig config={wagmiConfig}>
					<ThemeProvider theme={theme}>
						<ConfirmProvider
							defaultOptions={{
								dialogProps: { classes: { root: 'modal_dark' } }
							}}
						>
							<Provider store={storeRef.current}>{children}</Provider>
						</ConfirmProvider>
					</ThemeProvider>
				</WagmiConfig>
			</BrowserRouter>
		</NuqsAdapter>
	)
}
