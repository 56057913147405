import useAxiosFetch from 'hooks/useAxiosFetch'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verifyAirdropJoin } from 'services/auth.service'
import {
	setAirdropCheckDialogOpen,
	setAirdropSignUpDialogOpen,
	setLoginDialogMessage,
	setLoginDialogOpen,
	setStartDialogOpen
} from '../signInSlice'

export const useAirdropCheckDialog = () => {
	const [email, setEmail] = useState('')
	const [existUser, setExistUser] = useState(null)
	const { airdropCheckDialogOpen: open } = useSelector(state => state.signIn)
	const dispatch = useDispatch()
	const [message, setMessage] = useState(null)

	const openAirdropSignUpDialog = () => {
		dispatch(setAirdropSignUpDialogOpen(true))
		dispatch(setAirdropCheckDialogOpen(false))
	}

	const handleClose = () => {
		dispatch(setAirdropCheckDialogOpen(false))
		setExistUser(null)
		setMessage(null)
	}

	const handleGoBack = () => {
		dispatch(setStartDialogOpen(true))
		handleClose()
	}

	const handleKeypress = e => {
		if (e.charCode === 13) {
			handleVerify()
		}
	}

	const openLogin = () => {
		const msg = 'Looks like you already have an account, please sign in below.'
		dispatch(setLoginDialogMessage(msg))
		handleClose()
		dispatch(setLoginDialogOpen(true))
	}

	const [{ loading }, verifyAirdropEmail] = useAxiosFetch(
		verifyAirdropJoin(email),
		(data, error) => {
			if (!error) {
				if (!data?.success && !data?.existUser) {
					openAirdropSignUpDialog()
				} else {
					if (data?.existUser) openLogin()
					else {
						setExistUser(data?.existUser)
						const msg =
							'In order to start using Filmio, we need to confirm your email address. Please check your email for our message!'
						setMessage(msg)
					}
				}
			}
		}
	)

	const handleVerify = () => {
		verifyAirdropEmail()
	}

	useEffect(() => {
		dispatch(setLoginDialogMessage(null))
	}, [])

	return {
		open,
		email,
		loading,
		message,
		existUser,
		handleKeypress,
		handleVerify,
		handleGoBack,
		handleClose,
		setEmail
	}
}
