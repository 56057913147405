import useAxiosFetch from 'hooks/useAxiosFetch'
import useLoadingMask from 'hooks/useLoadingMask'
import { useEffect, useState } from 'react'
import {
	getDashboardQuestsService,
	getDashboardTilesService
} from 'services/dashboard.service'

export const useQuests = () => {
	const { showMask, hideMask } = useLoadingMask()

	const [{ loading, response, error }] = useAxiosFetch(
		getDashboardQuestsService()
	)

	useEffect(() => {
		loading ? showMask() : hideMask()
	}, [loading])

	return {
		loading,
		quests: !_.isNil(response?.data) ? response.data : [],
		error
	}
}
