import { useState } from 'react'
import { Menu } from '@mui/material'
import PropTypes from 'prop-types'
import { EditTweetDialog } from 'component/projectDetail/dashboardTab/socialMediaShareMenu/editTweetDialog/EditTweetDialog'
import { SocialButton } from '../socialButton'
import './SocialMediaShareMenu.scss'

export const SocialMediaShareMenu = ({ isDraft, className }) => {
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<SocialButton
				{...{
					text: 'Share your Project',
					icon: 'share-from-square',
					handleClick
				}}
			/>
			<Menu
				id="detail-social-share-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				elevation={0}
				getcontentanchorel={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<EditTweetDialog {...{ isDraft, closeMenu: handleClose }} />
			</Menu>
		</>
	)
}

SocialMediaShareMenu.propTypes = {
	isDraft: PropTypes.bool,
	className: PropTypes.string
}
