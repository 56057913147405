import { Button, Box, Typography } from '@mui/material'
import { CustomDialog } from 'component/common'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetWizard, setCloseModalOpen } from '../projectAddWizardSlice'
import useProjectAddWizard from '../useProjectAddWizard'
import styles from './CloseWizardDialog.module.scss'

const CloseWizardDialog = () => {
	const { closeModalOpen: open, project } = useSelector(
		state => state.projectAddWizard
	)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { handleSaveChanges } = useProjectAddWizard(false)

	const handleClose = () => {
		dispatch(setCloseModalOpen(false))
	}

	const handleDismiss = () => {
		dispatch(resetWizard())
		navigate('/')
	}

	const saveChanges = () => {
		handleClose()
		handleSaveChanges(true)()
	}

	const isTitleEmpty = _.isNil(project?.title) || project?.title.trim() === ''
	const message = isTitleEmpty
		? 'You need to add the title in order to save your changes.'
		: 'Do you want to save your changes?'

	return (
		<CustomDialog {...{ open, handleClose, variant: 'confirm_dialog' }}>
			<Box className={styles.content}>
				<Typography>{message}</Typography>
				<Box className={styles.actions}>
					<Button onClick={handleDismiss}>Dismiss Changes</Button>
					{isTitleEmpty ? (
						<Button onClick={handleClose}>Go Back</Button>
					) : (
						<Button onClick={saveChanges}>Save Changes</Button>
					)}
				</Box>
			</Box>
		</CustomDialog>
	)
}

export default CloseWizardDialog
