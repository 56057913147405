import { useEffect } from 'react'
import { updateMetaTags } from 'utils/utils'
import styles from './CodeOfConduct.module.scss'

const CodeOfConduct = () => {
	useEffect(() => {
		updateMetaTags({})
	}, [])

	return (
		<div className={styles.container}>
			<h2>Code of Conduct</h2>
			<p>
				<strong>Updated as of August 21, 2020</strong>
			</p>
			<p>
				The following rules apply to all Users of the Services and Site.
				Capitalized words not defined herein shall have the meanings ascribed to
				them in the Terms of Service, a copy of which can be found here.
			</p>
			<p>
				Filmio reserves the right to update or make changes to this Code of
				Conduct in its sole and absolute discretion from time to time. Filmio
				may notify you of changes by any reasonable means, including without
				limitation, by notifying you through the email you provide us in your
				User Profile or by posting the revised version of this Code of Conduct
				on the Site, which changes will become effective immediately upon the
				transmission of such email or posting on the Site, whichever occurs
				first. You can determine when this Code of Conduct was last revised by
				referring to the “Updated” legend at the top of this Code of Conduct.
				Please return to this page periodically to ensure familiarity with the
				most current version of this Code of Conduct.
			</p>
			<p>Users of the Services shall not:</p>
			<ol>
				<li>
					include any offensive comments based on a person’s age, color, race,
					national origin, gender identity, sexual preference or disability;
				</li>
				<li>
					include unlawful, hateful, racist, libelous, defamatory, profane,
					obscene, indecent, pornographic, sexual or otherwise objectionable
					content or language in any communications with other Users or within
					any Content posted on the Services or Site;
				</li>
				<li>
					defame, libel, ridicule, mock, disparage, threaten, harass, intimidate
					or abuse anyone;
				</li>
				<li>
					violate the contractual, personal, intellectual property or other
					rights of any person, or promote illegal activity;
				</li>
				<li>
					reveal any personal information about another individual, including
					another person&apos;s address, phone number, e-mail address, credit
					card number or any information that may be used to locate, contact or
					impersonate that individual;
				</li>
				<li>attempt to impersonate any other party;</li>
				<li>
					create user accounts by automated means or under false pretenses or
					mislead others as to the origins of your communications;
				</li>
				<li>
					trick, defraud, deceive or mislead the Company or other users,
					especially in any attempt to learn sensitive information;
				</li>
				<li>
					make improper use of the Company&apos;s support services or submit
					false reports of abuse or misconduct;
				</li>
				<li>
					create or transmit unwanted electronic communications such as
					&quot;spam,&quot; to other users or members of the Services or Site,
					or otherwise interfere with other users&apos; or members&apos;
					enjoyment of the Services or Site;
				</li>
				<li>
					disparage, tarnish, or otherwise harm, in the Company&apos;s opinion,
					the Company, the Services and/or the Site;
				</li>
				<li>
					disseminate or transmit viruses, worms, Trojan horses, RATs, keyboard
					loggers, time bombs, spyware, adware, cancelbots, passive collection
					mechanisms, or any other malicious or invasive code or program or
					upload or transmit (or attempt to upload or to transmit) any material
					that acts as a passive or active information collection or
					transmission mechanism, including, without limitation, clear graphics
					interchange formats (also known as &quot;gifs&quot;), 1x1 pixels, web
					bugs, cookies or other similar devices;
				</li>
				<li>
					copy or adapt the Services’ or Site’s software including but not
					limited to Flash, PHP, HTML, JavaScript or other code;
				</li>
				<li>
					except as may be the result of standard search engine or Internet
					browser usage, use or launch, develop or distribute any automated
					system, including, without limitation, any spider, robot (or
					&quot;bot&quot;), cheat utility, scraper or offline reader that
					accesses the Services or Site, or use or launch any unauthorized
					script or other software;
				</li>
				<li>
					access or search or attempt to access or search the Services or Site
					by any means (automated or otherwise) other than through the currently
					available, published interfaces that are provided by Filmio (and only
					consistent with terms and conditions), unless you have been
					specifically allowed to do so in a separate agreement with Filmio
					(crawling the Serviced is permissible in accordance with this Code of
					Conduct, but scraping the Services or Site without the prior consent
					of Filmio is expressly prohibited);
				</li>
				<li>
					forge any TCP/IP packet header or any part of the header information
					in any email or posting, or in any way use the Services or Site to
					send altered, deceptive or false source-identifying information;
				</li>
				<li>
					cover or obscure any notice, banner, advertisement or other branding
					on the Services or Site;
				</li>
				<li>
					disguise the source of materials or other information you submit to
					the Services or Site or use tools which anonymize your internet
					protocol address (e.g., anonymous proxy) to access the Services or
					Site;
				</li>
				<li>
					interfere with or circumvent any security feature or any feature that
					restricts or enforces limitations on use of or access to the Services,
					Site, Content or the User Content;
				</li>
				<li>
					probe, scan, or test the vulnerability of any system or network or
					breach or circumvent any security or authentication measures of the
					Services or Site; or
				</li>
				<li>
					interfere with or disrupt (or attempt to do so) the access of any
					user, host or network, including, without limitation, sending a virus,
					overloading, flooding, spamming, mail-bombing the Services or Site, or
					by scripting the creation of Content in such a manner as to interfere
					with or create an undue burden on the Services or Site.
				</li>
			</ol>
		</div>
	)
}

export default CodeOfConduct
