import { useRef } from 'react'
import Grid from '@mui/material/Grid'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, Icon } from '@mui/material'
import { ProjectItem } from '../projectItem'
import { TileItem } from '../tileItem'
import { FilteredDataChip } from '../filteredDataChip'
import useDashboard from '../useDashboard'
import styles from './DashboardProduct.module.scss'
import { DaoProposalItem } from '../daoProposalItem'

const DashBoardProduct = () => {
	const dashboardRef = useRef()
	const {
		items,
		loading,
		loadMore,
		isAuthenticated,
		handleOpenJoinDialog,
		sharerDisplayName,
		onScroll,
		hasBanner
	} = useDashboard()

	const components = {
		updates: TileItem,
		project: ProjectItem,
		daoproposal: DaoProposalItem
	}

	const isJoinPage = !isAuthenticated && sharerDisplayName
	const classes = `${styles.dashboard_product_section} ${
		isJoinPage ? styles.join_page : ''
	} ${hasBanner ? styles.with_banner : ''}`
	return (
		<InfiniteScroll
			onScroll={onScroll}
			dataLength={10000000}
			scrollableTarget="scroll-provider"
		>
			<section className={classes} ref={dashboardRef}>
				{isJoinPage && (
					<Box className={styles.top_join}>
						<span>
							<strong>{sharerDisplayName}</strong> has invited you to join
							Film.io!{' '}
							<a onClick={handleOpenJoinDialog}>Create your account here.</a>
						</span>
					</Box>
				)}
				<Box className={styles.product_container}>
					<Box className={styles.filtered_data_chip}>
						<FilteredDataChip />
					</Box>
					<Grid container>
						{items.map(item => {
							const type = item?.artifactType?.toLowerCase()
							const Component = components[type]
							return (
								<Box
									id={item.id}
									className={`${styles.itemContainer} ${styles[type]}`}
									key={item.id}
								>
									{Component && <Component {...{ ...item }} />}
								</Box>
							)
						})}
						{!loadMore && (
							<Box className={styles.loader}>
								Looks like you&apos;ve reached the end of the road
							</Box>
						)}
						{loading && (
							<Box className={styles.loader}>
								<Icon
									baseClassName="fas"
									className="fa-spinner fa-spin-pulse"
								/>
							</Box>
						)}
					</Grid>
				</Box>
			</section>
		</InfiniteScroll>
	)
}

export default DashBoardProduct

// className={`${classes.myClass}  ${this.props.classes.myClass2} MyStringClass`}
