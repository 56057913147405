import { notificationExtTypes } from 'component/common'
import { useDispatch, useSelector } from 'react-redux'
import { setWhitelistTransferDialogOpen } from 'component/profile/profileSlice'
import { useEffect, useState } from 'react'
import { abbreviateNumberFixed, formatAddress } from 'utils/utils'
import useWeb3 from 'hooks/useWeb3'
import useAxios from 'hooks/useAxios'
import {
	addBlockchainFanWithdrawalService,
	addFanWithdrawalService
} from 'services/wallet.service'
import useHeader from 'component/header/useHeader'
import _ from 'lodash'
import useNotification from 'hooks/useNotification'
import { reloadRecentActivity } from '../walletTabSlice'
import { setFrontTransactionsInProgress } from 'component/header/signInSlice'
import { FanTokenContract } from 'utils/blockchain/functions'
import { signVerificationMessage } from 'utils/blockchain/config/utils'
import useCommon from 'hooks/useCommon'
import { validateEmailCodeService } from 'services/profile.service'
import { truncateToDecimals } from 'utils/form.util'

const useWhitelistTransferDialog = () => {
	const [code, setCode] = useState('')
	const [amount, setAmount] = useState('')
	const [showCodeVerification, setShowCodeVerification] = useState(false)
	const [disableWithdraw, setDisableWithdraw] = useState(false)
	const [openConnectWalletModal, setOpenConnectWalletModal] = useState(false)
	const [clickedWithdraw, setClickedWithdraw] = useState(false)
	const { isConnected, signer } = useWeb3()
	const { getUnvisiteNotificationCount } = useHeader()
	const { sendEmailConfirmation, resendEmailConfirmation } = useCommon()
	const { user: signInUser } = useSelector(state => state.signIn)
	const { whitelistTransferDialogOpen: open } = useSelector(
		state => state.profile
	)
	const { overview: data } = useSelector(state => state.walletTab)

	const earningsTotal =
		Number(data.earningsStaking) + Number(data.actionsEarnings)

	const unlockedRewards = Number(data.unlockedRewards)
	const unlockedTokens = Number(data.unlockedTokens)
	const stakesTotal = Number(data.stakesTotal)
	const lockedRewards = earningsTotal - unlockedRewards

	const availableToWithdraw = unlockedTokens

	const dispatch = useDispatch()
	const { axiosService } = useAxios()
	const {
		showNotification,
		showNotificationExtended,
		completeNotificationExtended,
		closeNotificationExtended
	} = useNotification()

	const whitelistAddress = formatAddress(signInUser?.whitelistedAddress)

	const addBlockchainWithdrawal = (transactionId, blockchainHash) => {
		axiosService(
			addBlockchainFanWithdrawalService({
				transactionId,
				blockchainHash
			}),
			(_data, error) => {
				if (!error) {
					if (_.isNil(blockchainHash)) {
						closeNotificationExtended()
					} else {
						completeNotificationExtended()
					}
				}
				dispatch(reloadRecentActivity())
				getUnvisiteNotificationCount()
			}
		)
	}

	const afterCreateWithdrawal = async (transaction, error) => {
		if (!error) {
			const transactionId = transaction?.id
			dispatch(setFrontTransactionsInProgress(true))
			handleClose()
			showNotificationExtended({
				total: amount,
				type: notificationExtTypes.WITHDRAW
			})
			const hash = await FanTokenContract.transfer(
				signInUser?.whitelistedAddress,
				Number(amount),
				transactionId
			)

			addBlockchainWithdrawal(transactionId, hash, transaction?.amount)
			dispatch(setFrontTransactionsInProgress(false))

			if (!_.isNil(hash)) {
				setAmount('')
			}
			getUnvisiteNotificationCount()
		}
		setCode('')
		setDisableWithdraw(false)
	}

	const addFanWithdrawal = async () => {
		const { hash, signature } = await signVerificationMessage(
			signer,
			'Please sign this message to verify you are the owner of this wallet.'
		)
		axiosService(
			addFanWithdrawalService({
				amount,
				hash,
				signature,
				code
			}),
			afterCreateWithdrawal
		)
	}

	const handleVerify = () => {
		axiosService(
			validateEmailCodeService({ code, email: signInUser?.email }),
			(_data, error) => {
				if (!error) {
					handleWithdrawClick(amount)
					setShowCodeVerification(false)
				}
			}
		)
	}

	const handleWithdrawClick = async () => {
		setDisableWithdraw(true)
		const isToBlacklisted = await FanTokenContract.isBlacklisted(
			signInUser?.whitelistedAddress
		)
		if (isToBlacklisted === true) {
			showNotification({
				message:
					'The wallet you have whitelisted has been blocked for fraud. Please whitelist another wallet and try again.'
			})
			setDisableWithdraw(false)
			handleClose()
			return
		}
		setClickedWithdraw(true)
		if (isConnected) {
			setClickedWithdraw(false)
			addFanWithdrawal()
		} else {
			setOpenConnectWalletModal(true)
			setDisableWithdraw(false)
		}
	}

	const handleClose = () => {
		dispatch(setWhitelistTransferDialogOpen(false))
	}

	const handleClickResend = e => {
		resendEmailConfirmation(signInUser?.email)
	}

	const handleChangeForm = value => {
		const trancatedValue = truncateToDecimals(value, 4)
		setAmount(trancatedValue)
	}

	const handleClickTransfer = () => {
		if (amount === '') {
			showNotification({
				message: 'Please provide the amount of FAN to transfer'
			})
			setDisableWithdraw(false)
			setClickedWithdraw(false)
			return
		}
		if (amount < 250) {
			showNotification({ message: 'You must transfer at least 250 FAN' })
			setDisableWithdraw(false)
			setClickedWithdraw(false)
			return
		}
		if (amount > availableToWithdraw) {
			showNotification({
				message: `You can only transfer up to ${abbreviateNumberFixed(
					availableToWithdraw,
					2
				)} FAN. Platform rewards are unavailable to withdraw yet. Please stick to the limit and try again.`
			})
			setDisableWithdraw(false)
			setClickedWithdraw(false)
			return
		}
		sendEmailConfirmation(signInUser?.email)
		setShowCodeVerification(true)
	}

	useEffect(() => {
		if (clickedWithdraw && signer && !openConnectWalletModal) {
			setClickedWithdraw(false)
			addFanWithdrawal()
		}
	}, [clickedWithdraw, signer, openConnectWalletModal])

	return {
		code,
		open,
		amount,
		isConnected,
		stakesTotal,
		lockedRewards,
		earningsTotal,
		unlockedTokens,
		disableWithdraw,
		whitelistAddress,
		availableToWithdraw,
		showCodeVerification,
		openConnectWalletModal,
		setOpenConnectWalletModal,
		handleClickTransfer,
		handleClickResend,
		handleVerify,
		handleClose,
		handleChangeForm,
		setCode
	}
}

export default useWhitelistTransferDialog
