import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import styles from './CustomButton.module.scss'

const CustomButton = ({ customStyle, children, className, ...props }) => {
	const classes = `${className} ${styles[customStyle]}`
	return (
		<Button className={classes} {...props}>
			{children}
		</Button>
	)
}

CustomButton.propTypes = {
	customStyle: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	props: PropTypes.object
}

export default CustomButton
