import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogFooter,
	DialogTitle,
	DialogClose,
	DialogTrigger
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import StackedIcon from 'assets/images/fanDashboard/StackedIcon.svg'
import { useStakeForm } from './useStakeForm'
import { NumericFormat } from 'react-number-format'
import Image from 'next/image'

const StakeInfo = ({
	limit,
	amount,
	isAbleToStake,
	user,
	stakeLimit,
	balance
}: any) => {
	if (limit < amount) {
		return (
			<>
				<span className="text-pretty">
					This exceeds the staking limit of{' '}
					<strong className="text-primary">{stakeLimit} FAN</strong>.
				</span>
				<span>
					You can stake up to{' '}
					<strong className="text-primary">{limit} FAN</strong>.
				</span>
			</>
		)
	}

	if (!isAbleToStake) {
		return (
			<span className="text-pretty">
				You dont have enough tokens. You can stake up to{' '}
				<strong>{balance} FAN</strong>
			</span>
		)
	}

	return (
		<>
			<span>
				<span className="text-primary">FGR:</span> {user?.fgrRate}% + 10% Bonus
			</span>
			<span>
				<span className="text-primary">Limit:</span>{' '}
				<NumericFormat
					value={stakeLimit}
					displayType="text"
					thousandSeparator=","
				/>{' '}
				FAN (your stake: {(stakeLimit - limit).toFixed(2)})
			</span>
			<span>
				You can stake{' '}
				<NumericFormat value={limit} displayType="text" thousandSeparator="," />{' '}
				more FAN Tokens
			</span>
		</>
	)
}

export const StakeForm: React.FC = ({ selectedProject }: any) => {
	const {
		user,
		limit,
		errors,
		amount,
		project,
		balance,
		inputRef,
		stakeLimit,
		loadingLimit,
		disableAddBtn,
		isAbleToStake,
		isProjectOwner,
		handleStakeClick,
		handleRestoreForm,
		handleChangeForm
	} = useStakeForm(selectedProject)

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button variant="table" className="h-9 w-8 p-2">
					<Image
						src={StackedIcon}
						className="min-h-9 min-w-3"
						width={16}
						height={16}
						alt="stacked"
					/>
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[335px]">
				<DialogHeader>
					<DialogTitle className="text-xl font-semibold tracking-[-0.025em]">
						{project?.title}
					</DialogTitle>
					<DialogDescription className="flex flex-col items-center justify-center text-muted-foreground">
						<StakeInfo
							limit={limit}
							amount={amount}
							isAbleToStake={isAbleToStake}
							user={user}
							stakeLimit={stakeLimit}
							balance={balance}
						/>
					</DialogDescription>
				</DialogHeader>

				<div className="grid gap-4 py-4">
					<div className="grid grid-cols-1 items-center gap-4">
						<Input
							type="number"
							value={amount}
							onChange={e => handleChangeForm(e.target.value)}
							aria-invalid={!!errors}
							aria-describedby={errors ? 'stake-error' : undefined}
							error={errors}
							ref={inputRef}
							placeholder="Stake Amount"
							className="col-span-3"
						/>
						{errors && (
							<span
								id="stake-error"
								role="alert"
								className="text-sm text-destructive"
							>
								{errors}
							</span>
						)}
					</div>
				</div>

				<DialogFooter>
					<DialogClose asChild>
						<Button
							variant="secondary"
							type="button"
							className="w-full font-medium"
							onClick={handleRestoreForm}
						>
							Cancel
						</Button>
					</DialogClose>

					{!isProjectOwner && (
						<DialogClose asChild>
							<Button
								type="button"
								className="w-full"
								onClick={handleStakeClick(amount)}
								disabled={disableAddBtn}
							>
								Stake
								<img
									className="ml-2 invert-[0.9]"
									src={StackedIcon.src}
									alt=""
									aria-hidden="true"
								/>
							</Button>
						</DialogClose>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
