import {
	Box,
	Grid,
	Typography,
	Dialog,
	Icon,
	DialogContent,
	Button
} from '@mui/material'
import PropTypes from 'prop-types'
import styles from './SuperFanDialog.module.scss'
import { styled } from '@mui/material/styles'
import stacked from 'assets/images/superFan1/stack_at_least.png'
import ranked from 'assets/images/superFan1/ranked.svg'
import stayIcon from 'assets/images/superFan1/stay_stacked.svg'

const DialogWithbackground = styled(Dialog)(({ theme }) => ({
	backgroundColor: '#00000052',
	padding: '20px 18px',
	...theme.typography.body2
}))

export const SuperFanDialog = ({ open, handleClose }) => {
	const handleCloseDialog = () => {
		handleClose(false)
	}
	return (
		<DialogWithbackground
			open={open}
			disableScrollLock={true}
			className={`${styles.super_fun}`}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleCloseDialog}
		>
			<DialogContent className={styles.dialog_content}>
				<Box className={styles.close_icon} onClick={() => handleCloseDialog()}>
					<Icon baseClassName="fas" className="fa-times" />
				</Box>
				<Grid container spacing={2} className={styles.container}>
					<Grid item className={styles.title}>
						<Typography component={'h3'}>
							Become a Film Industry SUPERFAN
						</Typography>
					</Grid>
					<Grid item container className={styles.content}>
						<Typography className={styles.text}>
							Passionate about films? With Film.io, you&apos;re not just
							watching movies, you&apos;re helping create them! Have you ever
							dreamt of being part of a blockbuster from day one? Simply find a
							movie you love and become a Superfan!
						</Typography>
						<Typography className={styles.text}>
							A Superfan is an early supporter who believes in a film
							project&apos;s potential. When you stake at least 100 $FAN Tokens,
							you not only become a part of that project&apos;s community - you
							also secure exclusive benefits and opportunities as it evolves.
							&nbsp;
							<span className={styles.boldBox}>The benefits are endless!</span>
						</Typography>
						<Typography component={'h3'}>
							How you become a Project&apos;s SuperFAN
						</Typography>
						<Grid container item className={styles.superfun_imgs}>
							<Grid className={styles.img_box}>
								<img alt="stacked" src={stacked.src} />
							</Grid>
							<Grid className={styles.img_box}>
								<img alt="ranked" src={ranked.src} />
							</Grid>
							<Grid className={styles.img_box}>
								<img alt="stay_stacked" src={stayIcon.src} />
							</Grid>
						</Grid>
						<Typography className={styles.text}>
							Your Superfan Position isn&apos;t just a neutral number —
							it&apos;s your very own place in the community. Higher ranks get
							better chances for exclusive NFTs, funding participation and other
							perks.
						</Typography>
						<Typography component={'h3'}>
							Real Impact. Real Involvement.
						</Typography>
						<Grid container item className={styles.superfun_recog}>
							<Grid item className={styles.colored_box}>
								<Typography component={'h4'}>
									Receive Exclusive Access
								</Typography>
								<Typography className={styles.text}>
									Get early peeks at content and direct connections with
									filmmakers.
								</Typography>
							</Grid>
							<Grid item className={styles.colored_box}>
								<Typography component={'h4'}>
									Financial Opportunities
								</Typography>
								<Typography className={styles.text}>
									Get priority access to funding rounds and potential returns.
								</Typography>
							</Grid>
							<Grid item className={styles.colored_box}>
								<Typography component={'h4'}>Get Recognition</Typography>
								<Typography className={styles.text}>
									Have a chance to earn executive producer or more credits.
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Box className={styles.closeMobile}>
					<Button id="closeButton" onClick={handleCloseDialog}>
						Close
					</Button>
				</Box>
			</DialogContent>
		</DialogWithbackground>
	)
}

SuperFanDialog.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func
}
