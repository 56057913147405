import { Box, Button, Typography } from '@mui/material'
import newToken from 'assets/images/new_token_blue.svg'
import useFgrOverview from './useFgrOverview'
import styles from './FgrOverview.module.scss'
import { NumericFormat } from 'react-number-format'

const FgrOverview = () => {
	const { userFgr, claimAllUserFgr } = useFgrOverview()
	const { claimed, unclaimed } = userFgr

	return (
		<Box className={styles.overview}>
			<Box className={styles.claimed}>
				<img src={newToken.src} className={styles.token_icon} />
				<Box className={styles.center}>
					<Typography component={'h2'} className={styles.text}>
						Total FGR Claimed
					</Typography>
					<Typography component={'h1'} className={styles.text}>
						<NumericFormat
							value={claimed}
							displayType="text"
							thousandSeparator=","
						/>
					</Typography>
				</Box>
			</Box>
			<Box className={styles.earnings}>
				<Box className={styles.top}>
					<Typography component={'h2'} className={styles.text}>
						Total Unclaimed FGR
					</Typography>
				</Box>
				<Box className={styles.center}>
					<img src={newToken.src} />
					<Typography component={'h1'} className={styles.text}>
						<NumericFormat
							value={unclaimed}
							displayType="text"
							thousandSeparator=","
						/>
					</Typography>
				</Box>
			</Box>
			<Box className={styles.claim_all}>
				<Button onClick={claimAllUserFgr} disabled>
					<span>Claim</span>
					<NumericFormat
						value={unclaimed}
						displayType="text"
						thousandSeparator=","
					/>
				</Button>
			</Box>
		</Box>
	)
}

export default FgrOverview
