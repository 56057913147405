import badgeLockedGrey from './badge-locked-grey.png'
import badgeUnlocked from './badge-unlocked.png'
import vaultlockBlank from './vaultlock-blank.png'
import lockedPhase from './locked-phase.png'
import development from './development.png'
import developmentUnlocked from './development-unlocked.png'
import greenlight from './greenlight.png'
import funding from './funding.png'
import token from './token.png'
import posterNotAvailable from './poster-not-available.png'
import defaultProjectCover from './default-project-cover.png'
import qrLogo from './qr_combo.jpeg'

const IMAGES = {
	badgeLockedGrey: badgeLockedGrey.src,
	badgeUnlocked: badgeUnlocked.src,
	vaultlockBlank: vaultlockBlank.src,
	lockedPhase: lockedPhase.src,
	development: development.src,
	developmentUnlocked: developmentUnlocked.src,
	greenlight: greenlight.src,
	funding: funding.src,
	token: token.src,
	posterNotAvailable: posterNotAvailable.src,
	defaultProjectCover: defaultProjectCover.src,
	qrLogo: qrLogo.src
}

export default IMAGES
