import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import './TransInfo.scss'
import TransInfoTable from './TransInfoTable'
const TileManagement = () => {
	return (
		<div className="transinfo-section">
			<Container disableGutters>
				<Grid container justifyContent="center">
					<Grid item xs={12}>
						<div className="tile-heading">
							<h1>Transaction Information</h1>
						</div>
					</Grid>
					<Grid item xs={12}>
						<TransInfoTable />
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default TileManagement
