import { setLoginDialogOpen } from 'component/header/signInSlice'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
	resetPasswordIsValidService,
	resetPasswordService
} from 'services/auth.service'

const useResetPassword = () => {
	const [errors, setErrors] = useState(null)
	const [isValid, setIsValid] = useState(true)
	const [data, setData] = useState({
		password: '',
		repeat: ''
	})
	const [showPassword, setShowPassword] = useState(false)
	const { userId, token, action } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const afterResetPassword = (data, error) => {
		if (!error) {
			dispatch(setLoginDialogOpen(true))
			navigate('/')
		}
	}

	const [{ loading }, updatePassword] = useAxiosFetch(
		resetPasswordService({
			password: data.password,
			userId,
			token
		}),
		afterResetPassword
	)

	const afterCheckValidResetPassword = (data, error) => {
		if (!error) {
			setIsValid(data.isValid)
		}
	}

	const [{ loading: loadingIsValid }] = useAxiosFetch(
		resetPasswordIsValidService({
			userId,
			token
		}),
		afterCheckValidResetPassword
	)

	const handleClickSave = () => {
		updatePassword()
	}

	const handleClickShowPassword = () => setShowPassword(show => !show)

	const handleMouseDownPassword = event => {
		event.preventDefault()
	}

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const handleChange = payload => {
		const key = Object.keys(payload)[0]
		updateErrors({ [key]: false })
		setData({
			...data,
			...payload
		})
	}

	const showMask = loading || loadingIsValid
	const title = action === 'choose' ? 'Choose your password' : 'Reset Password'

	return {
		data,
		title,
		isValid,
		showMask,
		showPassword,
		handleClickShowPassword,
		handleMouseDownPassword,
		handleClickSave,
		handleChange
	}
}

export default useResetPassword
