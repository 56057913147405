import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Box, FormControlLabel, Checkbox } from '@mui/material'
import {
	TextFieldControl,
	CustomButton,
	CustomDialog,
	LoadMask,
	PasswordStrenght
} from 'component/common'
import useAidropJoinDialog from './useAidropJoinDialog'
import styles from './AidropJoinDialog.module.scss'
import _ from 'lodash'

export const AidropJoinDialog = () => {
	const {
		data,
		open,
		errors,
		loading,
		newsletter,
		passwordShow,
		setNewsletter,
		passwordtoggle,
		handleSubmit,
		handleChange,
		handleClose
	} = useAidropJoinDialog()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<CustomDialog {...{ open, handleClose, variant: 'join' }}>
					<Box onClose={handleClose} className={styles.title}>
						Be a Part of the World’s
						<br />
						First Filmmaking DAO.
						<span>
							Get ready to begin your creative journey on the world’s first
							entertainment DAO and <span>claim your FAN Tokens</span>.
						</span>
					</Box>
					<Box className={styles.content}>
						<Box className={styles.fields}>
							<Box className={styles.row}>
								<TextFieldControl
									error={errors?.firstName}
									classes={{ root: styles.first_name }}
									hiddenLabel
									fullWidth
									name="firstName"
									placeholder="First Name"
									value={data.firstName}
									onChange={e => {
										handleChange({ firstName: e.target.value })
									}}
								/>
								<TextFieldControl
									error={errors?.lastName}
									classes={{ root: styles.last_name }}
									hiddenLabel
									fullWidth
									name="lastName"
									placeholder="Last Name"
									value={data.lastName}
									onChange={e => {
										handleChange({ lastName: e.target.value })
									}}
								/>
							</Box>
							<TextFieldControl
								error={errors?.email}
								classes={{ root: styles.email_input }}
								hiddenLabel
								fullWidth
								name="email"
								disabled={true}
								placeholder="Email"
								value={data.email}
								onChange={e => {
									handleChange({ email: _.trim(e.target.value) })
								}}
							/>
							<Box className={styles.password_container}>
								<TextFieldControl
									error={errors?.password}
									FormHelperTextProps={{
										component: 'div',
										classes: { root: styles.form_helper }
									}}
									helperText={<PasswordStrenght password={data.password} />}
									type={passwordtoggle ? 'password' : 'text'}
									hiddenLabel
									fullWidth
									name="password"
									placeholder="Password"
									value={data.password}
									onChange={e => {
										handleChange({ password: e.target.value })
									}}
								/>

								<Button variant="text" onClick={passwordShow}>
									{passwordtoggle ? 'Show' : 'Hide'}
								</Button>
							</Box>
						</Box>
						<CustomButton customStyle={'blue_w_border'} onClick={handleSubmit}>
							Join
						</CustomButton>
					</Box>
					<Box className={styles.actions}>
						<Box className={styles.agree}>
							By signing up, you agree to the{' '}
							<Link to="/constitution">DAO Constitution</Link>
							{' and '}
							<Link to="/privacy-policy">Privacy Policy</Link>
						</Box>

						<FormControlLabel
							className={styles.newsletters}
							label="Stay up on all things Film.io. Join our newsletter today!"
							control={
								<Checkbox
									checked={newsletter}
									onChange={event => {
										setNewsletter(event.target.checked)
									}}
								/>
							}
						/>
					</Box>
				</CustomDialog>
			)}
		</>
	)
}

AidropJoinDialog.propTypes = {
	userId: PropTypes.string,
	token: PropTypes.string
}
