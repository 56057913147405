import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import {
	getGemOptionsService,
	speedUpService
} from 'services/gemOption.service'
import useLoadingMask from 'hooks/useLoadingMask'
import PropTypes from 'prop-types'
import { Box, Dialog, DialogContent, Typography, Button } from '@mui/material'
import styles from './speedUpDialog.module.scss'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import tokenIcon from 'assets/images/walletIcons/tokenIcon.svg'
import timerIcon from 'assets/images/walletIcons/timer.svg'
import rightArrowIcon from 'assets/images/walletIcons/right_arrow.svg'
import unLockIcon from 'assets/images/walletIcons/unlock.svg'
import Dimond from 'assets/images/walletIcons/dimond.svg'
import GrayDimond from 'assets/images/walletIcons/gray_diamond.svg'
import { LightTooltip } from 'component/common'
import { SpeedUpBar } from 'component/common/speedUpBar'
import { abbreviateNumberFixed, NumberWithDecimals } from 'utils/utils'

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#2E2C6A80' : '#2E2C6A80',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.primary.main,
	border: '1px solid #4E4BDE',
	borderRadius: '16px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}))

const NestedItem = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#4E4BDE' : '#4E4BDE',
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: 'center',
	color: theme.palette.primary.main,
	borderRadius: '12px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '56px',
	width: '100%',
	'&:hover': {
		backgroundColor: '#4E4BDE'
	},
	'&.Mui-disabled': {
		backgroundColor: '#585858',
		color: '#FFFFFF80'
	},
	'&:active , &:focus': {
		outline: 'none',
		transition:
			'all 1s ease-out,background-color 1s ease-out , box-shadow 1s ease-out !important',
		transitionDelay: '100ms',
		transitionTimingFunction: 'ease-out',
		transitionDuration: '600ms'
	},
	'&.active': {
		backgroundColor: '#2623BA !important',
		boxShadow: '0px 0px 25px 0px #BD02B266 !important'
	}
}))

const CustonButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#262262' : '#262262',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	width: '100%',
	color: theme.palette.primary.main,
	border: 'none',
	borderRadius: '12px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: '10px',
	transition: 'all 600ms ease-out !important',
	'&:hover': {
		background: '#332E82'
	}
}))

const DialogWithbackground = styled(Dialog)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#000000b3' : '#000000b3',
	...theme.typography.body2
}))

const headingText = 'Reward Release Schedule'
const YourGems = 'Your Gems:'
const bottomText =
	'Reward schedules may shift to match your pace as you earn new rewards.'

export const SpeedUpDialog = ({
	open,
	handleClose,
	gemTotal,
	timeToUnlock,
	rewardsTokens,
	releasedTokens,
	percentage,
	updateOverview
}) => {
	const { showMask, hideMask } = useLoadingMask()
	const { axiosService } = useAxios()
	const [options, setOptions] = useState([])
	const [isClicked, setIsClicked] = useState(-1)

	const getOptions = () => {
		showMask()
		axiosService(getGemOptionsService(), (data, error) => {
			if (!error) {
				setOptions(data)
			}
			hideMask()
		})
	}
	const handleSpeedUp = optionId => {
		setIsClicked(optionId)
		setTimeout(() => {
			setIsClicked(-1)
		}, 1300)
		showMask()
		axiosService(speedUpService(optionId), (data, error) => {
			if (!error) {
				updateOverview(data)
			}
			hideMask()
		})
	}

	useEffect(() => {
		getOptions()
	}, [])

	const handleCloseDialog = () => {
		handleClose()
	}

	const handleFontSize = value => {
		const len = String(value).length
		if (len <= 5) return `1.5rem`
		if (len > 5 && len <= 7) return `1.2rem`
		if (len > 7 && len <= 10) return `.8rem`
		if (len > 10) return `.7rem`
	}

	return (
		<DialogWithbackground
			open={open}
			disableScrollLock={true}
			className={`${styles.dialog} warning`}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleCloseDialog}
		>
			<DialogContent className={styles.dialog_content}>
				<div className={styles.flex_grid}>
					<Typography className={styles.flex_80} component={'h2'}>
						{headingText}{' '}
					</Typography>
					<LightTooltip
						enterTouchDelay={0}
						leaveTouchDelay={4000}
						placement="bottom"
						title="Rewards and Bonus Tokens are subject to Film.io's Rewards Release Schedule (RRS) and are released based on your activity, reputation, time, and can be accelerated using gems."
					>
						<span className={styles.mb_8}>
							<img src={timerIcon.src} alt="Timer Icon" />
						</span>
					</LightTooltip>
				</div>
				<Box>
					<Grid
						container
						rowSpacing={3}
						columnSpacing={{ xs: 1, sm: 2, md: 2 }}
					>
						<Grid xs={12}>
							<Item className={styles.first_Item}>
								<Box>
									<Typography className={styles.mb_10} component={'h3'}>
										{timeToUnlock}{' '}
									</Typography>
								</Box>
								<Box className={styles.center}>
									<Box className={styles.progress_container}>
										<SpeedUpBar value={percentage} />
									</Box>
								</Box>
								<Grid container xs={12} className={styles.token_convert}>
									<Grid xs={5}>
										<Box container xs={12} spacing={5}>
											<Grid
												container
												xs={12}
												rowSpacing={1}
												className={styles.flex_grid_token}
											>
												<Grid xs={4} className={styles.grid_img}>
													<img src={tokenIcon.src} alt="logo" />
												</Grid>
												<Grid xs={8} className={styles.flex_grid}>
													<Typography
														className={styles.align_left}
														style={{
															fontSize: `${handleFontSize(
																NumberWithDecimals(releasedTokens ?? 0, 4)
															)}`
														}}
														component={'h1'}
													>
														{abbreviateNumberFixed(rewardsTokens ?? 0, 2)}
													</Typography>
												</Grid>
											</Grid>
											<Grid xs={12} className={styles.flex_grid}>
												<div className={styles.flex_grid_with_gap}>
													<Typography component={'span'}>
														Rewards Tokens
													</Typography>
													<LightTooltip
														className={styles.light_tooltip}
														enterTouchDelay={0}
														leaveTouchDelay={4000}
														placement="bottom"
														title="Rewards and Bonus Tokens are subject to Film.io's Rewards Release Schedule (RRS) and are released based on your activity, reputation, time, and can be accelerated using gems."
													>
														<span className={styles.icon_after_text}>
															<img src={timerIcon.src} alt="Timer Icon" />
														</span>
													</LightTooltip>
												</div>
											</Grid>
										</Box>
									</Grid>
									<Grid className={styles.no_padding} xs={1}>
										<span className={styles.icon_after_text}>
											<img
												width={20}
												height={20}
												src={rightArrowIcon.src}
												alt="Arrow right Icon"
											/>
										</span>
									</Grid>
									<Grid xs={5}>
										<Box container xs={12} spacing={5}>
											<Grid
												container
												xs={12}
												rowSpacing={1}
												className={styles.flex_grid_token}
											>
												<Grid xs={4} className={styles.grid_img}>
													<img src={tokenIcon.src} alt="logo" />
												</Grid>
												<Grid xs={8} className={styles.flex_grid}>
													<Typography
														className={styles.align_left}
														style={{
															fontSize: `${handleFontSize(
																NumberWithDecimals(releasedTokens ?? 0, 4)
															)}`
														}}
														component={'h1'}
													>
														{NumberWithDecimals(releasedTokens ?? 0, 4)}
													</Typography>
												</Grid>
											</Grid>

											<Grid xs={12} className={styles.flex_grid}>
												<div className={styles.flex_grid_with_gap}>
													<Typography component={'span'}>
														Released Tokens
													</Typography>
													<LightTooltip
														enterTouchDelay={0}
														leaveTouchDelay={4000}
														placement="bottom"
														title="Released Tokens are awarded over time and are subject to the RRS. These are part of your 'available tokens' and can be transferred out of the platform."
													>
														<span className={styles.icon_after_text}>
															<img src={unLockIcon.src} alt="Timer Icon" />
														</span>
													</LightTooltip>
												</div>
											</Grid>
										</Box>
									</Grid>
								</Grid>
							</Item>
						</Grid>

						<Grid className={styles.flex_Gems} container xs={12}>
							<Typography className={styles.flex_width} component={'h3'}>
								{YourGems}{' '}
							</Typography>
							<LightTooltip
								enterTouchDelay={0}
								leaveTouchDelay={4000}
								placement="bottom"
								title="Gems are used to accelerate your RRS and are earned over time (similar to an FGR - the more FAN you stake the more gems you earn), as well as by completing quests at club.film.io"
							>
								<img width={17} height={17} src={Dimond.src} alt="Timer Icon" />
							</LightTooltip>
							<Typography className={styles.align_left} component={'h3'}>
								{abbreviateNumberFixed(gemTotal ?? 0, 2)}
							</Typography>
						</Grid>
						<Grid
							container
							className={styles.flex_grid_Gems}
							xs={12}
							spacing={1}
						>
							{options.map(el => {
								return (
									<Grid key={el.id} xs={4}>
										<NestedItem
											className={`${isClicked === el.id ? 'active' : ''}`}
											onClick={() => handleSpeedUp(el.id)}
											disabled={
												gemTotal < el.amount ||
												timeToUnlock ===
													'Congrats! All your Rewards are Unlocked!'
											}
										>
											{gemTotal < el.amount ? (
												<img
													width={'19px'}
													height={19}
													src={GrayDimond.src}
													alt="Timer Icon"
												/>
											) : (
												<img
													width={'19px'}
													height={19}
													src={Dimond.src}
													alt="Timer Icon"
												/>
											)}
											<Typography
												className={styles.header_gems}
												component={'h3'}
											>
												{el.amount}{' '}
											</Typography>
										</NestedItem>
									</Grid>
								)
							})}
						</Grid>
						<Grid xs={12} className={styles.custom_padding}>
							<CustonButton onClick={handleCloseDialog}>
								<Typography className={styles.header_gems} component={'h3'}>
									Close{' '}
								</Typography>
							</CustonButton>

							<Box className={styles.actions}>
								<Typography component={'p'}>{bottomText}</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</DialogWithbackground>
	)
}

SpeedUpDialog.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	gemTotal: PropTypes.number,
	timeToUnlock: PropTypes.string,
	rewardsTokens: PropTypes.number,
	releasedTokens: PropTypes.number,
	percentage: PropTypes.string,
	updateOverview: PropTypes.func
}
