export const getProjectCollaboratorRolePermissions = data => ({
	method: 'post',
	url: '/collaboratorRole/project/permissions',
	data,
	autoexec: false
})

export const userCanManageNewsreels = data => ({
	method: 'post',
	url: '/collaboratorRole/newsreels/permission',
	data,
	autoexec: false
})
