import useAxiosFetch from 'hooks/useAxiosFetch'
import { useDispatch, useSelector } from 'react-redux'
import { getGenresService } from 'services/genres.service'
import { getGoScoreStagesService } from 'services/goscore.service'
import {
	clearFilters,
	setSort,
	updateFilters,
	setStages,
	setGenres
} from '../dashboardSlice'

const useSidebarDashboard = () => {
	const dispatch = useDispatch()
	const { filter, sort } = useSelector(state => state.dashboard)
	const { activeContests, isAuthenticated } = useSelector(state => state.signIn)

	const afterFetchStages = data => {
		dispatch(setStages(data))
	}

	const [{ response: stages }] = useAxiosFetch(
		getGoScoreStagesService(),
		afterFetchStages
	)

	const afterFetchGenres = data => {
		dispatch(setGenres(data))
	}

	const [{ response: genres }] = useAxiosFetch(
		getGenresService(),
		afterFetchGenres
	)

	const handleFilterChange = (option, value) => () => {
		const exclusiveFilters = ['dao', 'contest']
		let options = exclusiveFilters.includes(value) ? [] : [...filter[option]]

		if (filter[option].includes(value)) {
			options = options.filter(el => el !== value)
		} else {
			options.push(value)
			exclusiveFilters.forEach(exclusiveValue => {
				if (value !== exclusiveValue && options.includes(exclusiveValue)) {
					options = options.filter(el => el !== exclusiveValue)
				}
			})
		}

		dispatch(updateFilters({ [option]: options }))
	}

	const handleSortChange = payload => {
		dispatch(setSort(payload))
	}

	const clearFilter = () => {
		dispatch(clearFilters())
	}

	return {
		sort,
		filter,
		activeContests,
		isAuthenticated,
		stages: stages?.data,
		genres: genres?.data,
		handleFilterChange,
		handleSortChange,
		clearFilter
	}
}

export default useSidebarDashboard
