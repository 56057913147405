import { Avatar, Icon, IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import useNotification from 'hooks/useNotification'
import { followUnfollowUser } from 'services/followUnfollow.service'
import styles from './UserCardItem.module.scss'

const UserCardItem = ({
	displayName,
	username,
	avatar,
	about,
	id,
	headerImg,
	fetchUsers,
	showDeleteButton = true
}) => {
	const { showNotification } = useNotification()

	const handleRemoveFollower = async () => {
		const res = await followUnfollowUser(id, true)
		if (res.status === 200) {
			const message = `You are no longer following ${displayName}`
			showNotification({ message })
			fetchUsers()
		}
	}

	return (
		<div className={styles.profile_card}>
			<div className={styles.header}>
				{headerImg && (
					<img className={styles.header_img} src={headerImg} alt="header" />
				)}
				<Link className={styles.profile_link} to={`/profile/${username}`}>
					<Avatar
						alt={displayName}
						sx={{
							bgcolor: '#000000',
							border: '1px solid #ffffff',
							height: 152,
							width: 152,
							marginRight: '12px'
						}}
						src={avatar}
					/>
				</Link>
			</div>
			<div className={styles.content}>
				<div className={styles.user_info}>
					<Link to={`/profile/${username}`}>
						<span className={styles.display_name}>{displayName}</span>
						<span className={styles.username}>@{username}</span>
					</Link>
					{showDeleteButton && (
						<IconButton size="small" onClick={handleRemoveFollower}>
							<Icon baseClassName="fas" className="fa-duotone fa-user-xmark" />
						</IconButton>
					)}
				</div>
				<span className={styles.about}>{about}</span>
			</div>
		</div>
	)
}

UserCardItem.propTypes = {
	avatar: PropTypes.string,
	displayName: PropTypes.string,
	username: PropTypes.string,
	about: PropTypes.string,
	id: PropTypes.string,
	headerImg: PropTypes.string,
	fetchUsers: PropTypes.func,
	showDeleteButton: PropTypes.bool
}
export default UserCardItem
