import PropTypes from 'prop-types'
import { StakeUserTimestamp } from './stakeUserTimestamp'
import { CommentReaction } from 'component/common'
import styles from './StakeItem.module.scss'
import { Box } from '@mui/material'

function StakeItem(data) {
	const {
		id,
		user,
		amount,
		project,
		comments,
		reactions,
		createdAt,
		commentsCount,
		blockchainHash
	} = data
	return (
		<Box className={styles.stake_item}>
			<Box className={styles.reviews}>
				<Box className={styles.review}>
					<Box className={styles.heading}>
						<StakeUserTimestamp
							{...{ createdAt, user, project, blockchainHash }}
						/>
					</Box>
					<Box className={styles.content}>
						{user.displayName} staked <span>{amount} FAN</span> tokens.
					</Box>
					<CommentReaction
						{...{
							comments,
							commentsCount,
							defaultReactions: reactions,
							type: 'stake',
							feedId: id
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}

StakeItem.propTypes = {
	data: PropTypes.object
}

export default StakeItem
