import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { updateMetaTags } from 'utils/utils'
import styles from './TermsOfService.module.scss'

const TermsOfService = () => {
	useEffect(() => {
		updateMetaTags({})
	}, [])

	return (
		<div className={styles.container}>
			<h2>Terms of Service</h2>
			<p>
				<strong>Updated as of August 22, 2020</strong>
			</p>
			<p>
				<strong>FDP</strong>
			</p>
			<p>
				(<strong>Filmio Decentralized Platform</strong>)
			</p>
			<p>
				Filmio, Inc. (“Filmio,” “Company,” “we” or “us”) welcomes you!
				<span className="Apple-converted-space">&nbsp; </span>We provide the
				website at <a href="https://film.io/">film.io</a> (the “Site”) to you,
				subject to the terms and conditions contained in this Terms of Service
				(this “TOS”).<span className="Apple-converted-space">&nbsp; </span>
				This TOS governs the relationship between Company and you, the User of
				the Site.
			</p>
			<p>
				<em>
					Note: No guarantees of success or of any projected profit return, nor
					of any other project outcome, can be made at this time, as this
					involves the implementation of a new business paradigm.
					&nbsp;Interested parties must conduct their own due diligence and
					financial analyses
				</em>
			</p>
			<p>
				<em>FILMIO DECENTRALIZED PLATFORM - TERMS AND CONDITIONS</em>
			</p>
			<p>
				<strong>Company Overview</strong>
			</p>
			<p>
				Filmio, Inc. (“Filmio” or “Company”), exists under the laws of the state
				of Delaware. &nbsp;The Company has designed and is in the process of
				building a blockchain-based entertainment utility protocol powered by a
				cryptographically secure multilayered network. The protocol works by a
				distributed ledger, a digitally recorded ecosystem of data in packages
				called “blocks” built on a specially designed blockchain (“Filmio
				Network”). In order to fund software development, business development,
				platform promotion, platform marketing, legal and regulatory affairs,
				and the complete development of the ecosystem, Filmio will initiate a
				token sale.
			</p>
			<p>
				Companies and individuals conducting various creative functions on the
				Filmio Network and its associated platform(s), or utilizing or
				benefiting from the services or features of same, shall be referred to
				herein as “Participants” or “Users” on the Filmio Decentralized
				Platform.
			</p>
			<p>
				<strong>Representations and Warranties</strong>
			</p>
			<p>
				To participate in the FDP, a Participant shall represent and warrant
				that:
			</p>
			<ol>
				<li>
					If a Creator, that the project is a valid, bona fide, act of artistic
					creation arising from the Creator&apos;s skill, experience, and
					knowledge and/or that of the Creator&apos;s associates.
				</li>
				<li>
					If a Creator, that all aspects of the project&apos;s IP (Intellectual
					Property) rights are unique, independently generated, and not an
					infringement on another party&apos;s rights to that IP.
				</li>
				<li>
					If a Fan, that the Fan will, in good faith, offer constructive
					feedback, and will -
					<span className="Apple-converted-space">&nbsp; </span>without
					inappropriate bias - vote and evaluate projects based on the
					Fan&apos;s personal impressions and satisfaction with the
					projects&apos; objectives, in a neutral, impartial, and fair manner.
				</li>
				<li>
					If making a pledge, that the User will pledge their tokens in a
					supportive and collaborative fashion, to foster and encourage
					creativity and independent thought and project design, weighing
					carefully the economic benefits of pledging Tokens against the need
					for the entire Filmio ecosystem and the FDP to achieve success and to
					foster innovation and creativity.
				</li>
			</ol>
			<p>
				All the above representations and warranties made by Participant shall
				be true, complete, accurate and non-misleading on and from the date of
				that Participant initiates actions on the FDP.
			</p>
			<p>
				The Filmio ecosystem, including the distributed ledger underlying the
				Tokens, is decentralized, and therefore, Filmio Entertainment Group does
				not operate or maintain all aspects of the network. As such, Filmio has
				no responsibility or liability for the network nor does it have any
				ability to control third parties&apos; use of the network.
			</p>
			<p>
				Ownership of Tokens carries no rights, expressed or implied, other than
				the right to use Tokens as a means to enable usage of and interaction
				with the ecosystem, if successfully completed and deployed. Participant
				understands, acknowledges and accepts that Tokens do not represent or
				confer any ownership right or stake, share or security or equivalent
				rights, or any right to receive future revenue shares, intellectual
				property rights, or any other form of participation in or relating to
				the FDP, Filmio ecosystem, network and/or the Company and its
				affiliates, other than rights relating to the provision and receipt of
				services in the network, subject to limitations and conditions in these
				Terms and applicable network terms and policies (as defined below).
				Participant understands and accepts that Tokens are not intended to be a
				digital currency, security, commodity, or any other kind of financial
				instrument.
			</p>
			<p>
				<strong>Limitation of Liability</strong>
			</p>
			<p>
				While Filmio will take reasonable steps to ensure the security and
				privacy of any information transmitted during Participants use of our
				services (the “Service” or “Services”), in no event will any such
				information be considered “confidential,” nor will its disclosure to a
				third party, accidental or otherwise, cause liability against Filmio,
				even if it occurs as a result of our negligence. Participant hereby
				warrants and agrees that Filmio is not responsible whatsoever for any
				damages caused by the interception, loss, or alteration to any
				information sent over the internet.
			</p>
			<p>
				The use of the Filmio Services is undertaken “at risk” which for greater
				clarity means that the Company expressly does not assume, and will hold
				no responsibility for any actual or anticipated loss by Participant, or
				any third-party, resulting from the use of the Services.
				<span className="Apple-converted-space">&nbsp; </span>Filmio will take
				every reasonable precaution to prevent and mitigate attacks; however,
				these problems may nevertheless occur from time to time for reasons that
				are beyond the control of the Company.
			</p>
			<p>
				Filmio will hold no liability to Participant for any actual or
				anticipatory damages from breach of this contract.
			</p>
			<p>
				Filmio Does Not Provide Legal, Financial or Investment Advice.
				<span className="Apple-converted-space">&nbsp; </span>At no time does
				the Company provide any legal, financial or investment advice and any
				publicized, provided, or referred to information or publications should
				not be considered as such.
			</p>
			<p>
				<strong>Email Communications</strong>
			</p>
			<p>
				The Company, through its, or that of a sub-contracted third-party,
				operates an email newsletter program used to inform Participants about
				products and services supplied by Filmio. Users of this email service
				(“Participants”) can subscribe through an online automated process
				should they wish to do so but do so at their own discretion. Some
				subscriptions may be manually processed through prior written agreement
				with the user.
			</p>
			<p>
				All personal details relating to Participant information are held
				securely. No personal details are passed on to third parties nor shared
				with companies/people outside of Filmio, or the company that operates
				this website.
			</p>
			<p>
				Email marketing campaigns published by this website or its owners may
				contain tracking facilities within the actual email. Participant
				activity is tracked and stored in a database for future analysis and
				evaluation. Such tracked activity may include; the opening of emails,
				forwarding of emails, the clicking of links within the email content,
				times, dates and frequency of activity. This is not a comprehensive list
				of possible activities.
			</p>
			<p>
				This information is used to refine future email campaigns and supply the
				Participant with more relevant content based around their activity.
			</p>
			<p>
				In compliance with international spam and privacy laws, Participants are
				given the opportunity to unsubscribe at any time through an automated
				system. This process is detailed at the footer of each email campaign.
				If an automated un-subscription system is unavailable clear instructions
				on how to un-subscribe will by detailed instead.
			</p>
			<p>
				<strong>
					Copyright of Communications in Connection with our Services
				</strong>
			</p>
			<p>
				Participant or Participants agree that any materials, information, or
				communications transmitted between Participant and Filmio in any form,
				or between Participant and Filmio via the Filmio wallet, are
				non-confidential and will become the sole, exclusive property of Filmio.
			</p>
			<p>
				Filmio will own all intellectual property rights to such communications
				or materials and may use or disseminate such communications without
				restriction for any legal purpose, commercial or otherwise, without
				notifying or compensating Participants or Participants. Participants and
				Participants hereby waive any right to litigation or recovery for
				perceived damages caused by the use of this information as is
				permissible by law.
			</p>
			<p>
				<strong>Contact Us</strong>
			</p>
			<p>
				If you have any questions about these Terms or the disclaimers as set
				forth herein, please contact us at:&nbsp;
				<span className="Apple-converted-space">&nbsp; </span>
				<a href="mailto:admin@Film.io">admin@Film.io</a>
			</p>
			<p>
				<strong>
					<em>RISK FACTORS FOR PARTICIPANTS IN THE FDP</em>
				</strong>
			</p>
			<p>
				<strong>RISKS WITH UNDERLYING PROTOCOL</strong>&nbsp;- Because Filmio is
				based on open-source blockchain protocol (“Filmio Network”), any
				malfunction, breakdown or abandonment of such open-source protocol may
				have a material adverse effect on the platforms (the “Platforms”) or
				Filmio Network. Hackers or other malicious groups or organizations may
				attempt to interfere with the Platforms or Filmio Network in a variety
				of ways, including, but not limited to, malware attacks, denial of
				service attacks, consensus-based attacks, Sybil attacks, smurfing and
				spoofing. Furthermore, because the Filmio Network and the Platforms are
				based on open source software, there is a risk that a third party may
				intentionally or unintentionally introduce weaknesses into the core
				infrastructure of the Filmio Network or that a malfunction, breakdown or
				abandonment of such open-source protocol occurs, either of which may
				have a material adverse effect on the Platforms or Filmio, including the
				utility of Filmio for obtaining services. In addition, advances in
				cryptography, or technical advances such as the development of quantum
				computing, could present risks to Filmio and the Platforms, including
				the utility of Filmio for obtaining services, by rendering ineffective
				the cryptographic consensus mechanism that underpins the Filmio Network
				technology.
			</p>
			<p>
				<strong>UNCERTAINTY OF REGULATIONS AND ENFORCEMENT</strong>&nbsp;- The
				regulatory status of the tokens and distributed ledger technology is
				unclear or unsettled in many jurisdictions. It is difficult to predict
				how or whether regulatory agencies may apply existing regulation with
				respect to such technology and its applications, including the platform
				and Filmio. It is likewise difficult to predict how or whether
				legislatures or regulatory agencies may implement changes to law and
				regulation affecting distributed ledger technology and its applications,
				including the platform and Filmio. Regulatory actions could negatively
				impact the Platforms and Filmio in various ways, including, for purposes
				of illustration only, through a determination that the purchase, sale
				and delivery of any tokens constitutes unlawful activity or that the
				tokens are a regulated instrument that require registration or licensing
				of those instruments or some or all of the parties involved in the
				purchase, sale and delivery thereof. Filmio may cease operations in a
				jurisdiction in the event that regulatory actions, or changes to law or
				regulation, make it illegal to operate in such jurisdiction, or
				commercially undesirable to obtain the necessary regulatory approval(s)
				to operate in such jurisdiction.
			</p>
			<p>
				<strong>TAXATION RISKS</strong>&nbsp;- The tax characterization of
				tokens, as well as the tax implications of your participation in the FDP
				is uncertain. You must seek your own tax advice in connection with
				purchasing tokens or using Filmio, which may result in adverse tax
				consequences to you, including withholding taxes, income taxes and tax
				reporting requirements.
			</p>
			<p>
				<strong>COMPETING PLATFORMS</strong>&nbsp;- It is possible that
				alternative platforms could be established that utilize the same
				open-source code and protocol underlying the Platforms and attempt to
				facilitate services that are materially similar to the Filmio services.
				The Platforms may compete with these alternatives, which could
				negatively impact the Platforms and Filmio.
			</p>
			<p>
				<strong>LACK OF INTEREST AND ADOPTION</strong>&nbsp;- It is possible
				that the Filmio Platform will not be used by a large number of
				individuals, companies and other entities or that there will be limited
				public interest in the creation and development of distributed platforms
				(such as the Filmio Platforms) more generally. Such a lack of use or
				interest could negatively impact the development of the Platforms and
				therefore the potential utility of Filmio, including the utility of
				Filmio for obtaining services. While a Filmio token should not be viewed
				as an investment, it may have value over time. That value may be limited
				if the Platforms lack use and adoption. If this becomes the case, there
				may be few or no markets following the launch of the application,
				resulting in an adverse impact on Filmio, from which it may never
				recover.
			</p>
			<p>
				<strong>RISKS WITH DEVELOPMENT AND MAINTENANCE OF THE PLATFORMS</strong>
				&nbsp;– The Filmio Network and the Platforms are still under development
				and may undergo significant changes over time. Although Filmio intends
				for the Filmio Network and the Platforms to function as described in the
				Filmio Whitepaper (available for download on this website) and intends
				to take commercially reasonable steps toward those ends, Filmio may have
				to make changes to the specifications of the Filmio tokens, the Filmio
				Network or the Platforms for any number of reasons. Moreover, Filmio has
				limited control over how other participants will use Filmio Network or
				how third-party products and services will utilize Filmio. This could
				create the risk that Filmio, Filmio Network or the Platforms, as further
				developed and maintained, may change significantly from the date of your
				purchase. Furthermore, despite Filmio&apos;s efforts to develop and
				participate in Filmio Network, it is possible that Filmio Network will
				experience malfunctions or otherwise fail to be adequately developed or
				maintained, which may negatively impact the Platforms and the potential
				utility of any Filmio.
			</p>
			<p>
				<strong>DISSOLUTION OF COMPANY OR PLATFORMS</strong>&nbsp;- It is
				possible that, due to any number of reasons, including, but not limited
				to, the failure of commercial relationships or intellectual property
				ownership challenges, the Platforms may no longer be viable to operate
				or Filmio may dissolve.
			</p>
			<p>
				<strong>NO GOVERNANCE RIGHTS</strong>&nbsp;- Because Filmio tokens
				confer no governance rights of any kind with respect to the Platforms or
				Filmio, all decisions involving Filmio&apos;s products or services
				within the Platforms or Filmio itself will be made by Filmio at its sole
				discretion, including, but not limited to, decisions to discontinue its
				products or services, to create and sell more Filmio tokens for use on
				the Platforms, or to sell or liquidate Filmio. These decisions could
				adversely affect the Platforms and the utility of any Filmio you own,
				including their utility for obtaining services.
			</p>
			<p>
				<strong>UNANTICIPATED RISKS</strong>&nbsp;- Cryptographic tokens such as
				Filmio are a new and untested technology. In addition to the risks noted
				above, there are other risks associated with your purchase, possession
				and use of Filmio or the Platforms, including unanticipated risks. Such
				risks may further materialize as unanticipated variations or
				combinations of the risks discussed in the herein and in Company&apos;s
				Whitepaper.
			</p>
			<p>
				<strong>FURTHER TERMS AND CONDITIONS</strong>
			</p>
			<ol>
				<li>
					<strong>Legally Binding Agreement; Changes to TOS.</strong>
				</li>
			</ol>
			<ol>
				<ol>
					<li>
						Please read this TOS carefully as it contains legal obligations. You
						agree that by registering for or using our Services (defined in
						Section 2 below), or by accessing or using our Site, you are
						entering into a legally binding agreement with Filmio. If you are
						using or accessing the Services or Site on behalf of your employer,
						another individual or an entity, such as a corporate entity, trust,
						partnership or other organization (collectively, “
						<strong>Authorizing Party</strong>”), you represent that you are
						duly authorized by such Authorizing Party to enter into this TOS on
						behalf of such Authorizing Party (and “you” as used in this TOS
						refers both to you individually and to the Authorizing Party). You
						also represent to Filmio that you are of the legal age to form a
						binding agreement with us and that you are not a person or entity
						barred from accessing or using the Site under the laws of the United
						States or other applicable jurisdiction. By checking the “I agree”
						checkbox at the time or after you register with us, you acknowledge
						that you have read and understood the terms and conditions of this
						TOS and that you agree to be bound by all of its provisions, that
						you also consent to use electronic signatures, and that you
						acknowledge your check of the “I agree” checkbox as one such
						signature. If you do not want to register for an account with us, or
						you do not agree to any terms of this TOS, do not check the “I
						agree” checkbox and do not access or use the Services or Site.
					</li>
				</ol>
			</ol>
			<ol>
				<ol start="2">
					<li>
						We reserve the right to update or make changes to this TOS from time
						to time in our sole discretion, and we may notify you of changes by
						via the email you provide us in your User Profile (defined in
						Section 2) or by posting the revised version of this TOS on the
						Site, which changes will become effective immediately upon the
						transmission of such email or posting on the Site, whichever occurs
						first. Please return to this page periodically to ensure familiarity
						with the most current version of this TOS. Your continued access or
						use of the Services (defined in Section 2) or Site after any changes
						to this TOS shall constitute your agreement and consent to such
						changes. You agree that your use, access and purchase of any
						Services may be subject to any additional posted guidelines, rules,
						terms and conditions applicable to such Services (collectively, “
						<strong>Policies</strong>”), which are hereby incorporated by
						reference into this TOS. In the event that any terms or conditions
						of such Policies contradict or are inconsistent with the terms and
						conditions of this TOS, such Policies will govern and prevail solely
						with respect to the specified Services to which they apply.
					</li>
				</ol>
			</ol>
			<p>
				<strong>2. Definitions.</strong>
			</p>
			<p>
				2.1 “<strong>Accredited Investor</strong>” has the meaning ascribed
				thereto by the SEC in Rule 501 of Regulation D (as amended from time to
				time) under the U.S. Securities Act of 1933 (the “<strong>Act</strong>
				”), or such other rule as may succeed Rule 501 from time to time. For
				informational purposes only, as of the date hereof, the “Accredited
				Investor” is defined as:
			</p>
			<p>
				(i) Any bank as defined in section 3(a)(2) of the Act, or any savings
				and loan association or other institution as defined in section
				3(a)(5)(A) of the Act whether acting in its individual or fiduciary
				capacity; any broker or dealer registered pursuant to section 15 of the
				Securities Exchange Act of 1934; any insurance company as defined in
				section 2(a)(13) of the Act; any investment company registered under the
				Investment Company Act of 1940 or a business development company as
				defined in section 2(a)(48) of that Act; any Small Business Investment
				Company licensed by the U.S. Small Business Administration under section
				301(c) or (d) of the Small Business Investment Act of 1958; any plan
				established and maintained by a state, its political subdivisions, or
				any agency or instrumentality of a state or its political subdivisions,
				for the benefit of its employees, if such plan has total assets in
				excess of $5,000,000; any employee benefit plan within the meaning of
				the Employee Retirement Income Security Act of 1974 if the investment
				decision is made by a plan fiduciary, as defined in section 3(21) of
				such act, which is either a bank, savings and loan association,
				insurance company, or registered investment adviser, or if the employee
				benefit plan has total assets in excess of $5,000,000 or, if a
				self-directed plan, with investment decisions made solely by persons
				that are accredited investors;
			</p>
			<p>
				(ii) Any private business development company as defined in section
				202(a)(22) of the Investment Advisers Act of 1940;
			</p>
			<p>
				(iii) Any organization described in section 501(c)(3) of the Internal
				Revenue Code, corporation, Massachusetts or similar business trust, or
				partnership, not formed for the specific purpose of acquiring the
				securities offered, with total assets in excess of $5,000,000;
			</p>
			<p>
				(iv) Any director, executive officer, or general partner of the issuer
				of the securities being offered or sold, or any director, executive
				officer, or general partner of a general partner of that issuer;
			</p>
			<p>
				(v) Any natural person whose individual net worth, or joint net worth
				with that person&apos;s spouse, at the time of his purchase exceeds
				$1,000,000.<span className="Apple-converted-space">&nbsp;</span>
			</p>
			<p>
				(1) Except as provided in paragraph 2.1(v) (2) below, for purposes of
				calculating net worth under this paragraph 2.1:
				<span className="Apple-converted-space">&nbsp;</span>
			</p>
			<p>
				(A) The person&apos;s primary residence shall not be included as an
				asset;
			</p>
			<p>
				(B) Indebtedness that is secured by the person&apos;s primary residence,
				up to the estimated fair market value of the primary residence at the
				time of the sale of securities, shall not be included as a liability
				(except that if the amount of such indebtedness outstanding at the time
				of sale of securities exceeds the amount outstanding 60 days before such
				time, other than as a result of the acquisition of the primary
				residence, the amount of such excess shall be included as a liability);
				and
			</p>
			<p>
				(C) Indebtedness that is secured by the person&apos;s primary residence
				in excess of the estimated fair market value of the primary residence at
				the time of the sale of securities shall be included as a liability;
			</p>
			<p>
				(2) Paragraph 2.1(v)(1) of this section will not apply to any
				calculation of a person&apos;s net worth made in connection with a
				purchase of securities in accordance with a right to purchase such
				securities, provided that:
				<span className="Apple-converted-space">&nbsp;</span>
			</p>
			<p>(A) Such right was held by the person on July 20, 2010;</p>
			<p>
				(B) The person qualified as an accredited investor on the basis of net
				worth at the time the person acquired such right; and
			</p>
			<p>
				(C) The person held securities of the same issuer, other than such
				right, on July 20, 2010.
			</p>
			<p>
				(vi) Any natural person who had an individual income in excess of
				$200,000 in each of the two most recent years or joint income with that
				person&apos;s spouse in excess of $300,000 in each of those years and
				has a reasonable expectation of reaching the same income level in the
				current year;
			</p>
			<p>
				(vii) Any trust, with total assets in excess of $5,000,000, not formed
				for the specific purpose of acquiring the securities offered, whose
				purchase is directed by a sophisticated person as described in Rule
				506(b)(2)(ii); and
			</p>
			<p>
				(viii) Any entity in which all of the equity owners are accredited
				investors.
			</p>
			<p>
				2.2 “<strong>Code of Conduct</strong>” means the rules governing the
				conduct by Users when using or accessing any of the Services or Site, as
				prescribed by Filmio from time to time. A copy of the current Code of
				Conduct can be found <Link to="/code-of-conduct">here</Link>, the
				contents of which are hereby incorporated by reference into this TOS.{' '}
				<span className="Apple-converted-space">&nbsp;</span>
			</p>
			<p>
				2.3 “<strong>Company Person(s)</strong>” means any employee, officer,
				director, agent, representative or affiliate of the Company.
			</p>
			<p>
				2.4 “<strong>Confidential Information</strong>” means all Content owned
				or controlled by a User made available on the Services or Site that is
				marked or designated by such User or Filmio as “confidential,” that is
				disclosed under circumstances indicating its confidential nature, or
				otherwise would be known to be confidential by a reasonable person. If a
				User, in the Company&apos;s reasonable determination, ceases to treat as
				confidential such User&apos;s Content designated by such User as
				Confidential Information, or if such Content is disclosed by such User
				to a third party without any obligations of confidentiality, or if such
				Content becomes public information through no fault of any receiving
				User, then such Content shall no longer be deemed Confidential
				Information for the purposes of this TOS.
			</p>
			<p>
				2.5 “<strong>Content</strong>” means (a) images, text, messages, works
				of authorship, photographs, graphics, comments, suggestions, video,
				audio, or other material uploaded, downloaded, appearing or otherwise
				made available on or through the Services or the Site; and (b)
				personally identifiable information, data and other information
				uploaded, downloaded, appearing or otherwise made available on or
				through the Services or the Site.
			</p>
			<p>
				2.6 “<strong>Eligible User Profile</strong>” means the profile created
				by the User which may contain the User&apos;s email address, the
				password used to access the User&apos;s account, payment information,
				and any other information the Company may require to complete or
				maintain your registration of an account.
			</p>
			<p>
				2.7 “<strong>Filmmaker</strong>” means a User who represents one or more
				film projects through the Services or Site and may (or may not) be
				interested in procuring investor interest for such film project(s)
				through the Services or Site.
			</p>
			<p>
				2.8 “<strong>Investor</strong>” means a User who has indicated an
				interest in finding investment opportunities offered by Filmmakers
				through the Services or Site.
			</p>
			<p>
				2.9 “<strong>Privacy Policy</strong>” means Filmio&apos;s policy on how
				Filmio collects, uses, discloses and manages information and data
				collected about Users of the Services and Site, which policy may be
				modified by Filmio from time to time. A copy of the current Privacy
				Policy can be found <a href="https://www.film.io/privacy">here</a>.{' '}
				<span className="Apple-converted-space">&nbsp;</span>
			</p>
			<p>
				2.10 “<strong>Prohibited Activity(ies)</strong>” means one or more
				activities designated by Filmio as prohibited, from time to time, in its
				sole and absolute discretion. Without limiting the foregoing, the
				following will be deemed Prohibited Activities:
			</p>
			<p>
				(i) The modification, adaptation, disassembly, de-compilation,
				translation, reverse engineering or other attempt to discover the source
				code or structure, sequence and organization of the Services or Site
				(except where the foregoing is permitted by applicable local law
				notwithstanding such restrictions, and then only to the extent that such
				intended activities are disclosed in advance in writing to us);
			</p>
			<p>
				(ii) The use of Services or Site in any manner that could damage,
				disable, overburden, impair or otherwise interfere with or disrupt the
				Services, Site or any networks or security systems of Company or its
				service providers, or otherwise interfere with other Users&apos; use of
				the Services or Site;
			</p>
			<p>
				(iii) The removal, obscuring or changing of any copyright, trademark,
				hyperlink or other proprietary rights notices contained in or on the
				Services, Site or any Content;
			</p>
			<p>
				(iv) The submission of any Content or material that expresses or implies
				that such Content or material is sponsored or endorsed by the Company;
			</p>
			<p>
				(v) The use of the Services or Site to violate the security of any
				computer network, including without limitation uploading Content that
				includes malware, viruses, Trojan horses, ransomware, and the like;
			</p>
			<p>
				(vi) The use of the Services or Site to transfer or store illegal
				material; or
			</p>
			<p>
				(vii) The use of the Services or the delivery of any Content in
				violation of any applicable law, including the securities laws,
				intellectual property laws, right of privacy or publicity laws and any
				laws of any jurisdiction (including that of the United States of
				America) applicable to you or Filmio.
			</p>
			<p>
				2.11 “<strong>SEC</strong>” means the U.S. Securities and Exchange
				Commission, and/or any successor entity or entities thereto.
			</p>
			<p>
				2.12 “<strong>Services</strong>” means any and all services made
				available on the Site, including without limitation any information,
				features, functionality and applications offered on the Site from time
				to time. Access to certain Services may be based on the type of
				subscription you have purchased and/or based on the designation of your
				user type (i.e. Investor, Filmmaker or other user type).
			</p>
			<p>
				2.13 “<strong>User</strong>” means any user of the Services and/or Site.
			</p>
			<p>
				<strong>
					3. Filmio Services, Related Undertakings and Limitations.
				</strong>
			</p>
			<p>
				3.1 <strong>Right to use the Services</strong>. Subject to your
				acceptance of, and compliance with, this TOS, we grant you a
				non-exclusive, revocable, non-assignable, non-sublicensable,
				non-transferable limited right to access and use the Site and the
				Services to which you have subscribed. The Services and Site are
				protected by copyright, trademark, and other laws of both the United
				States and foreign countries. Except as expressly provided herein,
				nothing in this TOS gives you a right to use our trademarks, trade
				names, service marks, logos, domain names, or other brand features.
				Filmio and its licensors reserve all rights not expressly granted in
				this TOS.
			</p>
			<p>
				3.2 <strong>Restrictions and Obligations</strong>. You agree that in
				accessing and using the Services and Site, you agree that you will:
			</p>
			<p>
				(i) not modify, alter, adapt or create a derivative work of any Content
				of another User, the Services or the Site;
			</p>
			<p>
				(ii) attribute the Site as the source of any information which you
				obtain from the Site, with such attribution in the form of a human and
				machine-followable link (a tag) linking back to the page on the Site
				displaying the original Content which contains such information (or if
				the original page is not accessible to the recipient or viewer of the
				information without logging in through an account with us, then you will
				attribute the Site generally as the source of such information). For
				purposes of clarity, you shall not have the right to use or disclose any
				Confidential Information in breach of your confidentiality obligations
				in Sections 4.2;
			</p>
			<p>
				(iii) upon request by Filmio, make a reasonable effort to update your
				Content on the Site and/or the Services to the most updated version (and
				we will similarly make a reasonable effort to update any of your Content
				on the Site upon our receipt of your written request);
			</p>
			<p>
				(iv) upon request, either by Filmio or by a User who provided the
				Content, make a reasonable attempt to permanently delete, destroy and
				erase such Content that has been deleted from the Site (and require that
				any party to whom such Content has been provided, through multiple
				tiers, do the same);
			</p>
			<p>
				(v) not use the Services or Site, or display any results, Content, or
				information acquired from the use of the Services or Site in any way to
				compete with Filmio, the Services or the Site;
			</p>
			<p>
				(vi) not sublicense, transfer, share, lend (via a service bureau or
				otherwise), distribute or resell to any third party your right to access
				or use the Services or Site (or any portion thereof);
			</p>
			<p>
				(vii) not use any data mining features, robots, spiders or similar data
				gathering and extraction tools in your use of the Services or Site to
				retrieve, index, “scrape,” “data mine,” or in any way gather content or
				data from the Services or Site, or otherwise circumvent the navigational
				structure or presentation of the Services or Site; and
			</p>
			<p>
				(viii) not circumvent, disable, override or otherwise interfere with any
				security-related features of the Services or Site, or any other features
				that prevent or restrict use, copying, data mining or otherwise or
				enforcement of the limitations on the use of the Services or Site.
			</p>
			<p>
				3.3 <strong>License to Your Content</strong>. By uploading or otherwise
				providing any Content through your use of the Services or Site, whether
				through your account with us or otherwise, you hereby grant to Filmio a
				non-exclusive, non-transferable (except as permitted in Section 10.8)
				royalty-free, fully paid-up, irrevocable, worldwide right and license to
				use, display, perform, exhibit, modify, reproduce and make available
				such Content through the Services and Site to other Users during the
				term of this TOS. Following the termination of this TOS for any reason,
				we shall have the irrevocable, royalty-free, fully-paid-up, perpetual,
				worldwide right and license to maintain a copy of such Content for use
				on the Site, with the Services, for archival purposes, and for enforcing
				our rights under this TOS.
			</p>
			<p>
				3.4 <strong>Ownership</strong>. Subject to the licenses granted in
				Section 3.3, as between Filmio and you, you shall exclusively own and
				retain all right, title and interest (including all intellectual and
				proprietary rights) in and to your Content. As between you and Filmio,
				Filmio shall exclusively own and retain all right, title and interest
				(including without limitation, all intellectual and proprietary rights)
				in and to the Services and Site, including all Content (other than your
				Content) and other materials appearing therein. Filmio shall also own
				any and all metadata related to your Content generated by Filmio&apos;s
				Services, Site and systems.
			</p>
			<p>
				3.5 <strong>Subscriptions; Fees.</strong>
			</p>
			<p>
				(i) In order to use and access certain portion of the Services and Site,
				you will be required to purchase subscriptions which may be offered with
				a monthly, annual or other term. We may offer different levels of
				subscriptions, which provide different levels of access to the features
				of the Services and Site. The term of your subscription will commence on
				the date you purchase such subscription. The Services provided may be
				subject to change.
			</p>
			<p>
				(ii) For monthly subscriptions, payment will be initiated on a monthly
				basis and will be paid in advance or on the first day of each such month
				of your subscription term. For annual subscriptions, you will be charged
				for the annual term of your subscription at the time you purchase such
				subscription. You hereby authorize us to collect from your payment
				method all outstanding fees and charges for your account. If any fees
				and charges are not paid by the due date, Filmio may suspend or
				terminate your access to the Services and Site, without notice to you,
				until all amounts due are paid. Additionally, any fees and charges which
				are not paid by the due date are subject to a late charge of one percent
				(1%) (or the maximum amount permitted by applicable law, whichever is
				lower) for each month, or fraction thereof, until the full amount due is
				paid in full. You are responsible to pay all fees and charges for your
				subscriptions, and except as described in this TOS, any suspension,
				termination or disconnection of the Services or Site does not relieve
				you of the obligation to pay such fees.
			</p>
			<p>
				(iii) You may cancel your monthly subscription by providing written
				notice to us of such cancellation. Your subscription will terminate at
				the end of the month during which you cancelled, and you will be charged
				the applicable subscription fee for such month. You may cancel your
				annual subscription by providing written notice to us of such
				cancellation, which notice must be received by us during the first 180
				days of your annual subscription. Upon such cancellation, your
				subscription will terminate at the end of such 180-day period and you
				will be entitled to a refund of fifty percent (50%) of the annual
				subscription fees actually paid to us, less any amounts owed to us. You
				will not be entitled to any refund of the subscription fee if you elect
				to cancel your annual subscription after the first 180 days thereof.
			</p>
			<p>
				(iv) If we terminate your subscription without a stated reason, you will
				only be responsible for the applicable subscription fees, on a pro-rata
				basis, through the date of the termination of your subscription. If we
				terminate your subscription for a Stated Reason, then you will be
				responsible for the applicable subscription fees through the end your
				subscription term. “Stated Reasons” include the following: (a) we have
				determined, in our sole discretion, that you have misused the Services
				or the Site, engaged in suspected fraudulent or illegal activity,
				engaged in other activity that adversely affects the Services, Site or
				our network, or other Users&apos; ability to use the Services or Site;
				and/or (b) you have breached this TOS. You understand that Company
				reserves the right to monitor all uses of the Services and Site,
				including without limitation your compliance with this TOS. Your
				subscription may be resumed after we have terminated your subscription
				for a Stated Reason, but the original term thereof shall not be
				extended.
			</p>
			<p>
				(v) Other features of the Services or Site may be subject to a fee, such
				as a submission fee for submitting a project to us. All such fees are
				non-refundable.
			</p>
			<p>
				3.6 <strong>Accredited Investors</strong>. We will require all new or
				prospective Users of Filmio who identify themselves as Investors to
				execute an Accredited Investor Questionnaire. We have created a
				questionnaire that is designed to make (prospective) Users review
				whether they truly are accredited and sophisticated, but we do not
				verify the representations they make or whether such Users would be
				deemed accredited under applicable state, federal or international
				securities laws or other laws. We have also designed the Site such that
				an Investor is not intended to be introduced to any Filmmaker whose
				capital raising activities were in process when that Investor becomes a
				member of Filmio, but we cannot guarantee that our design is foolproof,
				or that Investors who learn about a Filmmaker&apos;s business through
				Filmio will not find another way to be introduced to such Filmmaker.
			</p>
			<p>
				3.7{' '}
				<strong>
					Display Filmmakers&apos; Content and Allow Communications with
					Investors Only as Instructed by the Filmmaker/Investor.
				</strong>{' '}
				When you create an account with Filmio, and at any time thereafter, you
				will be able to set a variety of filters that are intended to control
				who is able to see your Confidential Information and what information of
				other Users will be accessible by you. We will make commercially
				reasonable efforts not to intentionally violate such filters; provided,
				however, that we do not guarantee or warranty the following:
			</p>
			<p>
				(i) that there will never be a software bug or a hacker attack that will
				allow unauthorized viewing of material or unsolicited contacts to occur;
			</p>
			<p>
				(ii) that any other User whom you have given access to Confidential
				Information will not violate the conditions of confidentiality that you
				and / or the Company have imposed on such User and such Confidential
				Information or that such User will abide by the TOS;
			</p>
			<p>
				3.8 <strong>Reservations and Limitations</strong>. Without limiting any
				right, privilege, limitation or reservation contained elsewhere in this
				TOS and benefiting the Company, the Company reserves the following
				rights:
			</p>
			<p>
				(i) Filmio reserves the right, but is not required, to monitor, modify,
				suppress, delete or control the Content posted via the Services or the
				Site and to take any action with respect to such Content at any time and
				without notice to you or other Users. Our failure to exercise that
				right, however, does not waive such right and does not give you or any
				other party any right to make a claim against Filmio.
			</p>
			<p>
				(ii) Filmio reserves the right, at any time and from time to time,
				temporarily or permanently, in whole or in part, to modify, suspend or
				discontinue the Services or Site in any way and at any time, with or
				without notice to you. You agree that any notices to you will be deemed
				provided to you and effective on the date of posting on the Site or on
				the day of email to the email account we have on file for your account,
				whichever occurs earlier. You agree that (a) Filmio shall not be liable,
				in whole or in part, to you or to any third party for any modification,
				suspension or discontinuance of the Services or Site, and (b) your
				continued use of the Services or Site after any changes to the Services
				or Site have been implemented will be deemed your acceptance of such
				changes. In the event that the Services are discontinued, you will be
				responsible for the applicable subscription fees, on a pro-rata basis,
				through the date of the discontinuance of such Services.
			</p>
			<p>
				(iii) Filmio reserves the right to terminate your access to the Services
				or Site without notice and, in the event that you have violated this
				TOS, reserves the right to pursue additional remedies available to
				Filmio at law or in equity.
			</p>
			<p>
				(iv) Filmio reserves the right to delete your account for any reason or
				for no reason at all, and if Filmio deletes your account, you will lose
				all access to any information, connections or other features that may
				have value to you that may have been associated with your account.
			</p>
			<p>
				(v) Filmio reserves the right to refuse registration of, or cancel, your
				account in its sole discretion for any reason or for no reason. In
				addition, Filmio reserves the right at all times (but will not have an
				obligation) to remove or refuse to distribute any Content through the
				Services or Site, to terminate Users, or to reclaim Usernames. Filmio
				also reserves the right to access, read, preserve, and disclose any
				information as it reasonably believes is necessary to: (1) comply with
				any applicable law, regulation, legal process or governmental request;
				(2) enforce this TOS (including investigation of potential violations
				hereof); (3) detect, prevent, or otherwise address fraud, security or
				technical issues; (4) respond to User support requests; and/or (5)
				protect the rights, networks, property or safety of Filmio, its Users
				and the public.
			</p>
			<p>
				(vi) Without limiting the generality of the foregoing, you specifically
				acknowledge that Filmio has the right to terminate or limit your account
				for any reason or no reason at all. Any Content that has been uploaded
				through the Services or Site may be deleted at any time without notice
				to you. Filmio shall have no liability to any User or person for any
				claim based upon its termination of an account or disabling of access to
				or removal of any Content, including material it believes, in its sole
				discretion to violate this TOS, regardless of whether the material
				ultimately is determined to be infringing or otherwise prohibited or
				Prohibited Activity, and regardless of whether such termination or
				disabling has the effect of reducing the value of any Content or
				opportunities that might otherwise have been available to you. By using
				the Services or Site, you agree that notice to you through an email to
				the email address you provided in your profile constitutes reasonable
				efforts to notify you of any removal or disabling if such notice is
				required.
			</p>
			<p>
				(vii) We are neither obligated to display your Content to any other
				User, nor are we obligated to introduce you to any Filmmaker or
				Investor.
			</p>
			<p>
				(viii) We are not responsible for conducting due diligence on the Users
				you meet through the Services or Site. Should we conduct due diligence
				on one or more Users, neither does this change the meaning or effect of
				the immediately foregoing sentence, nor are we responsible or can be
				held liable for the outcome of any such due diligence.
			</p>
			<p>
				(ix) We are not responsible for verifying that any Investor is an
				Accredited Investor or otherwise authorized or appropriate to invest in
				your project or for determining whether any use of Filmio constitutes a
				general solicitation of securities under U.S. law or the laws of any
				state or other jurisdiction, including foreign jurisdictions.
			</p>
			<p>
				(x) We do not recommend any films or other ventures for investment,
				endorse their fitness for investment, or verify the information on the
				Site or in our emails; and we do not claim that any Content provided by
				Users or the Company is accurate, complete, and not misleading.
			</p>
			<p>
				(xi) We are not obligated to maintain the confidentiality of any Content
				you give us, other than with respect to Confidential Information, and
				with respect to Confidential Information, we are not obligated to
				protect it other than by designating it as such and putting in place
				reasonable technological measures, which we have determined in good
				faith, to secure the confidentiality of such Confidential Information.
				We, however, cannot guarantee complete security; specifically, we cannot
				and do not guarantee that our measures will prevent all unauthorized
				access and use of Confidential Information, and we do not guarantee that
				the technology will never fail.
			</p>
			<p>
				(xii) We have no obligation to monitor or enforce any intellectual
				property rights that may be associated with Content you provide to us.
			</p>
			<p>
				(xiii) In the event that we invest in any film at any stage of
				development, we are not obligated to make that investment opportunity
				available to anyone else.
			</p>
			<p>
				(xiv) The Company does not endorse the Content found through the
				Services, the Site or external sites that may be linked to or from the
				Site and, therefore, the Company specifically disclaims any
				responsibility with regard thereto.
			</p>
			<p>
				(xv) The Company has no obligation to accept, display, review, monitor,
				or maintain any Content submitted by Users, User forum posts,
				commentary, ratings or compliments. We have the right to delete,
				suppress and modify Content or comments from the Services or Site
				without notice for any reason. The Company may move, re-format, edit,
				alter, distort, remove or refuse to exploit Content or comments without
				notice to you and without liability. Notwithstanding the foregoing
				rights, the Company reserves the right to treat both Content and
				comments as Content stored at the direction of Users for which the
				Company will not exercise editorial control except as required to
				enforce the rights of third parties and applicable Content restrictions
				below when violations are brought to the Company&apos;s attention.
			</p>
			<p>
				(xvi) The Services or Site may contain or deliver advertising and
				sponsorships. Advertisers and sponsors are solely responsible for
				ensuring that material submitted for inclusion is accurate and complies
				with applicable laws. We have no responsibility for insuring the
				accuracy of such Content or its compliance with applicable laws and are
				not responsible for the illegality or any error, inaccuracy or problem
				in the advertiser&apos;s or sponsor&apos;s Content.
			</p>
			<p>
				3.9 <strong>Compliance with Law</strong>. In using and accessing the
				Site and Services, you agree to comply with all applicable federal,
				state, local and international laws and regulations, including privacy
				laws, and export and import laws, regulations, orders or other
				restrictions, including those administered by the United States Commerce
				Department&apos;s Bureau of Export Administration, the United States
				Department of Treasury&apos;s Office of Foreign Assets Control or any
				other applicable government agency.
			</p>
			<p>
				<strong>4. Your Obligations.</strong>
			</p>
			<p>
				4.1 <strong>Requirements of Membership</strong>. You have the right,
				authority, and capacity to enter into this TOS on your own behalf and on
				behalf of any Authorizing Party on whose behalf, or in whose interest,
				you are acting, and you agree to abide by all of the terms and
				conditions contained herein, and that if any aspect of your access or
				use of the Services or Site violates any provisions of law to which you
				or the Services or Site are subject, you will cease all access and use
				of the Services and Site and will close your account. Furthermore, if
				deemed necessary or desirable by Filmio, in its sole discretion, you
				will cease all access and use of the Services and Site and will close
				your account.
			</p>
			<p>
				4.2 <strong>Confidentiality Obligations</strong>.
			</p>
			<p>
				(i) In accessing and using the Services and Site, based on the type of
				subscription you have purchased and/or based on the designation of your
				user type, you may be given access to the Confidential Information of
				one or more other Users (“<strong>Disclosers</strong>
				”). You agree (a) to hold the Confidential Information of Disclosers in
				strict confidence and to take all necessary precautions (but in no event
				less than reasonable care) to protect such Confidential Information from
				unauthorized disclosure, access or use; (b) not to disclose or provide
				access to any such Confidential Information or any information derived
				therefrom to any person other than Authorized Persons (defined below);
				(c) not to use such Confidential Information except for the sole and
				limited purpose of determining whether you (and not any other party)
				will enter into a business transaction with the Discloser (the “
				<strong>Purpose</strong>”). For purposes of clarity, you may not
				disclose a Discloser&apos;s Confidential Information to another party
				for the purpose of such party&apos;s determining whether to enter into a
				business transaction with the Discloser. If any other party wishes to
				obtain access to any Confidential Information of a Discloser, such third
				party shall not have the right to obtain such information through you,
				and such third party may be required to become a User of the Services
				and Site and to enter into this TOS in order to obtain access to such
				Confidential Information directly from the Discloser. As used in this
				TOS, “<strong>Authorized Persons</strong>” means your accountants,
				attorneys, financial advisors, banks, and sources of financing who are
				bound by confidentiality obligations no less stringent than those in
				this Section 4.2 to maintain the confidentiality of Discloser&apos;s
				Confidential Information and who need to have access to such
				Confidential Information for the Purpose.
			</p>
			<p>
				(ii) In addition, you may disclose such Confidential Information if you
				are legally compelled to do so pursuant to a written court order,
				subpoena, regulatory demand, or process of law; provided that prior to
				any such compelled disclosure, you shall (a) unless prohibited by
				applicable law, promptly notify the Discloser of such compelled
				disclosure, (b) fully cooperate with the Discloser (at Discloser&apos;s
				expense) in protecting against any such disclosure and/or obtaining a
				protective order narrowing the scope of such disclosure and/or use of
				the Confidential Information, and (c) solely disclose the portion of the
				Confidential Information compelled to be disclosed and solely to the
				party entitled to receive it. Any such compelled disclosure shall not
				otherwise affect your obligations hereunder with respect to the
				Discloser&apos;s Confidential Information so disclosed.
			</p>
			<p>
				(iii) You agree that solely with respect to a Discloser&apos;s
				Confidential Information, such Discloser shall be an intended
				third-party beneficiary of this Section 4.2 of this TOS and shall have
				the right to enforce the terms of this Section 4.2 directly against you,
				without first requiring that Filmio take or join any action to enforce
				this Section 4.2 against you. You also agree, as a Discloser of
				Confidential Information, that Filmio shall have the right, in its sole
				discretion but without obligation, to enforce this Section 4.2 against
				any Users to whom you provide your Confidential Information. You agree
				that, due to the unique nature of the Confidential Information of
				Disclosers, the unauthorized disclosure or use of such Confidential
				Information will cause irreparable harm and significant injury to such
				Disclosers, the extent of which will be difficult to ascertain and for
				which there will be no adequate remedy at law. Accordingly, you agree
				that Disclosers and Filmio, in addition to any other available remedies,
				shall have the right to seek an immediate injunction and other equitable
				relief enjoining any breach or threatened breach of your obligations
				under this Section 4.2 with respect to their Confidential Information,
				without the necessity of posting any bond or other security.
			</p>
			<p>
				(iv) You agree that at any time requested by the Discloser, you will
				destroy and permanently delete all Confidential Information of such
				Discloser (and all copies and extracts thereof) in your possession or
				control or as otherwise disclosed to Authorized Persons and shall
				certify to such Discloser that you and your Authorized Persons have
				completed such destruction and deletion within ten (10) days after your
				receipt of request from such Discloser to so destroy and delete such
				Confidential Information.
			</p>
			<p>
				4.3 <strong>Minimum Age</strong>. If you register as an Investor, you
				certify that you are at least 18 years old. If you register as anything
				other than an Investor, you certify that you are at least 13 years old,
				and if you are less than 18 years old, your parent or legal guardian has
				established and authorized your account on your behalf with us.
			</p>
			<p>
				4.4 <strong>Eligible User Profile</strong>. You agree that you will
				maintain an Eligible User Profile at all times. You shall be solely
				responsible for maintaining the confidentiality of your username and
				password and agree not to transfer, share, disclose or resell your
				access to the Services or Site, to any third party. You further agree to
				update your Eligible User Profile from time to time so that it remains
				true, correct and complete. You agree to immediately notify Company of
				any unauthorized use of your username, password and/or account, or any
				other breach of security related to your account, the Services or the
				Site, and you will ensure that you “log off” and exit from your account
				at the end of each session. WE ARE NOT LIABLE FOR ANY LOSS OR DAMAGE
				ARISING FROM YOUR FAILURE TO COMPLY WITH ANY OF THE FOREGOING
				OBLIGATIONS. YOU ARE ENTIRELY RESPONSIBLE FOR ALL ACTIVITIES TAKING
				PLACE THROUGH YOUR ACCOUNT, WHETHER OR NOT YOU ARE THE INDIVIDUAL WHO
				UNDERTAKES SUCH ACTIVITIES. You agree that an Eligible User Profile
				excludes:
			</p>
			<p>
				(i) Any use of a false name or an email address owned or controlled by
				another person with the intent to impersonate that person or for any
				other reason;
			</p>
			<p>
				(ii) Any use within a username if such name is subject to any rights of
				a person other than yourself without appropriate authorization; or
			</p>
			<p>
				(iii) Any other submission of false or misleading information to the
				Company.
			</p>
			<p>
				4.5 <strong>Professional Conduct</strong>. You will conduct yourself in
				a professional and respectful manner in all your interactions with
				Filmio and with any other User.
			</p>
			<p>
				4.6 <strong>Requirements related to Content on Filmio</strong>:
			</p>
			<p>
				(i) You represent and warrant that you have only provided, and will only
				provide, Filmio with Content that you have a right to provide to Filmio
				and to allow Filmio to display, publish and otherwise make available
				through the Services and Site. By submitting any Content to Filmio, you
				represent and warrant that you have all the necessary rights, whether
				under copyrights, trademarks, trade secrets, patents or other
				intellectual property, to grant the rights granted to Filmio under this
				TOS or in association with the Services and to otherwise display,
				publish and otherwise make your Content available through the Services
				and Site as contemplated in this TOS. You also represent and warrant
				that your Content complies with all applicable laws, rules and
				regulations. You understand and agree that your Content may be
				republished by Users or other third parties and if you do not have the
				right to submit Content for such use, it may subject you to liability.
				Filmio will not be responsible or liable for any use of your Content by
				Filmio in accordance with this TOS.
			</p>
			<p>
				(ii) You understand that Filmio: (1) does not endorse, support,
				represent or guarantee the completeness, truthfulness, accuracy, or
				reliability of any Content or communications displayed, published or
				otherwise made available through the Services or Site; and (2) does not
				endorse any opinions expressed through the Services or Site. It is your
				responsibility to verify the completeness, truthfulness, accuracy, and
				reliability of any Content before making any decision of material
				consequence, including investment decisions, or relying on such Content
				for any other reason. FILMIO MAKES NO REPRESENTATIONS OR WARRANTIES WITH
				RESPECT TO ANY CONTENT AND SHALL NOT BE LIABLE FOR ANY AND ALL USE
				THEREOF BY YOU OR ANY OTHER PARTY.
			</p>
			<p>
				(iii) You understand that by using the Services or Site, you may be
				exposed to Content that you might deem offensive, harmful, inaccurate or
				otherwise inappropriate, and you agree that Filmio shall have no
				liability to you or any other party for any such Content.
			</p>
			<p>
				(iv) You understand and agree that the Services or Site may include
				advertisements or other Content, which may be targeted to the Content or
				information on the Services or Site, queries made through the Services
				or Site, or other information, and that Filmio shall have no liability
				to you or any other party for the placement of advertising or similar
				Content on the Services or Site, or in connection with the display of
				Content or information from the Services or Site, whether submitted by
				you or others.
			</p>
			<p>
				4.7{' '}
				<strong>Understandings and Obligations related to Investments</strong>:
			</p>
			<p>
				(i) You understand and agree that Filmio is not, by providing the
				Services or Site, providing investment, legal, tax, financial,
				accounting or other advice to you or any other party.
			</p>
			<p>
				(ii) Filmio is not acting as an advisor or fiduciary in any respect in
				connection with Content. You will use your own independent judgment
				before making any decision to invest or to accept an investment.
			</p>
			<p>
				(iii) You are solely responsible at all times for complying with
				applicable law regarding any transaction, including without limitation
				the determination of whether any investor is an Accredited Investor and
				whether any investment complies with the terms of applicable law,
				including applicable securities laws (whether the law of a U.S. state,
				or the law of any foreign government with jurisdiction over you or any
				Investor).
			</p>
			<p>
				(iv) You have obtained, or will obtain, such professional advice as is
				appropriate to protect your interests, including legal, tax, accounting
				and other advice and to comply with applicable law.
			</p>
			<p>
				4.8 <strong>Understanding and Obligations Using the Services</strong>.
			</p>
			<p>(i) You represent and warrant the following:</p>
			<p>
				(a) You have not violated, and will not violate, Filmio&apos;s{' '}
				<em>Code of Conduct</em> which can be found{' '}
				<Link to="/code-of-conduct">here</Link>.
				<span className="Apple-converted-space">&nbsp;</span>
			</p>
			<p>
				(b) You have not engaged in, and will not engage in, any Prohibited
				Activity.
			</p>
			<p>
				(c) You will not claim any ownership right in any Content displayed on,
				published by or otherwise available through the Services or Site, other
				than the Content that you own and which you have provided through the
				Services or Site.
			</p>
			<p>
				(d) You will not copy or distribute any Content of any other Users
				except as specifically permitted pursuant to this TOS.
			</p>
			<p>
				(e) You will not attempt to access, view or alter any source code or
				object code of the Services or the Site.
			</p>
			<p>
				(f) You will not use the Services or Site to market services,
				particularly investment advisory services, that might cause Filmio to
				have to register as a broker dealer with the SEC, or any other federal
				or state government agency, or to be treated as an underwriter.
			</p>
			<p>
				(ii) You acknowledge that any communication from Filmio does not
				constitute any of the following: (a) an evaluation, confirmation,
				endorsement or verification of any User&apos;s or other party&apos;s
				statements; (b) a recommendation for any investment; or (c) a
				representation or warranty by Filmio or any Company Person with respect
				to any investment.
			</p>
			<p>
				4.9 <strong>Indemnification</strong>. You will indemnify and hold Filmio
				harmless against any and all liability that we may incur as a result of
				your use of the Services or Site, in accordance with the terms set out
				in this Sections 4.9.
			</p>
			<p>
				(i) Indemnity. You agree to indemnify and hold the Company and each
				Company Person harmless from and against any damages, expenses
				(including reasonable costs and attorneys&apos; fees), liability, causes
				of action, claims, demands, or settlements arising out of or related to:
				(a) your access to or use of the Services or Site, (b) the violation of
				any provision of this TOS by you, (c) the infringement by you, or any
				third party using your account, of any intellectual property rights or
				other right (including claims related to defamation, invasion of
				privacy, or other violation of a person&apos;s rights) of any person or
				entity, or (d) any Content made available by you through the Services or
				Site. Your obligations under the foregoing indemnity may not be offset
				against any other claim you may have against the Company or any Company
				Person. You remain solely responsible for all Content that you upload,
				post, email, transmit, or otherwise disseminate using, or in connection
				with, the Services or Site. You agree that the provisions in this
				paragraph will survive any termination of your account(s) and any or all
				of the Services.
			</p>
			<p>
				(ii) Release. You hereby forever irrevocably release and waive any
				claims that you may have or have had against Filmio and each Company
				Person related to the Services, the Site or your use of Content offered
				through the Services or Site, including any recommendations or referrals
				you may receive as a result of your registration with Filmio. You are
				solely responsible for your use of the Services and Site, for any
				Content you provide or use, and for any consequences thereof, including
				the use of your Content by other Users and third parties.
			</p>
			<p>
				(iii) Filmio Employees and Affiliates. You understand that Company
				Persons may participate in the Services as Filmmakers, Investors and
				other Users, and that Filmio is not responsible for any of their
				activities, including statements or other information in any emails or
				other communications such individuals make in their individual capacity.
			</p>
			<p>
				4.10 <strong>Matters Related to Licensing / Promotion</strong>.
			</p>
			<p>
				(i) Under the license granted by you in Section 3.3, you hereby grant to
				us a license to publish all the Content you provide and/or upload to the
				Services and Site, including any comments or other forum posts you may
				offer on the Site in order to provide the Services. Your Content will be
				viewable by other Users and through third party services and websites.
				You should only provide Content that you are comfortable sharing on
				those terms. You hereby represent that you understand and assume the
				risks associated therewith.
			</p>
			<p>
				(ii) You hereby ratify, and consent to, any action that may be taken
				with respect to your Content under any “droit moral” or similar rights
				you may have under applicable law (“<strong>Moral Rights</strong>”), and
				agree to waive and not to assert any Moral Rights with respect to any
				Content you provide to us. You further agree that you will confirm any
				such ratifications, consents and agreements in writing from time to time
				as requested by us.
			</p>
			<p>
				(iii) Filmio may wish to promote you, your company and other
				professional affiliation(s) or your film(s) to the Filmio community from
				time to time. You agree and acknowledge that Filmio may undertake such
				promotional activities, by including information relating to you, your
				company and other professional affiliation(s) as well as your film(s) on
				the Site, in general emails to Users, on our blog(s), on outside social
				networks (including, but not limited to, Facebook and/or Twitter), in
				Filmio newsletters, and in other marketing activities that may arise
				from time to time in various media; provided that we undertake to
				restrict such promotional activities to conform to the settings you
				control via the Site. If you are using Filmio for fundraising purposes,
				we will make commercially reasonable efforts to avoid promoting your
				film in a manner that would result in a general solicitation, as such
				term is construed in respect of private placement regulations existing
				from time to time under US securities laws.
			</p>
			<p>
				(iv) With respect to your activities on our Site or with our Services,
				you understand and agree that the Company is not obligated to compensate
				you or to cause any other party to compensate you or to feature or
				otherwise display your Content through any of our Services, on our Site,
				or on any third-party web page.
			</p>
			<p>
				(v) You understand and agree that neither this TOS nor the provision of
				the Services or Site entitles you to any support, upgrade, update,
				add-on patch, enhancement, or fix for the Services or Site
				(collectively, “<strong>Updates</strong>”). The Company may, however,
				occasionally provide automatic Updates to the Services in its sole and
				absolute discretion (and without any advance notification). Any such
				Updates for the Services shall become part of the Services and subject
				to this TOS.
			</p>
			<p>
				(vi) If you operate, manage or otherwise control a search engine or
				robot, or you republish a significant fraction of Filmio Content (as we
				may determine in our sole and absolute discretion), you must follow
				these rules: (a) you must use a descriptive user agent header; (b) you
				must follow robots.txt at all times; and (c) you must make it clear how
				to contact you, either in your user agent string, or on your website, if
				you have one.
			</p>
			<p>
				4.11{' '}
				<strong>
					Comments, Feedback, Suggestions, Ideas, and Other Submissions
				</strong>
				. The Services or Site may invite you to chat or participate in blogs,
				message boards, online forums and other functionality and may provide
				you with the opportunity to create, submit, post, display, transmit,
				perform, publish, distribute or broadcast Content to the Company, the
				Services and/or the Site. Any such Content will be treated as
				non-confidential and non-proprietary, except if such Content is clearly
				identified and treated by you and other Users as Confidential
				Information. All comments, feedback, suggestions, ideas, forum posts and
				other submissions disclosed, submitted, or offered to the Company in
				connection with the use of the Services, the Site or otherwise and any
				chat, blog, message board, online forum, text, email or other
				communication with the Company shall be deemed to be part of your
				Content submitted to Company.
			</p>
			<p>
				4.12 <strong>Application Programming Interface (API)</strong>. We may
				make available one or more APIs for interacting with the Services or
				Site. Your use of any Filmio API is subject to this TOS and the Filmio
				API Rules (which will become part of this TOS), which will be posted at
				such time as we may make such APIs available.
			</p>
			<p>
				4.13 <strong>No Partnership or Agency</strong>. It is expressly
				understood and agreed that neither this TOS nor anything in it shall
				constitute or be deemed to establish a partnership, agency relationship
				or joint venture between the Company (or any of its offices, employees
				or agent) and any User (or any of a User&apos;s offices, employees,
				agents or shareholders). Furthermore, neither the User nor any of its
				offices, employees or agents shall be authorized to make representations
				or take any other action whatsoever on behalf of Filmio. In accessing or
				using the Services or Site, no User will have no authority to bind
				Filmio in any way and nor will have the right or authority to make any
				representations to prospective users, customers or other third parties
				on behalf of Filmio.
			</p>
			<p>
				<strong>5. Privacy and Protection of Personal Information.</strong>
			</p>
			<p>
				5.1 Filmio values your privacy. Please review our{' '}
				<a href="https://www.film.io/privacy">Privacy Policy</a> to learn more
				about how we collect and use information about you.{' '}
				<span className="Apple-converted-space">&nbsp;</span>
			</p>
			<p>
				5.2 The Company may collect and collate a variety of information
				regarding the use of the Services and the Site. The Company is the sole
				owner of all such information it collects. By using the Services or Site
				you consent to the transfer of your information to the United States
				and/or other countries for storage, processing and use by Filmio as
				described in our{' '}
				<a href="https://www.film.io/privacy">Privacy Policy.</a>
			</p>
			<p>
				5.3 The Company uses industry-standard security measures to protect the
				loss, misuse and alteration of the information under our control.
				Although we make good faith efforts to store any non-public information
				provided to us through the Services or Site, or otherwise collected by
				the Company in a secure operating environment that is not available to
				the public, we cannot guarantee complete security. We cannot and do not
				guarantee that our security measures will prevent third-party “hackers”
				from illegally accessing our site and obtaining access to Content or
				information thereon.
			</p>
			<p>
				<strong>6. Disputes with Others.</strong>
			</p>
			<p>
				We reserve the right, but have no obligation, to monitor and/or manage
				disputes between you and other Users. If you have a dispute with another
				User, you hereby release the Company, and hereby agree to indemnify the
				Company, from and against all claims, demands, damages (actual and
				consequential) costs, attorneys&apos; fees, and other liabilities of
				every kind and nature, known and unknown, arising out of or in any way
				connected with such dispute.
			</p>
			<p>
				<strong>7. Disclaimers, Limitations, Waivers of Liability.</strong>
			</p>
			<p>
				7.1 You understand that Filmio cannot and does not guarantee or warrant
				that Site or Content provided by us or Users on the Site will be free of
				viruses or other destructive code. You are responsible for implementing
				sufficient procedures and checkpoints to satisfy your particular
				requirements for anti-virus protection and accuracy of data input and
				output, and for maintaining a means external to our Site for any
				reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR
				DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR
				OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
				EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO
				YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
				WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
				WEBSITE LINKED TO IT. YOU EXPRESSLY AGREE THAT ACCESS TO AND USE OF THE
				SERVICES AND SITE IS AT YOUR SOLE RISK, AND THAT THE SERVICES AND SITE
				ARE PROVIDED ON AN “AS IS” AND “WHERE IS” BASIS WITHOUT WARRANTIES OF
				ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE
				UNDER APPLICABLE LAW, COMPANY AND ITS AFFILIATES OR SUBSIDIARIES, AND
				THEIR DIRECTORS, EMPLOYEES, AGENTS, ATTORNEYS, THIRD-PARTY CONTENT
				PROVIDERS, DISTRIBUTORS, LICENSEES OR LICENSORS (COLLECTIVELY, “
				<strong>COMPANY PARTIES</strong>”) DISCLAIM ALL REPRESENTATIONS AND
				WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT
				NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
				PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUSES AND ALL
				WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE
				OR USAGE OF TRADE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY
				NOR THE COMPANY PARTIES PROVIDE ANY WARRANTY AS TO THE RELIABILITY,
				ACCURACY, TIMELINESS, USEFULNESS, ADEQUACY, COMPLETENESS OR SUITABILITY
				OF THE SERVICES OR SITE. YOU FURTHER SPECIFICALLY ACKNOWLEDGE THAT THE
				COMPANY PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD THE
				COMPANY PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING
				OTHER USERS OF THE SERVICES AND SITE AND OPERATORS OF EXTERNAL SITES.
			</p>
			<p>
				7.2 WITHOUT LIMITING THE FOREGOING, THE COMPANY PARTIES DO NOT
				REPRESENTS OR WARRANT THAT THE SITE, ITS CONTENT OR ANY SERVICES OR
				CONTENT OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE
				OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE
				SERVER THAT MAKES IT AVAILABLE ARE FREE FROM HARMFUL COMPONENTS OR THAT
				THE SITE OR ANY SERVICES OR CONTENT OBTAINED THROUGH THE WEBSITE WILL
				OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. The Company is not
				responsible for any technical malfunction or other problems of any
				telephone network or service, computer systems, servers or providers,
				computer or mobile phone equipment, software, failure of email or
				players on account of technical problems or traffic congestion on the
				internet or at any site or combination thereof, including injury or
				damage to your or to any other person&apos;s computer, mobile phone, or
				other hardware or software, related to or resulting from using or
				downloading materials in connection with the web and/or in connection
				with the Services, including any mobile software. Under no circumstances
				will the Company be responsible for any loss or damage, including any
				loss or damage to any Content or personal injury or death, resulting
				from anyone&apos;s use of any Services or the Site, any third party
				applications, software or Content posted on or through the Services or
				Site, or transmitted to Users, or any interactions between Users of the
				Services or Site, whether online or offline.
			</p>
			<p>
				7.3 WE MAKE NO WARRANTY AND DISCLAIM ALL RESPONSIBILITY AND LIABILITY
				FOR THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, SECURITY OR
				RELIABILITY OF THE SERVICES, THE SITE OR ANY CONTENT THEREON OR ANY
				CONTENT YOU RECEIVE AS A RESULT OF YOUR RELATIONSHIP WITH FILMIO OR
				OTHER USERS. FILMIO WILL NOT BE RESPONSIBLE OR LIABLE FOR ANY HARM TO
				YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT RESULTS FROM YOUR
				ACCESS TO OR USE OF THE SERVICES, THE SITE OR ANY CONTENT. YOU ALSO
				AGREE THAT FILMIO HAS NO RESPONSIBILITY OR LIABILITY FOR THE DELETION
				OF, OR THE FAILURE TO STORE OR TO TRANSMIT, ANY CONTENT AND OTHER
				COMMUNICATIONS MAINTAINED BY THE SERVICES OR THE SITE. NO ADVICE OR
				INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM FILMIO OR THROUGH
				THE SERVICES OR SITE WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
			</p>
			<p>
				7.4 UNDER NO CIRCUMSTANCES WILL COMPANY OR ANY COMPANY PARTY BE LIABLE
				FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR
				PUNITIVE DAMAGES OF ANY KIND, UNDER ANY CONTRACT, NEGLIGENCE, STRICT
				LIABILITY, TORT OR OTHER THEORY, INCLUDING WITHOUT LIMITATION, DAMAGES
				FOR COST OF SUBSTITUTE PROCUREMENT, LOSS OF PROFITS, USE OR DATA, LOSS
				OF OTHER INTANGIBLES, LOSS OF SECURITY OF INFORMATION YOU HAVE PROVIDED
				IN CONNECTION WITH YOUR USE OF THE SERVICES OR SITE OR UNAUTHORIZED
				INTERCEPTION OF ANY SUCH INFORMATION BY THIRD PARTIES, OR COST OF
				RECOVERY OR OTHER DAMAGES, EVEN IF ADVISED IN ADVANCE OF SUCH DAMAGES OR
				LOSSES. NOTWITHSTANDING ANYTHING ELSE IN THIS TOS, COMPANY&apos;S AND
				COMPANY PARTIES&apos; AGGREGATE LIABILITY ARISING OUT OF OR RELATING IN
				ANY WAY TO THIS TOS, THE SERVICES OR SITE, UNDER ANY CONTRACT,
				NEGLIGENCE, STRICT LIABILITY, TORT OR OTHER THEORY, SHALL NOT EXCEED THE
				FEES ACTUALLY PAID BY YOU TO COMPANY DURING THE THREE (3) MONTH PERIOD
				PRECEDING THE DATE ON WHICH COMPANY RECEIVES NOTICE OF A CLAIM FROM YOU.
				THE EXISTENCE OF ONE OR MORE CLAIMS WILL NOT ENLARGE THIS LIMIT. YOU
				ACKNOWLEDGE AND AGREE THAT THIS SECTION 7 IS AN ESSENTIAL ELEMENT OF
				THIS TOS.
			</p>
			<p>
				<strong>8. Term and Termination.</strong>
			</p>
			<p>
				Unless terminated by the Company, this TOS will remain in full force and
				effect while you use the Site, any of the Services, or maintain an
				account with Filmio. You may terminate this TOS at any time by deleting
				all other Users&apos; Content, by terminating your account and
				subscriptions (if any) with us, and by ceasing to use the Services and
				Site. The Company may terminate this TOS in the event you violate any
				provision of this TOS. Upon termination of this TOS for any reason or no
				reason, you shall destroy and remove from all computers, and other
				storage media, all Content (including, without limitation, copies of any
				intellectual property) owned by the Company or any other User of the
				Services or Site that you received as a result of your use of the
				Services or Site. Upon termination of this TOS by you or Filmio, Content
				that you have uploaded to the Site or provided to Filmio shall remain
				and may be used, displayed, and/or archived. Your representations and
				warranties in this TOS and the provisions of Sections 2, 3, 4, 7, 8, 9
				and 10 and any other provision which by their nature are designed to
				survive termination or expiration of this TOS shall survive any
				termination or expiration of this TOS.
			</p>
			<p>
				<strong>9. Copyright Policy.</strong>
			</p>
			<p>
				9.1 Filmio respects the intellectual property rights of others and
				expects Users of the Services and Site to do the same. We will respond
				to notices of alleged copyright infringement that comply with applicable
				law and are properly provided to us. The Digital Millennium Copyright
				Act (“<strong>DMCA</strong>”) provides recourse to copyright owners who
				believe that their rights under the United States Copyright Act have
				been infringed by acts of third parties over the Internet. If you
				believe that your copyrighted work has been used without your
				authorization and is available on or in the Services or Site in a way
				that may constitute copyright infringement, you may provide notice of
				your claim to the Company as described in this Section 9.
			</p>
			<p>
				9.2 If you believe that any material on the Services or Site violates
				your rights in your copyrighted work, please notify the Company as soon
				as possible by sending an email to us at copyright@Film.io, or by
				mailing a letter to the copyright agent (listed below) containing the
				following information in accordance with the DMCA:
			</p>
			<p>
				(i) a physical or electronic signature of the copyright owner or a
				person authorized to act on their behalf;
			</p>
			<p>
				(ii) identification of the copyrighted work claimed to have been
				infringed;
			</p>
			<p>
				(iii) identification of the material that is claimed to be infringing or
				to be the subject of infringing activity and that is to be removed or
				access to which is to be disabled, and information reasonably sufficient
				to permit us to locate the material;
			</p>
			<p>
				(iv) your contact information, including your address, telephone number,
				and an email address;
			</p>
			<p>
				(v) a statement by you that you have a good faith belief that use of the
				material in the manner complained of is not authorized by the copyright
				owner, its agent, or the law; and
			</p>
			<p>
				(vi) a statement that the information in the notification is accurate,
				and, under penalty of perjury, that you are authorized to act on behalf
				of the copyright owner.
			</p>
			<p>
				9.3 Our designated copyright agent for notice of alleged copyright
				infringement or other legal notices regarding Content appearing on the
				Services or Site is:
			</p>
			<p>
				Email: <a href="mailto:copyright@Film.io">copyright@Film.io</a>
			</p>
			<p>Filmio, Inc</p>
			<p>5825 Oberlin Drive</p>
			<p>Suite 5</p>
			<p>San Diego, CA 92121</p>
			<p>
				9.4 We reserve the right to remove Content alleged to be infringing or
				otherwise illegal without prior notice and at our sole discretion. In
				appropriate circumstances, Filmio will also terminate a User&apos;s
				account if the User is, or is likely to be, a repeat infringer (as
				determined by Filmio in its sole and absolute discretion).
			</p>
			<p>
				<strong>10. Miscellaneous.</strong>
			</p>
			<p>
				10.1 <strong>Governing Law/Resolution of Disputes</strong>. This TOS
				shall be governed by and construed in accordance with the internal laws
				of the United States and the State of California governing contracts
				entered into and to be fully performed in the State of California (i.e.,
				without regard to conflict of law&apos;s provisions) regardless of your
				location. With respect to any disputes or claims not subject to informal
				dispute resolution or arbitration (as set forth below), you agree not to
				commence or prosecute any action in connection therewith other than in
				the state and federal courts located in the city of San Diego,
				California, and you hereby consent to, and waive all defenses of lack of
				personal jurisdiction and forum non conveniens with respect to, venue
				and jurisdiction in the state and federal courts located in the city of
				San Diego, California The parties specifically disclaim application of
				the Convention on Contracts for the International Sale of Goods.
			</p>
			<p>
				10.2 <strong>Injunctive Relief</strong>. You acknowledge that the rights
				granted and obligations made hereunder to the Company are of a unique
				and irreplaceable nature, the loss of which shall irreparably harm the
				Company and which cannot be replaced by monetary damages alone so that
				the Company shall be entitled to injunctive or other equitable relief
				(without the obligations of posting any bond or surety) in the event of
				any breach or anticipatory breach by you. You irrevocably waive all
				rights to seek injunctive or other equitable relief and agree to limit
				your claims to claims for monetary damages (if any).
			</p>
			<p>
				10.3 <strong>Informal Negotiations</strong>. To expedite resolution and
				control the cost of any dispute, controversy or claim related to this
				TOS (“Dispute”), you and the Company agree to first attempt to negotiate
				any Dispute (except those Disputes expressly provided below) informally
				for at least thirty (30) days before initiating any arbitration or court
				proceeding. Such informal negotiations commence upon written notice from
				one person to the other. You will send your notice to legal@Filmio.com
				with notice of delivery.
			</p>
			<p>
				10.4 <strong>Binding Arbitration</strong>. If you and the Company are
				unable to resolve a Dispute through informal negotiations, either you or
				the Company may elect to have the Dispute (except those Disputes
				expressly excluded below) finally and exclusively resolved by binding
				arbitration conducted in the City of San Diego, California, US. Any
				election to arbitrate by one party shall be final and binding on the
				other. YOU UNDERSTAND THAT ABSENT THIS PROVISION, YOU WOULD HAVE THE
				RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be
				commenced and conducted under the Commercial Arbitration Rules of the
				American Arbitration Association (“<strong>AAA</strong>”) and, where
				appropriate, the AAA&apos;s Supplementary Procedures for Consumer
				Related Disputes (“<strong>AAA Consumer Rules</strong>”), both of which
				are available at the AAA website www.adr.org. The determination of
				whether a Dispute is subject to arbitration shall be governed by the
				Federal Arbitration Act and determined by a court rather than an
				arbitrator. Your arbitration fees and your share of arbitrator
				compensation shall be governed by the AAA Rules and, where appropriate,
				limited by the AAA Consumer Rules. If such costs are determined by the
				arbitrator to be excessive, the Company will pay all arbitration fees
				and expenses. The arbitration may be conducted in person, through the
				submission of documents, by phone or online. The arbitrator will make a
				decision in writing, but need not provide a statement of reasons unless
				requested by a party. The arbitrator must follow applicable law, and any
				award may be challenged if the arbitrator fails to do so. Except as
				otherwise provided in this TOS, you and the Company may litigate in
				court to compel arbitration, stay proceedings pending arbitration, or to
				confirm, modify, vacate or enter judgment on the award entered by the
				arbitrator.
			</p>
			<p>
				10.5 <strong>Restrictions in Respect of Arbitration</strong>. You and
				the Company agree that any arbitration shall be limited to the Dispute
				between the Company and you individually. To the full extent permitted
				by law:
			</p>
			<p>(i) no arbitration shall be joined with any other;</p>
			<p>
				(ii) there is no right or authority for any Dispute to be arbitrated on
				a className-action basis or to utilize className action procedures; and
			</p>
			<p>
				(iii) there is no right or authority for any Dispute to be brought in a
				purported representative capacity on behalf of the general public or any
				other persons.
			</p>
			<p>
				10.6{' '}
				<strong>Exceptions to Informal Negotiations and Arbitration</strong>.
				You and the Company agree that the following Disputes are not subject to
				the above provisions concerning informal negotiations and binding
				arbitration:
			</p>
			<p>
				(i) any Disputes seeking to enforce or protect, or concerning the
				validity of, any of your or the Company&apos;s intellectual property
				rights, including without limitation copyrights;
			</p>
			<p>
				(ii) any Dispute related to, or arising from, allegations of theft,
				piracy, invasion of privacy or unauthorized use; and
			</p>
			<p>(iii) any claim for injunctive relief.</p>
			<p>
				10.7 <strong>Waiver/Severability</strong>.
			</p>
			<p>
				(i) The failure of the Company to require or enforce strict performance
				by you of any provision of this TOS or to exercise any right under this
				TOS shall not be construed as a waiver or relinquishment of the
				Company&apos;s right to assert or rely upon any such provision or right
				in that or any other instance. In fact, the Company may choose to
				enforce certain portions of this TOS more strictly or to interpret
				certain provisions more strictly against certain Users than it does
				against Users in general, and such disparate treatment shall not be
				grounds for failing to comply with all this TOS as so interpreted.
			</p>
			<p>
				(ii) You and the Company agree that if any portion of this TOS is found
				illegal or unenforceable, in whole or in part by any court of competent
				jurisdiction, such provision shall, as to such jurisdiction, be
				ineffective to the extent of such determination of invalidity or
				unenforceability without affecting the validity or enforceability
				thereof in any other manner or jurisdiction and without affecting the
				remaining provisions of the Terms, which shall continue to be in full
				force and effect. If Section 10.5 is found to be illegal or
				unenforceable, then neither you nor the Company will elect to arbitrate
				any Dispute falling within that portion of Section10.5 found to be
				illegal or unenforceable and such Dispute shall be decided by a court of
				competent jurisdiction within the city of San Diego, State of
				California, United States of America, and you and the Company agree to
				submit to the personal jurisdiction of that court.
			</p>
			<p>
				(iii) The Company operates and controls the Services and Site from its
				offices in the United States. The Company makes no representation that
				the Services or Site are appropriate or available in other locations.
				The information provided on or through the Services or Site is not
				intended for distribution to or use by any person or entity in any
				jurisdiction or country where such distribution or use would be contrary
				to law or regulation or which would subject the Company to any
				registration requirement within such jurisdiction or country.
				Accordingly, those persons who choose to access any Service or Site from
				other locations do so on their own initiative and are solely responsible
				for compliance with local laws, if and to the extent local laws are
				applicable.
			</p>
			<p>
				10.8 Neither the course of conduct between the parties nor trade
				practice will act to modify this TOS to any party at any time without
				any notice to you. You may not assign this TOS without the
				Company&apos;s prior written consent which may be withheld in
				Company&apos;s sole discretion. Company may assign this TOS, the Site,
				the Services, and all Content by provided by you and other Users in the
				event of a merger, sale of stock, sale of assets, reorganization or
				similar transaction. This TOS contains the entire agreement between you
				and the Company, and supersedes all prior agreements of the parties
				hereto relating to the subject matter hereof. This TOS cannot be changed
				or modified by you but may be modified by Company as described in
				Section 1.2. No waiver by either party of any breach or default
				hereunder shall be deemed to be a waiver of any preceding or subsequent
				breach or default.
			</p>
			<p>
				10.9 <strong>Construction &amp; Compliance</strong>. The section
				headings used herein and the summary of terms at the beginning of this
				TOS are for convenience only and shall not be given any legal import.
				Upon the Company&apos;s request, you will furnish the Company any
				documentation, substantiation or releases necessary to verify your
				compliance with this TOS. You agree that this TOS will not be construed
				against the Company by virtue of having authored it. You hereby waive
				any and all defenses you may have based on the electronic form of this
				TOS and the lack of signing by the parties hereto to execute this TOS.
			</p>
			<p>
				10.10 <strong>Statute of Limitations</strong>. You and the Company both
				agree that regardless of any statute or law to the contrary, any claim
				or cause of action arising out of or related to: (a) use of the Services
				or the Site, (b) this TOS or (c) our Privacy Policy must be filed within
				ONE (1) YEAR after such claim or cause of action arose or be forever
				barred.
			</p>
			<p>
				10.11 <strong>No Third-Party Beneficiaries</strong>. This TOS is between
				you and the Company. No User has any rights to force the Company to
				enforce any rights it may have against any you or any other User, except
				as expressly permitted in Section 4.2
			</p>
			<p>
				10.12 <strong>Third Party Websites</strong>. The Services or Site may
				contain links to other websites not controlled by us. We are not
				responsible for the Content, accuracy or opinions expressed on such
				websites, and such websites are not investigated, monitored or checked
				for accuracy or completeness by us.
			</p>{' '}
		</div>
	)
}

export default TermsOfService
