import _ from 'lodash'
import { useState } from 'react'
import {
	reloadProject,
	setShowAddTeamMemberForm
} from 'component/projectDetail/projectDetailSlice'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useNotification from 'hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import { addProjectUserTeam } from 'services/project.service'
import {
	getCollaboratorRoles,
	getDepartments,
	getTeams
} from 'services/seed.service'
import useTeam from '../useTeamTab'

const useAddTeamForm = () => {
	const [teams, setTeams] = useState([])
	const [departments, setDepartments] = useState([])
	const [collaboratorRoles, setCollaboratorRoles] = useState([])
	const [errors, setErrors] = useState([])
	const { fetchProjectUserTeams } = useTeam(false)
	const { projectId, teamTabIndex, showAddTeamMemberForm } = useSelector(
		state => state.projectDetail
	)
	const [formData, setFormData] = useState({ teamId: teamTabIndex + 1 })
	const { showNotification } = useNotification()
	const dispatch = useDispatch()

	const [{ loading: loadingTeams }] = useAxiosFetch(getTeams(), setTeams)

	const [{ loading: loadingDepartments }] = useAxiosFetch(
		getDepartments(),
		setDepartments
	)

	const [{ loading: loadingCollaboratorRoles }] = useAxiosFetch(
		getCollaboratorRoles(),
		(data, error) => {
			if (!error) {
				const roles = data.filter(el => el.id !== 2)
				setCollaboratorRoles(roles)
			}
		}
	)

	const handleCloseForm = () => {
		dispatch(setShowAddTeamMemberForm(false))
	}

	const afterSave = async (_data, error) => {
		if (!error) {
			if (!_.isNil(formData?.collaboratorRolId)) {
				dispatch(reloadProject())
			}
			fetchProjectUserTeams()
			setFormData({ teamId: 1 })
			showNotification({
				message: 'Team member added successfully'
			})
		}
	}

	const [{ loading: saving }, save] = useAxiosFetch(
		addProjectUserTeam({ ...formData, projectId }),
		afterSave
	)

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const isValid = () => {
		let isValid = true
		const errorsTmp = {}
		const message = 'This field is required'
		if (_.isNil(formData.userId)) {
			errorsTmp.userId = message
			isValid = false
		}
		if (_.isNil(formData.collaboratorRolId) && formData.teamId === 3) {
			errorsTmp.collaboratorRolId = message
			isValid = false
		}
		updateErrors(errorsTmp)
		return isValid
	}

	const handleSave = () => {
		if (isValid()) save()
		else
			showNotification({
				message: 'Please fix the form errors to save changes',
				type: 'error'
			})
	}

	const handleChange = payload => {
		const key = Object.keys(payload)[0]
		updateErrors({ [key]: false })
		setFormData({
			...formData,
			...payload
		})
	}

	const showMask =
		loadingTeams || loadingDepartments || loadingCollaboratorRoles || saving

	return {
		teams,
		errors,
		showMask,
		formData,
		departments,
		collaboratorRoles,
		showAddTeamMemberForm,
		handleCloseForm,
		handleChange,
		handleSave
	}
}

export default useAddTeamForm
