import PropTypes from 'prop-types'
import { Box, Dialog, DialogContent, Typography, Button } from '@mui/material'
import useHeader from '../useHeader'
import styles from './congratsDialog.module.scss'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import Congrats from 'assets/images/walletIcons/congratsToken.svg'
import { useEffect } from 'react'
import useAxios from 'hooks/useAxios'
import { unlockCompletedService } from 'services/rewardBucket.service'
import useNotification from 'hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import { setCongratsDialogOpen } from '../signInSlice'
import { updateUser } from 'component/profile/profileSlice'
import {
	reloadRecentActivity,
	setOverview
} from 'component/profile/walletTab/walletTabSlice'
import { SpeedUpBar } from 'component/common/speedUpBar'

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#2E2C6A80' : '#2E2C6A80',
	...theme.typography.body2,
	padding: '10px 38px',
	textAlign: 'center',
	color: theme.palette.primary.main,
	border: '1px solid #4E4BDE',
	borderRadius: '16px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}))
const DialogWithbackground = styled(Dialog)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#000000b3' : '#000000b3',
	...theme.typography.body2
}))
const CustonButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#262262' : '#262262',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	width: '100%',
	color: theme.palette.primary.main,
	border: 'none',
	borderRadius: '12px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: '10px',
	'&:hover': {
		backgroundColor: '#4E4BDE'
	}
}))

export const CongratsDialog = () => {
	const { congratsDialogOpen: open } = useSelector(state => state.signIn)
	const { showNotification } = useNotification()
	const { axiosService } = useAxios()
	const dispatch = useDispatch()
	const headingText = 'Completed!'
	const Congratulations = 'Congratulations'
	const bottomText =
		'Reward schedules may shift to match your pace as you earn new rewards.'
	const { showConfetti } = useHeader()

	const handleClose = () => {
		dispatch(setCongratsDialogOpen(false))
	}

	const UnlockedCompleted = () => {
		axiosService(unlockCompletedService(), (data, error) => {
			if (!error) {
				if (!data?.success) {
					showNotification({ message: 'Something went wrong!' })
				} else {
					dispatch(
						updateUser({
							unlockRewardsStartDate: null,
							unlockRewardsEndDate: null
						})
					)
					dispatch(
						setOverview({
							unlockRewardsStartDate: null,
							unlockRewardsEndDate: null
						})
					)
					dispatch(reloadRecentActivity())
				}
			}
		})
	}

	useEffect(() => {
		if (open) {
			showConfetti(5000)
			UnlockedCompleted()
		}
	}, [open])

	const handleCloseDialog = () => {
		handleClose()
	}

	return (
		<DialogWithbackground
			open={open}
			disableScrollLock={true}
			className={`${styles.dialog} warning`}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleCloseDialog}
		>
			<DialogContent className={styles.dialog_content}>
				<div className={styles.flex_grid}>
					<Typography className={styles.center} component={'h2'}>
						{headingText}{' '}
					</Typography>
					<span className={styles.center}>
						<img src={Congrats.src} alt="Congrats Icon" />
					</span>
				</div>
				<Box>
					<Grid
						container
						rowSpacing={3}
						columnSpacing={{ xs: 1, sm: 2, md: 2 }}
					>
						<Grid xs={12}>
							<Item className={styles.first_Item}>
								<Box className={styles.box2}>
									<Typography className={styles.mb_10} component={'h3'}>
										{Congratulations}{' '}
									</Typography>
								</Box>
								<Box className={styles.box2}>
									<span className={styles.center}>
										<SpeedUpBar value={100} />
									</span>
								</Box>
							</Item>
						</Grid>

						<Grid xs={12}>
							<CustonButton onClick={handleCloseDialog}>
								<Typography className={styles.btn_close} component={'h3'}>
									Close{' '}
								</Typography>
							</CustonButton>
							<Box className={styles.actions}>
								<Typography component={'p'}>{bottomText}</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</DialogWithbackground>
	)
}

CongratsDialog.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func
}
