import { useEffect, useState } from 'react'
import { Input } from '@/components/ui/input'
import {
	Popover,
	PopoverContent,
	PopoverTrigger
} from '@/components/ui/popover'
import { FiSearch, FiCheckCircle } from 'react-icons/fi'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getGlobalSearchService } from 'services/dashboard.service'
import { Link } from 'react-router-dom'
import placeHolder from 'assets/images/placeholder.svg'
import { IoMdClose } from 'react-icons/io' 
export function SearchInput({ showFanSearchMobile, handleCloseSearch }) {
	const [anchorEl, setAnchorEl] = useState(null)
	const [search, setSearch] = useState('')
	const [items, setItems] = useState([])

	const [{ loading }, fetchData] = useAxiosFetch(
		getGlobalSearchService({ search }),
		afterFetchProject
	)

	function afterFetchProject(data, error) {
		if (!error) {
			setItems(data)
		}
	}

	const handleKeyup = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleLinkClick = () => {
		setSearch('')
		showFanSearchMobile && handleCloseSearch()
	}

	const renderItems = () =>
		items?.map(i => {
			const defaultAvatar = placeHolder
			const to =
				i.type === 'user' ? `/profile/${i.username}` : `/project/${i.slug}`

			return (
				<Link
					className="flex items-center gap-3 p-2 hover:bg-background80 text-secondary-foreground"
					key={i.id}
					to={to}
					onClick={handleLinkClick}
				>
					{i.type === 'user' ? (
						<span className="w-8 h-8 rounded-full overflow-hidden">
							<img src={i?.image ?? defaultAvatar.src} alt={i.name} />
						</span>
					) : (
						<span className="w-8 h-8 rounded overflow-hidden">
							<img src={i?.image ?? defaultAvatar.src} alt={i.name} />
						</span>
					)}
					<div className="flex flex-col">
						<h2 className="text-base font-semibold text-secondary-foreground">
							{i.name}
						</h2>
						{i.author && (
							<p className="text-sm text-secondary-foreground flex items-center">
								{i.author}
								{i.isVerified === 1 && (
									<FiCheckCircle className="ml-1 text-primary" />
								)}
							</p>
						)}
						<p className="text-sm text-gray-500">{i.description}</p>
					</div>
				</Link>
			)
		})

	useEffect(() => {
		if (search !== '') fetchData()
	}, [search]) 
	return (
		<div
			className={`relative w-full ${
				showFanSearchMobile ? 'max-[639px]:block' : 'max-[639px]:hidden '
			} `}
		>
			<Popover open={search !== ''}>
				<PopoverTrigger asChild>
					<div className="flex justify-center items-center relative w-full">
						<span className="absolute inset-y-0 left-0 flex items-center pl-3">
							<FiSearch className="text-[#71717A] w-3 h-3" />
						</span>

						<Input
							type="text"
							placeholder="Search option..."
							className="pl-10 placeholder:text-[#71717A]"
							value={search}
							onChange={e => setSearch(e.target.value)}
							onKeyUp={handleKeyup}
						/>
						{showFanSearchMobile && (
							<span className='cursor-pointer ml-4'>
								<IoMdClose onClick={handleCloseSearch} />
							</span>
						)}
					</div>
				</PopoverTrigger>
				<PopoverContent className="p-4 bg-background shadow-lg w-full sm:w-[850px]">
					{loading ? (
						<p className="text-center text-secondary-foreground">
							Searching...
						</p>
					) : items.length > 0 ? (
						renderItems()
					) : (
						<p className="text-center text-secondary-foreground">
							No results match your search
						</p>
					)}
				</PopoverContent>
			</Popover>
		</div>
	)
}
