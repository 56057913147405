'use client'

import _ from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
	closeVerificationEmailDialog,
	reloadDaoTiers,
	updateVerificationEmailData
} from 'component/profile/profileSlice'
import {
	sendEmailCodeService,
	validateEmailCodeService
} from 'services/profile.service'
import { addObjectToLocalStorage } from 'utils/utils'
import { updateSignInUser } from 'component/header/signInSlice'
import { CustomDialog, TextFieldControl } from 'component/common'
import useHeader from 'component/header/useHeader'
import useCommon from 'hooks/useCommon'
import useAxios from 'hooks/useAxios'
import { isValidEmail } from 'utils/form.util'
import useLoadingMask from 'hooks/useLoadingMask'
import styles from './VerificationEmailDialog.module.scss'
import { updateProfile } from 'component/profileEdit/profileEditSlice'

const Page1 = () => {
	const [error, setError] = useState(false)
	const { showMask, hideMask } = useLoadingMask()
	const { axiosService } = useAxios()
	const dispatch = useDispatch()
	const { verificationEmailData } = useSelector(state => state.profile)
	const { email } = verificationEmailData

	const resendEmailConfirmation = () => {
		showMask()
		axiosService(sendEmailCodeService({ email }), (_data, error) => {
			if (!error) {
				dispatch(updateVerificationEmailData({ page: 2 }))
			}
			hideMask()
		})
	}

	const setEmail = email => {
		dispatch(updateVerificationEmailData({ email }))
	}

	const handleClose = () => {
		dispatch(closeVerificationEmailDialog())
	}

	const handleNext = () => {
		if (isValid()) resendEmailConfirmation()
	}

	const isValid = () => {
		let isValid = true
		const message = 'This field is required'
		if (_.isNil(email) || email.trim() === '') {
			setError(message)
			isValid = false
		}
		if (!_.isNil(email) && !isValidEmail(email)) {
			setError('The email format is incorrect.')
			isValid = false
		}
		return isValid
	}

	return (
		<Box className={styles.email_verification}>
			<Box>
				<Typography component={'h1'}>Enter your Email</Typography>
				<Typography>
					Please enter your email to receive the verification code for the next
					step.
				</Typography>
				<TextFieldControl
					error={error}
					classes={{ root: styles.email_input }}
					hiddenLabel
					fullWidth
					name="email"
					placeholder="Email"
					value={email}
					onChange={e => {
						setEmail(_.trim(e.target.value))
					}}
				/>
			</Box>
			<Box className={styles.actions}>
				<Button className="yellow-bg-button" onClick={handleNext}>
					Next
				</Button>
				<Button onClick={handleClose}>Cancel</Button>
			</Box>
		</Box>
	)
}

const Page2 = () => {
	const [code, setCode] = useState('')
	const { loadUserTierInfo } = useHeader()
	const dispatch = useDispatch()
	const { axiosService } = useAxios()
	const { resendEmailConfirmation } = useCommon()
	const { verificationEmailData } = useSelector(state => state.profile)
	const { email } = verificationEmailData

	const handleClose = () => {
		dispatch(closeVerificationEmailDialog())
	}

	const handleClickResend = () => {
		resendEmailConfirmation(email)
	}

	const handleVerify = () => {
		axiosService(validateEmailCodeService({ code, email }), (_data, error) => {
			if (!error) {
				dispatch(reloadDaoTiers())
				loadUserTierInfo()
				const verified = { isEmailVerified: 1 }
				addObjectToLocalStorage(verified)
				dispatch(updateSignInUser(verified))
				dispatch(updateVerificationEmailData({ page: 3 }))
				dispatch(updateProfile({ email }))
			}
		})
	}

	return (
		<Box className={styles.email_verification}>
			<Box>
				<Typography component={'h1'}>Email Verification Code</Typography>
				<Typography>
					Please check your email for the 4-digit verification code.
					<br /> If you haven&apos;t received it, please click
					<Link onClick={handleClickResend}> Resend.</Link>
				</Typography>
				<TextFieldControl
					type="number"
					value={code}
					variant={'blue'}
					autoFocus
					onChange={e => {
						setCode(e.target.value)
					}}
				/>
			</Box>
			<Box className={styles.actions}>
				<Button className="yellow-bg-button" onClick={handleVerify}>
					Verify
				</Button>
				<Button onClick={handleClose}>Cancel</Button>
			</Box>
		</Box>
	)
}

const Page3 = () => {
	const dispatch = useDispatch()

	const handleClose = () => {
		dispatch(closeVerificationEmailDialog())
	}

	return (
		<Box className={styles.email_verification}>
			<Box>
				<Typography component={'h1'}>Thank you for Verifying!</Typography>
				<Typography>
					Congratulations! You have successfully verified your email. Enjoy
					using Film.io to its fullest capacity!
				</Typography>
			</Box>
			<Box className={styles.actions}>
				<Button className="yellow-bg-button" onClick={handleClose}>
					Close
				</Button>
			</Box>
		</Box>
	)
}

export const VerificationEmailDialog = () => {
	const dispatch = useDispatch()
	const { verificationEmailData } = useSelector(state => state.profile)
	const { page, open } = verificationEmailData

	const handleClose = () => {
		dispatch(closeVerificationEmailDialog())
	}

	return (
		<CustomDialog {...{ open, handleClose, variant: 'email_verification' }}>
			{page === 1 && <Page1 />}
			{page === 2 && <Page2 />}
			{page === 3 && <Page3 />}
		</CustomDialog>
	)
}
