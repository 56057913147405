import axios from 'axios'
import { baseUrl } from 'config'

axios.defaults.baseURL = baseUrl

axios.interceptors.request.use(
	async config => {
		const token = localStorage.getItem('jwt')

		if (token) {
			config.headers = {
				authorization: `Bearer ${token}`
			}
		}
		return config
	},
	error => Promise.reject(error)
)

axios.interceptors.response.use(
	response => response,
	async error => {
		if (error?.response?.status === 401) {
			localStorage.clear()
			window.location.href = '/'
		}
		return Promise.reject(error)
	}
)

export default axios
