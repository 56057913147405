import { Menu, Button, Box, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import { useSocialMediaShareMenu } from './useSocialMediaShareMenu'
import { EditTweetDialog } from './editTweetDialog'
import styles from './SocialMediaShareMenu.module.scss'

export const SocialMediaShareMenu = ({ isDraft, className }) => {
	const { anchorEl, handleClick, handleClose } = useSocialMediaShareMenu()

	return (
		<>
			<Box {...{ className }}>
				<Button
					className="sub"
					onClick={handleClick}
					startIcon={
						<Icon baseClassName="fal" className="fa-share-from-square" />
					}
				>
					Refer
				</Button>
			</Box>
			<Menu
				id={styles.share_menu}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				elevation={2}
				getcontentanchorel={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				classes={{
					paper: styles.menu_paper
				}}
			>
				<EditTweetDialog {...{ isDraft, closeMenu: handleClose }} />
			</Menu>
		</>
	)
}

SocialMediaShareMenu.propTypes = {
	isDraft: PropTypes.bool,
	className: PropTypes.string
}
