import { Box, Button, Grid, Typography } from '@mui/material'
import { TextFieldControl } from 'component/common'
import { CropPoster } from 'component/common/cropPoster'
import { DropZone } from 'component/common/dropZone'
import { getVideoInfoFromUrl } from 'utils/video.utils'
import { CropImage } from '../../common/cropImage'
import useProjectAddWizard from '../useProjectAddWizard'
import styles from './ArtworkPage.module.scss'

const ArtworkPage = () => {
	const {
		errors,
		project,
		videoInfo,
		handleOpenConfirmModal,
		setHeaderImage,
		handleNextPage,
		setVideoInfo,
		handleChange,
		updateErrors,
		setPoster
	} = useProjectAddWizard(false)

	return (
		<Grid container className={styles.artwork_page}>
			<Grid item xs={12} className={styles.edit_form} container spacing={4}>
				<Grid item xs={12}>
					<CropImage
						{...{
							helperTextLeft:
								'png, jpeg accepted. 24mb max. 1400px x 538px maximum',
							error: errors?.header,
							setCroppedImage: image => {
								setHeaderImage(image)
								updateErrors({ header: false })
							},
							defaultImage: project?.coverImg
						}}
					/>
				</Grid>

				<Grid item md={12} container spacing={2}>
					<Grid item md={6} xs={12} className={styles.leftColumn}>
						<Grid item md={12} className={styles.dropB}>
							<DropZone
								{...{
									error: errors.lookbook,
									maxFiles: 1,
									multiple: false,
									maxSize: 24 * 1024 * 1024,
									accept: {
										'document/pdf': [],
										'application/pdf': []
									},
									label: 'Lookbook',
									helperTextLeft: 'pdf accepted. 24mb max size',
									defaultFile: project?.lookbook,
									setFile: file => {
										handleChange({
											lookbook: file
										})
									},
									clearFile: () => {
										handleChange({
											lookbook: undefined
										})
									}
								}}
							/>
						</Grid>
						<Grid item md={12} xs={12} className={styles.dropB}>
							<DropZone
								{...{
									error: errors.treatment,
									maxFiles: 1,
									multiple: false,
									maxSize: 24 * 1024 * 1024,
									accept: {
										'document/pdf': [],
										'application/pdf': []
									},
									label: 'Treatment',
									helperTextLeft: 'pdf accepted. 24mb max size',
									defaultFile: project?.treatment,
									setFile: file => {
										handleChange({
											treatment: file
										})
									},
									clearFile: () => {
										handleChange({
											treatment: undefined
										})
									}
								}}
							/>
						</Grid>
						<Grid item md={12} className={styles.dropB}>
							<DropZone
								{...{
									error: errors.script,
									maxFiles: 1,
									multiple: false,
									maxSize: 24 * 1024 * 1024,
									accept: {
										'document/pdf': [],
										'application/pdf': []
									},
									label: 'Script',
									helperTextLeft: 'pdf accepted. 24mb max size',
									defaultFile: project?.script,
									setFile: file => {
										handleChange({
											script: file
										})
									},
									clearFile: () => {
										handleChange({
											script: undefined
										})
									}
								}}
							/>
						</Grid>

						<Grid item md={12}>
							<TextFieldControl
								label="Trailer"
								value={project.trailer ?? ''}
								variant={'blue'}
								placeholder="The link to your Trailer at YouTube"
								helperText="Youtube or Vimeo link"
								onChange={async e => {
									handleChange({ trailer: e.target.value })
									const info = await getVideoInfoFromUrl(e.target.value)
									setVideoInfo(info)
								}}
							/>
						</Grid>
						<Grid item md={12}>
							{videoInfo && !videoInfo?.error && (
								<Box className={styles.video_info}>
									<img src={videoInfo?.thumbnail_url} alt="" />
									<Box className={styles.info}>
										<Typography component={'h3'}>
											{videoInfo?.author_name}
										</Typography>
										<Typography component={'h4'}>{videoInfo?.title}</Typography>
									</Box>
								</Box>
							)}
							{videoInfo?.error && (
								<Typography component={'h4'}>
									Trailer video not found, put the id or the complete url
								</Typography>
							)}
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						sx={{ marginBottom: '2rem' }}
						className={styles.rightColumn}
					>
						<Typography
							component={'h2'}
							className="label"
							sx={{ marginBottom: '10px' }}
						>
							Poster*
						</Typography>

						<CropPoster
							{...{
								helperTextLeft:
									'png, jpeg accepted. 24mb max. 976px x 1371px maximum',
								setCroppedImage: setPoster,
								defaultImage: project?.poster
							}}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid md={12} className={styles.actions} item>
				<Button onClick={handleOpenConfirmModal}>Cancel</Button>
				<Button className="yellow-bg-button" onClick={handleNextPage}>
					{'Save & Continue'}
				</Button>
			</Grid>
		</Grid>
	)
}

export default ArtworkPage
