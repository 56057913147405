import { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Icon, IconButton, Typography } from '@mui/material'
import { followUnfollowProject } from 'services/followUnfollow.service'
import { TAB_MAP } from 'component/projectDetail'
import { LightTooltip } from 'component/common'
import useHeader from 'component/header/useHeader'
import { useSelector } from 'react-redux'
import { GoScore } from './goScore'
import { GoScoreV2 } from './goScoreV2'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import projectImages from 'assets/images/product-detail'
import images from 'assets/images'
import styles from './ProjectItem.module.scss'
import { abbreviateNumberFixed } from 'utils/utils'
import { ActiveContestBadge } from '../contests/badge/ActiveContestBadge'

const ProjectItem = ({
	id,
	likes,
	poster,
	reviewsAvg,
	stakesTotal,
	goScoreStage,
	isFollowedByUser,
	variant,
	title,
	slug,
	user,
	goscore,
	goScoreMilestones,
	staffPick,
	contests = [],
	createdAt
}) => {
	const { isAuthenticated } = useSelector(state => state.signIn)
	const { isGoScoreV2Enabled } = useSelector(state => state.home)
	const [isFollowed, setIsFollowed] = useState(isFollowedByUser)
	const [followCount, setFollowCount] = useState(likes)
	const [hoverButton, setHoverButton] = useState(false)
	const { loadUserTierInfo } = useHeader()
	const navigate = useNavigate()

	const handleFollowClick = projectId => async () => {
		if (!isAuthenticated) {
			navigate(`/project/${slug}`)
			return
		}
		const res = await followUnfollowProject(projectId, isFollowed)
		if (res.status === 200) {
			const followCountValue = isFollowed ? followCount - 1 : followCount + 1
			setFollowCount(followCountValue)
			setIsFollowed(prev => !prev)
			loadUserTierInfo()
		}
	}

	const buttonIcon = hoverButton ? images.newTokenBlack : images.newToken

	return (
		<Box className={[styles.project_item, styles[variant]].join(' ')}>
			<div className={styles.posterWrapper}>
				<Link to={`/project/${slug}`}>
					<img
						loading="lazy"
						className={styles.artwork}
						src={poster + '?tr=w-276,h-404' || projectImages.posterNotAvailable}
						alt={'poster for ' + title}
						width={276}
						height={404}
					/>
				</Link>
				<Box className={styles.SocialContainer}>
					<Box className={styles.likes}>
						<IconButton onClick={handleFollowClick(id)}>
							{isFollowed ? <FavoriteIcon /> : <FavoriteBorderIcon />}
						</IconButton>
						<span>{abbreviateNumberFixed(followCount, 2)}</span>
					</Box>
					<Box className={styles.greenlight_btn}>
						<Link
							to={`/project/${slug}`}
							onMouseOver={() => setHoverButton(true)}
							onMouseOut={() => setHoverButton(false)}
						>
							<Box>
								<img src={buttonIcon} alt="token" />
								HELP GREENLIGHT
							</Box>
						</Link>
					</Box>
					{staffPick === 1 && (
						<LightTooltip title="Chosen as a Daily Pick by Film.io staff">
							<Box className={styles.staff_pick}>
								<img
									src={images.staffPickLarge}
									className={styles.staff_pick}
								/>
							</Box>
						</LightTooltip>
					)}
				</Box>
			</div>
			<Box className={styles.item_box}>
				<Box className={styles.stage_button}>
					<span className={styles[goScoreStage?.label]}>
						{goScoreStage?.name}
					</span>
				</Box>
				<Box className={styles.likes}>
					<IconButton>
						<Icon baseClassName="fas" className="fa-star-half-stroke" />
					</IconButton>
					<span>{reviewsAvg}</span>
				</Box>
				<Box className={styles.likes}>
					<IconButton>
						<Icon baseClassName="fa-kit" className="fa-fan-stake" />
					</IconButton>
					<span>{abbreviateNumberFixed(stakesTotal, 2)}</span>
				</Box>
			</Box>
			<Box className={styles.item_content}>
				<Box className={styles.goscore}>
					{isGoScoreV2Enabled ? (
						<GoScoreV2 {...{ goscore, showText: false }} />
					) : (
						<GoScore {...{ goscore, goScoreMilestones, showText: false }} />
					)}
				</Box>
				<Typography component={'h3'}>
					<LightTooltip title={title}>
						<Link to={`/project/${slug}?${TAB_MAP.dashboard}`}>{title}</Link>
					</LightTooltip>
					<span>
						<Link to={`/profile/${user.username}`}>{user.displayName}</Link>
						{user?.isVerified === 1 && (
							<Icon baseClassName="fas" className="fa-badge-check" />
						)}
					</span>
				</Typography>
				<Box className={styles.badges}>
					{new Date(createdAt) < new Date('2023-11-30T12:00:00') && (
						<LightTooltip title="Participated in the 2023 Airdrop">
							<img
								className={styles.airdrop_image}
								src={images.airdrop2023}
								alt="airdrop 2023 participant"
							/>
						</LightTooltip>
					)}
					{contests.map(contest => {
						const isContestWinner = contest.isWinner && !contest.isActive
						const src = isContestWinner ? contest?.badgeWinner : contest?.badge
						const tootipTitle = isContestWinner
							? `Winner in ${contest?.name}`
							: `Participation in ${contest?.name}`
						return (
							<LightTooltip title={tootipTitle} key={contest?.id}>
								<Box className={styles.contest_box}>
									<ActiveContestBadge {...{ src, height: 15.8, width: 16 }} />{' '}
								</Box>
							</LightTooltip>
						)
					})}
				</Box>
			</Box>
		</Box>
	)
}

ProjectItem.propTypes = {
	id: PropTypes.string,
	slug: PropTypes.string,
	poster: PropTypes.string,
	likes: PropTypes.number,
	stakesTotal: PropTypes.number,
	reviewsAvg: PropTypes.number,
	goScoreStage: PropTypes.object,
	title: PropTypes.string,
	user: PropTypes.object,
	stakes: PropTypes.object,
	reviews: PropTypes.object,
	socialMediaShares: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	isFollowedByUser: PropTypes.bool,
	variant: PropTypes.string,
	goscore: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	goScoreMilestones: PropTypes.array,
	staffPick: PropTypes.number,
	contests: PropTypes.array,
	createdAt: PropTypes.string
}

export default ProjectItem
