import { Box, Icon } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setCreateMagicWalletDialogOpen } from '../signInSlice'
import styles from './NcWihoutMagicWarning.module.scss'

export const NcWihoutMagicWarning = () => {
	const { user: signInUser } = useSelector(state => state.signIn)
	const dispatch = useDispatch()

	const handleTriggerClaimMagicWallet = () => {
		dispatch(setCreateMagicWalletDialogOpen(true))
	}

	return (
		<>
			{signInUser?.isNcWithoutMagic && (
				<Box className={styles.nc_wihout_magic_warning}>
					<Box
						className={styles.message}
						onClick={handleTriggerClaimMagicWallet}
					>
						<span>
							<Icon baseClassName="fas" className="fa-triangle-exclamation" />
						</span>
						In order to transact on Film.io and earn rewards, you need a
						platform wallet! Please click here to get one.
					</Box>
				</Box>
			)}
		</>
	)
}
