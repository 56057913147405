import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	fgrClaimed: 0,
	open: false,
	projectTitle: '',
	claiming: false
}

export const claimFgrSlice = createSlice({
	name: 'claimFgr',
	initialState,
	reducers: {
		showFgrModal: (state, { payload }) => {
			const { fgrEarned, title, claiming } = payload
			state.fgrClaimed = fgrEarned
			state.projectTitle = title
			state.claiming = claiming
			state.open = true
		},
		hideFgrModal: state => {
			state.open = false
		},
		setClaiming: (state, { payload }) => {
			state.claiming = payload
		},
		setProjectTitle: (state, { payload }) => {
			state.projectTitle = payload
		}
	}
})

export const { showFgrModal, hideFgrModal, setClaiming, setProjectTitle } =
	claimFgrSlice.actions

export default claimFgrSlice.reducer
