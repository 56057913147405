'use client'

import { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import getCroppedImg from './getCropImage'
import { DropZone } from '../dropZone'
import { Box, Slider } from '@mui/material'
import projectImages from 'assets/images/product-detail'
import { useDropzone } from 'react-dropzone'
import styles from './CropAvatar.module.scss'

const CropAvatar = ({ setCroppedImage, defaultImage }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [file, setFile] = useState(null)
	const [image, setImage] = useState(null)
	const [isNewImage, setIsNewImage] = useState(false)
	const [defaultFile, setDefaultFile] = useState(defaultImage)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	useEffect(() => {
		const updateCroopedImage = async () => {
			if (image && (isNewImage || zoom !== 1)) {
				const croppedImage = await getCroppedImg(image, croppedAreaPixels)
				const fileName = file?.name || defaultImage?.url.split('/').pop()
				const fileCrooped = new File([croppedImage], fileName)
				setCroppedImage(fileCrooped)
			}
		}
		updateCroopedImage()
	}, [zoom, croppedAreaPixels, image])

	useEffect(() => {
		if (defaultImage) {
			const { url, ...defaultFile } = defaultImage
			setImage(url)
			setDefaultFile(defaultFile)
		}
	}, [defaultImage])

	const clearImage = () => {
		setCroppedImage(null)
		setDefaultFile(null)
		setImage(null)
	}

	const valueLabelFormat = value => `${Math.round(value * 100)}%`

	const setBlob = image => {
		setIsNewImage(true)
		setImage(image)
	}

	const onDrop = useCallback(acceptedFiles => {
		acceptedFiles.map(file => {
			setFile(file)
			setDefaultFile({
				path: `${file.lastModified}__${file.name}`,
				size: file.size
			})
			const reader = new FileReader()
			reader.onload = function (e) {
				setBlob(e.target.result)
			}
			reader.readAsDataURL(file)
			return file
		})
	}, [])

	const accept = { 'image/*': ['.jpeg', '.png', '.jpg'] }

	const { getRootProps, getInputProps } = useDropzone({
		accept,
		onDrop,
		noClick: true
	})

	return (
		<Box className={styles.crop_avatar} {...getRootProps()}>
			<input {...getInputProps()} />
			<Box className={styles.controls}>
				<DropZone
					{...{
						accept,
						clearFile: clearImage,
						setFile,
						setBlob,
						defaultFile
					}}
				/>
			</Box>

			<Box className={styles.crop_container}>
				<Cropper
					image={image || projectImages.defaultProjectCover}
					crop={crop}
					zoom={zoom}
					maxZoom={4}
					minZoom={1}
					aspect={4 / 4}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
					objectFit="contains"
					showGrid={false}
					zoomWithScroll={false}
					classes={{
						containerClassName: styles.crooper_container,
						mediaClassName: styles.crooper_media,
						cropAreaClassName: styles.crooper_crop_area
					}}
				/>
			</Box>

			<Box className={styles.zoom_range}>
				<Slider
					size="small"
					value={zoom}
					aria-label="Small"
					valueLabelDisplay="auto"
					min={1}
					max={4}
					step={0.1}
					getAriaValueText={valueLabelFormat}
					valueLabelFormat={valueLabelFormat}
					onChange={e => {
						setZoom(e.target.value)
					}}
				/>
			</Box>
		</Box>
	)
}

CropAvatar.propTypes = {
	setCroppedImage: PropTypes.func,
	defaultImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default CropAvatar
