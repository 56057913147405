import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
	project: {},
	projectId: null,
	tabIndex: 0,
	reviews: [],
	showReviewsForm: false,
	showAddCommentForm: false,
	stakes: [],
	reactions: [],
	teams: [],
	teamTabIndex: 0,
	rolePermissions: [],
	signupsStats: {
		count: 0,
		joinedToday: 0,
		joinedYesterday: 0,
		invitesLeft: 0
	},
	showAddTeamMemberForm: false,
	trailerInfo: {},
	isOwnerSignedIn: false,
	isAddReviewDisabled: false,
	isCreatorView: true,
	isReviewed: true,
	updateStakes: true,
	updateComments: true,
	reload: false,
	contests: []
}

export const projectDetailSlice = createSlice({
	name: 'projectDetail',
	initialState,
	reducers: {
		resetProject: () => initialState,
		updateProject: (state, { payload }) => {
			state.project = {
				...state.project,
				...payload
			}
		},
		setTabIndex: (state, { payload }) => {
			state.tabIndex = payload
		},
		setReviews: (state, { payload }) => {
			state.reviews = [...state.reviews, ...payload]
		},
		resetReviews: (state, { _payload }) => {
			state.reviews = []
		},
		setShowReviewsForm: (state, { payload }) => {
			state.showReviewsForm = payload
		},
		setShowAddCommentForm: (state, { payload }) => {
			state.showAddCommentForm = payload
		},
		setStakes: (state, { payload }) => {
			state.stakes = payload
		},
		setReactions: (state, { payload }) => {
			state.reactions = payload
		},
		setTeams: (state, { payload }) => {
			state.teams = payload
			state.showAddTeamMemberForm = false
		},
		setShowAddTeamMemberForm: (state, { payload }) => {
			state.showAddTeamMemberForm = payload
		},
		setTrailerInfo: (state, { payload }) => {
			state.trailerInfo = payload
		},
		setIsCreatorView: (state, { payload }) => {
			state.isCreatorView = payload
		},
		setIsAddReviewDisabled: (state, { payload }) => {
			state.isAddReviewDisabled = payload
		},
		setIsReviewed: (state, { payload }) => {
			state.isReviewed = payload
		},
		setProjectId: (state, { payload }) => {
			state.projectId = payload
		},
		reloadStakes: state => {
			state.updateStakes = !state.updateStakes
		},
		reloadComments: state => {
			state.updateComments = !state.updateComments
		},
		setIsOwnerSignedIn: (state, { payload }) => {
			state.isOwnerSignedIn = payload
		},
		setSignupsStats: (state, { payload }) => {
			state.signupsStats = payload
		},
		reviewCommentAddedHook: (state, { payload }) => {
			const items = [...current(state.reviews)]
			const newItems = items.map(el => {
				if (el?.id === payload?.feedId) {
					return {
						...el,
						comments: [payload, ...el?.comments]
					}
				}
				return el
			})
			state.reviews = newItems
		},
		addReviewHook: (state, { payload }) => {
			state.reviews = [payload, ...state.reviews]
		},
		setRolePermissions: (state, { payload }) => {
			state.rolePermissions = payload
		},
		setTeamTabIndex: (state, { payload }) => {
			state.teamTabIndex = payload
		},
		reloadProject: state => {
			state.reload = !state.reload
		},
		addBlockchainReviewHook: (state, { payload }) => {
			const items = [...current(state.reviews)]
			state.reviews = items.map(el => {
				return el.id === payload.id ? payload : el
			})
		}
	}
})

export const {
	setTabIndex,
	resetProject,
	setProjectId,
	updateProject,
	addReviewHook,
	reloadComments,
	setTeamTabIndex,
	setIsCreatorView,
	setRolePermissions,
	setShowReviewsForm,
	setShowAddCommentForm,
	reviewCommentAddedHook,
	addBlockchainReviewHook,
	setShowAddTeamMemberForm,
	setIsAddReviewDisabled,
	setIsOwnerSignedIn,
	setSignupsStats,
	setTrailerInfo,
	reloadProject,
	resetReviews,
	reloadStakes,
	setReactions,
	setReviews,
	setStakes,
	setTeams,
	setIsReviewed
} = projectDetailSlice.actions

export default projectDetailSlice.reducer
