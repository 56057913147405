import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const useUserCard = () => {
	const [tierProgress] = useState(null)
	const { user } = useSelector(state => state.profile)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { isAdmin } = signInUser
	const tierName = _.capitalize(signInUser?.daoTierName)
	const nextTierName = _.capitalize(signInUser?.nextTierName)
	const isDaoTierConfirmed = Number(signInUser?.daoTierConfirmed) === 1
	const isDaoTierCompleted = Number(signInUser?.daoTierCompleted) === 1
	const upgradeAvailable =
		(!isDaoTierConfirmed && isDaoTierCompleted) ||
		Number(signInUser?.nextTierCompleted) === 1
	const tierImage = upgradeAvailable
		? signInUser?.nextTierImage
		: signInUser?.daoTierImg
	function stringAvatar(name, wh = '2.2rem') {
		if (!name) name = '?'
		const splitedName = name.split(' ').map(el => el?.toUpperCase())
		return {
			sx: {
				bgcolor: '#000000',
				border: '1px solid #ffffff',
				height: wh,
				width: wh,
				marginRight: '12px'
			},
			children: `${_.get(splitedName, [0, 0], '')}${_.get(
				splitedName,
				[1, 0],
				''
			)}`
		}
	}

	return {
		user,
		isAdmin,
		tierName,
		nextTierName,
		tierImage,
		signInUser,
		tierProgress,
		stringAvatar,
		
	}
}

export default useUserCard
