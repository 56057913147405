import { Avatar, Box } from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styles from './CustomAvatar.module.scss'

function stringAvatar(name, wh = '2.2rem') {
	if (!name) name = '?'
	const splitedName = name.split(' ').map(el => el?.toUpperCase())
	return {
		sx: {
			bgcolor: '#000000',
			border: '1px solid #ffffff',
			height: wh,
			width: wh,
			marginRight: '12px'
		},
		children: `${_.get(splitedName, [0, 0], '')}${_.get(
			splitedName,
			[1, 0],
			''
		)}`
	}
}

export const RingAvatar = ({
	avatar,
	ring,
	levelUp,
	displayName = 'Jhon Doe',
	onClick,
	avatarClass = ''
}) => {
	const cursor = typeof onClick === 'function' ? 'pointer' : 'default'
	const classes = `${styles.avatar} ${!_.isNil(levelUp) ? styles.tier_up : ''}`
	return (
		<Box className={classes} {...{ onClick, style: { cursor } }}>
			<Avatar
				className={`avatar ${avatarClass}`}
				alt=""
				{...stringAvatar(displayName)}
				src={avatar}
			/>
			{ring && <img src={ring} className={styles.ring} />}
			{/* {levelUp && <img src={levelUp} className={styles.levelUp} />} */}
		</Box>
	)
}

RingAvatar.propTypes = {
	avatar: PropTypes.string,
	avatarClass: PropTypes.string,
	ring: PropTypes.string,
	levelUp: PropTypes.string,
	onClick: PropTypes.func,
	displayName: PropTypes.string
}
