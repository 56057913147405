import { useDispatch } from 'react-redux'
import {
	showNotification as showNotificationFn,
	updateExtended,
	closeNotificationExt
} from 'component/common/notification/notificationSlice'

const useNotification = () => {
	const dispatch = useDispatch()
	const showNotification = payload => {
		dispatch(showNotificationFn(payload))
	}

	const closeNotificationExtended = () => {
		dispatch(closeNotificationExt())
	}

	const showNotificationExtended = payload => {
		dispatch(
			updateExtended({
				...payload,
				openExtended: true,
				completed: false
			})
		)
	}

	const completeNotificationExtended = (timeout = 20) => {
		dispatch(
			updateExtended({
				completed: true
			})
		)
		setTimeout(() => {
			closeNotificationExtended()
		}, 3000)
	}

	return {
		showNotification,
		showNotificationExtended,
		completeNotificationExtended,
		closeNotificationExtended
	}
}

export default useNotification
