import { Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LoadMask } from 'component/common'

const Loading = () => {
	const navigate = useNavigate()
	const fullUrlPath = localStorage.getItem('fullUrlPath')
	const redirectTo =
		!fullUrlPath || fullUrlPath === '/' ? '/explore' : fullUrlPath
	setTimeout(() => {
		navigate(redirectTo)
	})

	return (
		<Container disableGutters maxWidth="md">
			<LoadMask />
		</Container>
	)
}

export default Loading
