import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import styles from './StatItem.module.scss'

export const StatItem = ({ amount, label, classes, variant = '' }) => {
	return (
		<Box className={`${styles.stat_item} ${classes} ${styles[variant]}`}>
			<Typography component={'h2'}>{amount}</Typography>
			<Typography>{label}</Typography>
		</Box>
	)
}

StatItem.propTypes = {
	amount: PropTypes.number,
	classes: PropTypes.string,
	variant: PropTypes.string,
	label: PropTypes.string
}
