'use client'

import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { ScrollArea } from '@/components/ui/scroll-area'
import Image from 'next/image'
import { Link as RouterLink } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import {
	FiCompass,
	FiLifeBuoy,
	FiLogOut,
	FiUser,
	FiArrowRight
} from 'react-icons/fi'
import { LuNewspaper, LuFilm, LuSettings, LuBadgeCheck } from 'react-icons/lu'
import { CiSearch } from 'react-icons/ci'
import { FaRegSquareFull } from 'react-icons/fa6'
import { FanTierInfoMenu } from '../profileMenu/fanTierInfoMenu'
import { BlokchainWallet } from '../../../header/blokchainWallet'
import useProfileMenu from '../profileMenu/useProfileMenu'
import useHeader from '../../../header/useHeader'
import useDefaultMenu from '../profileMenu/defaultMenu/useDefaultMenu'
import { cn } from '../../../../lib/utils'
import { UserKingIcon } from '../../sidebar'

const adminBaseUrl = process.env.NEXT_PUBLIC_ADMIN_URL
const helpBaseUrl = process.env.NEXT_PUBLIC_HELP_BASE_URL

const NavItem = ({ icon: Icon, label, to, onClick }) => (
	<RouterLink
		to={to}
		className="hover:bg-highlight/70 flex cursor-pointer items-center gap-3 rounded-md p-2 text-white hover:text-muted-foreground"
		onClick={onClick}
	>
		<Icon className="h-5 w-5" />
		<div className="text-lg">{label}</div>
	</RouterLink>
)

export default function MobileProfileSheet({ className, triggerClassName }) {
	const [open, setOpen] = useState(false)
	const [isShowingTierInfo, setIsShowingTierInfo] = useState(false)
	const { signInUser, stringAvatar } = useProfileMenu()
	const {
		jwt,
		tierName,
		tierImage,
		profileHref,
		tierProgress,
		nextTierName,
		upgradeAvailable,
		handleClickUpgradme,
		handleShowTierInfo,
		toggleSearchMobile,
		redirectToProfile,
		handleKYCStart
	} = useDefaultMenu()
	const { handleLogout } = useHeader()

	const {
		avatar,
		isVerified,
		isAdmin,
		username,
		daoTierRingImg,
		projectCount,
		displayName,
		nextTierId
	} = signInUser

	const handleClose = () => setOpen(false)

	function logout() {
		handleLogout()
		handleClose()
	}

	const isTokenSaleWithoutAccess =
		signInUser?.isTokenSale === 1 && signInUser?.appAccess === 0

	const closeDaoTierInfo = () => {
		setIsShowingTierInfo(false)
	}

	return (
		<Sheet className={className} open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild>
				<Button
					variant="outline"
					size="icon"
					className={cn(
						'relative overflow-hidden rounded-full bg-transparent hover:bg-transparent focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-opacity-50',
						triggerClassName
					)}
				>
					<Avatar className="relative flex h-[40px] w-[40px] items-center justify-center">
						<AvatarImage src={daoTierRingImg} alt="Dao tier ring image" />
					</Avatar>
					<Avatar className="absolute flex h-[40px] w-[40px] items-center justify-center">
						{avatar ? (
							<AvatarImage src={avatar} alt={`${displayName}'s avatar`} />
						) : (
							<AvatarFallback className="text-md absolute inset-0 flex items-center justify-center font-bold text-white">
								{stringAvatar(displayName).children}
							</AvatarFallback>
						)}
					</Avatar>
				</Button>
			</SheetTrigger>
			<SheetContent side="right" className="w-full max-w-full sm:max-w-md">
				<ScrollArea className="h-full">
					{isShowingTierInfo ? (
						<FanTierInfoMenu closeDaoTierInfo={closeDaoTierInfo} />
					) : (
						<div className="flex flex-col space-y-4 py-4">
							<BlokchainWallet />

							<div className="flex items-center space-x-4">
								<div className="relative" onClick={redirectToProfile}>
									<Avatar className="h-[50px] w-[50px]">
										<AvatarImage src={daoTierRingImg} alt="Dao tier ring" />
									</Avatar>
									<Avatar className="absolute inset-0">
										{avatar ? (
											<AvatarImage src={avatar} alt="User avatar" />
										) : (
											<AvatarFallback className="text-lg font-bold text-white">
												{stringAvatar(displayName).children}
											</AvatarFallback>
										)}
									</Avatar>
								</div>
								<div>
									<div className="flex items-center space-x-2">
										<RouterLink
											to={profileHref}
											className="text-base font-bold text-white"
											onClick={handleClose}
										>
											{displayName}
										</RouterLink>
										{isVerified === 0 ? (
											<Button
												className="h-6 w-20 rounded-md bg-primary p-0 text-background"
												onClick={handleKYCStart}
											>
												<LuBadgeCheck className="mr-1 h-4 w-4" />
												Verify
											</Button>
										) : isVerified === 1 ? (
											<Button className="h-6 w-6 rounded-md bg-primary p-0 text-background">
												<LuBadgeCheck className="h-4 w-4" />
											</Button>
										) : null}
									</div>
									<p className="text-sm font-medium text-icons opacity-50">
										{tierName} DAO Member
									</p>
								</div>
							</div>

							<button
								className="rounded-lg bg-highlight p-4 text-white"
								onClick={() => {
									setIsShowingTierInfo(true)
								}}
							>
								{upgradeAvailable && nextTierId ? (
									<div
										className="flex items-center justify-between"
										onClick={handleClickUpgradme}
									>
										<div>
											<h4 className="text-lg font-semibold">
												Click to Upgrade
											</h4>
											<p>
												Congrats on reaching{' '}
												<strong>{nextTierName} DAO!</strong>
											</p>
										</div>
										<Image
											src={tierImage}
											width={36}
											height={36}
											alt="Tier Avatar"
											className="overflow-hidden rounded-full"
										/>
									</div>
								) : (
									<div className="flex items-center justify-start gap-4">
										<div>
											<Image
												src={tierImage}
												width={36}
												height={36}
												alt="Avatar"
												className="overflow-hidden rounded-full"
											/>
										</div>
										<div className="flex w-full items-center justify-between gap-4">
											<div className="w-full flex-col items-center justify-between">
												<div className="flex items-center justify-between text-base">
													{tierName} DAO Member
												</div>
												<div className="relative mt-2 h-[8px] w-full rounded-full bg-black">
													<div
														className="h-full rounded-full rounded-r-none bg-gradient-to-r from-primary to-black"
														style={{ width: tierProgress }}
													></div>
												</div>
												{nextTierId && (
													<p className="mt-1 text-left text-sm text-gray-400">
														Current progress to {nextTierName}
													</p>
												)}
											</div>
											<div
												className="flex items-center justify-center"
												onClick={handleShowTierInfo}
											>
												<IoIosArrowForward className="h-6 w-6" />
											</div>
										</div>
									</div>
								)}
							</button>

							<div className="space-y-2">
								<NavItem
									icon={UserKingIcon}
									label="Fan Dashboard"
									to="/fan-dashboard"
									onClick={handleClose}
								/>
								<NavItem
									icon={FiCompass}
									label="Explore Projects"
									to="/explore"
									onClick={handleClose}
								/>
								<NavItem
									icon={LuNewspaper}
									label="Newsreels"
									to="/newsreel"
									onClick={handleClose}
								/>
								{projectCount > 0 && (
									<NavItem
										icon={LuFilm}
										label="Your Projects"
										to={`/profile/${username}?tab=2`}
										onClick={handleClose}
									/>
								)}
								<NavItem
									icon={LuSettings}
									label="Settings"
									to="/settings"
									onClick={handleClose}
								/>
								<NavItem
									icon={CiSearch}
									label="Search"
									to="#"
									onClick={() => {
										toggleSearchMobile()
										handleClose()
									}}
								/>

								<RouterLink to="/newProject" onClick={handleClose}>
									<Button className="w-full bg-primary text-background hover:bg-secondary hover:text-white">
										Submit Your Project <FiArrowRight className="ml-2" />
									</Button>
								</RouterLink>

								{Number(isAdmin) === 1 && (
									<NavItem
										icon={FaRegSquareFull}
										label="FET Management"
										to="/tile-management"
										onClick={handleClose}
									/>
								)}
								{jwt && Number(isAdmin) === 1 && (
									<NavItem
										icon={FiUser}
										label="Platform Administration"
										to={`${adminBaseUrl}/admin/auth/${jwt}`}
										onClick={() => {
											window.open(`${adminBaseUrl}/admin/auth/${jwt}`, '_blank')
											handleClose()
										}}
									/>
								)}
								<NavItem
									icon={FiLogOut}
									label={isTokenSaleWithoutAccess ? 'Disconnect' : 'Logout'}
									to="#"
									onClick={logout}
								/>
								<NavItem
									icon={FiLifeBuoy}
									label="Get Help"
									to={`${helpBaseUrl}/knowledgebase/welcome/what-is-film.io`}
									onClick={() => {
										window.open(
											`${helpBaseUrl}/knowledgebase/welcome/what-is-film.io`,
											'_blank'
										)
										handleClose()
									}}
								/>
							</div>
						</div>
					)}
				</ScrollArea>
			</SheetContent>
		</Sheet>
	)
}
