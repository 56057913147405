import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Box } from '@mui/material'
import { ConnectionsTab } from '../connectionsTab'
import { DashboardTab } from '../dashboardTab'
import { NewsreelTab, NEWSREEL_FEATURES } from 'component/common'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setTabIndex } from '../profileSlice'
import { ProjectTab } from '../projectTab'
import { WalletTab } from '../walletTab'
import styles from './ProfileTabSection.module.scss'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

export default function ProfileTabSection() {
	const dispatch = useDispatch()
	const { tabIndex, user, userId } = useSelector(state => state.profile)
	const { user: signInUser } = useSelector(state => state.signIn)
	const [searchParams, setSearchParams] = useSearchParams()
	const isSignInUserProfile = signInUser.id === userId

	const handleChange = (_event, tab) => {
		setSearchParams({ tab })
	}

	useEffect(() => {
		const tabParam = searchParams.get('tab')
		const tab = !isSignInUserProfile && tabParam === '3' ? '2' : tabParam
		dispatch(setTabIndex(Number(tab) || 0))
	}, [searchParams, isSignInUserProfile])

	const isSignInProfile = user?.id === signInUser?.id

	return (
		<Box className={styles.profile_tab}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={tabIndex}
					onChange={handleChange}
					variant="fullWidth"
					className={styles.tabs_list}
					TabIndicatorProps={{
						className: styles.tab_custom_indicator
					}}
					aria-label="basic tabs example"
				>
					<Tab label="Dashboard" {...a11yProps(0)} />
					<Tab label="Newsreel" {...a11yProps(1)} />
					{isSignInUserProfile && <Tab label="Projects" {...a11yProps(2)} />}
					<Tab label="Connections" {...a11yProps(!isSignInProfile ? 2 : 3)} />
					{isSignInProfile && <Tab label="Wallet" {...a11yProps(4)} />}
				</Tabs>
			</Box>
			<TabPanel value={tabIndex} index={0}>
				<DashboardTab />
			</TabPanel>
			<TabPanel value={tabIndex} index={1}>
				<NewsreelTab feature={NEWSREEL_FEATURES.profile} />
			</TabPanel>

			{isSignInUserProfile && (
				<TabPanel value={tabIndex} index={2}>
					{' '}
					<ProjectTab />
				</TabPanel>
			)}
			<TabPanel value={tabIndex} index={!isSignInProfile ? 2 : 3}>
				<ConnectionsTab />
			</TabPanel>
			{isSignInProfile && (
				<TabPanel value={tabIndex} index={4}>
					<WalletTab />
				</TabPanel>
			)}
		</Box>
	)
}
