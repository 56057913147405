import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import styles from './ReferralTrackingSection.module.scss'

export const ReferralTrackingSection = ({ children }) => {
	return (
		<Box className={styles.referral_tracking}>
			<Typography className={styles.title}>
				Your Current Growth stats
			</Typography>
			<Box className={styles.stats}>{children}</Box>
		</Box>
	)
}
ReferralTrackingSection.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}
