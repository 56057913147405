import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Box, Icon, IconButton } from '@mui/material'
import {
	SnapList,
	SnapItem,
	useScroll,
	useDragToScroll,
	useVisibleElements
} from 'react-snaplist-carousel'
import { getProjectBadgesService } from 'services/goscore.service'
import badgeLocked from 'assets/images/badge-locked.png'
import { GoScoreBadge } from '../goScoreBadge/GoScoreBadge'
import { useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'
import styles from './GoScoreBadges.module.scss'

export const GoScoreBadges = () => {
	const { projectId, project } = useSelector(state => state.projectDetail)
	const { createdAt } = project
	const [badges, setBadges] = useState([])
	const snapList = useRef(null)
	const { axiosService } = useAxios()

	const visible = useVisibleElements(
		{ debounce: 5, ref: snapList },
		([element]) => element
	)
	const goToSnapItem = useScroll({ ref: snapList })
	useDragToScroll({ ref: snapList, disabled: false })

	const getProjectBadges = () => {
		axiosService(getProjectBadgesService(projectId), (data, error) => {
			if (!error) {
				setBadges(data)
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			getProjectBadges()
		}
	}, [projectId])

	const handleNextClick = () => {
		goToSnapItem(visible + 5)
	}

	const handlePrevClick = () => {
		goToSnapItem(visible > 5 ? visible - 5 : 0)
	}

	const renderItems = () => {
		// filtering airdrop badge from badges list after november 30th,2023 12pm
		return badges
			.filter(
				badge =>
					!(
						badge?.name === 'Airdrop 2023' &&
						new Date(createdAt) > new Date('2023-11-30T12:00:00')
					)
			)
			.map(badge => {
				const image = badge.completed ? badge.image : badgeLocked.src
				return (
					<SnapItem key={`badge-${badge?.id}`} className={styles.snap_item}>
						<GoScoreBadge
							{...{
								...badge,
								image
							}}
						/>
					</SnapItem>
				)
			})
	}
	return (
		<Box className={styles.badges}>
			{visible > 0 && (
				<IconButton
					size="small"
					onClick={handlePrevClick}
					className={styles.prev}
				>
					<Icon baseClassName="fas" className="fa-chevron-left" />
				</IconButton>
			)}

			<SnapList ref={snapList} tabIndex={0}>
				{renderItems()}
			</SnapList>
			{visible < badges.length - 5 && (
				<IconButton
					size="small"
					onClick={handleNextClick}
					className={styles.next}
				>
					<Icon baseClassName="fas" className="fa-chevron-right" />
				</IconButton>
			)}
		</Box>
	)
}
