'use client'

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const Counter = ({ value, time }) => {
	const [percent, setPercent] = useState(0)

	const speed = (time * 1000) / value
	useEffect(() => {
		if (percent < value) {
			setTimeout(() => {
				setPercent(prev => prev + 1)
			}, speed)
		}
	}, [percent])

	return <>{percent}</>
}

Counter.propTypes = {
	value: PropTypes.number,
	time: PropTypes.number
}

export default Counter
