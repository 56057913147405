import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	phaseNoStartedDialogOpen: false,
	validateIpDialogOpen: false,
	unallowedLocationsOpen: false,
	claimUserDialogOpen: false,
	saleTransactions: false,
	reload: false,
	campaign: {}
}

export const tokenSaleExperienceSlice = createSlice({
	name: 'tokenSaleExperience',
	initialState,
	reducers: {
		setPhaseNoStartedDialogOpen: (state, { payload }) => {
			state.phaseNoStartedDialogOpen = payload
		},
		setValidateIpDialogOpen: (state, { payload }) => {
			state.validateIpDialogOpen = payload
		},
		setCampaign: (state, { payload }) => {
			state.campaign = payload
		},
		setUnallowedLocationsOpen: (state, { payload }) => {
			state.unallowedLocationsOpen = payload
		},
		setClaimUserDialogOpen: (state, { payload }) => {
			state.claimUserDialogOpen = payload
		},
		setSaleTransactions: (state, { payload }) => {
			state.saleTransactions = payload
		},
		reloadSaleTransactions: state => {
			state.reload = !state.reload
		}
	}
})

export const {
	setCampaign,
	reloadSaleTransactions,
	setPhaseNoStartedDialogOpen,
	setUnallowedLocationsOpen,
	setValidateIpDialogOpen,
	setClaimUserDialogOpen,
	setSaleTransactions
} = tokenSaleExperienceSlice.actions

export default tokenSaleExperienceSlice.reducer
