import { Button, Box } from '@mui/material'
import { TextFieldControl, CustomButton, CustomDialog } from 'component/common'
import useSaleMagicDialog from './useSaleMagicDialog'
import styles from './SaleMagicDialog.module.scss'

export const SaleMagicDialog = () => {
	const {
		open,
		email,
		errors,
		emailChangeHandler,
		handleKeypress,
		handleSubmit,
		handleClose
	} = useSaleMagicDialog()

	return (
		<CustomDialog {...{ open, handleClose }}>
			<Box className={styles.header}>
				Welcome Back
				<span>
					Let&apos;s make <span>great entertainment together.</span>
				</span>
			</Box>
			<Box className={styles.content}>
				<Box className={styles.fields}>
					<TextFieldControl
						autoFocus
						error={errors.email}
						hiddenLabel
						fullWidth
						name="username"
						placeholder="Email"
						value={email}
						onKeyPress={handleKeypress}
						onChange={emailChangeHandler}
					/>
				</Box>
				<CustomButton customStyle={'blue_w_border'} onClick={handleSubmit}>
					Claim User
				</CustomButton>

				<Button onClick={handleClose} className={styles.back}>
					Close
				</Button>
			</Box>
		</CustomDialog>
	)
}
