import _ from 'lodash'
import PropTypes from 'prop-types'
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Icon,
	IconButton
} from '@mui/material'
import { newsreelFilters } from 'component/common'
import useSideBarNewsreel from '../useSideBarNewsreel'
import logo from 'assets/images/beta-logo.png'
import { useSelector } from 'react-redux'
import styles from './SidebarMenu.module.scss'

export default function SidebarMenu({ handleClose }) {
	const { user } = useSelector(state => state.signIn)
	const { activeFilters, clearFilter, handleFilterChange } =
		useSideBarNewsreel()

	const isChecked = value => activeFilters.includes(value)

	const getClassName = value =>
		isChecked(value)
			? `${styles.only_show_label} ${styles.active}`
			: styles.only_show_label

	const isFilterEmpty = _.isEmpty(activeFilters)

	const renderOptions = el => {
		const iconBase = el?.iconBase ?? 'fal'
		return (
			<Box id={el?.label} className={styles.menu_item} key={`option-${el.id}`}>
				{el?.icon && (
					<Icon baseClassName={iconBase} className={`fa-${el?.icon}`} />
				)}
				<FormControlLabel
					className={getClassName(el.id)}
					control={
						<Checkbox
							checked={isChecked(el.id)}
							onChange={handleFilterChange(el.id)}
							name={el?.name}
						/>
					}
					label={el?.name}
				/>
			</Box>
		)
	}

	return (
		<Box className={styles.filters_item}>
			<IconButton
				size="small"
				onClick={handleClose}
				className={styles.close_btn}
			>
				<Icon baseClassName="fal" className="fa-arrow-left" />
			</IconButton>
			<img src={logo.src} alt="logo" className={styles.logo} />
			{!isFilterEmpty && (
				<Button size="small" variant="text" onClick={clearFilter}>
					Clear All
				</Button>
			)}

			<h3>Show More</h3>
			<FormGroup className={styles.links}>
				{newsreelFilters.map(el => renderOptions(el))}
			</FormGroup>
			<h3>HELPFUL SHORTCUTS</h3>
			<FormGroup className={styles.links}>
				{user?.projectCount > 0 && (
					<a href={`/profile/${user?.username}?tab=2`}>
						<Icon baseClassName="fal" className="fa-film" />
						My Projects
					</a>
				)}
				<a
					href="https://help.film.io/knowledgebase/welcome/what-is-film.io"
					target="_blank"
					rel="noreferrer"
				>
					<Icon baseClassName="fal" className="fa-graduation-cap" />
					Knowledge base
				</a>
			</FormGroup>
		</Box>
	)
}

SidebarMenu.propTypes = {
	handleClose: PropTypes.func
}
