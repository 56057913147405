import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuGroup
} from '@/components/ui/dropdown-menu'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'
import Image from 'next/image'
import { helpBaseUrl } from 'config'
import {
	FiCompass,
	FiLifeBuoy,
	FiLogOut,
	FiUser,
	FiArrowRight
} from 'react-icons/fi'
import { LuNewspaper, LuFilm, LuSettings, LuBadgeCheck } from 'react-icons/lu'
import { CiSearch } from 'react-icons/ci'
import { FaRegSquareFull } from 'react-icons/fa6'
import { BlokchainWallet } from 'component/header/blokchainWallet'
import useProfileMenu from '../useProfileMenu'
import useDefaultMenu from './useDefaultMenu'
import useHeader from 'component/header/useHeader'
import { FanTierInfoMenu } from '../fanTierInfoMenu'
import { UserKingIcon } from '../../../sidebar'
const adminBaseUrl = process.env.NEXT_PUBLIC_ADMIN_URL

const NavItem = ({ icon: Icon, label }) => (
	<div className="hover:bg-highlight/70 flex cursor-pointer items-center gap-3 rounded-md text-white hover:text-muted-foreground">
		<Icon className="h-5 w-5" />
		<div className="text-lg">{label}</div>
	</div>
)

export const DefaultMenu = () => {
	const { signInUser, handleClose, stringAvatar } = useProfileMenu()
	const {
		jwt,
		tierName,
		tierImage,
		profileHref,
		tierProgress,
		nextTierName,
		upgradeAvailable,
		handleClickUpgradme,
		handleShowTierInfo,
		toggleSearchMobile,
		redirectToProfile,
		handleKYCStart
	} = useDefaultMenu()

	const { handleLogout } = useHeader()
	const [isShowingTierInfo, setIsShowingTierInfo] = useState(false)

	const {
		avatar,
		isVerified,
		isAdmin,
		username,
		daoTierRingImg,
		projectCount,
		displayName,
		daoTierImg,
		nextTierId
	} = signInUser

	function logout() {
		handleLogout()
		handleClose()
	}
	const isTokenSaleWithoutAccess =
		signInUser?.isTokenSale === 1 && signInUser?.appAccess === 0

	const closeDaoTierInfo = () => {
		setIsShowingTierInfo(false)
	}

	if (isShowingTierInfo) {
		return <FanTierInfoMenu closeDaoTierInfo={closeDaoTierInfo} />
	}

	return (
		<>
			<DropdownMenuLabel className="w-full flex-1 px-0 pb-4 pt-2">
				<BlokchainWallet />
				<div className="relative flex items-center pl-3">
					<div
						className="relative flex items-center hover:cursor-pointer"
						onClick={redirectToProfile}
					>
						<Avatar className="relative z-10 mr-3 flex h-[50px] w-[50px] items-center justify-center">
							<AvatarImage src={daoTierRingImg} alt="@shadcn" />
						</Avatar>
						<Avatar className="absolute top-0 mr-3 flex h-[50px] w-[50px] items-center justify-center">
							{avatar ? (
								<AvatarImage src={avatar} alt="avatar" />
							) : (
								<AvatarFallback className="absolute inset-0 flex items-center justify-center text-lg font-bold text-white">
									{stringAvatar(displayName).children}
								</AvatarFallback>
							)}
						</Avatar>
					</div>
					<div>
						<div className="flex items-center justify-start gap-2">
							<Link
								className="font-bol flex items-center justify-start gap-2 text-base leading-5 text-white"
								to={profileHref}
								onClick={handleClose}
							>
								<div className="text-base font-bold leading-5 text-white">
									{displayName}
								</div>
							</Link>
							{isVerified == 0 && (
								<Button
									className="h-6 w-6 rounded-md bg-primary p-0 text-background focus-visible:border-0 focus-visible:outline-none"
									onClick={handleKYCStart}
								>
									<LuBadgeCheck width={16} height={16} />
								</Button>
							)}
							{isVerified == 1 && (
								<Button className="h-6 w-6 rounded-md bg-primary text-background focus-visible:border-0 focus-visible:outline-none">
									<LuBadgeCheck width={16} height={16} />
								</Button>
							)}
						</div>
						<p className="flex items-center justify-start text-sm font-medium text-icons opacity-50">
							{tierName} DAO Member
						</p>
					</div>
				</div>
			</DropdownMenuLabel>

			<DropdownMenuItem
				className="w-full cursor-pointer rounded-lg bg-highlight px-4 py-3 text-white"
				onSelect={e => {
					e.preventDefault()
					setIsShowingTierInfo(true)
				}}
			>
				{upgradeAvailable && nextTierId ? (
					<div className="mt-2 w-full text-white">
						<div
							className="flex items-center justify-start gap-6"
							onClick={handleClickUpgradme}
						>
							<div className="flex flex-col items-start justify-start gap-0">
								<h4 className="text-lg font-semibold">Click to Upgrade</h4>
								<p>
									Congrats on reaching <strong>{nextTierName} DAO!</strong>
								</p>
							</div>
							<Image
								src={tierImage}
								width={36}
								height={36}
								alt="Avatar"
								className="overflow-hidden rounded-full"
							/>
						</div>
					</div>
				) : (
					<div className="flex items-center justify-start gap-4">
						<div>
							<Image
								src={tierImage}
								width={36}
								height={36}
								alt="Avatar"
								className="overflow-hidden rounded-full"
							/>
						</div>
						<div className="flex w-full items-center justify-between gap-4">
							<div className="w-full flex-col items-center justify-between">
								<div className="flex items-center justify-between">
									{tierName} DAO Member
								</div>
								<div className="relative mt-2 h-[8px] w-full rounded-full bg-black">
									<div
										className="h-full rounded-full rounded-r-none bg-gradient-to-r from-primary to-black"
										style={{ width: tierProgress }}
									></div>
								</div>
								{nextTierId && (
									<p className="mt-1 text-sm text-gray-400">
										Current progress to {nextTierName}
									</p>
								)}
							</div>
							<div
								className="flex items-center justify-center"
								onClick={handleShowTierInfo}
							>
								<IoIosArrowForward className="h-6 w-6" />
							</div>
						</div>
					</div>
				)}
			</DropdownMenuItem>

			<DropdownMenuGroup className="flex w-full flex-col gap-1 pl-3">
				<DropdownMenuItem asChild>
					<Link to="/explore">
						<NavItem icon={FiCompass} label="Explore Projects" />
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem asChild>
					<Link to="/newsreel">
						<NavItem icon={LuNewspaper} label="Newsreels" />
					</Link>
				</DropdownMenuItem>
				{projectCount > 0 && (
					<DropdownMenuItem asChild>
						<Link to={`profile/${username}?tab=2`}>
							<NavItem icon={LuFilm} label="Your Projects" />
						</Link>
					</DropdownMenuItem>
				)}
				<DropdownMenuItem asChild>
					<Link to="/settings">
						<NavItem icon={LuSettings} label="Settings" />
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem asChild>
					<Link onClick={toggleSearchMobile} className="sm:hidden">
						<NavItem icon={CiSearch} label="Search" />
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem asChild>
					<div className="w-full">
						<Button asChild>
							<Link to="/newProject" className="min-h-11 w-full">
								Submit Your Project <FiArrowRight />
							</Link>
						</Button>
					</div>
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				{Number(isAdmin) === 1 && (
					<DropdownMenuItem asChild>
						<Link to="/tile-management">
							<NavItem icon={FaRegSquareFull} label="FET Management" />
						</Link>
					</DropdownMenuItem>
				)}
				{jwt && Number(isAdmin) === 1 && (
					<DropdownMenuItem asChild>
						<a
							href={`${adminBaseUrl}/admin/auth/${jwt}`}
							target="_blank"
							rel="noreferrer"
							onClick={handleClose}
						>
							<NavItem icon={FiUser} label="Platform Administration" />
						</a>
					</DropdownMenuItem>
				)}
				<DropdownMenuItem asChild>
					<Link onClick={logout}>
						{isTokenSaleWithoutAccess ? (
							<NavItem icon={FiLogOut} label="Disconnect" />
						) : (
							<NavItem icon={FiLogOut} label="Logout" />
						)}
					</Link>
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				<DropdownMenuItem asChild>
					<a
						href={`${helpBaseUrl}/knowledgebase/welcome/what-is-film.io`}
						target="_blank"
						rel="noreferrer"
						onClick={handleClose}
					>
						<NavItem icon={FiLifeBuoy} label="Get Help" />
					</a>
				</DropdownMenuItem>
			</DropdownMenuGroup>
		</>
	)
}
