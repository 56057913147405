import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow
} from '@/components/ui/table'
import { useEffect, useState } from 'react'
import {
	Pagination,
	PaginationContent,
	PaginationFirst,
	PaginationItem,
	PaginationLast,
	PaginationNext,
	PaginationPrevious
} from '@/components/ui/pagination'
import { StakeForm } from './StakeForm/StakeForm'
import { ShareForm } from './ShareForm'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getUserSuperFanProjectsService } from 'services/superfan.service'

const ProjectRow = ({ project }) => {
	return (
		<>
			<TableRow className="border-b border-gray-700">
				<TableCell className="w-32 min-w-32 p-4">
					<div className="flex flex-row justify-between gap-4">
						{project.poster ? (
							<img
								className="rounded-sm"
								src={project.poster}
								alt="poster"
								width={20}
								height={24}
							/>
						) : (
							<span className="flex !h-6 w-5 !min-w-5 items-center justify-center rounded-sm bg-white text-xs font-bold text-background"></span>
						)}
						<span className="flex w-full justify-start">{project?.name}</span>
					</div>
				</TableCell>
				<TableCell className="p-4">{project.place}</TableCell>
				<TableCell className="p-4">{project.lvl}</TableCell>
				<TableCell className="p-4">{project.staked}</TableCell>
				<TableCell className="p-4">{project.fgrPlus}</TableCell>
				<TableCell className="p-4">
					<div className="flex space-x-2">
						<ShareForm project={project} />
						<StakeForm
							selectedProject={{
								id: project.projectId,
								title: project.name,
								stageId: project.stageId,
								user: { id: project.userId }
							}}
						/>
					</div>
				</TableCell>
			</TableRow>
		</>
	)
}

export const SuperFanPerformance = () => {
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const [data, setData] = useState([])

	const afterFetchProjects = (response, error) => {
		if (!error) {
			setTotalPages(Math.ceil(response?.count / 5))
			setData(response?.rows)
		}
		hideMask()
	}

	const getProjects = page => {
		showMask()

		axiosService(
			getUserSuperFanProjectsService({
				limit: 5,
				offset: 5 * page - 5
			}),
			afterFetchProjects
		)
	}

	const handlePageChange = page => {
		setCurrentPage(page)
	}

	useEffect(() => {
		getProjects(currentPage)
	}, [currentPage])

	return (
		<div className="custom-scrollbar p-4">
			<div className="text-lg font-bold">SuperFan Performance</div>

			<>
				<Table className="w-full px-4 text-left text-sm">
					<TableHeader>
						<TableRow>
							<TableHead className="p-4">NAME</TableHead>
							<TableHead className="p-4">PLACE</TableHead>
							<TableHead className="p-4">LVL</TableHead>
							<TableHead className="p-4">STAKED</TableHead>
							<TableHead className="p-4">FGR+</TableHead>
							<TableHead className="p-4"></TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{data.map((row, index) => (
							<ProjectRow key={index} project={row} />
						))}
					</TableBody>
				</Table>

				{data.length === 0 && (
					<div className="space-y-2 py-4 text-base leading-snug">
						<div className="text-lg">Become a SuperFan!</div>
						<div className="text-balance text-muted-foreground">
							Support your favorite projects and unlock exclusive benefits.
							Stake tokens to become a superfan and track your performance here.
						</div>
					</div>
				)}

				{/* Pagination */}
				{data.length > 0 && (
					<div className="flex items-center justify-between p-4">
						<Pagination>
							<PaginationContent>
								<PaginationItem>
									<PaginationPrevious
										onClick={() =>
											handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
										}
										className={currentPage == 1 && 'opacity-50'}
									/>
									<PaginationFirst
										onClick={() => handlePageChange(currentPage > 1 ? 1 : 1)}
										className={currentPage == 1 && 'opacity-50'}
									/>
								</PaginationItem>
								<PaginationItem>
									Page {currentPage} of {totalPages}
								</PaginationItem>

								<PaginationItem>
									<PaginationLast
										onClick={() =>
											handlePageChange(
												currentPage < totalPages ? totalPages : totalPages
											)
										}
										disabled={currentPage === totalPages}
										className={currentPage == totalPages && 'opacity-50'}
									/>
									<PaginationNext
										onClick={() =>
											handlePageChange(
												currentPage < totalPages ? currentPage + 1 : totalPages
											)
										}
										disabled={currentPage === totalPages}
										className={currentPage == totalPages && 'opacity-50'}
									/>
								</PaginationItem>
							</PaginationContent>
						</Pagination>
					</div>
				)}
			</>
		</div>
	)
}
