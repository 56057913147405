import { useDispatch, useSelector } from 'react-redux'
import Chip from '@mui/material/Chip'
import { removeFilter } from '../dashboardSlice'
import styles from './FilteredDataChip.module.scss'

const FilteredDataChip = () => {
	const dispatch = useDispatch()
	const dashboard = useSelector(state => state.dashboard)

	const handleRemoveFilter = (option, id) => () => {
		dispatch(removeFilter({ option, id }))
	}

	const renderChips = option => {
		return dashboard.filter[option].map(id => {
			const item = dashboard[option].find(el => el.id === id)
			return (
				<Chip
					key={`${option}-${id}`}
					label={item?.name}
					className="dashboard-chip"
					onClick={handleRemoveFilter(option, id)}
					onDelete={handleRemoveFilter(option, id)}
				/>
			)
		})
	}

	return (
		<div className={styles.filtered_data_chip}>
			<ul>
				{renderChips('stages')}
				{renderChips('genres')}
				{renderChips('types')}
			</ul>
		</div>
	)
}

export default FilteredDataChip
