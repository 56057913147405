import { Button } from '@/components/ui/button'
import { useEffect, useState } from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { useDispatch } from 'react-redux'
import { IoArrowForward } from 'react-icons/io5'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getCarouselsService } from 'services/fanDashboard.service'
import { useNavigate } from 'react-router-dom'
import { setOnRampDialogOpen } from '../../../../../header/signInSlice'

export const Carousel = () => {
	const { axiosService } = useAxios()
	const navigate = useNavigate()
	const { showMask, hideMask } = useLoadingMask()
	const [data, setData] = useState([])
	const dispatch = useDispatch()

	const afterFetchCarousels = (response, error) => {
		if (!error) {
			setData(response)
		}
		hideMask()
	}

	const getCarousels = () => {
		showMask()

		axiosService(getCarouselsService(), afterFetchCarousels)
	}

	useEffect(() => {
		getCarousels()
	}, [])

	const handelClickButton = item => {
		switch (item.id) {
			case 1: // TODO open Fan Purchase Modal
			case 2:
				navigate('/explore')
				break
			case 4:
				dispatch(setOnRampDialogOpen(true))
				break
			default:
				navigate('/explore')
		}
	}
	return (
		<div className="flex w-full gap-4 overflow-x-auto overflow-y-hidden py-2">
			{data.map((item, index) => (
				<div key={index} className="shrink-0 basis-80">
					<Card
						className={`w-full ${
							index == 0 ? 'bg-border' : '!bg-transparent'
						} ${
							index !== 0 ? 'border' : 'border-0'
						} h-full rounded-lg border-border shadow-sm`}
					>
						<CardContent className="flex h-[216px] w-full flex-col items-start justify-between gap-1.5 !rounded-lg !p-6">
							<div className="flex flex-col items-start justify-between gap-1.5">
								<div className="truncate text-xl font-semibold tracking-[-0.025em] text-table-foreground">
									{item?.name}
								</div>
								<span
									dangerouslySetInnerHTML={{ __html: item?.content }}
									className="text-sm font-normal leading-[19px] text-table-foreground"
								></span>
							</div>
							<div className="flex w-full items-center justify-end">
								<Button
									variant={index == 0 ? 'default' : 'table'}
									onClick={() => handelClickButton(item)}
								>
									{item?.cta} <IoArrowForward />
								</Button>
							</div>
						</CardContent>
					</Card>
				</div>
			))}
		</div>
	)
}
