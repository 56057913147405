import { Button, Grid } from '@mui/material'
import {
	ChipSelectBox,
	SelectBox,
	TextArea,
	TextFieldControl
} from 'component/common'
import HtmlEditor from 'component/common/htmlEditor/HtmlEditor'
import useProjectAddWizard from '../useProjectAddWizard'
import styles from './DetialsPage.module.scss'

const DetialsPage = () => {
	const {
		types,
		genres,
		errors,
		project,
		handleChange,
		handleNextPage,
		handleOpenConfirmModal
	} = useProjectAddWizard(true)

	return (
		<Grid container className={styles.detail_page}>
			<Grid item md={12} className={styles.edit_form} container spacing={4}>
				<Grid item xs={12} md={12}>
					<TextFieldControl
						error={errors?.title}
						label="Title*"
						value={project.title || ''}
						variant={'blue'}
						autoFocus
						onChange={e => {
							handleChange({ title: e.target.value })
						}}
					/>
				</Grid>

				<Grid item xs={12} md={12} container spacing={2}>
					<Grid item xs={12} md={4}>
						<SelectBox
							{...{
								options: types,
								value: project?.typeId,
								variant: 'blue',
								placeholder: 'Project Type',
								label: 'Project Type',
								setValue: value => {
									handleChange({
										typeId: value
									})
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} md={8}>
						<ChipSelectBox
							{...{
								values: project.genres,
								variant: 'blue',
								label: 'Genres',
								options: genres ?? [],
								placeholder: 'Your Genres. Choose up to 3',
								helperText: 'Choose up to 3',
								setValues: values => {
									handleChange({ genres: values })
								}
							}}
						/>
					</Grid>
				</Grid>

				<Grid item xs={12} md={12}>
					<TextArea
						error={errors.logline}
						variant={'blue'}
						minRows={5}
						label="Logline"
						placeholder="-- EXAMPLE LOGLINE -- Wounded and on the run, notorious gunman Quirt Evans (John Wayne) gallops onto a farm owned by Quaker Thomas Worth (John Halloran) and his family and collapses. When Quirt urgently insists upon sending a telegram, Thomas and his daughter Penelope (Gail Russell) drive him into town in their wagon. -- EXAMPLE LOGLINE --"
						aria-label="logline"
						helperText="Your story’s synopsis"
						value={project?.logline ?? ''}
						onChange={e => {
							handleChange({
								logline: e.target.value
							})
						}}
					/>
				</Grid>

				<Grid item xs={12} md={12}>
					<HtmlEditor
						{...{
							error: errors.story,
							label: 'Story',
							helperText:
								'The project story is a place to get your fans excited about your project and your journey as a filmmaker. (You can use some HTML in your description as well as add images.)',
							value: project?.story,
							setValue: value => {
								handleChange({
									story: value
								})
							}
						}}
					/>
				</Grid>
			</Grid>
			<Grid md={12} className={styles.actions} item>
				<Button onClick={handleOpenConfirmModal}>Cancel</Button>
				<Button
					id="saveButton"
					className="yellow-bg-button"
					onClick={handleNextPage}
				>
					{'Save & Continue'}
				</Button>
			</Grid>
		</Grid>
	)
}

export default DetialsPage
