import axios from 'axios'

const buidTileFormData = data => {
	const formData = new FormData()
	const projectFields = [
		'id',
		'name',
		'content',
		'poster',
		'link',
		'startDate',
		'endDate',
		'projectId',
		'tileTypeId',
		'isExpired',
		'showOnFanDashboard',
		'cta'
	]

	const files = ['poster']

	projectFields.forEach(key => {
		if (data[key] instanceof File) {
			formData.append(key, data[key], data[key].name)
		} else if (!files.includes(key) && data[key]) {
			formData.append(key, data[key])
		}
	})
	return formData
}

export const addTileService = data => {
	const formData = buidTileFormData(data)
	return {
		method: 'post',
		url: '/tile',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}
}

export const updateTileService = data => {
	const formData = buidTileFormData(data)
	return {
		method: 'put',
		url: `/tile/${data.id}`,
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		autoexec: false
	}
}

export const updateTilesService = data => {
	return {
		method: 'put',
		url: `/tile`,
		data,
		autoexec: false
	}
}

export const getTilesService = () => ({
	method: 'get',
	url: '/tile'
})

export const getTileByIdService = id => ({
	method: 'get',
	url: `/tile/${id}`
})

export const deleteTile = async id => {
	return await axios.delete('/tile', {
		data: { id }
	})
}
