import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	project: {}
}

export const projectEditSlice = createSlice({
	name: 'projectEdit',
	initialState,
	reducers: {
		reset: () => initialState,
		updateProject: (state, { payload }) => {
			state.project = {
				...state.project,
				...payload
			}
		}
	}
})

export const { reset, updateProject } = projectEditSlice.actions

export default projectEditSlice.reducer
