import _ from 'lodash'
import { getProfileWalletOverview, getHodl } from 'services/profile.service'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, createRef } from 'react'
import useAxios from 'hooks/useAxios'
import {
	setClaimWalletDialogOpen,
	setHodl,
	setOverview
} from '../walletTabSlice'
import useHeader from 'component/header/useHeader'
import useWeb3 from 'hooks/useWeb3'
import {
	setWhitelistTransferDialogOpen,
	updateWhitelistWalletDialogData
} from 'component/profile/profileSlice'
import { formatAddress } from 'utils/utils'
import useNotification from 'hooks/useNotification'
import {
	setCongratsDialogOpen,
	setCreateMagicWalletDialogOpen
} from 'component/header/signInSlice'
import { removeWhitelistWalletService } from 'services/wallet.service'
import {
	customHumanDateDiff,
	getDateDiff,
	getNowDateDiff
} from 'utils/date.utils'
import useLoadingMask from 'hooks/useLoadingMask'

const useDiamondCircleHodl = () => {
	const dispatch = useDispatch()
	const {
		overview: data,
		hodl,
		reloadTransactions
	} = useSelector(state => state.walletTab)
	const { userId, whitelistTransferDialogOpen } = useSelector(
		state => state.profile
	)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { axiosService } = useAxios()
	const { relaodUserInfo } = useHeader()
	const { showNotification } = useNotification()
	const { showMask, hideMask } = useLoadingMask()
	const { signer } = useWeb3()
	const [withdrawAmount, setWithdrawAmount] = useState(0)
	const [errors, setErrors] = useState(null)

	const [openSpeedUpDialog, setOpenSpeedUpDialog] = useState(false)
	const [timeToUnlock, setTimeToUnlock] = useState('')
	const [progress, setProgress] = useState('100%')

	const inputRef = createRef()
	const isNcWithoutMagic = signInUser?.isNcWithoutMagic
	const isNonCustodial = signInUser?.isNonCustodial === 1
	const isRestricted = signInUser?.isRestricted === true
	const isWhitelisted = !_.isNil(signInUser?.whitelistedAddress)
	const whitelistAddress = formatAddress(signInUser?.whitelistedAddress)
	const walletAddress = formatAddress(signInUser?.walletAddress)
	const isAbleToWithdraw = Number(signInUser?.balance) >= Number(withdrawAmount)
	const isHodl = signInUser?.isHodl
	const { disconnectAsync } = useWeb3()

	const handleCopyWalletAddress = () => {
		navigator.clipboard.writeText(signInUser?.walletAddress).then(() => {
			showNotification({
				message: 'Your wallet address has been copied to your clipboard.'
			})
		})
	}

	const removeWhitelistedAddress = () => {
		axiosService(removeWhitelistWalletService(), (data, error) => {
			if (!error) {
				showNotification({
					message: 'Whitelisted wallet deleted successfully.'
				})
				relaodUserInfo()
			}
		})
	}

	const handleRemoveWallet = async () => {
		await disconnectAsync()
		removeWhitelistedAddress()
	}

	const handleFocus = event => event.target.select()

	const reloadWalletOverview = () => {
		showMask()
		axiosService(getProfileWalletOverview(userId), (result, error) => {
			if (!error) {
				dispatch(setOverview(result))
			}
			hideMask()
		})
	}

	const reloadHodl = () => {
		axiosService(getHodl(), (result, error) => {
			if (!error) {
				dispatch(setHodl(result))
			}
		})
	}

	const handleOpenClaimWallet = () => {
		dispatch(setClaimWalletDialogOpen(true))
	}

	const handleOpenWhitelistTransfer = () => {
		dispatch(setWhitelistTransferDialogOpen(true))
	}

	const handleOpenWhitelistWalletDialog = () => {
		dispatch(updateWhitelistWalletDialogData({ open: true }))
	}

	const handleUnlockRewards = () => {
		dispatch(setCreateMagicWalletDialogOpen(true))
	}

	const handleOpenSpeedUp = () => {
		setOpenSpeedUpDialog(true)
	}

	const handleCloseSpeedUp = () => {
		setOpenSpeedUpDialog(false)
	}

	const calculateProgress = (startDate, endDate) => {
		if (_.isNil(startDate) || _.isNil(endDate)) return 100
		const now = new Date().toISOString()
		const nowDiffEnd = getDateDiff(now, endDate)
		const nowDiffStart = getDateDiff(now, startDate)
		const startDiffEnd = getDateDiff(startDate, endDate)
		if (nowDiffStart >= 0 || startDiffEnd === 0) return 0
		let percentage = 100 - (nowDiffEnd / startDiffEnd) * 100
		if (percentage >= 100) return 100
		if (percentage <= 0) return 0
		percentage = (Math.round(percentage * 100) / 100).toFixed(2)
		return percentage
	}

	const generateHumanDateDiff = (startDate, endDate) => {
		if (_.isNil(startDate) || _.isNil(endDate)) {
			return 'Congrats! All your Rewards are Unlocked!'
		}

		const startDiffEnd = getDateDiff(startDate, endDate)
		if (startDiffEnd === 0) {
			return ''
		} else {
			const now = new Date().toISOString()
			return customHumanDateDiff(now, endDate)
		}
	}

	const updateOverview = newData => {
		dispatch(setOverview({ ...newData }))
	}

	useEffect(() => {
		let newProgress = 100
		let remainingTimeToUnlock = ''
		if (data.earningsTotal <= 0) {
			remainingTimeToUnlock = "You haven't earned any rewards yet!"
			newProgress = 0
		} else {
			remainingTimeToUnlock = generateHumanDateDiff(
				data.unlockRewardsStartDate,
				data.unlockRewardsEndDate
			)
			newProgress = calculateProgress(
				data.unlockRewardsStartDate,
				data.unlockRewardsEndDate
			)
		}
		setProgress(newProgress)
		setTimeToUnlock(remainingTimeToUnlock)
		if (
			!_.isNil(data?.unlockRewardsEndDate) &&
			getNowDateDiff(data?.unlockRewardsEndDate).seconds >= 0
		) {
			dispatch(setCongratsDialogOpen(true))
		}
	}, [
		data.unlockRewardsStartDate,
		data.unlockRewardsEndDate,
		data.earningsTotal
	])

	useEffect(() => {
		if (!_.isNil(userId)) reloadWalletOverview()
		if (signInUser.isHodl) reloadHodl()
		if (signInUser.balance !== data.balance) relaodUserInfo()
	}, [userId, reloadTransactions])

	return {
		...data,
		...hodl,
		signer,
		isHodl,
		errors,
		openSpeedUpDialog,
		timeToUnlock,
		progress,
		inputRef,
		isRestricted,
		isWhitelisted,
		walletAddress,
		isNonCustodial,
		withdrawAmount,
		isAbleToWithdraw,
		whitelistAddress,
		isNcWithoutMagic,
		whitelistTransferDialogOpen,
		handleOpenWhitelistWalletDialog,
		handleOpenWhitelistTransfer,
		handleCopyWalletAddress,
		handleOpenClaimWallet,
		handleUnlockRewards,
		handleRemoveWallet,
		setWithdrawAmount,
		handleFocus,
		setErrors,
		updateOverview,
		handleOpenSpeedUp,
		handleCloseSpeedUp
	}
}

export default useDiamondCircleHodl
