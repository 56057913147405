export const getSuperfanLevelService = (projectId) => ({
	method: 'get',
	url: `/superfan/userlevel/${projectId}`,
	autoexec: false
})

export const getUserSuperFanProjectsService = (params) => ({
	method: 'get',
	url: `/superfan/projects`,
	params,
	autoexec: false
})
