import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { ArtworkPage } from './artworkPage'
import { DetialsPage } from './detialsPage'
import { PageIndicator } from './pageIndicator'
import { SubmitPage } from './submitPage'
import useProjectAddWizard from './useProjectAddWizard'
import { CloseWizardDialog } from './closeWizardDialog'
import styles from './ProjectAddWizard.module.scss'

const ProjectAddWizard = () => {
	const {
		savingPreview,
		handleSaveChanges,
		handleOpenConfirmModal,
		handleSavePreviewChanges,
		handleSubmitForApproval
	} = useProjectAddWizard(false)
	const [searchParams, setSearchParams] = useSearchParams()

	const page = Number(searchParams.get('page'))

	let component = <DetialsPage />
	let message = 'All fields are required'
	let pageStyle = styles.project_wizard_details

	switch (page) {
		case 1:
			message = 'Required fields are marked with a *'
			pageStyle = styles.project_wizard_details
			break
		case 2:
			component = <ArtworkPage />
			message = 'Required fields are marked with a *'
			pageStyle = styles.project_wizard_artwork
			break
		case 3:
			component = <SubmitPage />
			message =
				'Please make sure your project is ready for submission, required fields are marked with an *'
			pageStyle = styles.project_wizard
			break
	}

	useEffect(() => {
		if (page === 0) setSearchParams({ page: 1 })

		const handler = event => {
			event.preventDefault()
			event.returnValue = ''
		}

		window.addEventListener('beforeunload', handler)
		return () => {
			window.removeEventListener('beforeunload', handler)
		}
	}, [])

	return (
		<Container disableGutters maxWidth="md" className={pageStyle}>
			<CloseWizardDialog />
			<Grid item md={12} xs={12} className={styles.header}>
				<PageIndicator {...{ value: 1, title: 'Details' }} />
				<PageIndicator {...{ value: 2, title: 'Artwork & Attachments' }} />
				<PageIndicator {...{ value: 3, title: 'Review & Submit' }} />
			</Grid>
			{page === 3 && (
				<Grid item md={12} xs={12}>
					<Box item xs={12} md={6} className={styles.edit_controls}>
						<Button
							className={styles.preview_btn}
							onClick={handleSavePreviewChanges}
							startIcon={
								savingPreview && (
									<i className="fa-duotone fa-spinner-third fa-spin"></i>
								)
							}
						>
							Preview
						</Button>
						<Button
							id="saveButton"
							className={styles.save_btn}
							onClick={handleSaveChanges(true)}
							startIcon={
								savingPreview && (
									<i className="fa-duotone fa-spinner-third fa-spin"></i>
								)
							}
						>
							Save
						</Button>

						<Button
							className={styles.approvalButton}
							onClick={handleSubmitForApproval}
							id="submitApproval"
						>
							Submit for approval
						</Button>

						<Button
							className={styles.cancel_btn}
							onClick={handleOpenConfirmModal}
						>
							Exit
						</Button>
					</Box>
				</Grid>
			)}
			<Grid item xs={12} md={6} className={styles.welcome}>
				<Typography component={'h1'}>Tell us about your project</Typography>
				<Typography>{message}</Typography>
			</Grid>
			{component}
		</Container>
	)
}

export default ProjectAddWizard
