// components/layout/ContentHeader.tsx
import { Button } from '@/components/ui/button'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'

import useProfileMenu from './useProfileMenu'
import { DefaultMenu } from './defaultMenu'
import { cn } from '../../../../lib/utils'

export const ProfileMenu = ({ className, triggerClassName }) => {
	const { signInUser, stringAvatar } = useProfileMenu()

	const { avatar, daoTierRingImg, displayName } = signInUser
	return (
		<DropdownMenu className={className}>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline"
					size="icon"
					className={cn(
						'relative overflow-hidden rounded-full bg-transparent hover:bg-transparent focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-opacity-50',
						triggerClassName
					)}
				>
					<Avatar className="relative flex h-[40px] w-[40px] items-center justify-center">
						<AvatarImage src={daoTierRingImg} alt="Dao tier ring image" />
					</Avatar>
					<Avatar className="absolute flex h-[40px] w-[40px] items-center justify-center">
						{avatar ? (
							<AvatarImage src={avatar} alt={`${displayName}'s avatar`} />
						) : (
							<AvatarFallback className="text-md absolute inset-0 flex items-center justify-center font-bold text-white">
								{stringAvatar(displayName).children}
							</AvatarFallback>
						)}
					</Avatar>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				className="flex w-full flex-col items-start justify-between gap-2 p-3"
				side="left"
				align="start"
				alignOffset={20}
				sideOffset={-20}
			>
				<DefaultMenu />
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
