import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import './ForgotPassword.scss'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { sendRecoveryPasswordEmail } from 'services/email.service'
import { useState } from 'react'
import { LoadMask } from 'component/common'
import _ from 'lodash'

const ForgotPassword = () => {
	const [email, setEmail] = useState('')
	const [emailSent, setEmailSent] = useState(false)

	const afterSentEmail = (data, error) => {
		if (!error) {
			setEmailSent(true)
		}
	}

	const [{ loading }, sentEmail] = useAxiosFetch(
		sendRecoveryPasswordEmail(email),
		afterSentEmail
	)

	const handleClick = () => {
		sentEmail()
	}

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="forgot-password-section">
					<div className="forgot-form">
						<Link to="/" title="Login to the FDP" className="back">
							Back to Log in
						</Link>
						<h2>Forgot Password</h2>
						<p>We&apos;ll send a link to reset your password</p>
						<form className="forgot-password-form">
							<label>Email address</label>
							<TextField
								hiddenLabel
								fullWidth
								variant="filled"
								className="blue"
								placeholder="e.g. spiderman@gmail.com"
								InputProps={{
									disableUnderline: true
								}}
								value={email}
								onChange={e => {
									setEmail(_.trim(e.target.value))
								}}
							/>
							{emailSent && (
								<p>
									If we have an email in our system that matches, we&apos;ll
									happily send you a password reset link!
								</p>
							)}
							<Button
								className="yellow-bg-button-forgot"
								onClick={handleClick}
								disabled={emailSent}
							>
								Send Reset Link
							</Button>
						</form>
					</div>
				</div>
			)}
		</>
	)
}

export default ForgotPassword
