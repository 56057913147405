import heartEye from './heart-eye.png'
import heart from './heart.png'
import lol from './lol.png'
import thinking from './thinking.png'
import wow from './wow.png'

const REACTIONS = {
	heartEye: heartEye.src,
	heart: heart.src,
	lol: lol.src,
	thinking: thinking.src,
	wow: wow.src
}

export default REACTIONS
