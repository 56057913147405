import _ from 'lodash'
import { updateSignInUser } from 'component/header/signInSlice'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useNotification from 'hooks/useNotification'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
	editProfileService,
	getProfileEditUserById
} from 'services/profile.service'
import { getSocialNetworks } from 'services/seed.service'
import { updateProfile } from './profileEditSlice'
import { isValidEmail } from 'utils/form.util'
import { updateMetaTags } from 'utils/utils'
import {
	updateVerificationEmailData,
	updateMagicEmailUpdateDialogData
} from 'component/profile/profileSlice'

const useProjectEdit = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { profile } = useSelector(state => state.profileEdit)
	const { user } = useSelector(state => state.signIn)
	const [headerImg, setHeaderImg] = useState(null)
	const [avatar, setAvatar] = useState(null)
	const [errors, setErrors] = useState({})
	const [social, setSocial] = useState({
		username: '',
		socialNetworkId: ''
	})
	const { userId } = useParams()
	const { showNotification } = useNotification()

	const [{ response: socialNetworks, loading: loadingSocialNetworks }] =
		useAxiosFetch(getSocialNetworks())

	const [{ loading }] = useAxiosFetch(
		getProfileEditUserById(userId),
		afterFetchProfile
	)

	function afterFetchProfile(data, error) {
		if (!error) {
			if (data?.isNonCustodial === 1 && data?.isEmailVerified === 0) {
				data.email = ''
			}
			dispatch(updateProfile(data))
			updateMetaTags({
				title: `Edit ${data?.displayName} — Filmio`
			})
		}
	}

	const [{ loading: saving }, saveChanges] = useAxiosFetch(
		editProfileService({
			...profile,
			headerImg,
			avatar
		}),
		afterSaveChanges
	)

	function afterSaveChanges(userUpdated, error, responseError) {
		if (!error) {
			showNotification({ message: 'Profile updated successfully' })
			navigate(`/profile/${userUpdated?.username}`)
			if (user.id === userId) {
				localStorage.setItem('avatar', userUpdated?.avatar)
				localStorage.setItem('displayName', userUpdated?.displayName)
				dispatch(updateSignInUser(userUpdated))
			}
		} else {
			if (_.get(responseError, ['response', 'status']) === 409) {
				const responseMsg = _.get(
					responseError,
					['response', 'data', 'message'],
					''
				)
				if (responseMsg.includes('email'))
					setErrors({ email: 'This email is alreay in use' })
				if (responseMsg.includes('username'))
					setErrors({ username: 'This username is alreay in use' })
			}
		}
	}

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const isValid = () => {
		let isValid = true
		const errorsTmp = {}
		const message = 'This field is required'

		if (_.isNil(profile.displayName) || profile.displayName.trim() === '') {
			errorsTmp.displayName = message
			isValid = false
		}
		if (_.isNil(profile.firstName) || profile.firstName.trim() === '') {
			errorsTmp.firstName = message
			isValid = false
		}
		if (_.isNil(profile.email) || profile.email.trim() === '') {
			errorsTmp.email = message
			isValid = false
		}
		if (_.isNil(profile.lastName) || profile.lastName.trim() === '') {
			errorsTmp.lastName = message
			isValid = false
		}
		if (_.isNil(profile.username) || profile.username.trim() === '') {
			errorsTmp.username = message
			isValid = false
		}
		if (!_.isNil(profile.email) && !isValidEmail(profile.email)) {
			errorsTmp.email = 'The email format is incorrect.'
			isValid = false
		}
		if (
			_.isNil(profile.about) ||
			profile.about.trim() === '' ||
			profile.about === '<p><br></p>'
		) {
			errorsTmp.about = message
			isValid = false
		}
		updateErrors(errorsTmp)
		return isValid
	}

	const handleChange = payload => {
		const key = Object.keys(payload)[0]

		if (_.isString(payload[key])) {
			if (payload[key].trim() === '') payload[key] = null
		} else {
			if (payload[key] === '') payload[key] = null
		}
		updateErrors({ [key]: false })
		dispatch(updateProfile(payload))
	}

	const handleSaveChanges = () => {
		if (isValid()) saveChanges()
		else
			showNotification({
				message: 'Please fix the form errors to save changes',
				type: 'error'
			})
	}

	const handleCancel = () => {
		navigate(-1)
	}

	const handleAddSocial = () => {
		let socialNetworks = [...profile?.socialNetworks]
		if (
			socialNetworks.some(el => el.socialNetworkId === social.socialNetworkId)
		) {
			socialNetworks = socialNetworks.map(sn =>
				sn.socialNetworkId === social.socialNetworkId ? social : sn
			)
		} else {
			socialNetworks.push(social)
		}
		dispatch(updateProfile({ socialNetworks }))
	}

	const handleRemoveSocial = id => {
		const socialNetworks = (profile?.socialNetworks || []).filter(
			el => el.socialNetworkId !== id
		)
		dispatch(updateProfile({ socialNetworks }))
	}

	const handleOpenVerificationEmailModal = async () => {
		if (user?.isEmailVerified !== 1) {
			dispatch(updateVerificationEmailData({ open: true }))
		} else {
			dispatch(updateMagicEmailUpdateDialogData({ open: true }))
		}
	}

	const showMask = loading || loadingSocialNetworks || saving

	return {
		user,
		social,
		saving,
		errors,
		profile,
		showMask,
		socialNetworks: socialNetworks?.data,
		handleOpenVerificationEmailModal,
		handleRemoveSocial,
		handleSaveChanges,
		handleAddSocial,
		handleCancel,
		handleChange,
		setHeaderImg,
		setSocial,
		setAvatar
	}
}

export default useProjectEdit
