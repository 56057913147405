import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	reactions: [],
	requestSent: false
}

export const reactionSlice = createSlice({
	name: 'reaction',
	initialState,
	reducers: {
		resetReactions: () => initialState,
		populateReactions: (state, { payload }) => {
			state.reactions = payload
		},
		setRequestSent: (state, { payload }) => {
			state.requestSent = payload
		}
	}
})

export const { resetReactions, populateReactions, setRequestSent } =
	reactionSlice.actions

export default reactionSlice.reducer
