'use client'

import App from './App'
import { ContextProviders } from './ContextProviders'

const ClientApp = () => {
	return (
		<ContextProviders>
			<App />
		</ContextProviders>
	)
}

export default ClientApp
