import _ from 'lodash'
import PropTypes from 'prop-types'
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Icon,
	IconButton
} from '@mui/material'
import {
	dashboardItemTypes as dashboardItemTypesDefault,
	SelectBox,
	LightTooltip
} from 'component/common'
import { SearchFilter } from 'component/dashboard/searchFilter'
import useSidebarDashboard from '../useSidebarDashboard'
import { createSelectOptions } from 'utils/utils'
import staffPickImg from 'assets/images/staff_pick.svg'
import styles from './SidebarMenu.module.scss'
import { ActiveContestBadge } from '../../contests/badge/ActiveContestBadge'

export default function SidebarMenu({ handleClose }) {
	const {
		sort,
		stages,
		genres,
		filter,
		activeContests,
		handleFilterChange,
		handleSortChange,
		isAuthenticated
	} = useSidebarDashboard()
	const dashboardItemTypes = dashboardItemTypesDefault.filter(item => {
		return !item.requiresAuth || isAuthenticated
	})
	const dashboardItemTypesUpdated =
		activeContests.length > 0
			? dashboardItemTypes
			: dashboardItemTypes.filter(el => el.id !== 'contest')

	const sortOptions = createSelectOptions([
		'Newest',
		'Trending',
		'Most Stakers'
	])

	const isChecked = (option, value) => filter[option].includes(value)

	const getClassName = (option, value) =>
		isChecked(option, value)
			? `${styles.only_show_label} ${styles.active}`
			: styles.only_show_label

	const isFilterEmpty =
		_.isEmpty([...filter?.types, ...filter?.stages, ...filter?.genres]) ||
		filter?.types.includes('dao')

	const renderOptions = (option, el) => {
		let cat = null
		let tTip = null

		switch (el?.label) {
			case 'adoption':
				cat = <span className={styles.adoption}></span>
				break
			case 'distribution':
				cat = <span className={styles.distro}></span>
				break
			case 'development':
				cat = <span className={styles.dev}></span>
				break
		}

		if (option === 'stages' && el.id === 1) return ''
		let icon = null

		if (el?.icon)
			icon = <Icon baseClassName="fal" className={`fa-${el?.icon}`} />
		if (option === 'types' && el.id === 'staff_picks')
			icon = <img src={staffPickImg.src} />
		if (option === 'types' && el.id === 'contest')
			icon = <ActiveContestBadge src={_.get(activeContests, [0, 'badge'])} />
		if (option === 'types' && el.tooltip)
			tTip = (
				<LightTooltip
					enterTouchDelay={0}
					leaveTouchDelay={4000}
					title={el.tooltip}
				>
					<span className={styles.helpIcon} aria-haspopup="true">
						<Icon baseClassName="fal" className="fa-circle-question" />
					</span>
				</LightTooltip>
			)

		return (
			<Box
				id={el?.label}
				className={styles.menu_item}
				key={`${option}-${el.id}`}
			>
				{icon}
				{cat}
				<FormControlLabel
					className={getClassName(option, el.id)}
					control={
						<Checkbox
							checked={isChecked(option, el.id)}
							onChange={handleFilterChange(option, el.id)}
							name={el?.name}
						/>
					}
					label={el?.name}
				/>{' '}
				{tTip}
			</Box>
		)
	}

	return (
		<Box className={styles.filters_item}>
			<IconButton
				size="small"
				onClick={handleClose}
				className={styles.close_btn}
			>
				<Icon baseClassName="fal" className="fa-arrow-left" />
			</IconButton>

			{!isFilterEmpty && (
				<>
					<h3 className={styles.mt_30}>Sort</h3>
					<Box className={styles.select_box}>
						<i className="fa-thin sorter fa-sort"></i>
						<SelectBox
							{...{
								options: sortOptions,
								value: sort || '',
								variant: 'blue_sidebar',
								setValue: handleSortChange,
								placeholder: 'Sort By'
							}}
						/>
					</Box>
				</>
			)}

			<h3>Type</h3>
			<FormGroup>
				{dashboardItemTypesUpdated.map(el => renderOptions('types', el))}
			</FormGroup>
			<h3>Go Score™ Stages</h3>
			<FormGroup>
				{(stages || []).map(el => renderOptions('stages', el))}
			</FormGroup>
			<h3>Genres</h3>
			<div className={styles.search_filter}>
				{genres && (
					<SearchFilter
						{...{
							genres,
							renderOptions
						}}
					/>
				)}
			</div>
		</Box>
	)
}

SidebarMenu.propTypes = {
	handleClose: PropTypes.func
}
