import { useDispatch, useSelector } from 'react-redux'
import { clearFilters, updateActiveFilters } from '../newsreelPageSlice'

const useSideBarNewsreel = () => {
	const dispatch = useDispatch()
	const { activeFilters } = useSelector(state => state.newsreelPage)

	const handleFilterChange = value => () => {
		let options = [...activeFilters]
		if (activeFilters.includes(value)) {
			options = options.filter(el => el !== value)
		} else {
			options.push(value)
		}
		dispatch(updateActiveFilters(options))
	}

	const clearFilter = () => {
		dispatch(clearFilters())
	}

	return {
		activeFilters,
		clearFilter,
		handleFilterChange
	}
}

export default useSideBarNewsreel
