import { createRef, useEffect, useCallback } from 'react'
import * as DOMPurify from 'dompurify'
import {
	Box,
	Button,
	Container,
	Grid,
	Icon,
	IconButton,
	Typography
} from '@mui/material'
import {
	CropAvatar,
	LoadMask,
	SelectBox,
	TextFieldControl
} from 'component/common'
import HtmlEditor from 'component/common/htmlEditor/HtmlEditor'
import { CropImage } from '../common/cropImage'
import useProjectEdit from './useProfileEdit'
import { formatDate } from 'utils/date.utils'
import styles from './ProfileEdit.module.scss'
import _ from 'lodash'

const ProjectEdit = () => {
	const {
		errors,
		social,
		profile,
		showMask,
		socialNetworks,
		handleOpenVerificationEmailModal,
		handleSaveChanges,
		handleRemoveSocial,
		handleAddSocial,
		setHeaderImg,
		handleChange,
		handleCancel,
		setAvatar,
		setSocial
	} = useProjectEdit()

	const displayNameRef = createRef()

	useEffect(() => {
		if (
			displayNameRef.current !== null &&
			(errors.displayName ||
				errors.firstName ||
				errors.lastName ||
				errors.email ||
				errors.about ||
				errors.username)
		)
			displayNameRef.current.scrollIntoView()
	}, [errors])

	const renderSocials = (userSocialNetworks = []) => {
		const icons = socialNetworks.reduce((prev, el) => {
			prev[el.id] = el.icon
			return prev
		}, {})
		return userSocialNetworks.map(sn => {
			const icon = icons[sn.socialNetworkId]
			return (
				<Box className={styles.social_item} key={`sn-${sn.socialNetworkId}`}>
					<Icon baseClassName="fab" className={`fa-${icon}`} />
					<Typography component="span">@{sn.username}</Typography>
					<IconButton
						size="small"
						onClick={() => handleRemoveSocial(sn.socialNetworkId)}
					>
						<Icon baseClassName="fas" className="fa-duotone fa-trash-can" />
					</IconButton>
				</Box>
			)
		})
	}

	const handleSanitizeChange = useCallback(
		(field, value) => {
			if (value.startsWith('javascript:')) {
				handleChange({ [field]: null })
				return null
			}
			const sanitized = DOMPurify.sanitize(value)
			handleChange({ [field]: sanitized })
		},
		[DOMPurify]
	)

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<Container disableGutters maxWidth="md" className={styles.profile_edit}>
					<Grid container spacing={4}>
						<Grid item xs={12} md={6} className={styles.welcome}>
							<h1>Tell us about yourself</h1>
							<p>
								Please make sure your profile is as complete as possible,
								required fields are marked with an *
							</p>
						</Grid>
						<Grid item xs={12} md={6} className={styles.edit_controls}>
							<Button className="yellow-bg-button" onClick={handleSaveChanges}>
								Save
							</Button>
							<Button className={styles.cancel_btn} onClick={handleCancel}>
								Exit
							</Button>
						</Grid>
						<Grid item xs={12}>
							<CropImage
								{...{
									setCroppedImage: setHeaderImg,
									defaultImage: profile?.headerImg
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={7}
							className={styles.edit_form}
							container
							spacing={4}
						>
							<Grid item xs={12} className={styles.email_box}>
								<TextFieldControl
									error={errors?.email}
									label="Email"
									value={profile.email || ''}
									variant={'blue'}
									onChange={e => {
										handleChange({ email: _.trim(e.target.value) })
									}}
									disabled={true}
								/>
								<IconButton
									aria-label="Edit Email"
									size="small"
									onClick={handleOpenVerificationEmailModal}
									className={styles.edit_email}
								>
									<Icon baseClassName="fal" className="fa-pen" />
								</IconButton>
							</Grid>
							<Grid item xs={12} md={6} ref={displayNameRef}>
								<TextFieldControl
									error={errors?.displayName}
									label="Display Name*"
									value={profile.displayName || ''}
									variant={'blue'}
									autoFocus
									onChange={e => {
										handleChange({ displayName: e.target.value })
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextFieldControl
									error={errors?.username}
									label="Username"
									value={profile?.username || ''}
									variant={'blue'}
									onChange={e => {
										handleChange({ username: e.target.value })
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextFieldControl
									error={errors?.firstName}
									label="First Name*"
									value={profile.firstName || ''}
									variant={'blue'}
									onChange={e => {
										handleChange({ firstName: e.target.value })
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextFieldControl
									error={errors?.lastName}
									label="Last Name*"
									value={profile.lastName || ''}
									variant={'blue'}
									onChange={e => {
										handleChange({ lastName: e.target.value })
									}}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<HtmlEditor
									{...{
										label: 'About*',
										error: errors?.about,
										value: profile?.about,
										setValue: value => {
											handleSanitizeChange('about', value)
										}
									}}
								/>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
							md={5}
							className={styles.edit_form}
							container
							spacing={4}
						>
							<Grid item xs={12} className={styles.preview_container}>
								<div className={styles.form_label}>
									<h3>Avatar (required)</h3>
								</div>
								<CropAvatar
									{...{
										setCroppedImage: setAvatar,
										defaultImage: profile?.avatar
									}}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<TextFieldControl
									label="Birthday"
									value={formatDate(profile.birthday, 'inputDate') || ''}
									variant={'blue'}
									type="date"
									onChange={e => {
										handleChange({ birthday: e.target.value })
									}}
								/>
							</Grid>

							<Grid item xs={12} md={12}>
								<TextFieldControl
									label="Website"
									value={profile.website || ''}
									placeholder="https://website.com"
									variant={'blue'}
									onChange={e =>
										handleSanitizeChange('website', e.target.value)
									}
								/>
							</Grid>

							<Grid item xs={12} container spacing={2}>
								<Grid item xs={12} md={5}>
									<SelectBox
										{...{
											options: socialNetworks,
											value: social.socialNetworkId,
											variant: 'blue',
											label: 'Socials',
											setValue: value => {
												setSocial({
													...social,
													socialNetworkId: value
												})
											}
										}}
									/>
								</Grid>

								<Grid item xs={11} md={6} className={styles.icon_container}>
									<TextFieldControl
										label="&#8203;"
										placeholder="username"
										value={social.username}
										variant={'blue'}
										onChange={e => {
											setSocial({
												...social,
												username: e.target.value.replace('@', '')
											})
										}}
									/>
									<IconButton size="small" onClick={handleAddSocial}>
										<Icon baseClassName="fas" className="fa-solid fa-plus" />
									</IconButton>
								</Grid>

								<Grid item xs={12}>
									{renderSocials(profile?.socialNetworks, socialNetworks)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={12} className={styles.edit_controls_bottom}>
							<Button className="yellow-bg-button" onClick={handleSaveChanges}>
								Save
							</Button>
							<Button className={styles.cancel_btn} onClick={handleCancel}>
								Exit
							</Button>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	)
}

export default ProjectEdit
