import { useSelector } from 'react-redux'
import styles from './LoadMask.module.scss'

export const LoadMask = () => {
	const { loadingMessage } = useSelector(state => state.signIn)
	const renderMessages = (message = '') =>
		message.split('\n').map(el => <p key={el}>{el}</p>)
	return (
		<div className={styles.loader}>
			<h1 className="animated_fast fadeIn">
				<div id={styles.loader}></div>
			</h1>
			{loadingMessage && (
				<div className={styles.loader_text}>
					<div>{renderMessages(loadingMessage)}</div>
				</div>
			)}
		</div>
	)
}
