import useAxiosFetch from 'hooks/useAxiosFetch'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getDashboardService } from 'services/dashboard.service'

const useTopGenres = () => {
	const [projects, setProjects] = useState([])
	const filter = {
		types: [],
		stages: [],
		genres: [],
		sort: 'trending',
		page: 0
	}
	const { project, projectId } = useSelector(state => state.projectDetail)

	const afterFetch = (data, error) => {
		if (!error) {
			const getProjects = data =>
				data
					.filter(p => {
						return p.id !== projectId
					})
					.slice(0, 4)

			const projs = getProjects(data)
			setProjects(projs)
		}
	}

	const [{ loading }] = useAxiosFetch(
		getDashboardService(
			{
				...filter,
				genres: project?.genres?.map(el => el.id)
			},
			true
		),
		afterFetch
	)

	return {
		loading,
		projects
	}
}

export default useTopGenres
