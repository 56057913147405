import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Icon, IconButton } from '@mui/material'
import {
	SnapList,
	SnapItem,
	useScroll,
	useDragToScroll,
	useVisibleElements
} from 'react-snaplist-carousel'
import badgeLocked from 'assets/images/badge-locked.png'
import { GoScoreBadge } from '../goScoreBadge/GoScoreBadge'
import styles from './GoScoreBadges.module.scss'

export const GoScoreBadgesStatic = ({ badges }) => {
	const snapList = useRef(null)

	const visible = useVisibleElements(
		{ debounce: 5, ref: snapList },
		([element]) => element
	)
	const goToSnapItem = useScroll({ ref: snapList })
	useDragToScroll({ ref: snapList, disabled: false })

	const handleNextClick = () => {
		goToSnapItem(visible + 5)
	}

	const handlePrevClick = () => {
		goToSnapItem(visible > 5 ? visible - 5 : 0)
	}

	const renderItems = () => {
		return badges.map(badge => {
			const image = badge.completed ? badge.image : badgeLocked
			return (
				<SnapItem key={`badge-${badge?.id}`} className={styles.snap_item}>
					<GoScoreBadge
						{...{
							...badge,
							image
						}}
					/>
				</SnapItem>
			)
		})
	}
	return (
		<Box className={styles.badges}>
			{visible > 0 && (
				<IconButton
					size="small"
					onClick={handlePrevClick}
					className={styles.prev}
				>
					<Icon baseClassName="fas" className="fa-chevron-left" />
				</IconButton>
			)}

			<SnapList ref={snapList} tabIndex={0}>
				{renderItems()}
			</SnapList>
			{visible < badges.length - 5 && (
				<IconButton
					size="small"
					onClick={handleNextClick}
					className={styles.next}
				>
					<Icon baseClassName="fas" className="fa-chevron-right" />
				</IconButton>
			)}
		</Box>
	)
}

GoScoreBadgesStatic.propTypes = {
	badges: PropTypes.array
}
