import { TierItem } from './tierItem'
import { Progress } from '@/components/ui/progress'
import useDaoTiers from './useDaoTiers'

const DaoTiers = () => {
	const { tiers, userMetrics, globalProgress, showDAO } = useDaoTiers()
	const renderTiers = () => {
		return tiers.map((tier, i) => (
			<TierItem
				key={`tier-${i}`}
				className={'flex-1 flex-shrink-0'}
				{...{
					...tier,
					userMetrics
				}}
			/>
		))
	}

	return (
		<div className="relative isolate w-full flex-1">
			<Progress
				value={globalProgress}
				className="absolute left-[10%] right-[10%] top-2 w-auto rounded-full"
			/>
			<div className="flex items-center justify-between">{renderTiers()}</div>
		</div>
	)
}

export default DaoTiers
