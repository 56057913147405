import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	projects: { trending: [], mostStakes: [] },
	isGoScoreV2Enabled: !!localStorage.getItem('isGoScoreV2Enabled')
}

export const homeSlice = createSlice({
	name: 'home',
	initialState,
	reducers: {
		setProjects: (state, { payload }) => {
			state.projects = payload
		},
		setIsGoScoreV2Enabled: (state, { payload }) => {
			state.isGoScoreV2Enabled = payload
		}
	}
})

export const { setProjects, setIsGoScoreV2Enabled } = homeSlice.actions

export default homeSlice.reducer
