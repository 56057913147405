import {
	FormControl,
	FormHelperText,
	TextField,
	Typography
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styles from './TextFieldControl.module.scss'

const TextFieldControl = ({
	helperTextRight,
	label,
	variant = 'black',
	error,
	...props
}) => {
	const classesArr = [styles.form_control, styles[variant]]
	if (error) {
		props.helperText = error
		classesArr.push(styles.error)
	}
	const classes = classesArr.join(' ')
	return (
		<FormControl className={classes} hiddenLabel>
			{label && <Typography component={'h3'}>{label}</Typography>}
			<TextField
				error={!_.isNil(error)}
				hiddenLabel
				fullWidth
				variant="filled"
				classes={{ root: styles.text_field }}
				InputProps={{
					disableUnderline: true
				}}
				label=""
				{...props}
			/>
			{helperTextRight && (
				<FormHelperText className={styles.helper_text_right}>
					{helperTextRight}
				</FormHelperText>
			)}
		</FormControl>
	)
}

TextFieldControl.propTypes = {
	helperTextRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	variant: PropTypes.string,
	label: PropTypes.string,
	props: PropTypes.object
}

export default TextFieldControl
