import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@mui/material'
import { VoterItem } from './voterItem'
import styles from './VotersList.module.scss'
import useVotersList from './useVotersList'

const VotersList = ({ daoProposalId }) => {
	const { items, loading, loadMore, handleLoadMore } =
		useVotersList(daoProposalId)

	const renderItems = () => {
		return items.map(el => {
			const {
				id,
				daoProposal,
				accepted,
				votingWeight,
				blockchainHash,
				statusId,
				userId,
				daoProposalId
			} = el
			const { avatar, displayName } = el?.user
			return (
				<VoterItem
					{...{
						avatar,
						accepted,
						votingWeight,
						displayName,
						blockchainHash,
						statusId,
						userId,
						id,
						daoProposal,
						daoProposalId
					}}
					key={el.id}
				/>
			)
		})
	}

	return (
		<>
			<Box className={styles.userList}>
				<Typography component={'h3'}>Votes</Typography>
				<Box
					className={styles.scroller}
					id="scrollableDiv"
					style={{ maxHeight: '170px', overflow: 'auto' }}
				>
					{items.length > 0 && renderItems()}
				</Box>
				{loadMore && (
					<Button
						onClick={handleLoadMore}
						disabled={!loadMore}
						className={styles.load_more}
						startIcon={
							loading && <i className="fa-duotone fa-spinner-third fa-spin"></i>
						}
					>
						Load More
					</Button>
				)}
			</Box>
		</>
	)
}

VotersList.propTypes = {
	daoProposalId: PropTypes.number
}

export default VotersList
