import { notificationTypes } from 'component/common'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useNotification from 'hooks/useNotification'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGenresService } from 'services/genres.service'
import {
	updateProfileSettingsService,
	getProfileSettingsUserById
} from 'services/profile.service'
import {
	getCountries,
	getDigestOptionsService,
	getProjectTypes,
	getTriggeredOptionsService,
	getUserGenders
} from 'services/seed.service'
import { setClaimWalletDialogOpen } from 'component/profile/walletTab/walletTabSlice'
import { updateMetaTags } from 'utils/utils'
import { updateUser } from './settingsSlice'
import { useNavigate } from 'react-router-dom'

const useSettings = () => {
	const [projectTypes, setProjectTypes] = useState([])
	const [genres, setGenres] = useState([])
	const [userGenres, setUserGenres] = useState([])
	const [triggeredOptions, setTriggeredOptions] = useState([])
	const [triggeredOptionsFilter, setTriggeredOptionsFilter] = useState('')
	const [digestOptions, setDigestOptions] = useState([])
	const [triggeredOptionsSelected, setTriggeredOptionsSelected] = useState([])
	const [digestOptionsSelected, setDigestOptionsSelected] = useState([])
	const [password, setPassword] = useState({})
	const { showNotification } = useNotification()
	const { user } = useSelector(state => state.settings)
	const { user: signInUser } = useSelector(state => state.signIn)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const walletAddress = signInUser?.walletAddress
	const isNonCustodial = signInUser?.isNonCustodial === 1

	const isCreator = signInUser.projectsPublishedCount > 0

	const [{ loading: loadingUser }] = useAxiosFetch(
		{
			...getProfileSettingsUserById(),
			autoexec: true
		},
		afterLoadUser
	)

	function afterLoadUser(user) {
		dispatch(updateUser(user))
		const triggerOptions = user?.triggeredEmailUserOption.map(
			el => el?.optionId
		)
		setTriggeredOptionsSelected(triggerOptions)
		const digestOptions = user?.digestEmailUserOption.map(el => el?.optionId)
		setDigestOptionsSelected(digestOptions)
	}

	const [{ loading: loadingProjectTypes }] = useAxiosFetch(
		{
			...getProjectTypes(),
			autoexec: true
		},
		setProjectTypes
	)

	const handleOpenClaimWallet = () => {
		dispatch(setClaimWalletDialogOpen(true))
	}

	const [{ loading: loadingGenres }] = useAxiosFetch(
		getGenresService(),
		setGenres
	)

	const [{ loading: loadingUserGenres }] = useAxiosFetch(
		getUserGenders(),
		setUserGenres
	)

	const [{ loading: loadingDigestOptions }] = useAxiosFetch(
		getDigestOptionsService(),
		data => {
			const options = isCreator
				? data
				: data?.filter(el => el.isCreatorOption === 0)
			setDigestOptions(options)
		}
	)

	const [{ loading: loadingTriggeredOptions }] = useAxiosFetch(
		getTriggeredOptionsService(),
		data => {
			const options = isCreator
				? data
				: data?.filter(el => el.isCreatorOption === 0)
			setTriggeredOptions(options)
		}
	)

	const [{ response: countries, loading: loadingCountries }] = useAxiosFetch(
		getCountries()
	)

	const [{ loading: saving }, saveChanges] = useAxiosFetch(
		updateProfileSettingsService({
			id: user?.id,
			genres: user?.favGenres,
			genderId: user?.genderId,
			countryId: user?.countryId,
			projectTypes: user?.favProjectTypes,
			password: password?.current || '',
			newPassword: password?.new || '',
			triggeredOptionsSelected,
			digestOptionsSelected
		}),
		afterSaveChanges
	)

	function afterSaveChanges() {
		showNotification({ message: 'The user settings were saved successfully!' })
	}

	const isTriggeredOptionChecked = optionId =>
		triggeredOptionsSelected.includes(optionId)
	const checkTriggeredOption = (optionId, checked) => {
		if (checked) setTriggeredOptionsSelected(arr => [...arr, optionId])
		else setTriggeredOptionsSelected(to => to.filter(el => el !== optionId))
	}

	const isDigestOptionChecked = optionId =>
		digestOptionsSelected.includes(optionId)
	const checkDigestOption = (optionId, checked) => {
		if (checked) setDigestOptionsSelected(arr => [...arr, optionId])
		else setDigestOptionsSelected(to => to.filter(el => el !== optionId))
	}

	const handleChange = payload => {
		dispatch(updateUser(payload))
	}

	const handlePasswdChange = payload => {
		setPassword({
			...password,
			...payload
		})
	}

	const isValid = () => {
		if (
			(password?.new && !password?.repeat) ||
			(!password?.new && password?.repeat)
		) {
			return false
		}
		return password.new === password.repeat
	}

	const handleSave = () => {
		if (isValid()) {
			saveChanges()
		} else {
			showNotification({
				type: notificationTypes.ERROR,
				message: 'Passwords not match'
			})
		}
	}

	const handleCancel = () => {
		navigate(-1)
	}

	useEffect(() => {
		updateMetaTags({})
	}, [])

	const showMask =
		saving ||
		loadingGenres ||
		loadingProjectTypes ||
		loadingUser ||
		loadingDigestOptions ||
		loadingTriggeredOptions ||
		loadingCountries ||
		loadingUserGenres

	return {
		user,
		genres,
		password,
		showMask,
		userGenres,
		countries: countries?.data,
		projectTypes,
		digestOptions,
		triggeredOptions,
		triggeredOptionsFilter,
		setTriggeredOptionsFilter,
		isTriggeredOptionChecked,
		isDigestOptionChecked,
		checkTriggeredOption,
		handlePasswdChange,
		checkDigestOption,
		handleChange,
		handleSave,
		handleCancel,
		isValid,
		walletAddress,
		isNonCustodial,
		handleOpenClaimWallet
	}
}

export default useSettings
