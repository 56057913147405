import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	user: {},
	selectedDigestOptions: [],
	selectedTriggeredOptions: []
}

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		reset: () => initialState,
		updateUser: (state, { payload }) => {
			state.user = {
				...state.user,
				...payload
			}
		},
		updateTriggeredOptions: (state, { payload }) => {
			state.selectedDigestOptions = payload
		},
		updateDigestOptions: (state, { payload }) => {
			state.selectedTriggeredOptions = payload
		}
	}
})

export const {
	reset,
	updateUser,
	updateTriggeredOptions,
	updateDigestOptions
} = settingsSlice.actions

export default settingsSlice.reducer
