'use client'

import { useState } from 'react'
import {
	Autocomplete,
	TextField,
	Paper,
	FormHelperText,
	FormControl,
	Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import './ChipSelectBox.scss'

const CustomPaper = props => {
	return <Paper {...props} className="autoComplete-list" />
}

export const ChipSelectBox = ({
	values,
	setValues,
	options,
	label,
	placeholder = '',
	limitTags = 3,
	helperText,
	variant = 'black',
	...others
}) => {
	const [hidePlaceholder, setHidePlaceholder] = useState(values?.length > 0)

	return (
		<FormControl className="chip_select_box_container">
			{label && <Typography component={'h3'}>{label}</Typography>}
			<Autocomplete
				multiple
				options={options}
				className={`chip_select_box ${variant}`}
				PaperComponent={CustomPaper}
				getOptionLabel={option => option.name}
				value={values}
				noOptionsText={'No match found'}
				getOptionDisabled={() => values?.length === limitTags}
				onChange={(event, reason) => {
					setHidePlaceholder(reason?.length > 0)
					setValues(reason)
				}}
				renderInput={params => (
					<TextField
						{...params}
						placeholder={hidePlaceholder ? '' : placeholder}
					/>
				)}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				{...others}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	)
}

ChipSelectBox.propTypes = {
	values: PropTypes.array,
	setValues: PropTypes.func,
	options: PropTypes.array,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	limitTags: PropTypes.number,
	helperText: PropTypes.string,
	variant: PropTypes.string,
	others: PropTypes.object
}
