import { Link } from 'react-router-dom'
import { Avatar } from '@mui/material'
import PropTypes from 'prop-types'
import styles from './ProfileLink.module.scss'

export const BasicProfileLink = ({
	displayName,
	username,
	avatar,
	showName = true
}) => {
	return (
		<>
			<Link
				className={styles.basic_profile_link}
				{...{ to: `/profile/${username}` }}
			>
				<Avatar
					alt={displayName}
					sx={{
						bgcolor: '#000000',
						border: '1px solid #ffffff',
						height: 29,
						width: 29,
						marginRight: '12px'
					}}
					src={avatar}
				/>
				{showName && <span className={styles.name}>{displayName}</span>}
			</Link>
		</>
	)
}

BasicProfileLink.propTypes = {
	displayName: PropTypes.string,
	avatar: PropTypes.string,
	username: PropTypes.string,
	showName: PropTypes.bool
}

export const TeamMemberProfileLink = ({
	displayName,
	avatar,
	username,
	position,
	role = 'Collaborator'
}) => {
	const pos = position ? `: ${position}` : ''

	return (
		<>
			<Link
				className={styles.team_member_profile_link}
				{...{ to: `/profile/${username}` }}
			>
				<Avatar
					alt={displayName}
					sx={{
						bgcolor: '#000000',
						border: '1px solid #ffffff',
						height: 42,
						width: 42,
						marginRight: '12px'
					}}
					src={avatar}
				/>
				<div className={styles.info_container}>
					<div className={styles.info}>
						<span className={styles.name}>{displayName}</span>
					</div>
					{username && <span className={styles.username}>@{username}</span>}
					<span className={styles.role}>
						{role} {pos}
					</span>
				</div>
			</Link>
		</>
	)
}

TeamMemberProfileLink.propTypes = {
	displayName: PropTypes.string,
	avatar: PropTypes.string,
	username: PropTypes.string,
	position: PropTypes.string,
	role: PropTypes.string
}
