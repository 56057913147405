import { Button } from '@/components/ui/button'
import logo from 'assets/images/fanDashboard/logo.svg'
import logoMobile from 'assets/images/fanDashboard/logo-mobile.png'
import filmioIcon from 'assets/images/fanDashboard/filmio-icon.png'
import { Link } from 'react-router-dom'
import { ProfileMenu } from './profileMenu'
import { SearchInput } from './searchInput/SearchInput'
import { Notifications } from './notifications'
import Image from 'next/image'
import { useDispatch, useSelector } from 'react-redux'
import {
	setLoginDialogOpen,
	setOnRampDialogOpen,
	setShowFanSearchMobile
} from 'component/header/signInSlice'
import { cn } from '../../../lib/utils'
import { abbreviateNumberFixed } from '../../../utils/utils'
import MobileProfileSheet from './profileSheet/ProfileSheet'
import { CustomButton } from '../../common'
import { ShoppingCartIcon } from 'lucide-react'

export const Header = ({ className }) => {
	const dispatch = useDispatch()
	const { showFanSearchMobile, user, isAuthenticated } = useSelector(
		state => state.signIn
	)

	const handleCloseSearch = () => {
		dispatch(setShowFanSearchMobile(false))
	}

	const showOnRamp = () => {
		dispatch(setOnRampDialogOpen(true))
	}

	const openLoginDialog = () => {
		dispatch(setLoginDialogOpen(true))
	}

	return (
		<header
			className={cn(
				`${
					showFanSearchMobile
						? 'max-[639px]:!h-[120px] max-[639px]:flex-wrap'
						: 'h-header'
				} flex w-full items-center justify-between border-b-[0.25px] border-[#373833] bg-[#121212] px-[16px] transition-all duration-300 zoom-in-150`,
				className
			)}
		>
			<div
				className={`text-3xl font-bold ${
					showFanSearchMobile ? 'max-[639px]:order-1' : ''
				}`}
			>
				<Link to="/explore">
					<Image
						className="object-contain max-[639px]:hidden sm:block"
						src={logo.src}
						alt="logo"
						width={120}
						height={41}
					/>
					<Image
						className="max-[639px]:block sm:hidden"
						src={logoMobile.src}
						alt="logo"
						width={41}
						height={41}
					/>
				</Link>
			</div>
			<div
				className={`${
					showFanSearchMobile
						? 'max-[639px]:order-3 max-[639px]:basis-full'
						: ''
				} sm:relative sm:!flex sm:w-1/2 sm:items-center sm:justify-center`}
			>
				<SearchInput
					showFanSearchMobile={showFanSearchMobile}
					handleCloseSearch={handleCloseSearch}
				/>
			</div>
			<div
				className={`${
					showFanSearchMobile ? 'max-[639px]:order-2' : ''
				} flex h-10 items-center justify-center gap-6`}
			>
				{isAuthenticated ? (
					<>
						<div className="hidden sm:flex sm:items-center sm:justify-center">
							<Notifications />
						</div>

						{user?.isOnRamperEnabled && (
							<CustomButton onClick={showOnRamp} customStyle="buy_fan">
								<ShoppingCartIcon
									baseClassName="fal"
									className="fa-cart-shopping"
								/>
							</CustomButton>
						)}

						<Button
							variant="outline"
							className="flex items-center justify-center"
							asChild
						>
							<Link to={`/profile/${user.username}?tab=4`}>
								<img
									className="h-[16px] w-[16px] object-contain"
									src={filmioIcon.src}
									alt="Filmio Icon"
								/>
								{abbreviateNumberFixed(Number(user.balance).toFixed(2), 2)}
							</Link>
						</Button>

						<ProfileMenu triggerClassName="hidden sm:inline-flex" />
						<MobileProfileSheet triggerClassName="sm:hidden" />
					</>
				) : (
					<>
						<Button
							variant="ghost"
							className="inline-flex px-2 text-white hover:text-primary"
							asChild
						>
							<Link to="/explore">Explore</Link>
						</Button>

						<Button
							className="inline-flex px-2 text-white hover:text-primary"
							variant="ghost"
							asChild
						>
							<Link to="/newsreel">Newsreel</Link>
						</Button>

						<Button className="inline-flex" onClick={openLoginDialog}>
							Login/Signup
						</Button>
					</>
				)}
			</div>
		</header>
	)
}
