import PropTypes from 'prop-types'
import { CustomDialog, TextArea, TextFieldControl } from 'component/common'
import {
	Box,
	Button,
	Icon,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Typography
} from '@mui/material'
import { useEditTweetDialog } from './useEditTweetDialog'
import styles from './EditTweetDialog.module.scss'

export const EditTweetDialog = ({ isDraft, closeMenu }) => {
	const {
		open,
		link,
		shareLink,
		message,
		limitChars,
		openDialog,
		setMessage,
		handleClose,
		handleCopy,
		handleCopyMobile,
		projectCover,
		handleCopyLink
	} = useEditTweetDialog(closeMenu)

	return (
		<>
			<MenuItem disabled={isDraft} onClick={handleCopyLink}>
				<ListItemIcon>
					<Box className={styles.backDrop}>
						<Icon baseClassName="fas" className="fa-envelope" />
					</Box>
				</ListItemIcon>
				<ListItemText>
					Copy <strong>Link</strong>
				</ListItemText>
			</MenuItem>
			<MenuItem disabled={isDraft} onClick={openDialog('custom')}>
				<ListItemIcon>
					<Box className={styles.backDrop}>
						<Icon baseClassName="fas" className="fa-hammer-brush" />
					</Box>
				</ListItemIcon>
				<ListItemText>
					Custom <strong>Share</strong>
				</ListItemText>
			</MenuItem>
			<MenuItem disabled={isDraft} onClick={openDialog('x')}>
				<ListItemIcon>
					<Box className={styles.backDrop}>
						<Icon baseClassName="fab" className="fa-x-twitter" />
					</Box>
				</ListItemIcon>
				<ListItemText>
					Share on <strong>Twitter/X</strong>
				</ListItemText>
			</MenuItem>
			<MenuItem disabled={isDraft} onClick={openDialog('facebook')}>
				<ListItemIcon>
					<Box className={styles.backDrop}>
						<Icon baseClassName="fab" className="fa-facebook-f" />
					</Box>
				</ListItemIcon>
				<ListItemText>
					Share on <strong>Facebook</strong>
				</ListItemText>
			</MenuItem>
			<MenuItem disabled={isDraft} onClick={openDialog('linkedin')}>
				<ListItemIcon>
					<Box className={styles.backDrop}>
						<Icon baseClassName="fab" className="fa-linkedin" />
					</Box>
				</ListItemIcon>
				<ListItemText>
					Share on <strong>Linkedin</strong>
				</ListItemText>
			</MenuItem>

			{open && (
				<CustomDialog {...{ open, handleClose, variant: 'edit_tweet' }}>
					<Box className={styles.edit_tweet}>
						<Typography component={'h2'}>Share</Typography>
						<img
							className={styles.header}
							src={`${projectCover}?tr=w-1080`}
							alt="cover"
						/>
						<TextArea
							label="Message"
							minRows={3}
							maxRows={5}
							aria-label="Message"
							placeholder="Message"
							value={message}
							limitChars={limitChars}
							onChange={e => {
								if (e.target.value?.length <= limitChars)
									setMessage(e.target.value)
							}}
						/>
						<a href="#" onClick={handleCopyMobile} className={styles.copy_link}>
							<Icon baseClassName="far" className="fa-copy" /> Copy Your Message
						</a>
						<Box className={styles.link}>
							<TextFieldControl
								classes={{ root: styles.first_name }}
								hiddenLabel
								disabled
								label="Link"
								value={link}
							/>
						</Box>
						<Box className={styles.note}>
							When you click <b>share</b> your message will be copied to the
							clipboard. Simply paste it into the share box at your social
							network of choice.
						</Box>
						<Box className={styles.footer}>
							<Button onClick={handleClose}>Cancel</Button>
							<Button onClick={handleCopy} className={styles.share_btn}>
								Share!
							</Button>
							<a href={shareLink} className={styles.share_link}>
								Share!
							</a>
						</Box>
					</Box>
				</CustomDialog>
			)}
		</>
	)
}

EditTweetDialog.propTypes = {
	isDraft: PropTypes.bool,
	closeMenu: PropTypes.func
}
