export const getNewsreelsByFeatureIdService = params => ({
	url: '/newsreel',
	params
})

export const getNewsreelsByIdService = newsreelId => ({
	url: `/newsreel/${newsreelId}`
})

export const addNewsreelService = ({ media, content, projectId }) => {
	const formData = new FormData()
	media?.forEach(file => {
		formData.append('media', file, file.name)
	})
	formData.append('content', content)
	if (projectId) formData.append('projectId', projectId)
	return {
		method: 'post',
		url: '/newsreel',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}
}

export const deleteNewsreelByIdService = id => ({
	method: 'delete',
	url: '/newsreel',
	data: { id }
})
