import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormGroup } from '@mui/material'
import { Search } from 'component/common'
import styles from './SearchFilter.module.scss'

const SearchFilter = ({ genres, renderOptions }) => {
	const [results, setResults] = useState(genres)
	const [search, setSearch] = useState('')

	useEffect(() => {
		const filtered = genres.filter(data => {
			return data.name
				.replace(/ /g, '')
				.toLowerCase()
				.includes(search.toLowerCase())
		})
		setResults(filtered)
	}, [search])

	return (
		<>
			<Search
				{...{
					setSearch,
					search,
					placeholder: 'Filter',
					sx: { marginTop: '10px', marginBottom: '10px' }
				}}
			/>
			<div className={styles.search_item}>
				<FormGroup>{results.map(el => renderOptions('genres', el))}</FormGroup>
			</div>
		</>
	)
}

SearchFilter.propTypes = {
	genres: PropTypes.array,
	renderOptions: PropTypes.func
}

export default SearchFilter
