import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Box } from '@mui/material'
import { FollowingFollowerTab } from './followingFollowerTab'
import './ConnectionsTab.scss'

function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
}

function a11yProps(index) {
	return {
		id: `conn-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

const ConnectionsTab = () => {
	const [value, setValue] = useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<Box sx={{ width: '100%' }} className="details-tab team-tab-section">
			<Box
				sx={{ borderBottom: 1, borderColor: 'divider' }}
				className="team-header"
			>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					className="tabs-list sub-tabs-list"
					TabIndicatorProps={{
						className: 'tab-custom-indicator'
					}}
				>
					<Tab label="Following" {...a11yProps(0)} />
					<Tab label="Followers" {...a11yProps(1)} />
				</Tabs>
			</Box>

			<TabPanel value={value} index={0}>
				<FollowingFollowerTab type={'following'} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<FollowingFollowerTab type={'follower'} />
			</TabPanel>
		</Box>
	)
}

export default ConnectionsTab
