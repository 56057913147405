import _ from 'lodash'
import { useEffect, useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { registerService } from 'services/auth.service'
import {
	setAuthenticated,
	setSocialJoinParams,
	setIsIngXRestrictedInvestor
} from 'component/header/signInSlice'
import useNotification from 'hooks/useNotification'
import { isValidEmail } from 'utils/form.util'
import {
	addObjectToLocalStorage,
	updateMetaTags,
	isMetamask,
	isMobile
} from 'utils/utils'
import useLoadingMask from 'hooks/useLoadingMask'
import { getCampaignBySlug } from 'services/campaign.service'
import { notificationTypes } from 'component/common'
import { Mixpanel } from 'services/mixpanel.service'
import magic from 'utils/blockchain/config/constants'
import { magicSignUpService } from 'services/profile.service'
import useAxios from 'hooks/useAxios'
import useHeader from 'component/header/useHeader'
import useWeb3 from 'hooks/useWeb3'

const useOnboardJoin = () => {
	const initialData = {
		email: '',
		firstName: '',
		lastName: '',
		tokenSaleDocsAgreed: true
	}

	const [data, setData] = useState(initialData)
	const [errors, setErrors] = useState({})
	const [campaign, setCampaign] = useState({
		benefits: []
	})
	const { captchaToken } = useSelector(state => state.captchaCheck)
	const { slug } = useParams()
	const { showNotification } = useNotification()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { hideMask, showMask } = useLoadingMask()
	const { axiosService } = useAxios()
	const { loadUserTierInfo, claimUserRewards } = useHeader()
	const { openConnectWalletModal, connectAsync, connectors } = useWeb3()

	const [{ loading: loadingCampaign }] = useAxiosFetch(
		getCampaignBySlug(slug),
		(data, error) => {
			if (!error) {
				setCampaign(data)
				const description = (data?.subText ?? '').split('.')[0]
				updateMetaTags({
					title: data.name,
					description
				})
				if (data?.expired) {
					showNotification({
						type: notificationTypes.INFO,
						message: 'This campaign has reached its threshold and is sold out!'
					})
					navigate('/')
				}
			} else {
				navigate('/')
			}
		}
	)

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const clearForm = () => {
		setData(initialData)
		setErrors({})
	}

	const magicSignUp = iToken => {
		showMask()
		axiosService(magicSignUpService({ iToken }), (data, error) => {
			if (!error) {
				claimUserRewards(data?.id)
				clearForm()
				dispatch(setAuthenticated(data))
				const isIngXRestrictedInvestor =
					!_.isNil(data?.isIngXInvestor) && data?.isIngXInvestor
				dispatch(setIsIngXRestrictedInvestor(isIngXRestrictedInvestor))
				addObjectToLocalStorage({
					...data,
					isAuthenticated: true,
					isIngXRestrictedInvestor
				})
				loadUserTierInfo()
			}
			hideMask()
		})
	}

	const displayName = `${data.firstName} ${data.lastName}`
	const registerUser = iToken => {
		showMask()
		const afterRegister = (response, error, responseError) => {
			if (!error) {
				clearForm()
				magicSignUp(iToken)
				Mixpanel.track('usr_custom_campaign_registered_evt', {
					distinct_id: response?.id,
					displayName: response?.displayName,
					username: response?.username,
					email: response?.email
				})
			} else {
				if (_.get(responseError, ['response', 'status']) === 409)
					setErrors({ email: 'This email is alreay in use' })
			}
		}
		axiosService(
			registerService({
				...data,
				password: data?.email,
				displayName,
				campaignId: campaign.id,
				captchaToken,
				iToken
			}),
			afterRegister
		)
	}

	const authOrRegisterMagicUser = async email => {
		try {
			const iToken = await magic.auth.loginWithEmailOTP({ email })
			registerUser(iToken)
		} catch (error) {
			console.log({ error })
		}
	}

	const isValid = () => {
		let valid = true
		const errorsTmp = {}
		const message = 'This field is required'
		if (_.isNil(data.firstName) || data.firstName.trim() === '') {
			errorsTmp.firstName = message
			valid = false
		}
		if (_.isNil(data.lastName) || data.lastName.trim() === '') {
			errorsTmp.lastName = message
			valid = false
		}
		if (_.isNil(data.email) || data.email.trim() === '') {
			errorsTmp.email = message
			valid = false
		}
		if (!_.isNil(data.email) && !isValidEmail(data.email)) {
			errorsTmp.email = 'The email format is incorrect.'
			valid = false
		}
		updateErrors(errorsTmp)
		return valid
	}

	const handleSubmit = () => {
		if (isValid()) authOrRegisterMagicUser(data?.email)
		else
			showNotification({
				message: 'Please fix the form errors to save changes',
				type: 'error'
			})
	}

	const handleChange = payload => {
		const key = Object.keys(payload)[0]
		updateErrors({ [key]: false })
		setData({
			...data,
			...payload
		})
	}

	const handleMMLogin = async () => {
		if (isMetamask() || !isMobile()) {
			const injectedConnector = connectors.find(
				connector => connector.id === 'injected'
			)
			if (!_.isNil(injectedConnector)) {
				await connectAsync({ connector: injectedConnector })
			}
		}
	}

	const handleNcLogin = () => {
		openConnectWalletModal()
	}

	useEffect(() => {
		loadingCampaign ? showMask() : hideMask()
	}, [loadingCampaign])

	useEffect(() => {
		dispatch(setSocialJoinParams({ campaignSlug: slug }))
	}, [slug])

	return {
		data,
		errors,
		campaign,
		captchaToken,
		handleNcLogin,
		handleMMLogin,
		handleSubmit,
		handleChange
	}
}

export default useOnboardJoin
