import useAxiosFetch from 'hooks/useAxiosFetch'
import { useDispatch, useSelector } from 'react-redux'
import { getTilesService } from 'services/tile.service'
import { updateTiles } from './tileManagementSlice'

const useTileManagement = (autoexec = true) => {
	const dispatch = useDispatch()
	const { tiles } = useSelector(state => state.tileManagement)

	const [{ loading }, getTiles] = useAxiosFetch(
		{
			...getTilesService(),
			autoexec
		},
		afterGetTiles
	)

	function afterGetTiles(response) {
		dispatch(updateTiles(response))
	}

	function setTiles(tiles) {
		dispatch(updateTiles(tiles))
	}

	return {
		tiles,
		getTiles,
		setTiles,
		loading
	}
}

export default useTileManagement
