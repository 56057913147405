import PropTypes from 'prop-types'
import {
	Typography,
	TableSortLabel,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Button
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { formatDate } from 'utils/date.utils'
import useStakesTable from './useStakesTable'
import { getTransactionLink } from 'utils/utils'
import styles from './StakesTable.module.scss'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'

const headCells = [
	{
		id: 'amount',
		label: 'Amount',
		align: 'center'
	},
	{
		id: 'user',
		label: 'By'
	},
	{
		id: 'createdAt',
		label: 'Time Stamp'
	},
	{
		id: 'status',
		label: 'Status'
	},
	{
		id: 'blockchainHash',
		label: 'Transaction'
	},
	{
		id: 'actions',
		label: ''
	}
]

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => {
					const active = orderBy === headCell.id
					return (
						<TableCell
							key={headCell.id}
							align={headCell.align}
							padding={'none'}
							sortDirection={active ? order : false}
						>
							{headCell.id !== 'icon' && (
								<TableSortLabel
									active={active}
									direction={active ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{active ? (
										<Box component="span" sx={visuallyHidden}>
											{order === 'desc'
												? 'sorted descending'
												: 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
							)}
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired
}

export default function StakesTable() {
	const {
		user,
		pagin,
		count,
		order,
		stakes,
		project,
		orderBy,
		emptyRows,
		isAuthenticated,
		handleChangeRowsPerPage,
		handleClickTransaction,
		handleRequestSort,
		handleChangePage,
		handleUnstake
	} = useStakesTable()

	const renderRows = (data = []) => {
		return data.map(row => {
			const {
				id,
				amount,
				createdAt,
				blockchainHash,
				originWallet,
				status,
				statusId
			} = row
			const statusClass = status?.name?.toLowerCase()
			const disableUnstake =
				[1, 4].includes(statusId) || _.isNil(blockchainHash)

			const isTransactionOwner = user?.id === originWallet?.user?.id
			const to = isAuthenticated ? `/profile/${user.username}?tab=4` : '#'
			const hash = !_.isNil(blockchainHash) ? (
				isAuthenticated ? (
					getTransactionLink(blockchainHash)
				) : (
					<a>{blockchainHash}</a>
				)
			) : (
				<Link
					className={styles.wallet_menu_btn}
					to={to}
					onClick={handleClickTransaction}
				>
					{isAuthenticated ? 'Go to your wallet' : 'Go to login'}
				</Link>
			)
			return (
				<TableRow key={id}>
					<TableCell align="center" width="14%">
						<NumericFormat
							value={amount}
							displayType="text"
							thousandSeparator=","
						/>
					</TableCell>
					<TableCell align="left" width="20%">
						{originWallet?.user?.displayName}
					</TableCell>
					<TableCell align="left" width="18%">
						{formatDate(createdAt, 'med')}
					</TableCell>
					<TableCell align="left" width="18%" className={styles.status}>
						<span className={styles[statusClass]}>{status?.name}</span>
					</TableCell>
					<TableCell align="left" width="18%" maxwidth="14%">
						{hash}
					</TableCell>
					<TableCell align="left" width="18%" maxwidth="14%">
						{isTransactionOwner && (
							<Button
								onClick={handleUnstake(id, amount)}
								disabled={disableUnstake}
							>
								Unstake
							</Button>
						)}
					</TableCell>
				</TableRow>
			)
		})
	}

	return (
		<Box className={styles.stake_table}>
			<h4>The most recent stakes to {project.title}</h4>
			<Box>
				<TableContainer className={styles.table_container}>
					{stakes?.length === 0 ? (
						<Typography className={styles.title}>
							No found stakes yet
						</Typography>
					) : (
						<Table stickyHeader>
							<EnhancedTableHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
							/>
							<TableBody>
								{renderRows(stakes)}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * emptyRows
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					)}
				</TableContainer>
				{stakes?.length > 0 && (
					<TablePagination
						rowsPerPageOptions={[6, 18, 60]}
						component="div"
						count={count || 0}
						rowsPerPage={pagin.limit}
						classes={{ root: styles.pagination }}
						page={pagin.page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Box>
		</Box>
	)
}
