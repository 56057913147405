import { cn } from '@/lib/utils'
import Image from 'next/image'
import { LuBadgeAlert } from 'react-icons/lu'

import PropTypes from 'prop-types'
import { useState } from 'react'
import {
	Popover,
	PopoverTrigger,
	PopoverContent
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { formatDate } from 'utils/date.utils'
import infoBackground from 'assets/images/dao-tiers/info_background.svg'

const TierItem = ({
	className,
	id,
	date,
	image,
	title,
	completed,
	confirmed,
	benefits,
	metrics = {},
	userMetrics = {}
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}
	const renderMetrics = () =>
		Object.keys(metrics).map((key, idx) => {
			const threshold = metrics[key]
			const value = userMetrics[key]
			const isCompleted = value >= threshold
			let name = ''
			let label = ''
			let goal = ''

			switch (key) {
				case 'Is User Verified':
					name = 'Verified Account'
					break
				case 'Setup Platform Wallet':
					name = key
					break
				default:
					name = key
					label = key === 'Stake' ? 'FAN ' : 'projects '
					goal = threshold
					break
			}

			return (
				<p
					key={key}
					className={`text-xs ${
						isCompleted ? 'font-semibold text-green-500' : ''
					}`}
				>
					{idx + 1}. {name} {goal} {label}
					<span>
						{' '}
						({value}/{threshold})
					</span>
					{isCompleted && <i className="fas fa-check ml-1 text-green-500"></i>}
				</p>
			)
		})
	const name = _.capitalize(title)
	return (
		<div className={cn('flex flex-col items-center', className)}>
			<div
				className={cn(
					'z-10 flex h-6 w-6 items-center justify-center rounded-full border',
					completed || confirmed
						? 'border-primary bg-primary text-transparent'
						: 'border-primary bg-primary text-transparent opacity-20'
				)}
			>
				<Image src={image} width={16} height={16} alt="steps" />
			</div>
			<div className="text-xs font-semibold leading-5 text-white sm:text-base">
				<div className="mt-2 flex flex-col items-center justify-center gap-1">
					{title}
					<Popover open={Boolean(anchorEl)} onOpenChange={setAnchorEl}>
						<PopoverTrigger asChild>
							<button className="flex h-6 w-6 items-center justify-center rounded-md bg-secondary">
								<LuBadgeAlert onClick={handlePopoverOpen} />
							</button>
						</PopoverTrigger>
						<PopoverContent
							className="w-60 rounded-md bg-background p-4 text-center text-secondary-foreground shadow-lg"
							style={{ backgroundImage: `url(${infoBackground})` }}
						>
							<div className="mb-2 flex h-6 w-full items-center justify-center rounded-md bg-transparent">
								<LuBadgeAlert />
							</div>
							<div>
								<div className="mb-1 text-sm font-semibold">{name} Rank</div>
								{confirmed ? (
									<>
										<p className="text-xs">You achieved {name} Rank</p>
										<p className="text-xs">on {formatDate(date, 'full')}</p>
									</>
								) : (
									<>
										<div className="text-xs font-semibold">
											To Earn {name}, you need to:
										</div>
										{renderMetrics()}
									</>
								)}
							</div>
						</PopoverContent>
					</Popover>
				</div>
			</div>
		</div>
	)
}
TierItem.propTypes = {
	id: PropTypes.number,
	className: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.string,
	date: PropTypes.string,
	benefits: PropTypes.array,
	metrics: PropTypes.object,
	userMetrics: PropTypes.object,
	completed: PropTypes.bool,
	confirmed: PropTypes.bool
}

export default TierItem
