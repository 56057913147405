import * as React from "react"
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsHorizontalIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon
} from "@radix-ui/react-icons"

import { cn } from "@/lib/utils"
import { ButtonProps, buttonVariants } from "@/components/ui/button"

const Pagination = ({ className, ...props }: React.ComponentProps<"nav">) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn("mx-auto flex w-full justify-center text-table-foreground", className)}
    {...props}
  />
)
Pagination.displayName = "Pagination"

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<"ul">
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn("flex flex-row items-center gap-2 text-table-foreground", className)}
    {...props}
  />
))
PaginationContent.displayName = "PaginationContent"

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<"li">
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn("text-table-foreground text-sm font-medium flex justify-center items-center", className)} {...props} />
))
PaginationItem.displayName = "PaginationItem"

type PaginationLinkProps = {
  isActive?: boolean
} & Pick<ButtonProps, "size"> &
  React.ComponentProps<"a">

const PaginationLink = ({
  className,
  isActive,
  size = "icon",
  ...props
}: PaginationLinkProps) => (
  <a
    aria-current={isActive ? "page" : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? "outline" : "ghost",
        size,
        
      }),
      className
    )}
    {...props}
  />
)
PaginationLink.displayName = "PaginationLink"

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to previous page"
    size="default" 
    className={cn("gap-1 p-0 text-table-foreground disabled:opacity-50", className)}
    {...props}
  >
        <span className="flex justify-center items-center w-8 h-8 bg-card-background100 border border-secondary rounded-md"><ChevronLeftIcon className="h-4 w-4  text-table-foreground " /></span>
  </PaginationLink>
)
PaginationPrevious.displayName = "PaginationPrevious"

const PaginationLast = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to Last page"
    size="default"
    className={cn("gap-1 p-0 text-table-foreground", className)}
    {...props}
  >
        <span className="flex justify-center items-center w-8 h-8 bg-card-background100 border border-secondary rounded-md"><DoubleArrowRightIcon className="h-4 w-4  text-table-foreground " /></span> 
  </PaginationLink>
)
PaginationLast.displayName = "PaginationLast"

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to next page"
    size="default"
    className={cn("gap-1 p-0 text-table-foreground", className)}
    {...props}
  >
    <span className="flex justify-center items-center w-8 h-8 bg-card-background100 border border-secondary rounded-md"><ChevronRightIcon className="h-4 w-4  text-table-foreground " /></span>
  </PaginationLink>
)
PaginationNext.displayName = "PaginationNext"

const PaginationFirst = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go Back to First page"
    size="default"
    className={cn("gap-1 p-0 text-table-foreground", className)}
    {...props}
  >
        <span className="flex justify-center items-center w-8 h-8 bg-card-background100 border border-secondary rounded-md"><DoubleArrowLeftIcon className="h-4 w-4  text-table-foreground " /></span> 
  </PaginationLink>
)
PaginationFirst.displayName = "PaginationFirst"

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden
    className={cn("flex h-9 w-9 items-center justify-center text-table-foreground", className)}
    {...props}
  >
    <DotsHorizontalIcon className="h-2 w-1 text-table-foreground" />
    <span className="sr-only text-table-foreground">More pages</span>
  </span>
)
PaginationEllipsis.displayName = "PaginationEllipsis"

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
  PaginationFirst,
  PaginationLast
}
