import {
	fanTokenABI,
	filmioProjectABI,
	stakingABI,
	stakingABIV1,
	addressLinkerABI,
	filmioDaoABI,
	USDTABI,
	USDCABI
} from './abi'
import { Magic } from 'magic-sdk'
import { ethers } from 'ethers'
import {
	polygon as rawPolygon,
	mainnet as rawMainnet,
	sepolia as rawSepolia
} from 'wagmi/chains'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'

export const env = process.env.NEXT_PUBLIC_ENVIRONMENT || 'development'

const PolygonRpcUrl =
	'https://polygon-mainnet.g.alchemy.com/v2/ulxRc6gogLFb_eEuewj2Djth5qxEOtnY'
const AmoyRpcUrl =
	'https://polygon-amoy.g.alchemy.com/v2/K-KnnE6xqv-JFNJ1uXwqLmqgXKMtORv6'
const EthereumRpcUrl =
	'https://eth-mainnet.g.alchemy.com/v2/YFfMuLvsycU7p0atYPPLI7tO8SCCpvh7'
const SepoliaRpcUrl =
	'https://eth-sepolia.g.alchemy.com/v2/_6IXshNVe97RL-LQ1qccs3E5ef8fbwfk'

export const networks = Object.freeze({
	AMOY: 'Amoy Testnet',
	SEPOLIA: 'Sepolia Testnet',
	POLYGON: 'Polygon Mainnet',
	ETHEREUM: 'Ethereum Mainnet'
})

const rpcs = {
	development: {
		[networks.AMOY]: AmoyRpcUrl,
		[networks.SEPOLIA]: SepoliaRpcUrl
	},
	stage: {
		[networks.AMOY]: AmoyRpcUrl,
		[networks.SEPOLIA]: SepoliaRpcUrl
	},
	production: {
		[networks.POLYGON]: PolygonRpcUrl,
		[networks.ETHEREUM]: EthereumRpcUrl
	}
}

export const defaultNetwork =
	env === 'production' ? networks.POLYGON : networks.AMOY

export const jsonRpcProvider = network =>
	new ethers.providers.JsonRpcProvider(rpcs[env][network ?? defaultNetwork])

const magicConfig = {
	development: [
		'pk_live_6E60D0F6E86C9666',
		{
			network: {
				rpcUrl: AmoyRpcUrl,
				chainId: 80002
			}
		}
	],
	stage: [
		'pk_live_68BC8C49E85F97EA',
		{
			network: {
				rpcUrl: AmoyRpcUrl,
				chainId: 80002
			}
		}
	],
	production: [
		'pk_live_88F10C3C1A1456D5',
		{
			network: {
				rpcUrl: PolygonRpcUrl,
				chainId: 137
			}
		}
	]
}

const polygon = {
	...rawPolygon,
	rpcUrls: {
		...rawPolygon.rpcUrls,
		default: { http: [PolygonRpcUrl] },
		public: { http: [PolygonRpcUrl] }
	},
	name: networks.POLYGON
}

const amoy = {
	id: 80_002,
	name: networks.AMOY,
	testnet: true,
	nativeCurrency: {
		decimals: 18,
		name: 'MATIC',
		symbol: 'MATIC'
	},
	rpcUrls: {
		public: { http: [AmoyRpcUrl] },
		default: { http: [AmoyRpcUrl] }
	},
	blockExplorers: {
		default: {
			name: 'OK LINK',
			url: 'https://www.oklink.com/amoy'
		}
	},
	contracts: {
		multicall3: {
			address: '0xca11bde05977b3631167028862be2a173976ca11',
			blockCreated: 3127388
		}
	}
}

const mainnet = {
	...rawMainnet,
	rpcUrls: {
		...rawMainnet.rpcUrls,
		default: { http: [EthereumRpcUrl] },
		public: { http: [EthereumRpcUrl] }
	},
	name: networks.ETHEREUM
}

const sepolia = {
	...rawSepolia,
	rpcUrls: {
		...rawSepolia.rpcUrls,
		default: { http: [SepoliaRpcUrl] },
		public: { http: [SepoliaRpcUrl] }
	},
	name: networks.SEPOLIA
}

const NonCustoidalChain = {
	development: [amoy, sepolia],
	stage: [amoy, sepolia],
	production: [polygon, mainnet]
}

const magic = new Magic(...magicConfig[env])
const ethersInstance = new ethers.providers.Web3Provider(magic.rpcProvider)

const walletConnectProjectId = '341fd0bf24baf377e068135467332d78'

export const chains = NonCustoidalChain[env]
export const wagmiConfig = defaultWagmiConfig({
	chains,
	projectId: walletConnectProjectId
})

createWeb3Modal({
	wagmiConfig,
	projectId: walletConnectProjectId,
	chains,
	defaultChain: chains[0]
})

const contractsAddresses = {
	development: {
		fanToken: { [networks.AMOY]: '0x8b879Ef9324136283191559aE129faF402C338eD' },
		filmioProject: {
			[networks.AMOY]: '0xAdf1EF5E0f7D8AA8c13d7a075D235dab34367A88'
		},
		staking: { [networks.AMOY]: '0xdAeB7449712aD566F8F79D72659FdC65BCfB7998' },
		addressLinker: {
			[networks.AMOY]: '0x4B749F5a838DBf8B423bEF9108D5A3aadA9000d5'
		},
		escrowWallet: {
			[networks.AMOY]: '0x438556d783F187b4D3D9929F85D4C83d8467C29F'
		},
		filmioDao: {
			[networks.AMOY]: '0xB4a63BceE14B250001455fd6F75E8b1aC105b05E'
		},
		fanPurchaseEscrow: { 
			[networks.AMOY]: '0x66e5A1c6fB2CEce5B98C725E2162a4884a4b4FE4', 
			[networks.SEPOLIA]: '0x66e5A1c6fB2CEce5B98C725E2162a4884a4b4FE4' 
		},
		USDT: {
			[networks.AMOY]: '0xFf829F87B03df2C7DF5BDACCDFfe1900E9703ec6',
			[networks.SEPOLIA]: '0xedAe14a9448683f50080bbc176836E170c6945aB'
		}
	},
	stage: {
		fanToken: { [networks.AMOY]: '0x9eb9Cb6289Db6bE2f2dF14018EBc03b9998462de' },
		filmioProject: { [networks.AMOY]: '0xC404FbD95D80935f9548DD9C25F4e1DE10b2Bcf8' },
		staking: { [networks.AMOY]: '0x62F408078E34260f6f257B02ed3Cad2318ABc212' },
		addressLinker: { [networks.AMOY]: '0x907611C79F3fc65D017ff0CBeAF4f26D0200C0A1' },
		escrowWallet: { [networks.AMOY]: '0x438556d783F187b4D3D9929F85D4C83d8467C29F' },
		filmioDao: { [networks.AMOY]: '0xbc7D9706DC9CF59B793F5bD7fCAcbf3d3CefAdF9' },
		fanPurchaseEscrow: { 
			[networks.AMOY]: '0x66e5A1c6fB2CEce5B98C725E2162a4884a4b4FE4', 
			[networks.SEPOLIA]: '0x66e5A1c6fB2CEce5B98C725E2162a4884a4b4FE4' 
		},
		USDT: {
			[networks.AMOY]: '0xFf829F87B03df2C7DF5BDACCDFfe1900E9703ec6',
			[networks.SEPOLIA]: '0xedAe14a9448683f50080bbc176836E170c6945aB'
		}
	},
	production: {
		fanToken: { [networks.POLYGON]: '0x3B9E9100Db1389c518d47c635D80a90ad4C4f41b' },
		filmioProject: { [networks.POLYGON]: '0x409d5adE3D65E8F7C0949c4a07fe262d7B74D257' },
		staking: { [networks.POLYGON]: '0x28Ff331816F185F016448B5FA02Edf303A25E86a' },
		addressLinker: { [networks.POLYGON]: '0x5A96169826A57966fB08d162300d0909bA026051' },
		escrowWallet: { [networks.POLYGON]: '0x3bb6Bd3e572fdC4d50270954756e7585B060C181' },
		filmioDao: { [networks.POLYGON]: '0xC2BB10b555a14017520E553490EFD66b5a37BDf5' },
		fanPurchaseEscrow: { [networks.POLYGON]: 'TODO', [networks.ETHEREUM]: 'TODO' },
		USDT: {
			[networks.POLYGON]: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
			[networks.ETHEREUM]: '0xdac17f958d2ee523a2206206994597c13d831ec7'
		},
		USDC: {
			[networks.ETHEREUM]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
		}
	}
}

const contractsConfig = {
	fanToken: {
		address: contractsAddresses[env].fanToken,
		abi: fanTokenABI
	},
	filmioProject: {
		address: contractsAddresses[env].filmioProject,
		abi: filmioProjectABI
	},
	staking: {
		address: contractsAddresses[env].staking,
		abi: stakingABI,
		abiV1: stakingABIV1
	},
	addressLinker: {
		address: contractsAddresses[env].addressLinker,
		abi: addressLinkerABI
	},
	filmioDao: {
		address: contractsAddresses[env].filmioDao,
		abi: filmioDaoABI
	},
	escrowWallet: {
		address: contractsAddresses[env].escrowWallet
	},
	fanPurchaseEscrow: {
		address: contractsAddresses[env].fanPurchaseEscrow
	},
	USDT: {
		address: contractsAddresses[env].USDT,
		abi: USDTABI
	},
	USDC: {
		address: contractsAddresses[env].USDC,
		abi: USDCABI
	}
}

export { ethersInstance, contractsConfig }

export default magic
