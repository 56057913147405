import _ from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	setAuthenticated,
	setCreateMagicWalletDialogOpen,
	setPlatformWalletCreatedDialogOpen
} from 'component/header/signInSlice'
import useNotification from 'hooks/useNotification'
import { isValidEmail } from 'utils/form.util'
import useLoadingMask from 'hooks/useLoadingMask'
import { addObjectToLocalStorage } from 'utils/utils'
import useAxios from 'hooks/useAxios'
import {
	isEmailAvailableService,
	ncMagicWalletClaimService,
	getProfileTransactionsService
} from 'services/profile.service'
import magic from 'utils/blockchain/config/constants'
import { AddressLinkerContract } from 'utils/blockchain/functions'
import useWeb3 from 'hooks/useWeb3'
import { reloadDaoTiers } from 'component/profile/profileSlice'
import useHeader from '../useHeader'
const useCreateMagicWalletDialog = () => {
	const { createMagicWalletDialogOpen: open } = useSelector(
		state => state.signIn
	)
	const dispatch = useDispatch()
	const [email, setEmail] = useState('')
	const { showNotification } = useNotification()
	const [errors, setErrors] = useState({})
	const { showMask, hideMask } = useLoadingMask()
	const { axiosService } = useAxios()
	const { signer, isConnectedWrongNetwork } = useWeb3()
	const { loadUserTierInfo } = useHeader()

	const completeRegisterMagicUser = async (data, error) => {
		if (error) {
			showNotification({ message: 'Process failed, please try again.' })
			return
		}
		try {
			const iToken = await magic.auth.loginWithEmailOTP({ email })
			showMask()
			const isExistentUser =
				data?.count > 0 && data?.rows.some(el => el?.statusId === 2)
			if (isExistentUser) {
				if (isConnectedWrongNetwork) {
					showNotification({
						message: 'Please connect to the required network.'
					})
					hideMask()
					return
				}
				const ownership = await AddressLinkerContract.transferFilmioOwnership(
					signer
				)

				if (_.isNil(ownership)) {
					showNotification({ message: 'Process failed, please try again.' })
					hideMask()
					return
				}
			}
			await ncMagicWalletClaim(iToken)
		} catch (error) {
			console.log({ error })
		}
		hideMask()
	}

	const authOrRegisterMagicUser = () => {
		axiosService(
			getProfileTransactionsService({
				limit: 10,
				offset: 0
			}),
			completeRegisterMagicUser
		)
	}

	const showMessageLoop = sign => {
		if (!sign) {
			showMask()
			return null
		}
		let index = 0
		const messagePrefix = `We're Upgrading Your Film.io Experience.\n`
		const initialMessage = `${messagePrefix} This will take 1-2 minutes, please hang tight.`
		showMask(initialMessage)
		const loadMessages = [
			`We’re still working on the upgrade, thanks for your patience!`,
			`You’re on your way to a much faster blockchain experience!`,
			`We’re almost there, soon you’ll be able to process multiple transactions at the same time!`,
			`Getting close!  You are on your way to a more secure and convenient no password experience!`
		]
		const intervalId = setInterval(() => {
			const message = `${messagePrefix} ${loadMessages[index]}`
			showMask(message)
			index = index === 3 ? 0 : index + 1
		}, 15000)
		return intervalId
	}

	const ncMagicWalletClaim = async iToken => {
		const intervalId = showMessageLoop(true)
		axiosService(ncMagicWalletClaimService({ iToken }), (data, error) => {
			if (!error) {
				afterAddMagicWallet(data, false)
			}
			clearInterval(intervalId)
			hideMask()
		})
	}

	const afterAddMagicWallet = (data, error) => {
		if (!error) {
			dispatch(reloadDaoTiers())

			dispatch(setAuthenticated(data))
			loadUserTierInfo()
			addObjectToLocalStorage({ ...data, isAuthenticated: true })
			setEmail('')
			dispatch(setPlatformWalletCreatedDialogOpen(true))
		}
		hideMask()
	}

	const checkIsEmailAvailable = () => {
		showMask()
		axiosService(isEmailAvailableService(email), (data, error) => {
			if (!error) {
				if (!data?.isEmailAvailable) {
					showNotification({ message: 'Email is already in use' })
					hideMask()
					dispatch(setCreateMagicWalletDialogOpen(true))
					return
				}
				authOrRegisterMagicUser()
			}
			hideMask()
		})
	}

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const isValid = () => {
		let isValid = true
		const errorsTmp = {}
		const message = 'This field is required'
		if (_.isNil(email) || email.trim() === '') {
			errorsTmp.email = message
			isValid = false
		}
		if (!_.isNil(email) && !isValidEmail(email)) {
			errorsTmp.email = 'The email format is incorrect.'
			isValid = false
		}
		updateErrors(errorsTmp)
		return isValid
	}

	const handleSubmit = () => {
		if (isValid()) {
			checkIsEmailAvailable()
			handleClose()
		} else
			showNotification({
				message: 'Please fix the form errors to continue',
				type: 'error'
			})
	}

	const emailChangeHandler = event => {
		updateErrors({ email: false })
		setEmail(_.trim(event.target.value))
	}

	const handleClose = () => {
		dispatch(setCreateMagicWalletDialogOpen(false))
	}

	const handleKeypress = e => {
		if (e.charCode === 13) {
			handleSubmit()
		}
	}

	return {
		open,
		email,
		errors,
		authOrRegisterMagicUser,
		emailChangeHandler,
		handleKeypress,
		handleSubmit,
		handleClose
	}
}

export default useCreateMagicWalletDialog
