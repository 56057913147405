import { useSelector, useDispatch } from 'react-redux'
import { setPlatformWalletCreatedDialogOpen } from 'component/header/signInSlice'

export const usePlatformWalletCreatedDialog = () => {
	const dispatch = useDispatch()
	const { platformWalletCreatedDialogOpen: open } = useSelector(
		state => state.signIn
	)

	const close = () => {
		dispatch(setPlatformWalletCreatedDialogOpen(false))
	}

	return {
		open,
		close
	}
}
