import { Box } from '@mui/material'
import styles from './Explore.module.scss'
import { ExploreCarrousel } from './exploreCarrousel'
import useExplore from './useExplore'

const Explore = () => {
	const {
		tiles,
		recent,
		favorites,
		daoProposals,
		featuredCreators,
		trending,
		newest,
		hasBanner
	} = useExplore()

	return (
		<section
			className={`${styles.explore} ${hasBanner ? styles.with_banner : ''}`}
		>
			<Box className={styles.container}>
				<ExploreCarrousel
					{...{
						title: 'DAO Proposals',
						elements: daoProposals,
						toolTip:
							'This section contains important platform updates and information',
						type: 'daoProposal'
					}}
				/>
				<ExploreCarrousel
					{...{
						title: 'Film.io Updates',
						elements: tiles,
						toolTip:
							'This section contains important platform updates and information',
						type: 'tile'
					}}
				/>
				<ExploreCarrousel
					{...{
						title: 'Your Favorites',
						elements: favorites,
						toolTip: 'Projects that you follow will appear within this section',
						type: 'favorites'
					}}
				/>
				<ExploreCarrousel
					{...{
						title: 'Featured Creators',
						elements: featuredCreators,
						toolTip: 'Creators who have been selected for promotion',
						type: 'user',
						showViewAll: false
					}}
				/>
				<ExploreCarrousel
					{...{
						title: 'Trending Projects',
						elements: trending,
						toolTip:
							'These projects have the most activity and views over the past few days',
						type: 'trending'
					}}
				/>
				<ExploreCarrousel
					{...{
						title: 'Just Added',
						elements: newest,
						toolTip: 'The latest projects added to the platform',
						type: 'newest'
					}}
				/>
				<ExploreCarrousel
					{...{
						title: 'Recently Viewed',
						elements: recent,
						toolTip: "These are projects you've visited recently",
						type: 'recent',
						showViewAll: false
					}}
				/>
			</Box>
		</section>
	)
}

export default Explore
