import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	captchaToken: null
}

export const captchaCheckSlice = createSlice({
	name: 'captchaCheck',
	initialState,
	reducers: {
		setCaptchaToken: (state, { payload }) => {
			state.captchaToken = payload
		}
	}
})

export const { setCaptchaToken } = captchaCheckSlice.actions

export default captchaCheckSlice.reducer
