import PropTypes from 'prop-types'
import { Grid, Icon, IconButton, Paper } from '@mui/material'
import { LoadMask, TeamMemberProfileLink } from 'component/common'
import styles from './TeamCard.module.scss'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { deleteProjectUseTeam } from 'services/project.service'
import useTeam from '../useTeamTab'
import useNotification from 'hooks/useNotification'
import { reloadProject } from 'component/projectDetail/projectDetailSlice'
import { useDispatch } from 'react-redux'

export default function TeamCard({
	userId,
	projectId,
	teamId,
	avatar,
	displayName,
	username,
	role,
	position,
	about
}) {
	const { showNotification } = useNotification()
	const { showAdd: showDeleteButton, fetchProjectUserTeams } = useTeam(false)
	const dispatch = useDispatch()

	const afterDelete = (_data, error) => {
		if (!error) {
			fetchProjectUserTeams()
			showNotification({ message: 'Team member deleted successfully' })
			dispatch(reloadProject())
		}
	}

	const [{ loading }, deleteUser] = useAxiosFetch(
		deleteProjectUseTeam({ userId, projectId, teamId }),
		afterDelete
	)

	const handleDeleteUser = () => {
		deleteUser()
	}

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<Grid item md={4} xs={12} key={userId}>
					<Paper elevation={0} className={styles.member_list}>
						<Grid>
							<Grid className={styles.teamCard}>
								<Grid className={styles.header}>
									<TeamMemberProfileLink
										{...{
											displayName,
											userId,
											avatar,
											username,
											role,
											position
										}}
									/>
									<IconButton
										aria-label="delete"
										size="small"
										sx={{
											color: '#fff',
											display: showDeleteButton ? 'inline-flex' : 'none'
										}}
										className={styles.close}
										onClick={handleDeleteUser}
									>
										<Icon
											baseClassName="fas"
											className="fa-regular fa-trash-can"
											fontSize={'1rem'}
										/>
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			)}
		</>
	)
}

TeamCard.propTypes = {
	userId: PropTypes.string,
	projectId: PropTypes.string,
	teamId: PropTypes.number,
	avatar: PropTypes.string,
	displayName: PropTypes.string,
	username: PropTypes.string,
	role: PropTypes.string,
	position: PropTypes.string,
	about: PropTypes.string
}
