export const getProfileFgrListByProject = ({ limit, offset }) => ({
	method: 'get',
	url: `/fgr/profile/list`,
	params: { limit, offset }
})

export const getUserFgrService = () => ({
	method: 'get',
	url: `/fgr/user`
})

export const claimAllUserFgrService = () => ({
	method: 'post',
	url: `/fgr/user/all`
})
