import _ from 'lodash'
import { useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
	airdropIsValidService,
	airdropRecoverService
} from 'services/auth.service'
import { setAirdropJoinOpen } from 'component/header/signInSlice'
import useNotification from 'hooks/useNotification'
import { isValidEmail } from 'utils/form.util'
import useLoginDialog from '../loginDialog/useLoginDialog'
import { Mixpanel } from 'services/mixpanel.service'

const useAidropJoinDialog = () => {
	const initialData = {
		email: '',
		password: '',
		firstName: '',
		lastName: ''
	}

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { userId, token } = useParams()
	const [data, setData] = useState(initialData)
	const [newsletter, setNewsletter] = useState(false)
	const [passwordtoggle, setPasswordtoggle] = useState(true)
	const { airdropJoinOpen: open } = useSelector(state => state.signIn)
	const { showNotification } = useNotification()
	const [errors, setErrors] = useState({})
	const { handleLogin } = useLoginDialog()

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const clearForm = () => {
		setData(initialData)
		setNewsletter(false)
		setPasswordtoggle(true)
		setErrors({})
	}

	const afterResetPassword = (_response, error) => {
		if (!error) {
			const { email, password } = data
			handleLogin(email, password)
			handleClose()
		} else {
			navigate('/')
		}
	}

	const [{ loading }, registerUser] = useAxiosFetch(
		airdropRecoverService({
			password: data.password,
			firstName: data.firstName,
			lastName: data.lastName,
			userId,
			token
		}),
		afterResetPassword
	)

	const afterCheckValidUser = (response, error) => {
		if (!error) {
			if (response.isValid) {
				const { firstName, lastName, id, displayName, email } = response?.user
				setData({ firstName, lastName, id, displayName, email, password: '' })
				Mixpanel.track('airdrop_user_validate_evt', {
					distinct_id: id,
					firstName,
					lastName,
					id,
					displayName,
					email
				})
				dispatch(setAirdropJoinOpen(true))
			} else {
				navigate('/')
				showNotification({
					message: 'This link is expired. You can check again for Early Access'
				})
			}
		}
	}

	const [{ loading: loadingIsValid }] = useAxiosFetch(
		airdropIsValidService(
			{
				userId,
				token
			},
			open
		),
		afterCheckValidUser
	)

	const isValid = () => {
		let isValid = true
		const errorsTmp = {}
		const message = 'This field is required'
		if (_.isNil(data.firstName) || data.firstName.trim() === '') {
			errorsTmp.firstName = message
			isValid = false
		}
		if (_.isNil(data.lastName) || data.lastName.trim() === '') {
			errorsTmp.lastName = message
			isValid = false
		}
		if (_.isNil(data.email) || data.email.trim() === '') {
			errorsTmp.email = message
			isValid = false
		}
		if (_.isNil(data.password) || data.password.trim() === '') {
			errorsTmp.password = message
			isValid = false
		}
		if (!_.isNil(data.email) && !isValidEmail(data.email)) {
			errorsTmp.email = 'The email format is incorrect.'
			isValid = false
		}
		if (!_.isNil(data.password) && !containsUpperAndLowerCase(data.password)) {
			errorsTmp.password =
				'The password should contain upper and lower case characters.'
			isValid = false
		}
		if (
			!_.isNil(data.password) &&
			!containsNumberOrSpecialChar(data.password)
		) {
			errorsTmp.password =
				'The password should contain some number or special character.'
			isValid = false
		}
		if (!_.isNil(data.password) && data.password.length < 8) {
			errorsTmp.password = 'The password should have at least 8 characters.'
			isValid = false
		}
		updateErrors(errorsTmp)
		return isValid
	}

	const handleSubmit = () => {
		if (isValid()) registerUser()
		else
			showNotification({
				message: 'Please fix the form errors to save changes',
				type: 'error'
			})
	}

	const passwordShow = () => {
		setPasswordtoggle(!passwordtoggle)
	}

	const handleClose = () => {
		navigate('/')
		dispatch(setAirdropJoinOpen(false))
		clearForm()
	}

	const containsUpperAndLowerCase = () => {
		return /(?=.*[a-z])(?=.*[A-Z])/.test(data.password)
	}

	const containsNumberOrSpecialChar = () => {
		return /[0-9!@#$%^&*)(+=.\-_]+/.test(data.password)
	}

	const handleChange = payload => {
		const key = Object.keys(payload)[0]
		updateErrors({ [key]: false })
		setData({
			...data,
			...payload
		})
	}

	const showLoadMask = loading || loadingIsValid

	return {
		data,
		open,
		errors,
		loading: showLoadMask,
		newsletter,
		passwordShow,
		setNewsletter,
		passwordtoggle,
		handleSubmit,
		handleChange,
		handleClose
	}
}

export default useAidropJoinDialog
