import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
	projectId: null,
	profileId: null,
	search: '',
	activeFilters: [],
	items: [],
	firtsPageReload: null,
	page: {
		value: 0,
		reload: true
	}
}

export const newsreelPageSlice = createSlice({
	name: 'newsreelPage',
	initialState,
	reducers: {
		resetNewsreelPage: () => initialState,
		clearFilters: state => {
			state.activeFilters = initialState.activeFilters
		},
		removeFilter: (state, { payload }) => {
			state.activeFilters = state.activeFilters.filter(el => el !== payload)
		},
		updateActiveFilters: (state, { payload }) => {
			state.activeFilters = payload
		},
		clearItems: state => {
			state.items = initialState.items
		},
		updateItems: (state, { payload }) => {
			const newItems = [...state.items, ...payload].filter(
				(v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
			)
			state.items = newItems
		},
		insertItemsBefore: (state, { payload }) => {
			const newItems = [...payload, ...state.items].filter(
				(v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
			)
			state.items = newItems
		},
		setPage: (state, { payload }) => {
			state.page = { value: payload, reload: !state.page.reload }
		},
		setSearch: (state, { payload }) => {
			state.search = payload
		},
		commentAddedHook: (state, { payload }) => {
			const items = [...current(state.items)]
			const newItems = items.map(el => {
				if (el?.record?.id === payload?.feedId) {
					return {
						...el,
						record: {
							...el.record,
							comments: [payload, ...el?.record?.comments]
						}
					}
				}
				return el
			})
			state.items = newItems
		},
		commentDeletedHook: (state, { payload }) => {
			const items = [...current(state.items)]
			const newItems = items.map(el => {
				const newComments = el?.record?.comments.filter(
					el => el?.id !== payload
				)
				return {
					...el,
					record: {
						...el.record,
						comments: newComments
					}
				}
			})
			state.items = newItems
		},
		newsreelDeletedHook: (state, { payload }) => {
			const items = [...current(state.items)]
			const newItems = items.filter(el => {
				return el?.record?.id !== payload
			})
			state.items = newItems
		},
		reloadFirtsPage: state => {
			state.firtsPageReload = !state.firtsPageReload
		}
	}
})

export const {
	setSearch,
	clearItems,
	clearFilters,
	removeFilter,
	commentAddedHook,
	resetNewsreelPage,
	commentDeletedHook,
	newsreelDeletedHook,
	updateActiveFilters,
	insertItemsBefore,
	reloadFirtsPage,
	updateItems,
	setPage
} = newsreelPageSlice.actions

export default newsreelPageSlice.reducer
