import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Grid, Box, Typography } from '@mui/material'
import { getProjectByUserId } from 'services/project.service'
import { ProjectCarousel } from './projectCarousel'
import { useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'
import styles from './ProjectTab.module.scss'

const ProjectTab = () => {
	const { userId } = useSelector(state => state.profile)
	const [projects, setProjects] = useState([])
	const { axiosService } = useAxios()

	const getUsersProjects = () => {
		axiosService(getProjectByUserId(userId), (data, error) => {
			if (!error) {
				setProjects(data)
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(userId)) getUsersProjects()
	}, [userId])

	// const totalStakes = (projects.reduce((total,currentItem) => total + currentItem.stakesTotal , 0 ));
	// const totalViews = (projects.reduce((total,currentItem) => total + currentItem.socialMediaShares , 0 ));

	return (
		<Grid className="dashboard-product-section">
			<Grid item md={12} xs={9} className={styles.project}>
				<Box className={styles.content}>
					<Box className={styles.pending}>
						<Box className={styles.pendingDesc}>
							<h3>
								These projects
								<br />
								are pending publication.
							</h3>
							<p>
								Projects that have been submitted for approval appear here. You
								will receive an email when your project has been approved, or
								needs more work.
							</p>
						</Box>
						<ProjectCarousel {...{ items: projects, status: 2 }} />
					</Box>
				</Box>
			</Grid>
			<Grid item md={12} xs={9} className={styles.project}>
				<Box className={styles.content}>
					<Box className={styles.publishd}>
						<Box className={styles.publishdDesc}>
							<h3>
								These are your
								<br />
								published projects.
							</h3>
							<Typography>
								Projects that have been approved for publication to the
								platform, making them available for sharing, staking and
								reviewing appear here.
							</Typography>
							<Typography>
								<strong>
									If you’re added as a collaborator to a project, it will appear
									in this section.
								</strong>
							</Typography>
						</Box>
						<ProjectCarousel {...{ items: projects, status: 4 }} />
					</Box>
				</Box>
			</Grid>
			<Grid item md={12} xs={9} className={styles.project}>
				<Box className={styles.content}>
					<Box className={styles.draft}>
						<Box className={styles.draftDesc}>
							<h3>
								These are your
								<br />
								draft projects.
							</h3>
							<Typography>
								Projects that are in progress, but haven’t been approved for
								publication to the platform appear here.
							</Typography>
						</Box>
						<ProjectCarousel {...{ items: projects, status: 1 }} />
					</Box>
				</Box>
			</Grid>
			{/* <Grid item md={12} xs={9} className={styles.project}>
				<Box className={styles.content}>
					<Box className={styles.pending}>
						<Grid item md={12} className={styles.pendingDesc}>
							<h3>These projects<br/>are pending publication.</h3>
							<p>Projects that have been submitted for approval appear here. You will receive an email when your project has been approved, or needs more work.</p>
						</Grid>
						<ProjectCarousel {...{ items: projects, status: 2 }} />
					</Box>
				</Box>
			</Grid> */}
		</Grid>
	)
}

export default ProjectTab
