import { Box } from '@mui/material'
import { TopGenreProject } from './topGenreProject'
import styles from './TopGenres.module.scss'
import useTopGenres from './useTopGenres'

const TopGenres = () => {
	const { projects } = useTopGenres()

	const renderProjects = (projects = []) =>
		projects.map(project => (
			<TopGenreProject {...{ project }} key={project.id} />
		))

	return <Box className={styles.top_genres}>{renderProjects(projects)}</Box>
}

export default TopGenres
