import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from '../header/Header'
import { Sidebar } from '../sidebar'
import { useSelector } from 'react-redux'
import ReactConfetti from 'react-confetti'
import { useWindowSize } from 'react-use'
import { LoadMask } from '../../common'

import { Toaster } from '@/components/ui/toaster'
import { BanDialog } from '../../header/banDialog'
import { LoginDialog } from '../../header/loginDialog'
import { SaleMagicDialog } from '../../header/saleMagicDialog'
import { JoinDialog } from '../../header/joinDialog'
import { StartDialog } from '../../header/startDialog'
import { AirdropCheckDialog } from '../../header/airdropCheckDialog'
import { AirdropSignUpDialog } from '../../header/airdropSignUpDialog'
import { getReactions } from '../../../services/reaction.service'
import { useDispatch } from 'react-redux'
import { populateReactions } from '../../common/reactions/reactionSlice'
import { OnRampDialog } from '../../header/onRampDialog'
import { ContestJoinedDialog } from '../../projectDetail/contestJoinedDialog/contestJoinedDialog'
import { CongratsDialog } from '../../header/congratsDialog'
import { FeatureModalDialog } from '../../header/featureModalDialog'
import { PlatformWalletCreatedDialog } from '../../header/platformWalletCreatedDialog'
import { CreatePlatformWalletDialog } from '../../header/createPlatformWalletDialog'
import { NcWihoutMagicWarning } from '../../header/ncWihoutMagicWarning'
import FraudWarningDialog from '../../header/fraudWarningDialog/FraudWarningDialog'
import { CreateMagicWalletDialog } from '../../header/createMagicWalletDialog'
import { ClaimFgrDialog } from '../../header/claimFgrDialog'
import { ImportFanTokenDialog } from '../../header/importFanTokenDialog'
import { AddProjectDialog } from '../../header/addProjectDialog'
import { DaoTierLevelingDialog } from '../../header/daoTierLevelingDialog'
import { DaoTierLevelChangeDialog } from '../../header/daoTierLevelChangeDialog'
import { VerificationEmailDialog } from '../../profile/dashboardTab/verificationEmailDialog/VerificationEmailDialog'
import { MagicEmailUpdateDialog } from '../../profile/dashboardTab/magicEmailUpdateDialog/MagicEmailUpdateDialog'
import useLoginDialog from '../../header/loginDialog/useLoginDialog'
import useWeb3 from '../../../hooks/useWeb3'
import useHeader from '../../header/useHeader'

const Layout = () => {
	const dispatch = useDispatch()
	const {
		showConfetti,
		frontTransactionsInProgress,
		socialJoinParams,
		isAuthenticated,
		joinDialogOpen,
		createPlatformWalletDialogOpen,
		showLoading,
		user
	} = useSelector(state => state.signIn)
	const { width, height } = useWindowSize()
	const { reactions } = useSelector(state => state.reactions)
	const { captchaToken } = useSelector(state => state.captchaCheck)
	const { signer, address, isConnected } = useWeb3()
	const { authNcWallet } = useLoginDialog()
	const { handleLogout, relaodUserInfo } = useHeader()

	const fanTokenImported = user?.fanTokenImported === 1

	useEffect(() => {
		const fetchReactions = async () => {
			const reactions = await getReactions()
			dispatch(populateReactions(reactions.data))
		}

		if (isAuthenticated) {
			relaodUserInfo()
		}

		if (isAuthenticated && reactions.length === 0) {
			fetchReactions()
		}
	}, [isAuthenticated, reactions])

	useEffect(() => {
		const handleBeforeUnload = event => {
			event.preventDefault()
		}
		const handlePageHide = event => {
			event.stopPropagation()
		}

		if (frontTransactionsInProgress) {
			window.addEventListener('beforeunload', handleBeforeUnload)
			window.addEventListener('pagehide', handlePageHide)
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload)
			window.removeEventListener('pagehide', handlePageHide)
		}
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
			window.removeEventListener('pagehide', handlePageHide)
		}
	}, [frontTransactionsInProgress])

	useEffect(() => {
		const authUser = async () => {
			if (address && signer && !isAuthenticated) {
				try {
					const message =
						'Please sign this message to verify you are the owner of this wallet.'
					const { hash, signature } = await signVerificationMessage(
						signer,
						message
					)
					authNcWallet({
						captchaToken,
						hash,
						signature,
						address,
						...socialJoinParams
					})
				} catch (error) {
					await handleLogout()
					console.log('reloaded here')
					window.location.reload() // TODO find why is needed the reload and search for a better solution
				}
			}
		}
		authUser()
	}, [address, signer, isAuthenticated, isConnected])

	return (
		<>
			<div className="flex h-screen flex-col overflow-hidden">
				<Header className="sticky top-0 z-10" />
				<div className="flex flex-1 overflow-hidden">
					{isAuthenticated ? (
						<Sidebar className="sticky top-0 z-10 h-screen" />
					) : null}
					<main
						id="scroll-provider"
						className="custom-scrollbar relative flex-1 overflow-auto"
					>
						<div className="mx-auto h-full w-full max-w-screen-2xl px-4">
							<Outlet />
						</div>
					</main>
				</div>
			</div>

			{isAuthenticated ? (
				<>
					<AddProjectDialog />
					<DaoTierLevelingDialog />
					<DaoTierLevelChangeDialog />
					{!fanTokenImported && <ImportFanTokenDialog />}
					<ClaimFgrDialog />
					<VerificationEmailDialog />
					<CreateMagicWalletDialog />
					<MagicEmailUpdateDialog />
					<FraudWarningDialog />
					<NcWihoutMagicWarning />
					<CreatePlatformWalletDialog
						open={createPlatformWalletDialogOpen}
						username={user.username}
					/>
					<PlatformWalletCreatedDialog />
					<FeatureModalDialog />
					<CongratsDialog />
					<ContestJoinedDialog />
					<OnRampDialog />
				</>
			) : (
				<>
					<BanDialog />
					<LoginDialog />
					<SaleMagicDialog />
					{joinDialogOpen && <JoinDialog />}
					<StartDialog />
					<AirdropCheckDialog />
					<AirdropSignUpDialog />
				</>
			)}

			{showLoading && <LoadMask />}
			{showConfetti && <ReactConfetti width={width} height={height} />}

			<Toaster />
		</>
	)
}

export default Layout
