import PropTypes from 'prop-types'
import { useShareForm } from './useShareForm'
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils' // A utility function for conditional Tailwind classes if needed
import { useState } from 'react'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { GoShareAndroid } from 'react-icons/go'
import { Label } from '@/components/ui/label'

export const ShareForm = ({ project }) => {
	const [isOpen, setIsOpen] = useState(false)

	function closeMenu() {
		setIsOpen(false)
	}
	const {
		open,
		link,
		shareLink,
		message,
		limitChars,
		openDialog,
		setMessage,
		handleClose,
		handleCopy,
		handleCopyMobile,
		projectCover,
		handleCopyLink
	} = useShareForm({ closeMenu: closeMenu, project: project })

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button variant="table" className="p-2 w-8 h-9">
						<GoShareAndroid />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className="w-56 text-white">
					<DropdownMenuItem
						onSelect={handleCopyLink}
						disabled={isOpen}
						className={cn(
							'flex items-center space-x-2 cursor-pointer hover:opacity-70',
							isOpen && 'opacity-50 cursor-not-allowed'
						)}
					>
						<i className="fas fa-envelope mr-2" />
						<span>
							Copy <strong>Link</strong>
						</span>
					</DropdownMenuItem>

					<DropdownMenuItem
						onSelect={() => openDialog('custom')}
						disabled={isOpen}
						className={cn(
							'flex items-center space-x-2 cursor-pointer hover:opacity-70',
							isOpen && 'opacity-50 cursor-not-allowed'
						)}
					>
						<i className="fas fa-hammer-brush mr-2" />
						<span>
							Custom <strong>Share</strong>
						</span>
					</DropdownMenuItem>

					<DropdownMenuItem
						onSelect={() => openDialog('x')}
						disabled={isOpen}
						className={cn(
							'flex items-center space-x-2 cursor-pointer hover:opacity-70',
							isOpen && 'opacity-50 cursor-not-allowed'
						)}
					>
						<i className="fab fa-x-twitter mr-2" />
						<span>
							Share on <strong>Twitter/X</strong>
						</span>
					</DropdownMenuItem>

					<DropdownMenuItem
						onSelect={() => openDialog('facebook')}
						disabled={isOpen}
						className={cn(
							'flex items-center space-x-2 cursor-pointer hover:opacity-70',
							isOpen && 'opacity-50 cursor-not-allowed'
						)}
					>
						<i className="fab fa-facebook-f mr-2" />
						<span>
							Share on <strong>Facebook</strong>
						</span>
					</DropdownMenuItem>

					<DropdownMenuItem
						onSelect={() => openDialog('linkedin')}
						disabled={isOpen}
						className={cn(
							'flex items-center space-x-2 cursor-pointer hover:opacity-70',
							isOpen && 'opacity-50 cursor-not-allowed'
						)}
					>
						<i className="fab fa-linkedin mr-2" />
						<span>
							Share on <strong>LinkedIn</strong>
						</span>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>

			{open && (
				<Dialog open={open} onOpenChange={handleClose}>
					<DialogContent className="sm:max-w-[335px]">
						<DialogHeader>
							<DialogTitle className="text-xl tracking-[-0.025em] font-semibold">
								Share
							</DialogTitle>
						</DialogHeader>
						<div className="space-y-4">
							<img
								className="w-full rounded-lg h-48"
								src={project.poster}
								alt="cover"
							/>

							<Label>Message</Label>
							<Textarea
								placeholder="Message"
								value={message}
								maxLength={limitChars}
								onChange={e => setMessage(e.target.value)}
								className="w-full custom-scrollbar text-xs"
								rows={4}
							/>

							<a
								href="#"
								onClick={handleCopyMobile}
								className="w-full sm:hidden text-white flex items-center justify-end text-sm space-x-2 hover:cursor-pointer"
							>
								<i className="far fa-copy"></i>
								<span>Copy your Message</span>
							</a>
							<span className="text-white flex items-center text-sm space-x-2">
								Link
							</span>
							<div className="mt-2 text-gray-800">
								<Input
									value={link}
									readOnly
									className="w-full cursor-not-allowed"
								/>
							</div>
							<div className="text-sm">
								When you click <b>share</b>, your message will be copied to the
								clipboard. Simply paste it into the share box on your social
								network of choice.
							</div>
							<div className="flex justify-end space-x-2">
								<Button variant="secondary" onClick={handleClose}>
									Cancel
								</Button>
								<Button variant="outline" className='text-white' onClick={handleCopy}>Share!</Button> 
							</div>
						</div>
					</DialogContent>
				</Dialog>
			)}
		</>
	)
}

ShareForm.propTypes = {
	isOpen: PropTypes.bool,
	closeMenu: PropTypes.func
}
