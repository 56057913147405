// components/shared/InteractionsBar.tsx
import { Button } from '@/components/ui/button'
import { ChatBubbleIcon } from '@radix-ui/react-icons'
import { Reaction } from '../../types'
import { ReactionList, Reactions } from './Reactions'

type InteractionType = 'newsreel' | 'review' | 'stake' | 'comment'

interface BaseInteractionsBarProps {
	id: string
	type: InteractionType
	reactions: Reaction[]
	setReactions: (reactions: Reaction[]) => void
}

interface WithCommentsProps extends BaseInteractionsBarProps {
	hasComments: true
	commentsCount: number
	onCommentClick: () => void
}

interface WithoutCommentsProps extends BaseInteractionsBarProps {
	hasComments: false
	commentsCount?: never
	onCommentClick?: never
}

type InteractionsBarProps = WithCommentsProps | WithoutCommentsProps

export const InteractionsBar = ({
	id,
	type,
	commentsCount,
	reactions,
	hasComments,
	setReactions,
	onCommentClick
}: InteractionsBarProps) => (
	<div className="flex items-center gap-2 pt-2">
		{hasComments ? (
			<Button
				variant="ghost"
				size="sm"
				className="text-gray-400"
				onClick={onCommentClick}
			>
				<ChatBubbleIcon className="mr-1.5 h-4 w-4" />
				{commentsCount}
			</Button>
		) : null}
		<div className="flex items-center gap-2">
			<Reactions
				feedId={id}
				type={type}
				setReactions={setReactions}
				className="ml-auto"
			/>
			<ReactionList
				reactions={reactions}
				feedId={id}
				type={type}
				setReactions={setReactions}
			/>
		</div>
	</div>
)
