import { useDispatch, useSelector } from 'react-redux'
import {
	setMenuFanOpen,
	setShowFanDashboardTierInfoMenu
} from 'component/header/signInSlice'
import { useState } from 'react'
export default function useProfileMenu() {
	const dispatch = useDispatch()
	const [anchorEl, setAnchorEl] = useState(null)
	const {
		menuFanOpen,
		user: signInUser,
		showFanDashboardTierInfoMenu
	} = useSelector(state => state.signIn)

	const handleOpen = event => {
		setAnchorEl(event.currentTarget)
		dispatch(setMenuFanOpen(true))
	}

	const handleClose = () => {
		dispatch(setMenuFanOpen(false))

		setTimeout(() => {
			dispatch(setShowFanDashboardTierInfoMenu(false))
		}, 500)
	}
	function stringAvatar(name, wh = '2.2rem') {
		if (!name) name = '?'
		const splitedName = name.split(' ').map(el => el?.toUpperCase())
		return {
			sx: {
				bgcolor: '#000000',
				border: '1px solid #ffffff',
				height: wh,
				width: wh,
				marginRight: '12px'
			},
			children: `${_.get(splitedName, [0, 0], '')}${_.get(
				splitedName,
				[1, 0],
				''
			)}`
		}
	}
	const isDaoTierConfirmed = Number(signInUser?.daoTierConfirmed) === 1
	const isDaoTierCompleted = Number(signInUser?.daoTierCompleted) === 1
	const upgradeAvailable =
		(!isDaoTierConfirmed && isDaoTierCompleted) ||
		Number(signInUser?.nextTierCompleted) === 1

	return {
		anchorEl,
		menuFanOpen,
		signInUser,
		showFanDashboardTierInfoMenu,
		upgradeAvailable,
		handleClose,
		handleOpen,
		stringAvatar
	}
}
