import { Box } from '@mui/material'
import styles from './GoScoreImpactBar.module.scss'
import PropTypes from 'prop-types'

const GoScoreImpactBar = ({ value1, value2, value3, value4 }) => {
	const magnifingValues = value => {
		if (value > 0 && value < 2) return 2
		return value
	}

	return (
		<Box className={styles.progress_container}>
			<Box
				className={styles.progress_bar1}
				style={{ width: `${magnifingValues(value1)}%` }}
			></Box>
			<Box
				className={styles.progress_bar2}
				style={{ width: `${magnifingValues(value2)}%` }}
			></Box>
			<Box
				className={styles.progress_bar3}
				style={{ width: `${magnifingValues(value3)}%` }}
			></Box>
			<Box
				className={styles.progress_bar4}
				style={{ width: `${magnifingValues(value4)}%` }}
			></Box>
		</Box>
	)
}

GoScoreImpactBar.propTypes = {
	value1: PropTypes.number,
	value2: PropTypes.number,
	value3: PropTypes.number,
	value4: PropTypes.number
}

export default GoScoreImpactBar
