import { Button, Box } from '@mui/material'
import { TextFieldControl } from 'component/common'
import styles from './AddStakeForm.module.scss'
import { useAddStakeForm } from './useAddStakeForm'
import { NumericFormat } from 'react-number-format'

export const AddStakeForm = () => {
	const {
		user,
		limit,
		errors,
		amount,
		project,
		balance,
		inputRef,
		stakeLimit,
		loadingLimit,
		disableAddBtn,
		isAbleToStake,
		isProjectOwner,
		handleStakeClick,
		handleRestoreForm,
		handleKeypress,
		handleFocus,
		handleChangeForm
	} = useAddStakeForm()

	return (
		<>
			{!loadingLimit && (
				<Box id={styles.stake_dialogue}>
					<p className={styles.headline}>Stake to {project.title}</p>
					<Box>
						<TextFieldControl
							inputRef={inputRef}
							type="number"
							error={errors}
							onKeyPress={handleKeypress}
							onFocus={handleFocus}
							value={amount}
							variant={'blue'}
							autoFocus
							onChange={e => {
								handleChangeForm(e.target.value)
							}}
						/>
						<small
							className={!isAbleToStake || limit < amount ? styles.danger : ''}
						>
							{limit >= amount &&
								(isAbleToStake ? (
									<>
										FGR: {user?.fgrRate}% <span className={styles.space}></span>{' '}
										Project Staking Limit:{' '}
										<span className={styles.green}>
											<NumericFormat
												value={stakeLimit}
												displayType="text"
												thousandSeparator=","
											/>
										</span>{' '}
										FAN (your stake:{' '}
										<span className={styles.green}>
											{(stakeLimit - limit).toFixed(2)})
										</span>
										. <span className={styles.space}></span>You can stake{' '}
										<span className={styles.green}>
											<NumericFormat
												value={limit}
												displayType="text"
												thousandSeparator=","
											/>
										</span>{' '}
										more FAN tokens.
									</>
								) : (
									<>
										You don’t have enough tokens. You can stake up to{' '}
										<strong>{balance} FAN</strong>
									</>
								))}
							{limit < amount && (
								<>
									This exceeds the staking limit of{' '}
									<strong>{stakeLimit} FAN</strong>. You can stake up to{' '}
									<strong>{limit} FAN</strong>.
								</>
							)}
						</small>
						<Box className={styles.controls}>
							<Button className="cancel-button" onClick={handleRestoreForm}>
								Clear
							</Button>
							{!isProjectOwner && (
								<Button
									className={styles.yellow_button}
									onClick={handleStakeClick(amount)}
									disabled={disableAddBtn}
								>
									Stake
								</Button>
							)}
						</Box>
					</Box>
				</Box>
			)}
		</>
	)
}
