import { Grid, Icon, Typography, Box } from '@mui/material'
import { SocialMediaShareMenu } from './socialMediaShareMenu'
import { RecentActivity } from './recentActivity'
import { Stages } from './stages'
import { ImageWithViewer } from 'component/common'
import { TrailerDialog } from './trailerDialog'
import { SocialButton } from './socialButton'
import useHomeTab, { ContestDialogState } from './useHomeTab'
import { GoScoreUnlockProgress } from './goScoreUnlockProgress'
import { GoScoreBadges } from '../dashboardTab/goScoreBadges'
import { Medias } from '../dashboardTab/medias'
import { Link } from 'react-router-dom'
import { TopGenres } from './topGenres'
import { TAB_MAP } from '../tabsSection'
import { RecentSignupsTable } from './recentSignupsTable'
import styles from './HomeTab.module.scss'
import { ReferralTrackingSection } from 'component/common/referralTrackingSection'
import { StatItem } from 'component/common/statItem'
import { ActiveContestBadge } from '../../dashboard/contests/badge/ActiveContestBadge'
import { JoinContestDialog } from '../joinContestDialog/joinContestDialog'

const HomeTab = () => {
	const {
		project,
		trailerInfo,
		signupsStats,
		configuration,
		handleGoToEditProject,
		handleSocialClick,
		canEnrollInContest,
		contestDialogState,
		openReadOnlyJoinContestDialog,
		openAgreementJoinContestDialog,
		closeJoinContestDialog,
		activeContest
	} = useHomeTab()

	const {
		id,
		title,
		genres,
		poster,
		coverImg,
		thumbnail,
		goScoreStage,
		stageId
	} = project

	const buildCategoriesLine = () =>
		genres?.map(g => <li key={`genre-${g.id}`}>{g.name}</li>)

	const isNotPublished = stageId !== 4
	const disableSocialShareLink =
		configuration?.enableProjectShare === 0 || isNotPublished

	return (
		<Grid container spacing={2}>
			<Grid item container md={12} className={styles.header_title} spacing={1}>
				<Grid item md={6}>
					<Typography>
						<strong>Project</strong> Status
					</Typography>
				</Grid>
				<Grid item md={6}>
					<Typography>
						Grow <strong>{title}</strong>
					</Typography>
				</Grid>
			</Grid>
			<Grid item container md={12} spacing={1} className={styles.header}>
				<Grid item container md={6}>
					<Grid item container md={12} className={styles.status}>
						<GoScoreUnlockProgress />
						<Grid item md={5} className={styles.grow_hacks}>
							<Typography className={styles.title}>Growth Hacks</Typography>
							<Box className={styles.info}>
								<Icon baseClassName="fal" className="fa-circle-1" />
								<Typography>
									Invite people! People you invite are more likely to be active
									on your project.
								</Typography>
							</Box>
							{disableSocialShareLink ? (
								<SocialButton
									{...{
										text: 'Share your Project',
										icon: 'share-from-square',
										disabled: true
									}}
								/>
							) : (
								<SocialMediaShareMenu />
							)}
							<Box className={styles.info}>
								<Icon baseClassName="fal" className="fa-circle-2" />
								<Typography>
									Be active on your newsreel, and make sure to add pictures to
									your posts.
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container md={6}>
					<Box className={styles.grow}>
						{canEnrollInContest ? (
							<SocialButton
								{...{
									disabled: isNotPublished,
									text: 'Enter Contest',
									img: !isNotPublished && (
										<ActiveContestBadge
											height={23}
											width={23}
											src={activeContest?.badge}
										/>
									),
									dark: true,
									handleClick: openAgreementJoinContestDialog
								}}
							/>
						) : activeContest ? (
							<SocialButton
								{...{
									text: 'Contest Joined',
									img: (
										<ActiveContestBadge
											height={23}
											width={23}
											src={activeContest?.badge}
										/>
									),
									dark: true,
									handleClick: openReadOnlyJoinContestDialog
								}}
							/>
						) : null}
						{isNotPublished ? (
							<SocialButton
								{...{
									text: 'Post a message',
									icon: 'comment',
									disabled: true
								}}
							/>
						) : (
							<SocialButton
								{...{ text: 'Post a message', icon: 'comment' }}
								handleClick={handleSocialClick(TAB_MAP.newsreel)}
							/>
						)}
						<SocialButton
							{...{ text: 'Add Assets', icon: 'rectangle-history-circle-plus' }}
							handleClick={handleGoToEditProject}
						/>
						<SocialButton
							{...{
								text: 'Donation Campaign',
								icon: 'circle-dollar-to-slot',
								disabled: true
							}}
						/>
						<SocialButton
							{...{
								text: 'Create an FET',
								icon: 'file-circle-plus',
								disabled: true
							}}
						/>
						<SocialButton
							{...{
								text: 'NFT Campaign',
								icon: 'hexagon-vertical-nft',
								disabled: true
							}}
						/>
					</Box>
				</Grid>
			</Grid>

			<Grid item container md={12}>
				<Grid item md={12}>
					<Typography>
						The GoScore Journey for <strong>{title}</strong>
					</Typography>
				</Grid>
				<Grid item container md={12}>
					<Stages />
				</Grid>
			</Grid>

			<Grid item container md={12} spacing={4}>
				<Grid item container md={6}>
					<Grid item md={12} className={styles.top_genres}>
						<ReferralTrackingSection>
							<StatItem
								{...{
									label: 'Fans Joined',
									amount: signupsStats?.count,
									variant: 'green_highlight'
								}}
							/>
							<StatItem
								{...{
									label: 'Joined Today',
									amount: signupsStats?.joinedToday
								}}
							/>
							<StatItem
								{...{
									label: 'Joined Yesterday',
									amount: signupsStats?.joinedYesterday
								}}
							/>
							{/* <StatItem
								{...{
									label: 'Fan Earned',
									amount: signupsStats?.fanEarned ?? 0,
									variant: 'light_blue'
								}}
							/> */}
						</ReferralTrackingSection>
						<RecentSignupsTable />
						<Box className={styles.header}>
							<Typography component={'h3'}>
								Top Projects in Your Genres
							</Typography>
							<Typography>
								Seeing what is working for other projects like yours is a good
								way to decide what to change or improve.
							</Typography>
						</Box>
						<ul className={styles.categories}>
							{buildCategoriesLine()}
							<li>
								<Link to={`/project/edit/${id}`}>Edit</Link>
							</li>
						</ul>

						<TopGenres />
					</Grid>
				</Grid>

				<Grid item container md={6}>
					<Grid item md={12} className={styles.projectinfo}>
						<Box className={styles.header}>
							<h5>About this Project</h5>
							<ul className={styles.categories}>
								<li
									key="goscore"
									style={{ backgroundColor: goScoreStage?.color }}
								>
									{goScoreStage?.name}
								</li>
							</ul>
						</Box>

						<Box className={styles.media}>
							<Box className={styles.media_header}>
								<Typography component={'h3'}>Attached Media</Typography>
								<Link to={`/project/edit/${id}`}>Edit</Link>
							</Box>
							<Medias {...{ items: [poster, coverImg, thumbnail] }}>
								{trailerInfo?.thumbnail_url && (
									<TrailerDialog
										{...{
											thumbnail: trailerInfo?.thumbnail_url,
											trailerSrc: trailerInfo?.player_embed_url
										}}
									/>
								)}
								{poster && <ImageWithViewer src={poster} />}
								{coverImg && <ImageWithViewer src={coverImg} />}
								{thumbnail && <ImageWithViewer src={thumbnail} />}
							</Medias>
						</Box>
					</Grid>
					<Grid item md={12} className={styles.badges_achievements}>
						<Grid item md={12} className={styles.badges}>
							<Typography component={'h3'}>
								{'Badges & Achievements'}
							</Typography>
							<GoScoreBadges />
						</Grid>
						<Grid item md={12} className={styles.recent_activity}>
							<Typography component={'h3'}>Recent Activity</Typography>
							<RecentActivity />
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<JoinContestDialog
				projectId={project.id}
				contestId={activeContest?.id}
				open={contestDialogState !== ContestDialogState.CLOSED}
				readOnly={contestDialogState === ContestDialogState.OPENED_AS_READ_ONLY}
				handleClose={closeJoinContestDialog}
			/>
		</Grid>
	)
}

export default HomeTab
