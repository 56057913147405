export const loginService = data => ({
	method: 'post',
	url: '/user/login',
	data
})

export const socialLoginService = data => ({
	method: 'post',
	url: '/user/socialLogin',
	data,
	autoexec: true
})

export const registerService = data => ({
	method: 'post',
	url: '/user',
	data
})

export const claimUserRewardsService = data => ({
	method: 'post',
	url: '/user/claimRewards',
	data
})

export const authTokenSaleUserService = data => ({
	method: 'post',
	url: '/user/authTokenSaleUser',
	data
})

export const authNcWalletService = data => ({
	method: 'post',
	url: '/user/authNcWallet',
	data
})

export const getMagicStatusService = data => ({
	method: 'post',
	url: '/user/magicStatus',
	data
})

export const updateUserSaleEmailService = data => ({
	method: 'put',
	url: '/user/saleEmail',
	data
})

export const resetPasswordService = data => ({
	method: 'put',
	url: '/user/resetPassword',
	data,
	autoexec: false
})

export const airdropRecoverService = data => ({
	method: 'put',
	url: '/user/airdropJoin',
	data,
	autoexec: false
})

export const resetPasswordIsValidService = ({ userId, token }) => ({
	method: 'get',
	url: `/user/resetPassword/${userId}/${token}`,
	autoexec: true
})

export const airdropIsValidService = ({ userId, token }) => ({
	method: 'get',
	url: `/user/airdropJoin/${userId}/${token}`,
	autoexec: userId && token
})

export const verifyAirdropJoin = email => ({
	method: 'post',
	url: '/user/airdropJoin',
	data: { email },
	autoexec: false
})
