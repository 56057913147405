import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { updateMetaTags } from 'utils/utils'
import { DashboardProduct } from './dashboardProduct'
import { Explore } from './explore'
import { SidebarDashboard } from './sidebarDashboard'
import { useEffect } from 'react'
import { clearFilters, updateFilters } from './dashboardSlice'
import { ActiveContestBanner } from './contests/ActiveContestBanner'

const Dashboard = () => {
	const { filter } = useSelector(state => state.dashboard)
	const dispatch = useDispatch()

	const isFilterEmpty = _.isEmpty([
		...filter?.types,
		...filter?.stages,
		...filter?.genres
	])

	updateMetaTags({})

	useEffect(
		() => () => {
			dispatch(clearFilters())
			dispatch(updateFilters({ types: ['project'] }))
		},
		[]
	)

	return (
		<section>
			<ActiveContestBanner />
			<SidebarDashboard />
			{isFilterEmpty ? <Explore /> : <DashboardProduct />}
		</section>
	)
}

export default Dashboard
