import {
	Box,
	FormControl,
	FormHelperText,
	TextareaAutosize,
	Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import styles from './TextArea.module.scss'

export const TextArea = ({
	variant = 'black',
	label,
	limitChars,
	helperText,
	error,
	...other
}) => {
	const classesArr = [styles.form_control_ta, styles[variant]]
	if (error) classesArr.push(styles.error)
	const classes = classesArr.join(' ')
	return (
		<FormControl className={classes}>
			<Box className={styles.top}>
				{label && <Typography component="h3">{label}</Typography>}
				{limitChars && (
					<em>
						{`${other?.value?.length ?? 0}`}/{limitChars}
					</em>
				)}
			</Box>
			<TextareaAutosize {...other} />
			<FormHelperText>{error || helperText}</FormHelperText>
		</FormControl>
	)
}

TextArea.propTypes = {
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	helperText: PropTypes.string,
	variant: PropTypes.string,
	label: PropTypes.string,
	limitChars: PropTypes.number,
	other: PropTypes.object
}
