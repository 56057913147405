import { isNil } from 'lodash'
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Icon,
	Typography
} from '@mui/material'
import { CustomDialog } from 'component/common'
import { useValidateIpDialog } from './useValidateIpDialog'
import styles from './ValidateIpDialog.module.scss'
import useHeader from 'component/header/useHeader'

export const ValidateIpDialog = () => {
	const {
		error,
		approved,
		locationAllowed,
		validateIpDialogOpen,
		showListOfUnallowedLocations,
		handleGoToNext,
		setApproved
	} = useValidateIpDialog()
	const { handleLogout } = useHeader()

	const regionClass = !locationAllowed ? 'danger' : 'success'
	const regionText = !locationAllowed
		? 'Prohibited Jurisdiction Detected!'
		: 'You passed the Region Check'
	const regionIcon = !locationAllowed ? (
		<Icon baseClassName="far" className={`fa-warning`} />
	) : (
		<Icon baseClassName="far" className={`fa-check`} />
	)

	const listOfCountries = (
		<span
			className={styles.country_list_text}
			onClick={showListOfUnallowedLocations(styles.country_list)}
		>
			{' '}
			list of prohitibited jurisdictions here
		</span>
	)

	return (
		<CustomDialog
			{...{
				open: validateIpDialogOpen,
				handleClose: handleLogout,
				variant: 'detect_dialogue',
				onClose: null
			}}
		>
			{error ? (
				<Box className={[styles.danger, styles.detect]} key={'boxError'}>
					<span>
						<Icon baseClassName="far" className={`fa-warning`} />
					</span>
					We cannot check your location. Disable adblock and reload to ensure a
					smooth experience. Welcome to our exclusive token pre-sale at Film.io!
				</Box>
			) : (
				<>
					{!isNil(locationAllowed) ? (
						<Box className={[styles[regionClass], styles.detect]} key={'box1'}>
							<span>{regionIcon}</span>
							{regionText}
						</Box>
					) : (
						<Box className={styles.detect} key={'box2'}>
							<span>
								<Icon baseClassName="far" className="fa-spinner fa-spin" />
							</span>
							Checking Your Region...
						</Box>
					)}
				</>
			)}
			<Box className={styles.header}>
				{!error && (
					<>
						{!locationAllowed ? (
							<>
								<Typography>
									<strong>
										Unfortunately access to the pre-sale is restricted to
										individuals of
										{listOfCountries}.
									</strong>
								</Typography>
							</>
						) : (
							<>
								<Typography className={styles.headline}>
									Welcome to the <strong>Film.io FAN Token Pre-sale!</strong>
								</Typography>
								<Typography>
									Clicking this
									{listOfCountries} will open a complete list of jurisdictions
									prohibited from this sale.
								</Typography>
								<span
									className={`${styles.checkbox} ${
										isNil(locationAllowed) ? styles.disabled : ''
									}`}
								>
									<FormControlLabel
										label={
											<Typography>
												Checking this box confirms that you are not a resident
												of any prohibited jurisdictions.
											</Typography>
										}
										control={
											<Checkbox
												disabled={isNil(locationAllowed)}
												checked={approved}
												onChange={event => {
													setApproved(event.target.checked)
												}}
											/>
										}
									/>
								</span>
							</>
						)}
					</>
				)}
			</Box>
			<Box className={styles.content}>
				<Box className={styles.footer}>
					{error ? (
						<>
							<Button
								onClick={() => {
									window.location.reload()
								}}
							>
								Reload
							</Button>
							<Button onClick={handleLogout}>Logout</Button>
						</>
					) : (
						<>
							{!locationAllowed ? (
								<>
									<Button
										onClick={() => {
											window.location.reload()
										}}
									>
										Reload
									</Button>
									<Button onClick={handleLogout}>Logout</Button>
								</>
							) : (
								<Button onClick={handleGoToNext} disabled={!approved}>
									All done, let me in!
								</Button>
							)}
						</>
					)}
				</Box>
			</Box>
		</CustomDialog>
	)
}
