import { useEffect, useState } from 'react'
import useLoadingMask from 'hooks/useLoadingMask'
import useAxios from 'hooks/useAxios'
import { getProposalVotersService } from 'services/daoProposal.service'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const useVotersList = daoProposalId => {
	const [loading, setLoading] = useState(false)
	const [loadMore, setLoadMore] = useState(true)
	const [page, setPage] = useState(0)
	const [totalVotes, setTotalVotes] = useState(0)
	const [items, setItems] = useState([])
	const { daoProposalUpdated } = useSelector(state => state.dashboard)
	const { showMask, hideMask } = useLoadingMask()
	const { axiosService } = useAxios()

	const fetchItems = (page, replace = false) => {
		showMask()
		setLoading(true)
		axiosService(
			getProposalVotersService({ page, daoProposalId }),
			(data, error) => {
				if (!error) {
					setTotalVotes(data?.count)
					setLoadMore(data?.count > items.length + data?.rows?.length)
					replace
						? setItems(data?.rows ?? [])
						: setItems([...items, ...data?.rows])
				}
				setLoading(false)
				hideMask()
			}
		)
	}

	const handleLoadMore = () => {
		setPage(p => p + 1)
	}

	useEffect(() => {
		if (!_.isNil(daoProposalUpdated)) {
			fetchItems(page, true)
		}
	}, [daoProposalUpdated])

	useEffect(() => {
		fetchItems(page)
	}, [page])

	useEffect(() => {
		setPage(0)
	}, [])
	return {
		items,
		loading,
		loadMore,
		totalVotes,
		handleLoadMore
	}
}

export default useVotersList
