import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { registerService } from 'services/auth.service'
import {
	setAuthenticated,
	setIsIngXRestrictedInvestor,
	setJoinDialogOpen,
	setLoginDialogOpen
} from 'component/header/signInSlice'
import useNotification from 'hooks/useNotification'
import { isValidEmail } from 'utils/form.util'
import useHeader from '../useHeader'
import magic from 'utils/blockchain/config/constants'
import { magicSignUpService } from 'services/profile.service'
import useLoadingMask from 'hooks/useLoadingMask'
import useAxios from 'hooks/useAxios'
import { addObjectToLocalStorage, isMetamask, isMobile } from 'utils/utils'
import { getSocialNetworkId } from 'utils/socialShare.util'
import useWeb3 from 'hooks/useWeb3'
import useCommon from 'hooks/useCommon'

const useJoinDialog = () => {
	const {
		joinDialogOpen: open,
		joinDialogEmail,
		socialJoinParams
	} = useSelector(state => state.signIn)
	const { captchaToken } = useSelector(state => state.captchaCheck)

	const initialData = {
		email: joinDialogEmail,
		password: '',
		firstName: '',
		lastName: '',
		tokenSaleDocsAgreed: true
	}

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { pathname, search } = useLocation()
	const [data, setData] = useState(initialData)
	const [newsletter, setNewsletter] = useState(false)
	const [passwordtoggle, setPasswordtoggle] = useState(true)
	const [errors, setErrors] = useState({})
	const { loadUserTierInfo, claimUserRewards } = useHeader()
	const { showNotification } = useNotification()
	const { showMask, hideMask } = useLoadingMask()
	const { isAuthenticatedReload } = useCommon()
	const { axiosService } = useAxios()
	const { openConnectWalletModal, connectors, connectAsync } = useWeb3()

	const fullUrlPath = `${pathname}${search}`
	const { projectShortUrl, userShortUrl, network } = socialJoinParams

	const socialNetworkId = getSocialNetworkId(network)

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const clearForm = () => {
		setData(initialData)
		setNewsletter(false)
		setPasswordtoggle(true)
		setErrors({})
	}

	const authOrRegisterMagicUser = async email => {
		try {
			if (isAuthenticatedReload()) {
				return
			}
			dispatch(setJoinDialogOpen(false))
			const iToken = await magic.auth.loginWithEmailOTP({ email })
			registerUser(iToken)
		} catch (error) {
			console.log({ error })
		}
	}

	const magicSignUp = iToken => {
		showMask()
		axiosService(magicSignUpService({ iToken }), (data, error) => {
			if (!error) {
				clearForm()
				dispatch(setAuthenticated(data))
				const isIngXRestrictedInvestor =
					!_.isNil(data?.isIngXInvestor) && data?.isIngXInvestor
				dispatch(setIsIngXRestrictedInvestor(isIngXRestrictedInvestor))
				addObjectToLocalStorage({
					...data,
					isAuthenticated: true,
					isIngXRestrictedInvestor
				})
				loadUserTierInfo()
				claimUserRewards(data?.id)
			}
			hideMask()
		})
	}

	const displayName = `${data.firstName} ${data.lastName}`

	const registerUser = iToken => {
		showMask()
		const afterRegister = (_response, error, responseError) => {
			if (!error) {
				addObjectToLocalStorage({ fullUrlPath })
				navigate('/')
				magicSignUp(iToken)
			} else {
				if (_.get(responseError, ['response', 'status']) === 409)
					setErrors({ email: 'This email is alreay in use' })
				hideMask()
				dispatch(setJoinDialogOpen(true))
			}
		}
		axiosService(
			registerService({
				...data,
				password: data?.email,
				displayName,
				userShortUrl,
				socialNetworkId,
				projectShortUrl,
				captchaToken,
				iToken
			}),
			afterRegister
		)
	}

	const isValid = () => {
		let isValid = true
		const errorsTmp = {}
		const message = 'This field is required'
		if (_.isNil(data.firstName) || data.firstName.trim() === '') {
			errorsTmp.firstName = message
			isValid = false
		}
		if (_.isNil(data.lastName) || data.lastName.trim() === '') {
			errorsTmp.lastName = message
			isValid = false
		}
		if (_.isNil(data.email) || data.email.trim() === '') {
			errorsTmp.email = message
			isValid = false
		}
		if (!_.isNil(data.email) && !isValidEmail(data.email)) {
			errorsTmp.email = 'The email format is incorrect.'
			isValid = false
		}
		updateErrors(errorsTmp)
		return isValid
	}

	const handleSubmit = () => {
		if (isValid()) authOrRegisterMagicUser(data?.email)
		else
			showNotification({
				message: 'Please fix the form errors to save changes',
				type: 'error'
			})
	}

	const passwordShow = () => {
		setPasswordtoggle(!passwordtoggle)
	}

	const handleClose = () => {
		dispatch(setJoinDialogOpen(false))
		clearForm()
	}

	const handleGoToLogin = () => {
		dispatch(setLoginDialogOpen(true))
		handleClose()
	}

	const handleChange = payload => {
		const key = Object.keys(payload)[0]
		if (key === 'email') payload[key] = _.trim(payload[key])
		updateErrors({ [key]: false })
		setData({
			...data,
			...payload
		})
	}

	const handleMMLogin = () => {
		if (isMetamask() || !isMobile()) {
			const injectedConnector = connectors.find(
				connector => connector.id === 'injected'
			)
			if (!_.isNil(injectedConnector)) {
				connectAsync({ connector: injectedConnector })
			}
		}
		handleClose()
	}

	const handleNcLogin = () => () => {
		openConnectWalletModal()
		handleClose()
	}

	useEffect(() => {
		setData(el => ({ ...el, email: joinDialogEmail }))
	}, [joinDialogEmail])

	return {
		data,
		open,
		errors,
		newsletter,
		captchaToken,
		passwordtoggle,
		handleGoToLogin,
		handleNcLogin,
		handleMMLogin,
		setNewsletter,
		passwordShow,
		handleSubmit,
		handleChange,
		handleClose
	}
}

export default useJoinDialog
