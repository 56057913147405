export const getNotificationsService = params => ({
	method: 'get',
	url: '/notification',
	params
})

export const getUnvisiteNotificationCountService = params => ({
	method: 'get',
	url: '/notification/unvisiteCount',
	params
})
