import { Box, Icon, Typography, useMediaQuery } from '@mui/material'
import { CircularProgressBar } from 'component/common/circularProgressBar'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styles from './Stage.module.scss'

const Stage = ({ ringImage, centerImage, name, color, progress, ...props }) => {
	const [isHover, setIsHover] = useState(false)
	const isMobileScreen = useMediaQuery('(max-width:420px)')

	const handleMouseEnter = () => {
		setIsHover(true)
	}
	const handleMouseLeave = () => {
		setIsHover(false)
	}

	const progressHeight = isMobileScreen ? 60 : 80

	return (
		<Box
			className={styles.stage_container}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			{...props}
		>
			{progress >= 100 && (
				<span>
					<Icon baseClassName="fas" className="fa-check" />
				</span>
			)}
			<Box
				className={styles.stage}
				style={{
					boxShadow: isHover ? `0 0 0.9rem 0.4rem ${color}` : 'none'
				}}
			>
				<CircularProgressBar
					{...{
						height: progressHeight,
						lineWidth: 8,
						lineColor: color,
						background: '#121212',
						lineEmptyColor: '#121212',
						time: 0.5,
						value: progress
					}}
				/>
				<img src={ringImage} className={styles.ring_image} />
				<img src={centerImage} className={styles.center_image} />
			</Box>
			<Typography className={styles.text}>{name}</Typography>
		</Box>
	)
}

Stage.propTypes = {
	ringImage: PropTypes.string,
	centerImage: PropTypes.string,
	name: PropTypes.string,
	color: PropTypes.string,
	progress: PropTypes.number
}

export default Stage
