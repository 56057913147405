import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Dashboard } from './Dashboard'
import { Newsreel } from './Dashboard/Newsreel/Newsreel'

export const TabsDashboard = () => {
	return (
		<Tabs defaultValue="Dashboard" className="w-full">
			<TabsList className="mb-2 sm:hidden">
				<TabsTrigger value="Dashboard" className="sm:hidden">
					Dashboard
				</TabsTrigger>
				<TabsTrigger value="Newsreel" className="sm:hidden">
					Newsreel
				</TabsTrigger>
				<TabsTrigger className="hidden" value="Learn">
					Learn
				</TabsTrigger>
			</TabsList>
			<TabsContent value="Dashboard">
				<Dashboard />
			</TabsContent>
			<TabsContent value="Newsreel">
				<Newsreel />
			</TabsContent>
		</Tabs>
	)
}
