import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	showVestingDialog: false
}

export const vestingSlice = createSlice({
	name: 'vesting',
	initialState,
	reducers: {
		setDialogOpen: (state, { payload }) => {
			state.showVestingDialog = payload
		}
	}
})

export const { setDialogOpen } = vestingSlice.actions

export default vestingSlice.reducer
