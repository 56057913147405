import { Box, Button, Typography } from '@mui/material'
import { CustomDialog, TextFieldControl } from 'component/common'
import { useAirdropCheckDialog } from './useAirdropCheckDialog'
import styles from './AirdropCheckDialog.module.scss'
import _ from 'lodash'

export const AirdropCheckDialog = () => {
	const {
		open,
		email,
		message,
		existUser,
		handleKeypress,
		handleVerify,
		handleGoBack,
		handleClose,
		setEmail
	} = useAirdropCheckDialog()

	return (
		<CustomDialog {...{ open, handleClose }}>
			<Box className={styles.header}>
				Be a Part of the World’s First Filmmaking DAO.
				<span>
					Let&apos;s make <span>great entertainment together.</span>
				</span>
			</Box>
			{!message ? (
				<Box className={styles.content}>
					<TextFieldControl
						hiddenLabel
						fullWidth
						name="username"
						placeholder="Email"
						value={email}
						onKeyPress={handleKeypress}
						onChange={event => {
							setEmail(_.trim(event.target.value))
						}}
					/>
					<Button onClick={handleVerify}>Check</Button>
				</Box>
			) : (
				<>
					{!existUser && (
						<Typography className={styles.green}>
							Congrats! Looks like your on the list!
						</Typography>
					)}
					<Typography className={styles.message}>{message}</Typography>
				</>
			)}
			{!message && (
				<Box className={styles.actions}>
					<Button onClick={handleVerify} className={styles.check_btn}>
						Check
					</Button>
					<Button onClick={handleGoBack} className={styles.back}>
						Go Back
					</Button>
				</Box>
			)}
		</CustomDialog>
	)
}
