import _ from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useAxiosFetch from 'hooks/useAxiosFetch'
import {
	resetProject,
	setIsOwnerSignedIn,
	setProjectId,
	setRolePermissions,
	updateProject
} from './projectDetailSlice'
import useNotification from 'hooks/useNotification'
import {
	followUnfollowProject,
	followUnfollowUser
} from 'services/followUnfollow.service'
import {
	getProjectById,
	getProjectBySlugService,
	editProjectService
} from 'services/project.service'
import { useEffect } from 'react'
import useSuperfan from './dashboardTab/superfan/useSuperfan'
import useHeader from 'component/header/useHeader'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getProjectCollaboratorRolePermissions } from 'services/collaboratorRole.service'
import { setLoginDialogOpen } from 'component/header/signInSlice'

const useProjectDetail = (draft = false) => {
	const dispatch = useDispatch()
	const {
		project,
		isOwnerSignedIn,
		rolePermissions,
		projectId,
		tabIndex,
		reload
	} = useSelector(state => state.projectDetail)
	const { user: signInUser, isAuthenticated } = useSelector(
		state => state.signIn
	)
	const setSearchParams = useSearchParams()[1]
	const { showNotification } = useNotification()
	const { updateSuperfanLevel } = useSuperfan(false)
	const { loadUserTierInfo } = useHeader()
	const { axiosService } = useAxios()
	const { hideMask, showMask } = useLoadingMask()
	const navigate = useNavigate()

	const [{ loading }, fetchProject] = useAxiosFetch(
		getProjectById({
			id: projectId,
			isUpdate: false,
			draft,
			checkViews: true
		}),
		afterFetchProject
	)

	const getProjectBySlug = slug => {
		showMask()
		axiosService(getProjectBySlugService(slug), (result, error) => {
			if (!error) {
				dispatch(setProjectId(result?.id))
			} else navigate('/notfound')
			hideMask()
		})
	}

	const getPermissions = () => {
		if (!isAuthenticated) {
			return
		}
		showMask()
		axiosService(
			getProjectCollaboratorRolePermissions({ projectId }),
			async (data, error) => {
				if (!error) {
					dispatch(setRolePermissions(data))
				}
				hideMask()
			}
		)
	}

	function afterFetchProject(data, error) {
		if (!error) {
			const isCreatorSignedIn = _.get(data, ['user', 'id']) === signInUser?.id
			const isCreatorOrAdmin =
				isCreatorSignedIn || Number(signInUser?.isAdmin) === 1
			if (data?.stageId !== 4 && !isCreatorOrAdmin) {
				navigate('/explore')
				return
			}
			dispatch(updateProject(data))
			dispatch(setIsOwnerSignedIn(isCreatorSignedIn))
		} else {
			navigate('/explore')
		}
	}

	const updateProjectDb = (extra = {}) => {
		axiosService(
			editProjectService({
				...project,
				...extra
			}),
			afterSaveChanges
		)
	}

	function afterSaveChanges(data, error) {
		if (!error) {
			window.location.reload(false)
		}
	}

	const handleFollowUnfollowUserClick = async () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}
		const followingUser = project?.user?.isFollowedByUser
		const userId = project?.user?.id
		const res = await followUnfollowUser(userId, followingUser)
		if (res.status === 200) {
			const username = _.get(project, 'user.displayName', 'this creator')
			const message = `You are ${
				followingUser ? 'no longer' : 'now'
			} following ${username}`
			showNotification({ message })
			dispatch(
				updateProject({
					user: {
						...project?.user,
						isFollowedByUser: !followingUser
					}
				})
			)
		}
	}

	const handleFollowUnfollowProjectClick = async () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}
		const followingProject = project.isFollowedByUser
		const res = await followUnfollowProject(projectId, followingProject)
		if (res.status === 200) {
			dispatch(
				updateProject({
					isFollowedByUser: !followingProject,
					likes: followingProject ? project.likes - 1 : project.likes + 1
				})
			)
			loadUserTierInfo()
			updateSuperfanLevel()
		}
	}

	const handleSocialClick = tab => () => {
		setSearchParams({ tab })
	}

	const handleCancelApproval = () => {
		updateProjectDb({ stageId: 1 })
	}

	const haveEditPermission = () =>
		rolePermissions.some(el => el?.permissionId === 2)

	const handleResetProject = () => {
		dispatch(resetProject())
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			fetchProject()
			getPermissions()
		}
	}, [projectId, reload])

	useEffect(() => {
		loading ? showMask() : hideMask()
	}, [loading])

	return {
		project,
		loading,
		tabIndex,
		signInUser,
		fetchProject,
		isOwnerSignedIn,
		haveEditPermission,
		handleFollowUnfollowProjectClick,
		handleFollowUnfollowUserClick,
		handleCancelApproval,
		handleResetProject,
		handleSocialClick,
		getProjectBySlug
	}
}

export default useProjectDetail
