import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	fanLevel: {}
}

export const superfanSlice = createSlice({
	name: 'projectDetail',
	initialState,
	reducers: {
		reset: () => initialState,
		setFanLevel: (state, { payload }) => {
			state.fanLevel = {
				...state.fanLevel,
				...payload
			}
		}
	}
})

export const { reset, setFanLevel } = superfanSlice.actions

export default superfanSlice.reducer
