import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow
} from '@/components/ui/table'
import { useEffect, useState } from 'react'
import {
	Pagination,
	PaginationContent,
	PaginationFirst,
	PaginationItem,
	PaginationLast,
	PaginationNext,
	PaginationPrevious
} from '@/components/ui/pagination'
import { Button } from '@/components/ui/button'
import { FiUserPlus } from 'react-icons/fi'
import { FiUserMinus } from 'react-icons/fi'
import { IoEyeOutline } from 'react-icons/io5'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { TbSquareRotated } from 'react-icons/tb'
import { PiArrowFatLineUpLight } from 'react-icons/pi'
import { formatDate } from 'utils/date.utils'

import { getJoinStatsBySharerId } from 'services/socialJoin.service'
import { useNavigate } from 'react-router-dom'
import { followUnfollowUser } from 'services/followUnfollow.service'
import useNotification from 'hooks/useNotification'

function stringAvatar(name, wh = '2.2rem') {
	if (!name) name = '?'
	const splitedName = name.split(' ').map(el => el?.toUpperCase())
	return {
		sx: {
			bgcolor: '#000000',
			border: '1px solid #ffffff',
			height: wh,
			width: wh,
			marginRight: '12px'
		},
		children: `${_.get(splitedName, [0, 0], '')}${_.get(
			splitedName,
			[1, 0],
			''
		)}`
	}
}
export function TableReferral() {
	const { axiosService } = useAxios()
	const navigate = useNavigate()

	const { showNotification } = useNotification()
	const { showMask, hideMask } = useLoadingMask()
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const [data, setData] = useState([])

	const afterFetchProjects = (response, error) => {
		if (!error) {
			setTotalPages(Math.ceil(response?.count / 5))
			setData(response?.rows)
		}
		hideMask()
	}

	const getProjects = page => {
		showMask()

		axiosService(
			getJoinStatsBySharerId({
				limit: 5,
				offset: 5 * page - 5
			}),
			afterFetchProjects
		)
	}

	const handlePageChange = page => {
		setCurrentPage(page)
	}

	const handleViewProfile = row => {
		navigate(`/profile/${row?.user?.username}`)
	}

	const handleFollowUnfollowUserClick = async user => {
		const res = await followUnfollowUser(user?.id, user?.following)
		if (res.status === 200) {
			const message = `You are ${
				user?.following ? 'no longer' : 'now'
			} following ${user?.displayName}`

			setData(
				data.map(item => {
					if (item?.user?.id === user?.id) {
						return {
							...item,
							user: {
								...item.user,
								following: !user?.following
							}
						}
					}
					return item
				})
			)
			showNotification({ message })
		}
	}

	useEffect(() => {
		getProjects(1)
	}, [])

	useEffect(() => {
		getProjects(currentPage)
	}, [currentPage])

	if (!data.length) {
		return null
	}

	return (
		<div className="p-4">
			<Table className="w-full px-4 text-left text-sm">
				<TableHeader>
					<TableRow>
						<TableHead className="p-4">NAME</TableHead>
						<TableHead className="p-4">JOIN DATE</TableHead>
						<TableHead className="p-4">GEMs</TableHead>
						<TableHead className="p-4">XP</TableHead>
						<TableHead className="p-4"></TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{data.map((row, index) => (
						<TableRow key={index} className="border-b border-gray-700">
							<TableCell className="w-40 min-w-40 p-4">
								<div className="flex flex-row justify-between gap-4">
									{row?.user?.avatar ? (
										<img
											className="rounded-full"
											src={row?.user?.avatar}
											alt="avatar"
											width={24}
											height={24}
										/>
									) : (
										<span className="flex !h-6 w-6 !min-w-6 items-center justify-center rounded-full bg-white text-xs font-bold text-background">
											{stringAvatar(row?.user?.displayName).children}
										</span>
									)}
									<span className="flex w-full justify-start">
										{row?.user?.displayName}
									</span>
								</div>
							</TableCell>
							<TableCell className="p-4">
								{formatDate(row?.createdAt, 'full')}
							</TableCell>
							<TableCell className="p-4">
								<div className="flex min-w-5 flex-row items-center justify-center gap-1">
									<TbSquareRotated />
									{row.gems}
								</div>
							</TableCell>
							<TableCell className="p-4">
								<div className="flex min-w-5 flex-row items-center justify-center gap-1">
									<PiArrowFatLineUpLight />
									{row.xp}
								</div>
							</TableCell>
							<TableCell className="p-4">
								<div className="flex space-x-2">
									<Button
										variant="table"
										className="h-9 w-10 p-2"
										onClick={() => handleFollowUnfollowUserClick(row?.user)}
									>
										{row?.user?.following ? <FiUserMinus /> : <FiUserPlus />}
									</Button>
									<Button
										variant="table"
										className="h-9 w-10 p-2"
										onClick={() => handleViewProfile(row)}
									>
										<IoEyeOutline />
									</Button>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div className="flex items-center justify-between p-4">
				<Pagination>
					<PaginationContent>
						<PaginationItem>
							<PaginationPrevious
								onClick={() =>
									handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
								}
								className={currentPage == 1 && 'opacity-50'}
							/>
							<PaginationFirst
								onClick={() => handlePageChange(currentPage > 1 ? 1 : 1)}
								className={currentPage == 1 && 'opacity-50'}
							/>
						</PaginationItem>
						<PaginationItem>
							Page {currentPage} of {totalPages}
						</PaginationItem>

						<PaginationItem>
							<PaginationLast
								onClick={() =>
									handlePageChange(
										currentPage < totalPages ? totalPages : totalPages
									)
								}
								disabled={currentPage === totalPages}
								className={currentPage == totalPages && 'opacity-50'}
							/>
							<PaginationNext
								onClick={() =>
									handlePageChange(
										currentPage < totalPages ? currentPage + 1 : totalPages
									)
								}
								disabled={currentPage === totalPages}
								className={currentPage == totalPages && 'opacity-50'}
							/>
						</PaginationItem>
					</PaginationContent>
				</Pagination>
			</div>
		</div>
	)
}
