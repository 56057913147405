import { Box, Button, Dialog, Typography } from '@mui/material'
import fraudTopImg from 'assets/images/fraud_top_img.svg'
import styles from './FraudWarningDialog.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setFraudWarningDialogOpen } from '../signInSlice'

export default function FraudWarningDialog() {
	const dispatch = useDispatch()
	const { fraudWarningDialogOpen: open } = useSelector(state => state.signIn)

	const handleClose = () => {
		dispatch(setFraudWarningDialogOpen(false))
	}

	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={styles.fraud_dialog}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
		>
			<img src={fraudTopImg.src} />
			<Box className={styles.content}>
				<Typography component={'h1'}>Fraud Detection</Typography>
				<Typography>
					{' '}
					Our system enforces certain minimum requirements to combat fraud and
					spam on the platform. Unfortunately you failed to pass our fraud
					prevention system.
				</Typography>
				<Typography>
					As a result you won’t be awarded a welcome bonus, dao tier upgrades or
					tokens from any contests you may be associated with.
				</Typography>
				<Typography>
					If you believe you were flagged incorrectly, you can{' '}
					<a
						href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
						target="_blank"
						rel="noreferrer"
					>
						submit a support ticket
					</a>{' '}
					and we’ll look into it.
				</Typography>
				<Box className={styles.controls}>
					<Button onClick={handleClose}>Got It!</Button>
				</Box>
			</Box>
			<Box className={styles.detail_dialog}></Box>
		</Dialog>
	)
}
