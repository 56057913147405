import PropTypes from 'prop-types'
import { Box, Icon, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import styles from './PageIndicator.module.scss'

const PageIndicator = ({ value, title }) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Number(searchParams.get('page'))

	const active = page === value
	// const completed = page > value
	const inactive = page !== value
	const handleGoTopage = () => {
		searchParams.set('page', value)
		setSearchParams(searchParams)
	}

	return (
		<Box className={styles.page_indicator} onClick={handleGoTopage}>
			{inactive && (
				<Box className={styles.circleInactive}>
					<Icon baseClassName="fas" className="fa-circle" />
				</Box>
			)}
			{active && (
				<Box className={styles.circle}>
					<Icon baseClassName="fas" className="fa-circle" />
				</Box>
			)}
			<Typography className={active ? styles.active : ''}>{title}</Typography>
		</Box>
	)
}

PageIndicator.propTypes = {
	value: PropTypes.number,
	title: PropTypes.string
}

export default PageIndicator
