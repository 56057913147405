// components/shared/ReactionButton.tsx
import { Button } from '@/components/ui/button'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { Reaction } from '../../types'
import REACTIONS from '../../../../../../../../assets/images/reactions'

interface ReactionButtonProps {
	reaction: Reaction
	onClick?: () => void
	size?: 'sm' | 'lg' | 'default' | 'icon' | undefined
	className?: string
}

export const ReactionButton = ({
	reaction,
	onClick,
	size = 'sm' as const,
	className
}: ReactionButtonProps) => {
	const buttonSizes = {
		sm: 'h-8 px-2',
		md: 'h-9 px-3',
		lg: 'h-10 px-4',
		default: 'h-9 px-3',
		icon: 'h-8 px-2'
	} as const

	const imgSizes = {
		default: 'h-5 w-5',
		icon: 'h-4 w-4',
		md: 'h-5 w-5',
		lg: 'h-6 w-6',
		sm: 'h-4 w-4'
	} as const

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						variant="ghost"
						size={size}
						onClick={onClick}
						className={cn(
							buttonSizes[size],
							'flex w-14 items-center gap-1.5 rounded-full',
							{
								'bg-blue-500 text-background': reaction.highlight,
								'text-gray-400': !reaction.highlight,
								'hover:text-blue-400': !reaction.highlight,
								'hover:bg-blue-400/80': true
							},
							className
						)}
					>
						<img
							src={REACTIONS[reaction.icon]}
							alt={reaction.name}
							className={imgSizes[size]}
						/>
						<span className="min-w-[1ch]">{reaction.count}</span>
					</Button>
				</TooltipTrigger>
				<TooltipContent>{reaction.name}</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
