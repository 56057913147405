import { Box, Typography, Container, IconButton, Icon } from '@mui/material'
import styles from './NotFound.module.scss'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
	const navigate = useNavigate()

	const handleNewsreelClick = () => {
		navigate('/explore')
	}

	return (
		<Container disableGutters maxWidth="md" className={styles.notfound_detail}>
			<Box className={styles.notfound}>
				<Box className={styles.notfound_icon}>
					<Icon baseClassName="far" className="fa-magnifying-glass" />
					<Icon baseClassName="fad" className="fa-browser" />
				</Box>
				<Typography component="h2">
					This content isn&lsquo;t available right now
				</Typography>
				<Typography component="p">
					When this happens, it&lsquo;s usually because the owner only shared it
					with a small group of people, changed who can see it or it&lsquo;s
					been deleted.
				</Typography>
				<IconButton
					className={styles.newsreelButton}
					onClick={handleNewsreelClick}
				>
					Go To The Explore Page
				</IconButton>
			</Box>
		</Container>
	)
}

export default NotFound
