import { Card, CardContent } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { LuBadgeCheck } from 'react-icons/lu'
import BgCard from 'assets/images/fanDashboard/bg-profile.png'
import Image from 'next/image'
import useUserCard from './useUserCard'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import DaoTiers from './DaoTiers/DaoTiers'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '@/components/ui/select'
import { useKyc } from '../../hooks/useKyc'
import { formatDate } from '../../../../utils/date.utils'
import { UserKingIcon } from '../../sidebar'

export const UserCard = () => {
	const { user, tierName, signInUser, stringAvatar } = useUserCard()
	const { avatar, displayName, isVerified, createdAt } = signInUser
	const { showKyc } = useKyc()
	const profileHeaderImg = user.headerImg || BgCard.src

	return (
		<Card className="relative w-full border-0 bg-profile-gradient">
			<Image
				src={profileHeaderImg}
				alt="Picture of the author"
				layout="fill"
				objectFit="cover"
				className="-z-10 h-full w-full rounded-xl"
			/>
			<div className="absolute inset-0 bg-black opacity-30" ></div>
			<CardContent className="px-4 py-4 sm:px-5 sm:py-6">
				<div className="flex flex-wrap items-center justify-between gap-x-24 gap-y-8 xl:flex-nowrap">
					<div className="flex flex-col justify-start gap-4 sm:h-[124px] md:flex-row md:items-center">
						<div className="relative flex w-full items-center pl-3">
							<Avatar className="relative z-10 mr-3 flex items-center justify-center max-[639px]:h-[86px] max-[639px]:w-[86px] sm:h-[124px] sm:w-[124px]">
								{avatar ? (
									<AvatarImage src={avatar} alt="avatar" />
								) : (
									<AvatarFallback className="absolute inset-0 flex items-center justify-center font-bold text-icons max-[639px]:text-4xl sm:text-5xl">
										{stringAvatar(displayName).children}
									</AvatarFallback>
								)}
							</Avatar>

							<div className="w-full">
								<div className="flex items-center justify-start max-[639px]:w-full max-[639px]:flex-wrap max-[639px]:gap-2 sm:h-9 sm:gap-4">
									<div className="flex items-center justify-start gap-2 text-nowrap text-3xl font-semibold leading-5 tracking-[-0.02em] text-icons max-[639px]:order-1 max-[639px]:flex-[100%] max-[639px]:text-xl">
										{displayName}
									</div>
									<Label
										htmlFor="name"
										className="text-base font-normal leading-7 tracking-[0.15px] text-icons max-[639px]:order-2 max-[639px]:flex-[100%] max-[639px]:text-sm sm:hidden"
									>
										Welcome back {displayName?.split(' ')[0]}!
									</Label>
									<Select className="max-w-32 max-[639px]:order-4 max-[639px]:flex-auto">
										<SelectTrigger className="hidden w-max items-center justify-center gap-2 border-0 bg-background text-[#faf4f5] focus:border-0 focus:ring-0 focus-visible:border-0 max-[639px]:order-4">
											<SelectValue placeholder="Fan" />
										</SelectTrigger>
										<SelectContent className="bg-background">
											<SelectItem value="light" className="bg-background">
												<div className="flex w-max items-center justify-start gap-2">
													<UserKingIcon
														width={16}
														height={16}
														alt="user king"
													/>
													<span className="text-sm font-normal text-[#faf4f5]">
														Fan
													</span>
												</div>
											</SelectItem>
											<SelectItem value="dark">
												<div className="flex w-max items-center justify-start gap-2">
													<UserKingIcon
														width={16}
														height={16}
														alt="king user"
													/>
													<span className="text-sm font-normal text-[#faf4f5]">
														Creator
													</span>
												</div>
											</SelectItem>
										</SelectContent>
									</Select>

									{isVerified == 0 && (
										<Button
											className="flex h-8 items-center justify-center gap-2 rounded-md bg-primary text-xs text-background focus-visible:border-0 focus-visible:outline-none max-[639px]:order-3 sm:w-[101px]"
											onClick={showKyc}
										>
											<LuBadgeCheck width={16} height={16} />
											<span>Verify</span>
										</Button>
									)}
									{isVerified == 1 && (
										<Button className="flex h-8 w-10 items-center justify-center gap-2 rounded-md bg-primary p-0 text-xs text-background focus-visible:border-0 focus-visible:outline-none max-[639px]:order-3 max-[639px]:flex-1 sm:w-[101px]">
											<LuBadgeCheck width={16} height={16} />
											<span>Verified</span>
										</Button>
									)}
								</div>

								<div className="flex flex-col justify-start">
									<Label
										htmlFor="name"
										className="text-base font-normal leading-7 tracking-[0.15px] text-icons max-[639px]:hidden"
									>
										Welcome back {displayName?.split(' ')[0]}!
									</Label>
									<p className="flex h-4 items-center justify-start text-sm font-medium leading-[14px] text-icons opacity-50 max-[639px]:hidden">
										Joined {formatDate(createdAt)} | {tierName} DAO Member
									</p>
								</div>
							</div>
						</div>
						<p className="flex h-4 pl-3 text-sm font-medium leading-[14px] text-icons opacity-50 sm:hidden">
							Joined {formatDate(createdAt)} | {tierName} DAO Member
						</p>
					</div>
					<div className="flex w-full items-center justify-center rounded-lg bg-card-background80 py-8 xl:max-w-lg">
						<DaoTiers />
					</div>
				</div>
			</CardContent>
		</Card>
	)
}
