import { Box, Grid, Typography } from '@mui/material'
import { ReviewItem } from './reviewItem'
import WriteReview from './writeReview/WriteReview'
import useReviewsTab from './useReviewsTab'
import { RecentActivitySection } from '../dashboardTab/recentActivity/RecentActivity'
import InfiniteScroll from 'react-infinite-scroll-component'
import images from 'assets/images'
import styles from './ReviewsTab.module.scss'

const ReviewsTab = () => {
	const {
		showWriteReview,
		reviews,
		isAuthenticated,
		handleOpenLoginModal,
		onScroll
	} = useReviewsTab()

	return (
		<InfiniteScroll {...{ onScroll, dataLength: 10000000 }} scrollableTarget="scroll-provider">
			<Grid container className={styles.reviewRatting} spacing={2}>
				<Grid item md={7} className="border-top">
					{isAuthenticated ? (
						<WriteReview hide={!showWriteReview} />
					) : (
						<Box className={styles.message_box} onClick={handleOpenLoginModal}>
							<img src={images.newsreelEmpty} />
							<Typography component={'h1'}>
								{'Click to leave a review on this project'}
							</Typography>
						</Box>
					)}
					<div className="projectinfo">
						{reviews?.map(item => (
							<ReviewItem {...item} key={item.id} />
						))}
					</div>
				</Grid>
				<Grid item md={5}>
					<Grid item md={12} className={styles.badges_achievements}>
						<RecentActivitySection />
					</Grid>
				</Grid>
			</Grid>
		</InfiniteScroll>
	)
}

export default ReviewsTab
