import { isNil } from 'lodash'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { authTokenSaleUserService } from 'services/auth.service'
import { addObjectToLocalStorage } from 'utils/utils'
import useLoadingMask from 'hooks/useLoadingMask'
import { getTokenSaleCampaignById } from 'services/tokenSaleCampaign.service'
import useAxios from 'hooks/useAxios'
import { useDispatch, useSelector } from 'react-redux'
import {
	setAuthenticated,
	setNcWalletAddress,
	setSaleEmailDialogOpen,
	setSaleUserId,
	setIsIngXRestrictedInvestor
} from 'component/header/signInSlice'
import useNotification from 'hooks/useNotification'
import { magicSignInService } from 'services/profile.service'
import { Mixpanel } from 'services/mixpanel.service'
import useHeader from 'component/header/useHeader'
import magic from 'utils/blockchain/config/constants'

const useTokenSaleJoin = () => {
	const navigate = useNavigate()
	const { ncWalletAddress } = useSelector(state => state.signIn)
	const { captchaToken } = useSelector(state => state.captchaCheck)
	const { id } = useParams()
	const { hideMask, showMask } = useLoadingMask()
	const { axiosService } = useAxios()
	const dispatch = useDispatch()
	const { showNotification } = useNotification()
	const { loadUserTierInfo, handleLogout } = useHeader()

	const currentURL = window.location.href
	const metaMaskButtonUrl = currentURL
		.replace('https://', '')
		.replace('http://', '')

	const getTokenSaleCampaign = () => {
		showMask()
		axiosService(getTokenSaleCampaignById(id), (data, error) => {
			if (!error) {
				if (!isNil(data?.userId))
					showNotification({
						message:
							'This code has already been used. To continue, please head to the login page and reconnect your wallet.',
						buttonText: 'Go To Login',
						buttonCallback: () => {
							navigate('/tokenSale')
						}
					})
			} else {
				navigate('/tokenSale')
			}
			hideMask()
		})
	}

	const authOrRegisterMagicUser = async email => {
		hideMask()
		try {
			const iToken = await magic.auth.loginWithEmailOTP({ email })
			await magicSignUp(iToken)
		} catch (error) {
			console.log({ error })
			hideMask()
		}
	}

	const magicSignUp = async iToken => {
		showMask()
		axiosService(
			magicSignInService({ iToken, captchaToken }),
			(data, error) => {
				if (!error) {
					const isIngXRestrictedInvestor =
						!isNil(data?.isIngXInvestor) && data?.isIngXInvestor
					dispatch(setIsIngXRestrictedInvestor(isIngXRestrictedInvestor))
					addObjectToLocalStorage({
						...data,
						isAuthenticated: true,
						isIngXRestrictedInvestor
					})
					dispatch(setAuthenticated(data))
					registerMixpanelEvent(data)
					loadUserTierInfo()
				}
				hideMask()
			}
		)
	}

	const registerMixpanelEvent = data => {
		Mixpanel.identify(data?.id)
		Mixpanel.people.set({
			displayName: data?.displayName,
			id: data?.id,
			username: data?.username,
			isDaoTermsAgreed: data?.isDaoTermsAgreed,
			isAirdropCreator: data?.isAirdropCreator,
			isAdmin: data?.isAdmin
		})
		Mixpanel.track('user_login_success_evt', {
			distinct_id: data?.id,
			displayName: data?.displayName,
			id: data?.id,
			username: data?.username,
			isDaoTermsAgreed: data?.isDaoTermsAgreed,
			isAirdropCreator: data?.isAirdropCreator,
			isAdmin: data?.isAdmin,
			projectCount: data?.projectCount,
			balance: data?.balance
		})
	}

	const authTokenSaleUser = data => {
		showMask()
		axiosService(authTokenSaleUserService(data), (response, error) => {
			const appAccess = +response?.appAccess === 1
			if (!error) {
				if (!appAccess && !response?.isMagicUser) {
					dispatch(setSaleUserId(response?.id))
					dispatch(setSaleEmailDialogOpen(true))
				} else {
					authOrRegisterMagicUser(response?.email)
				}
			} else {
				handleLogout()
			}
			if (!appAccess) navigate('/tokenSale')
			hideMask()
		})
	}

	useEffect(() => {
		const event = new Event('startWidget')
		window.dispatchEvent(event)

		const handler = event => {
			if (event?.data?.sender === 'presale-widget-connected') {
				const { address, hash, signature } = event?.data
				const walletData = {
					address,
					hash,
					signature
				}
				dispatch(setNcWalletAddress(walletData))
				addObjectToLocalStorage(walletData)
			}
		}
		window.addEventListener('message', handler)
		return () => {
			window.removeEventListener('message', handler)
		}
	}, [id])

	useEffect(() => {
		if (!isNil(id)) getTokenSaleCampaign()
	}, [id])

	useEffect(() => {
		if (!isNil(ncWalletAddress?.address))
			authTokenSaleUser({ ...ncWalletAddress, tokenSaleCampaignId: id ?? '0' })
	}, [ncWalletAddress])

	return { metaMaskButtonUrl, isLogin: isNil(id) }
}

export default useTokenSaleJoin
