import { TabsDashboard } from './main-content/tabsDashboard'
import { UserCard } from './main-content/userCard'

export const FanDashboard = () => {
	return (
		<div className="space-y-6 p-2 md:p-6 mt-6 md:mt-0">
			<UserCard />
			<TabsDashboard />
		</div>
	)
}
