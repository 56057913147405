import { Box, Button } from '@mui/material'
import { useCallback } from 'react'
import { CustomDialog } from 'component/common'
import { useDispatch, useSelector } from 'react-redux'
import { setDialogOpen } from '../vestingSlice'
import styles from './VestingDialog.module.scss'

export const VestingDialog = () => {
	const { showVestingDialog } = useSelector(state => state.vesting)
	const dispatch = useDispatch()

	const handleClose = useCallback(() => {
		dispatch(setDialogOpen(false))
	})

	return (
		<CustomDialog {...{ open: showVestingDialog, handleClose }}>
			<Box className={styles.header}>
				<h3>When will I receive the rest of my Fan Tokens?</h3>
				<p>
					Your remaining FAN token disbursements will commence as soon as FAN
					tokens are listed on an exchange and will be distributed to you in
					monthly intervals.
				</p>
				<p>
					You can see your vesting token amount, available token amount, and
					total tokens on the previous wallet page summary. As soon as we have a
					definitive date, we will update this section to show your exact
					release schedule.
				</p>
				<p>
					If you have any questions or believe there to be an error, please
					don&rsquo;t hesitate to{' '}
					<a
						target="_blank"
						rel="noreferrer"
						href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
						title="Submit a ticket to our support portal"
					>
						submit a ticket
					</a>{' '}
					and one of our support staff will be happy to assist you!
				</p>
			</Box>
			<Box className={styles.content}>
				<Button onClick={handleClose}>Got It!</Button>
			</Box>
		</CustomDialog>
	)
}
