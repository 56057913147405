import { getTransactionFee } from '../config/utils'
import { getContractValue, sendContractBlockchainTransaction } from '../magic'

class FilmioProjectContract {
	static contractName = 'filmioProject'

	// Write Functions
	static async createLock(projectId, transactionNote, signer = null) {
		const projectAlreadyExists = await FilmioProjectContract.doesProjectExist(
			projectId
		)

		if (projectAlreadyExists) {
			return null
		}

		return await sendContractBlockchainTransaction(
			FilmioProjectContract.contractName,
			'createLock',
			[projectId],
			transactionNote,
			signer
		)
	}

	static async getFeesCreateLock(
		projectId, signer
	) {
		return await getTransactionFee(
			signer,
			FilmioProjectContract.contractName,
			'createLock',
			[projectId]
		)
	}

	static async addRating(
		projectId,
		rating,
		reviewGiven,
		transactionNote,
		signer = null
	) {
		return await sendContractBlockchainTransaction(
			FilmioProjectContract.contractName,
			'createRating',
			[projectId, rating, reviewGiven],
			transactionNote,
			signer
		)
	}

	static async getFeesAddRating(
		projectId,
		rating,
		reviewGiven,
		signer
	) {
		return await getTransactionFee(
			signer,
			FilmioProjectContract.contractName,
			'createRating',
			[projectId, rating, reviewGiven],
		)
	}

	// Read Functions
	static async doesProjectExist(projectId) {
		return await getContractValue(
			FilmioProjectContract.contractName,
			'doesProjectExist',
			[projectId]
		)
	}
}

export default FilmioProjectContract
