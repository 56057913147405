'use client'

import { setBronzeTierData } from 'component/header/signInSlice'
import useAxiosFetch from 'hooks/useAxiosFetch'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	calculateUserDaoGlobalProgress,
	getUserFullTiersMetricsService
} from 'services/daoTier.service'
import userflow from 'userflow.js'

const useDaoTiers = () => {
	const [data, setData] = useState({ tiers: [], userMetrics: {} })
	const [globalProgress, setGlobalProgress] = useState(0)
	const { userId, updateDaoTiers } = useSelector(state => state.profile)
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const dispatch = useDispatch()

	const [{ loading }, getUserDaoTiers] = useAxiosFetch(
		getUserFullTiersMetricsService(),
		(response, error) => {
			if (!error) {
				const tiers = response.tiers.map(tier => {
					const metrics = tier.metrics.reduce((acc, curr) => {
						acc[curr.name] = curr.threshold
						return acc
					}, {})
					return {
						id: tier?.id,
						image: tier?.image,
						title: tier?.name,
						benefits: tier?.benefits,
						date: tier?.tierUser?.achievedDate,
						completed: tier?.tierUser?.completed === 1,
						confirmed: tier?.tierUser?.confirmed === 1,
						metrics
					}
				})

				const {
					projectsReviewed,
					stakesTotal,
					projectsFollowed,
					refers,
					isVerified
				} = response

				const userMetrics = {
					Review: projectsReviewed,
					Stake: stakesTotal,
					Follow: projectsFollowed,
					Refer: refers,
					'Is User Verified': isVerified,
					'Setup Platform Wallet':
						user?.isEmailVerified === 1 && !_.isNil(user?.walletAddress) ? 1 : 0
				}
				setData({ tiers, userMetrics })
				const bronzeData = tiers.find(el => el?.title === 'Bronze')
				dispatch(setBronzeTierData({ ...bronzeData, userMetrics }))
			}
		}
	)

	const [{ loading: loadingProgress }, calculateProgress] = useAxiosFetch(
		calculateUserDaoGlobalProgress(),
		(data, error) => {
			if (!error) {
				setGlobalProgress(data.progress)
			}
		},
		!isAuthenticated
	)

	const showDAO = () => {
		userflow.start('a7869d6f-e196-4637-85c0-81fef0968c29')
	}
	useEffect(() => {
	
		if (!_.isNil(user?.id)) {
			getUserDaoTiers()
			calculateProgress()
		}
	}, [user, updateDaoTiers])

	return {
		...data,
		loading,
		loadingProgress,
		getUserDaoTiers,
		globalProgress,
		showDAO
	}
}

export default useDaoTiers
