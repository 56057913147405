import { useDispatch } from 'react-redux'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getHomeProjectsService } from 'services/home.service'
import { checkGoScoreV2Service } from 'services/goscore.service'
import { setProjects, setIsGoScoreV2Enabled } from './homeSlice'
import { Mixpanel } from 'services/mixpanel.service'

const useHome = () => {
	const dispatch = useDispatch()

	const afterFetch = data => {
		Mixpanel.track('homepage_visited_evt', {
			distinct_id: localStorage.getItem('id')
		})
		if (!data?.error) dispatch(setProjects(data))
	}

	const afterFetchGoScore = data => {
		if (!data?.error) {
			localStorage.setItem('isGoScoreV2Enabled', data?.isV2Enabled)
			dispatch(setIsGoScoreV2Enabled(data?.isV2Enabled))
		}
	}

	const [{ loadingHome }] = useAxiosFetch(getHomeProjectsService(), afterFetch)
	const [{ loadingCheckGoScore }] = useAxiosFetch(
		checkGoScoreV2Service(),
		afterFetchGoScore
	)

	return {
		loading: loadingHome || loadingCheckGoScore
	}
}

export default useHome
