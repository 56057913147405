import { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardFooter } from '@/components/ui/card'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
	DropdownMenuItem
} from '@/components/ui/dropdown-menu'
import { FaChevronDown } from 'react-icons/fa' // Import the icon for the dropdown
import { FiLoader } from 'react-icons/fi'
import { getFileUrl } from 'utils/url.util'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getCheckoutProjectsService } from 'services/dashboard.service'
import {
	Tooltip,
	TooltipTrigger,
	TooltipContent,
	TooltipProvider
} from '@/components/ui/tooltip'
import { useNavigate } from 'react-router-dom'

const SORT_OPTIONS = [
	{ name: 'Trending', id: 'trending' },
	{ name: 'Newest', id: 'createdAt' },
	{ name: 'Most Active', id: 'createdAt' },
	{ name: 'Most Stakes', id: 'most_stakers' }
]

export function ProjectsToCheckout() {
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const [isExpanded, setIsExpanded] = useState(false)
	const [sort, setSort] = useState(SORT_OPTIONS[0])
	const navigate = useNavigate()
	const [currentPage, setCurrentPage] = useState(1)
	const [data, setData] = useState([])

	const afterFetchProjects = (response, error) => {
		if (!error) {
			setData([...response])
		}
		hideMask()
	}

	const getProjects = () => {
		showMask()

		axiosService(
			getCheckoutProjectsService({
				limit: 5 * currentPage,
				offset: 0,
				sort: sort.id
			}),
			afterFetchProjects
		)
	}

	useEffect(() => {
		getProjects()
	}, [])

	useEffect(() => {
		getProjects()
	}, [currentPage])

	useEffect(() => {
		setCurrentPage(1)
		getProjects()
	}, [sort])

	const toggleExpand = () => {
		setCurrentPage(currentPage + 1)
		setIsExpanded(true)
	}

	const redirectToProject = slug => {
		navigate(`/project/${slug}`)
	}

	const redirectProjects = () => {
		navigate(`/explore`)
	}
	return (
		<div className="bg-background80 p-4 text-white">
			<div className="mb-4 flex items-center justify-between gap-3 text-nowrap">
				<h2 className="text-lg font-semibold text-white">
					Projects To Checkout
				</h2>
				<div className="flex w-full items-center justify-end space-x-4 sm:justify-between px-0">
					<Button
						onClick={redirectProjects}
						variant="secondary"
						size="sm"
						className="max-[639px]:hidden bg-[#09090B]"
					>
						View All
					</Button>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button
								variant="ghost"
								size="sm"
								className="flex items-center space-x-1 max-[639px]:!m-0 max-[639px]:p-0"
							>
								<span>{sort?.name}</span>
								<FaChevronDown />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent className="bg-background text-white" align='end'>
							{SORT_OPTIONS.map((ele, index) => {
								return (
									<DropdownMenuItem key={index} onClick={() => setSort(ele)} className="hover:bg-[#000]">
										{ele?.name}
									</DropdownMenuItem>
								)
							})}
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</div>

			<div
				className={`custom-scrollbar mb-4 flex flex-wrap items-start justify-start gap-3 transition-all duration-300 sm:gap-6 ${
					isExpanded
						? 'max-h-[350px] overflow-y-auto'
						: 'max-h-[163px] overflow-y-hidden'
				}`}
			>
				{data.map((ele, index) => (
					<Card
						key={index}
						className="border-0 bg-transparent p-0 text-txt transition-all duration-75"
					>
						<CardContent className="w-fit overflow-hidden rounded-md p-0">
							<img
								alt="Product image"
								className="aspect-square h-32 w-[84px] cursor-pointer object-cover transition-all duration-300 ease-in-out hover:scale-[1.1]"
								src={getFileUrl(ele?.image)}
								onClick={() => {
									redirectToProject(ele.slug)
								}}
							/>
						</CardContent>
						<CardFooter className="w-fit p-0 pt-2 text-txt">
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger asChild>
										<p className="!max-w-[84px] truncate text-nowrap text-center align-middle text-sm font-medium sm:w-[84px]">
											{ele?.name}
										</p>
									</TooltipTrigger>
									<TooltipContent className="bg-background text-muted">
										<span>{ele?.name}</span>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</CardFooter>
					</Card>
				))}
			</div>

			<div className="flex justify-center">
				<Button variant="secondary" className="bg-[#09090B]" size="sm" onClick={toggleExpand}>
					{'More'}{' '}
					<span className="ml-2">
						<FiLoader />
					</span>
				</Button>
			</div>
		</div>
	)
}
