import { Box, Button, Icon, IconButton, Typography } from '@mui/material'
import { ProjectItem } from 'component/dashboard/projectItem'
import { TileItem } from 'component/dashboard/tileItem'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import {
	SnapList,
	SnapItem,
	useScroll,
	useDragToScroll,
	useVisibleElements
} from 'react-snaplist-carousel'
import { UserItem } from 'component/dashboard/userItem'
import { useDispatch } from 'react-redux'
import { DaoProposalItem } from 'component/dashboard/daoProposalItem'
import { setSort, updateFilters } from 'component/dashboard/dashboardSlice'
import userflow from 'userflow.js'
import styles from './ExploreCarrousel.module.scss'
import useCommon from 'hooks/useCommon'

const ExploreCarrousel = ({ title, type, elements, showViewAll = true }) => {
	const dispatch = useDispatch()
	const snapList = useRef(null)
	const { checkNoAuthToShowJoin } = useCommon()
	const itemsCount = 4
	const components = {
		tile: TileItem,
		newest: ProjectItem,
		favorites: ProjectItem,
		trending: ProjectItem,
		recent: ProjectItem,
		user: UserItem,
		daoProposal: DaoProposalItem
	}

	const showExplanation = () => {
		switch (type) {
			case 'tile':
				userflow.start('3e957195-1f63-48fd-827f-3ca1fd52aabe')
				break
			case 'daoProposal':
				userflow.start('baf88175-18f5-40a9-9d1f-0f2061062c7b')
				break
			case 'favorites':
				userflow.start('c45812c5-f921-46cc-9361-5559f91d0663')
				break
			case 'trending':
				userflow.start('30e9df1c-09ae-455b-b843-09bfb4b22b29')
				break
			case 'newest':
				userflow.start('fa209ce8-6d1d-4409-9608-3525487cdaf6')
				break
			case 'recent':
				userflow.start('73e31da0-7d07-458c-a421-d343c8decb3a')
				break
			case 'user':
				userflow.start('a1f4a00a-5d9f-423b-9290-cb8948654ec8')
				break
		}
	}

	const visible = useVisibleElements(
		{ debounce: 4, ref: snapList },
		([element]) => element
	)
	const goToSnapItem = useScroll({ ref: snapList })
	useDragToScroll({ ref: snapList, disabled: false })

	const handleNextClick = () => {
		goToSnapItem(visible + itemsCount)
	}

	const handlePrevClick = () => {
		goToSnapItem(visible > itemsCount ? visible - itemsCount : 0)
	}

	const renderChildren = () =>
		elements.map(el => {
			const Component = components[type]
			return (
				<SnapItem className={styles.slide} snapAlign="center" key={el?.id}>
					<Component {...el} />
				</SnapItem>
			)
		})

	const handleViewAll = () => {
		if (checkNoAuthToShowJoin()) {
			switch (type) {
				case 'tile':
					dispatch(updateFilters({ types: ['tile'] }))
					break
				case 'favorites':
					dispatch(updateFilters({ types: ['favorites_projects'] }))
					break
				case 'trending':
					dispatch(updateFilters({ types: ['project'] }))
					dispatch(setSort('trending'))
					break
				case 'newest':
					dispatch(updateFilters({ types: ['project'] }))
					dispatch(setSort('newest'))
					break
			}
		}
	}

	return (
		<>
			{elements.length > 0 && (
				<Box className={styles.explore_section}>
					<Box className={styles.header}>
						<Box>
							<Typography>{title}</Typography>
							<span onClick={showExplanation}>
								<Icon baseClassName="fas" className="fa-brain-circuit" />
							</span>
						</Box>
						{showViewAll && <Button onClick={handleViewAll}>View All</Button>}
					</Box>
					<Box className={styles.carrousel}>
						<IconButton
							size="small"
							onClick={handlePrevClick}
							className={visible > 0 ? styles.prev : styles.prev_hidden}
						>
							<Icon baseClassName="fas" className="fa-chevron-left" />
						</IconButton>

						<SnapList className={styles.carrousel_item} ref={snapList}>
							{renderChildren()}
						</SnapList>

						<IconButton
							size="small"
							onClick={handleNextClick}
							className={
								visible < elements.length - itemsCount
									? styles.next
									: styles.next_hidden
							}
						>
							<Icon baseClassName="fas" className="fa-chevron-right" />
						</IconButton>
					</Box>
				</Box>
			)}
		</>
	)
}

ExploreCarrousel.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
	elements: PropTypes.array,
	showViewAll: PropTypes.bool
}

export default ExploreCarrousel
