export const addStakeService = data => ({
	method: 'post',
	url: '/stake',
	data
})

export const addBlockchainStakeService = data => ({
	method: 'post',
	url: '/stake/blockchain',
	data
})

export const getProjectStakeService = (projectId, params, autoexec = true) => ({
	method: 'get',
	url: `/stake/${projectId}`,
	params,
	autoexec
})

export const getUserStakeLimitService = projectId => ({
	method: 'get',
	url: `/stake/userLimit/${projectId}`
})

export const getUserStakeTotalService = projectId => ({
	method: 'get',
	url: `/stake/total/${projectId}`,
	autoexec: true
})

export const unstakeService = data => ({
	method: 'post',
	url: '/stake/unstake',
	data
})

export const unstakeBlockchainService = data => ({
	method: 'post',
	url: '/stake/blockchain/unstake',
	data
})
