import axios from 'axios'
import { notificationTypes } from 'component/common'
import useLoadingMask from 'hooks/useLoadingMask'
import useNotification from 'hooks/useNotification'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

const useAxios = () => {
	const { showNotification } = useNotification()
	const { hideMask } = useLoadingMask()
	const navigate = useNavigate()

	const axiosService = (config, callback) => {
		axios(config)
			.then(response => {
				if (response.status === 200) {
					callback && callback(response.data, false)
				} else {
					const message = _.get(response, 'data.message', null)
					if (message)
						showNotification({
							message,
							title: _.get(response, 'data.title', null),
							type: notificationTypes.ERROR
						})
					hideMask()
				}
			})
			.catch(error => {
				if (error?.response?.status === 404) {
					navigate('/notfound')
					hideMask()
					return
				}
				const message = _.get(error, 'response.data.message', null)
				callback && callback(null, error)
				if (message)
					showNotification({
						message,
						title: _.get(error, 'response.data.title', null),
						type: notificationTypes.ERROR
					})
			})
	}

	return { axiosService }
}

export default useAxios
